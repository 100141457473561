import { Box, Button, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Joi from 'joi';
import _ from 'lodash';
import {
  Field,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import {
  FieldFactory,
  ReduxField,
  stopProp,
  transformLaravelErrors,
  useRecord,
  useSpecificFormValues,
  validateUsingRules,
} from '@/admin';
import AttachmentsCard from '@/components/Attachments/AttachmentsCard';
import AttachmentsList from '@/components/Attachments/AttachmentsList';
import DesignColors from '@/components/Designs/DesignColors';
import { useConfig } from '@/contexts/AppConfigContext';
import { isScreenPrint } from '@/helpers';
import { Customer, Design, Order } from '@/models';
import { maybeAddQueryParams } from '@/utils/query';

function SectionHeader({
  title,
  subtitle,
  first,
}: {
  title: string;
  subtitle: string;
  first?: boolean;
}) {
  return (
    <Box mb={2} mt={first ? 0 : 3}>
      <Typography variant="subtitle1">
        <span style={{ fontWeight: 500 }}>{title}</span>
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {subtitle}
      </Typography>
    </Box>
  );
}

interface RequestedDesignFormProps {
  design: Design;
  orderDesignId: number;
  onSave: () => void;
}

function RequestedDesignForm({
  design,
  handleSubmit,
  onSave,
  form,
  orderDesignId,
}: InjectedFormProps<Partial<Design>, RequestedDesignFormProps> & RequestedDesignFormProps) {
  const { customer_id: customerId } = useRecord<Order>();
  const decorationType = useSpecificFormValues(form, (d: Partial<Design>) => d.decoration_type);
  const { decorationLocations } = useConfig();
  const decorationUnit = decorationType?.unit;
  const decorationTypeName = decorationType?.name;

  const { data: customer } = useQuery(
    ['customer', customerId],
    () => axios.get<Customer>(`/api/customers/${customerId}`).then(({ data }) => data),
    {
      enabled: customerId != null,
      staleTime: 5 * 60 * 1000,
    },
  );

  const onSubmit: FormSubmitHandler<Partial<Design>, RequestedDesignFormProps> = (values) =>
    axios
      .put(
        `/api/order-designs/${orderDesignId}/design`,
        _.pick(values, [
          'name',
          'decoration_type',
          'request_notes',
          'location',
          'complexity',
          'special_design_type',
          'colors',
        ]),
      )
      .then(() => {
        onSave();
      })
      .catch((e) => {
        if (e.response.status === 422) {
          throw new SubmissionError(transformLaravelErrors(e.response.data.errors));
        }
        throw e;
      });

  let locationNames: string[] = [];
  if (decorationType && decorationType.name in decorationLocations) {
    locationNames = decorationLocations[decorationType.name].map((l) => l.name);
  }

  return (
    <form onSubmit={stopProp(handleSubmit)(onSubmit)}>
      <SectionHeader
        title="Required Details"
        subtitle="Enter the basic details of the design you are requesting."
        first
      />

      <Box mb={1}>
        <ReduxField field={FieldFactory.text('name').setRequired()} />
      </Box>

      <Box mb={1}>
        <ReduxField
          field={FieldFactory.belongsTo('decoration_type', 'decorationTypes').setRequired()}
        />
      </Box>

      <Box mb={1}>
        <ReduxField
          field={
            locationNames.length > 0
              ? FieldFactory.select('location', locationNames)
              : FieldFactory.text('location')
          }
        />
      </Box>

      {isScreenPrint(decorationUnit) && (
        <div>
          <SectionHeader
            title="Colors"
            subtitle="Optionally enter the colors in your design so you can apply ink changes."
          />
          <Field
            name="colors"
            component={DesignColors}
            decorationUnit="colors"
            decorationTypeName={decorationTypeName}
            customer={customer}
            freeSolo={false}
          />
        </div>
      )}

      <SectionHeader
        title="Optional Details"
        subtitle="Enter other design details to assist with pricing."
      />

      {decorationUnit && (
        <Box mb={1}>
          <ReduxField
            field={FieldFactory.number('complexity').withLabel(
              `# of ${_.startCase(decorationUnit)}`,
            )}
          />
        </Box>
      )}

      <Box mb={1}>
        <ReduxField field={FieldFactory.belongsTo('special_design_type', 'specialDesignTypes')} />
      </Box>

      <SectionHeader
        title="Notes"
        subtitle="Please describe what you are asking the artist to create."
      />
      <ReduxField field={FieldFactory.textarea('request_notes').withLabel('Notes')} />

      <SectionHeader
        title="Attachments"
        subtitle="Upload logos, artwork, or examples here. Vector files preferred. Logos from the customer's brand files are automatically included."
      />

      {customer?.brand_files && (
        <AttachmentsList
          attachments={customer.brand_files.map((br) => ({
            id: br.id,
            file: br.file,
            name: br.name,
            image: br.image,
            url: br.file,
            download_url: maybeAddQueryParams(br.file, { dl: 1 }) as string,
            group: 'brand',
            size: null,
            created_at: br.created_at,
          }))}
        />
      )}

      {design && (
        <AttachmentsCard
          resourceId={design.id}
          resource="designs"
          group="request"
          title={null}
          elevation={0}
        />
      )}

      <Box mt={3}>
        <Button type="submit" variant="contained">
          Save
        </Button>
      </Box>
    </form>
  );
}

export default reduxForm<Partial<Design>, RequestedDesignFormProps>({
  form: 'RequestedDesignForm',
  validate: (v) =>
    validateUsingRules(
      v,
      Joi.object({
        location: Joi.any().required(),
        name: Joi.any().required(),
        decoration_type: Joi.any().required(),
      }).unknown(),
    ),
})(RequestedDesignForm);
