import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '@/admin';
import {
  useAddNotification,
  useInfiniteNotifications,
  useMarkRead,
  useMarkUnread,
} from '@/api/notifications';
import { useConfig } from '@/contexts/AppConfigContext';
import { Notification } from '@/models';

const NotificationContext = createContext<{
  notifications: Notification[];
  getNotifications: () => void;
  unreadCount: number;
  unreadOnly?: boolean;
  onToggleUnreadOnly: () => void;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onMarkRead: (ids: number[]) => void;
  onMarkUnread: (ids: number[]) => void;
}>({
  notifications: [],
  getNotifications: () => {},
  unreadCount: 0,
  unreadOnly: false,
  onToggleUnreadOnly: () => {},
  query: '',
  setQuery: () => {},
  loading: false,
  onMarkRead: () => {},
  onMarkUnread: () => {},
});

export function useNotificationContext() {
  return useContext(NotificationContext);
}

export default function NotificationProvider({ children }: { children: ReactNode }) {
  const { user } = useConfig();
  const [unreadOnly, setUnreadOnly] = useState(false);
  const [query, setQuery] = useState<string>('');
  const deferredQuery = useDebounce(query, 500);

  const apiParams = useMemo(
    () => ({ query: deferredQuery, unread_only: unreadOnly }),
    [deferredQuery, unreadOnly],
  );
  const markRead = useMarkRead(apiParams);
  const markUnread = useMarkUnread(apiParams);
  const onAddNewNotification = useAddNotification(apiParams);
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteNotifications({
    query: deferredQuery,
    unread_only: unreadOnly,
  });
  const notifications = data ? data.pages.flatMap((p) => p.data) : [];
  const unreadCount = data?.pages.at(-1)?.meta.unread_count ?? 0;

  useEffect(() => {
    const channel = window.Echo.private(`users.${user.id}`);

    channel.listen('NotificationCreated', (e: { notification: Notification }) => {
      onAddNewNotification(e.notification);
    });

    return () => {
      channel.stopListening('NotificationCreated');
    };
  }, [user.id, onAddNewNotification]);

  const notificationId = useSearchParams()[0].get('notification_id');
  useEffect(() => {
    // Waiting until we have loaded the initial notifications or there will be no notification to mark read
    if (notificationId && notifications.length > 0) {
      markRead.mutate([Number(notificationId)]);
    }
  }, [notificationId, notifications.length]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        getNotifications: hasNextPage ? fetchNextPage : () => {},
        unreadCount,
        unreadOnly,
        onToggleUnreadOnly: () => setUnreadOnly((prev) => !prev),
        query,
        setQuery,
        loading: isLoading,
        onMarkRead: markRead.mutate,
        onMarkUnread: markUnread.mutate,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
