import { HTMLInputTypeAttribute, ReactNode } from 'react';
import { InputAdornment, TextField as MaterialTextField } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { FieldProps } from '../../types';
import Field from '../Field';

export function TextInput({ input, field, meta }: FieldProps<TextInputField>) {
  const InputProps = field.InputProps || {};
  if (field.prefix) {
    InputProps.startAdornment = <InputAdornment position="start">{field.prefix}</InputAdornment>;
  }
  if (field.postfix) {
    InputProps.endAdornment = <InputAdornment position="end">{field.postfix}</InputAdornment>;
  }

  return (
    <MaterialTextField
      {...input}
      {...field.getTextFieldProps(meta)}
      type={field.type}
      InputProps={InputProps}
    />
  );
}

export default class TextInputField extends Field {
  prefix?: ReactNode;
  postfix?: ReactNode;
  type: HTMLInputTypeAttribute;

  constructor(name: string, type: HTMLInputTypeAttribute = 'text') {
    super(name);
    this.type = type;
  }

  renderEditComponent(props: WrappedFieldProps) {
    return <TextInput {...props} field={this} />;
  }
}
