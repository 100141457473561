import { Card } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from '@/admin';
import ProductDetails from '@/components/Products/ProductDetails';
import ProductSearchGrid from '@/components/Products/ProductSearchGrid';

export default function ProductSearch() {
  useTitle('Product Search');

  const navigate = useNavigate();
  const { id: productId } = useParams();
  const { data: selectedProduct } = useQuery(
    ['product', productId],
    () =>
      axios
        .get(`/api/products/${productId}?with=vendor,brand,variants,tags,images`)
        .then(({ data }) => data),
    { enabled: !!productId },
  );

  return (
    <Card>
      {productId ? (
        <ProductDetails
          product={selectedProduct}
          onProductChange={(p) => navigate(`/product-search/${p.id}`)}
          onBack={() =>
            navigate('/product-search', {
              replace: true,
            })
          }
        />
      ) : (
        <ProductSearchGrid onSelect={(p) => navigate(`/product-search/${p.id}`)} />
      )}
    </Card>
  );
}
