import { useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import { App as AdminApp, NavItem, useToast } from '@/admin';
import Logo from '@/assets/logo.svg?react';
import AdditionalMenuItems from '@/components/AppDrawer/AdditionalMenuItems';
import UserActions from '@/components/AppDrawer/UserActions';
import NotificationProvider from '@/components/Notifications/NotificationProvider';
import ScheduleDrawer from '@/components/Production/ScheduleDrawer';
import ImpersonatingBanner from '@/components/Shared/ImpersonatingBanner';
import { AppConfigProvider } from '@/contexts/AppConfigContext';
import { SchedulingProvider } from '@/contexts/SchedulingContext';
import '@/helpers';
import pages, { direct } from '@/pages';
import resources from '@/resources';
import { AppConfig, FileUpload } from '@/responses';

function redirectToLogin(params = {}) {
  const qs = new URLSearchParams(params);
  qs.append('redirect_to', window.location.href);
  window.location.href = `/login?${qs.toString()}`;
}

export const onUpload = async (
  files: FileList | File[],
  type = 'file',
  progress?: (p: number) => void,
) => {
  const promises = [...files].map(async (file) => {
    const { data } = await axios.post<FileUpload>('/api/files', {
      extension: file.name.split('.').pop(),
      content_type: file.type,
      type,
    });

    await axios.put(data.signed_url, file, {
      headers: _.omit(data.headers, ['Host']),
      onUploadProgress: (progressEvent) => {
        if (progress && progressEvent.total != null) {
          progress(progressEvent.loaded / progressEvent.total);
        }
      },
    });

    return {
      name: data.name,
      file: data.file,
      url: data.url,
      image: data.url,
    };
  });

  return Promise.all(promises);
};

function BootstrapAxios() {
  const toast = useToast();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        const { data, headers } = response;
        if (data.message && Object.keys(data).length < 3) {
          toast(data.message);
        } else if (headers['x-toast-message']) {
          toast(headers['x-toast-message']);
        }
        return response;
      },
      (error: AxiosError) => {
        if (error.status === 401) {
          redirectToLogin();
          return;
        }
        const message = _.get(error, 'response.data.message');
        const errors = _.get(error, 'response.data.errors', {});
        const errorText = _.map(errors, (v) => v[0]).join(', ');

        if (message) {
          toast(errorText || message, 'error');
        }
        throw error;
      },
    );
  }, [toast]);

  return null;
}

export default function AuthenticatedApp({
  config,
  businessId,
  onBusinessChange,
}: {
  config: AppConfig;
  businessId: number | null;
  onBusinessChange: (newId: number | null) => void;
}) {
  const filteredPages: NavItem[] = businessId
    ? pages
        .map((p) => {
          if ('children' in p) {
            return {
              ...p,
              children: p.children.filter((c) => !c.isGlobal),
            };
          }
          return p;
        })
        .filter((p) => !p.isGlobal)
    : pages;

  return (
    <AppConfigProvider value={config}>
      <NotificationProvider>
        <SchedulingProvider>
          <ImpersonatingBanner />
          <AdminApp
            title="Avail ERP"
            logo={
              <Logo
                style={{
                  width: '100%',
                  height: 40,
                  color: 'white',
                  margin: '10px 0',
                }}
              />
            }
            pages={filteredPages}
            routes={direct}
            drawerChildren={
              <AdditionalMenuItems businessId={businessId} onBusinessChange={onBusinessChange} />
            }
            userActions={<UserActions />}
            config={{
              resources: _.mapValues(resources, (v) => v(config)),
              onUpload,
              user: config.user,
              roles: config.roles,
              permissions: config.permissions,
              logoutHref: import.meta.env.VITE_BASE_URL + 'api/logout',
              getRecordsFromResponse: (res) => res.data.data,
              getMetaFromResponse: (res) => res.data.meta,
              getTotalFromResponse: (res) => res.data.meta.total,
            }}
          >
            <ScheduleDrawer />
            <BootstrapAxios />
          </AdminApp>
        </SchedulingProvider>
      </NotificationProvider>
    </AppConfigProvider>
  );
}
