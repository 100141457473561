import { ReactNode } from 'react';
import Field from '../models/Field';

export default function renderCell(
  field: Field,
  value: any,
  row: Record<string, any> = {},
  fallback: ReactNode = null,
) {
  if (field.customRenderCellFunc) {
    return field.customRenderCellFunc(value, row);
  }

  if (value == null || value === '') {
    return fallback;
  }

  return field.renderCell(value, row);
}
