import { ChangeEvent, useEffect, useState } from 'react';
import { Button, TableBody, TableCell, Table, TableHead, TableRow, Checkbox } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ClosableDrawer, FieldFactory, NonReduxField, numString } from '@/admin';
import { OrderWithItems } from '@/models';

export default function OrderKitAddItemsDrawer({
  orderId,
  open,
  onClose,
}: {
  orderId: number;
  open: boolean;
  onClose: () => void;
}) {
  const [selected, setSelected] = useState<number[]>([]);
  const [order, setOrder] = useState<OrderWithItems>();
  const queryClient = useQueryClient();

  useEffect(() => {
    setSelected([]);
    setOrder(undefined);
  }, [open]);

  const onUpdated = () => {
    queryClient.invalidateQueries([]);
  };

  const onChange = (newOrder: any) => {
    setOrder(newOrder as OrderWithItems);
    setSelected([]);
  };

  const handleCheck = (id: number) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected((prev) => [...prev, id]);
    } else {
      setSelected((prev) => prev.filter((p) => p !== id));
    }
  };

  const onSubmit = () => {
    axios.post(`/api/orders/${orderId}/kit-items`, { order_item_ids: selected }).then(() => {
      onUpdated();
      onClose();
    });
  };

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Add Items from Other Orders">
      <NonReduxField
        value={order}
        onChange={onChange}
        field={FieldFactory.belongsTo('order', 'orders').withRequestParams({
          'filter[id][not]': orderId,
          with: 'items',
        })}
      />

      {order && order.items && (
        <Table size="small" sx={{ mt: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>Number</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Qty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items.map((item) => (
              <TableRow key={item.id}>
                <TableCell padding="checkbox">
                  <Checkbox checked={selected.includes(item.id)} onChange={handleCheck(item.id)} />
                </TableCell>
                <TableCell>{item.number}</TableCell>
                <TableCell>{item.color}</TableCell>
                <TableCell>{item.size}</TableCell>
                <TableCell>{item.qty}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Button
        sx={{ mt: 2 }}
        type="button"
        variant="contained"
        disabled={selected.length === 0}
        onClick={onSubmit}
      >
        Add {numString(selected.length, 'Items')}
      </Button>
    </ClosableDrawer>
  );
}
