import { useMemo } from 'react';
import { DataTable, Resource, FieldProps, FieldFactory, DataTableState, wrap } from '@/admin';
import { PAYMENT_METHODS } from '@/constants';

export default function DepositPaymentSelector({ input }: FieldProps) {
  const selected = wrap(input.value) as { id: number }[];

  const resource = useMemo(
    () =>
      new Resource('Payments')
        .withQueryParams({
          index: {
            undeposited: 'true',
            'filter[payment_type]': 'payment',
          },
          single: {},
        })
        .withColumns([
          FieldFactory.date('payment_date').filterable().sortable(),
          FieldFactory.belongsTo('customer', 'customers').filterable(),
          FieldFactory.text('payment_number').sortable(),
          FieldFactory.curr('amount').sortable(),
          FieldFactory.select('method', PAYMENT_METHODS).filterable(),
        ]),
    [],
  );

  const onStateUpdated = (s: DataTableState) => {
    input.onChange(s.selected.map((id) => ({ id })));
  };

  return (
    <DataTable
      resource={resource}
      onStateUpdated={onStateUpdated}
      initialState={{
        selected: selected.map((s) => s.id),
      }}
    />
  );
}
