import { Dispatch, SetStateAction } from 'react';
import { Add } from '@mui/icons-material';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import {
  Box,
  FormLabel,
  LinearProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { green } from '@mui/material/colors';
import { CardElement } from '@stripe/react-stripe-js';
import { useGetPaymentMethods } from '@/api/paymentMethods';
import { curr } from '@/helpers';
import StripePaymentMethodListItem from './StripePaymentMethodListItem';

export const CASH_BALANCE_ID = 'cash_balance';

export default function ChooseSavedCard({
  customerId,
  selected,
  setSelected,
  allowNew = false,
}: {
  customerId: number;
  selected?: string;
  setSelected: Dispatch<SetStateAction<string | undefined>>;
  allowNew: boolean;
}) {
  const { data, isFetching } = useGetPaymentMethods(customerId);
  const paymentMethods = data?.data;
  const cashBalance = data?.cash_balance.available?.usd;

  const toggleSelected = (id: string) => {
    setSelected((prev) => (prev === id ? undefined : id));
  };

  return (
    <Box my={2}>
      <FormLabel>Choose a Payment Method</FormLabel>

      {isFetching && <LinearProgress />}

      {paymentMethods && paymentMethods.length === 0 && !cashBalance ? (
        <Typography variant="body2" sx={{ mt: 2 }}>
          This customer has no saved payment methods.
        </Typography>
      ) : null}

      {paymentMethods && (
        <List>
          {cashBalance ? (
            <ListItemButton onClick={() => toggleSelected(CASH_BALANCE_ID)}>
              <ListItemAvatar>
                <Avatar>
                  <MoneyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Cash Balance"
                secondary={`Available: ${curr(cashBalance / 100)}`}
              />
              <ListItemSecondaryAction>
                <Radio checked={selected === CASH_BALANCE_ID} />
              </ListItemSecondaryAction>
            </ListItemButton>
          ) : null}

          {paymentMethods.map((m) => (
            <StripePaymentMethodListItem
              method={m}
              key={m.id}
              onSelect={toggleSelected}
              selected={selected}
            />
          ))}

          {allowNew && (
            <ListItemButton onClick={() => toggleSelected('new')}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: green[300] }}>
                  <Add />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="New Card" />
              <ListItemSecondaryAction>
                <Radio checked={selected === 'new'} />
              </ListItemSecondaryAction>
            </ListItemButton>
          )}
        </List>
      )}

      {selected === 'new' && (
        <div>
          <Box my={1} p={2} borderRadius="4px" border={1} borderColor="action.disabled">
            <CardElement />
          </Box>
        </div>
      )}
    </Box>
  );
}
