import _ from 'lodash';
import { Field, FieldArray, WrappedFieldInputProps } from 'redux-form';
import FieldModel from '../../models/Field';
import { Read } from '../../models/Fields/ReadField';
import { FieldArrayProps, FieldProps } from '../../types';

export function GenericField(props: FieldProps) {
  const { field, ...other } = props;

  if (field.readOnly) {
    return <Read {...props} />;
  }
  return field.renderEditComponent(other);
}

export function GenericArrayField(props: FieldArrayProps) {
  const { field, ...other } = props;
  return field.renderArrayComponent(other);
}

export function NonReduxField({
  field,
  value,
  onChange,
  name,
  onBlur = () => null,
}: {
  field: FieldModel;
  value: any;
  onChange: WrappedFieldInputProps['onChange'];
  name?: string;
  onBlur?: WrappedFieldInputProps['onBlur'];
}) {
  return (
    <GenericField
      field={field}
      input={{
        name: name || field.name,
        value,
        onChange,
        onBlur: onBlur,
        onFocus: () => null,
        onDragStart: () => null,
        onDrop: () => null,
      }}
      meta={{
        autofilled: false,
        asyncValidating: false,
        dirty: false,
        form: '',
        invalid: false,
        pristine: false,
        submitting: false,
        submitFailed: false,
        touched: false,
        valid: false,
        visited: false,
        dispatch: (t) => t,
        initial: null,
      }}
    />
  );
}

export default function ReduxField(props: {
  field: FieldModel;
  parentName?: string;
  isCreate?: boolean;
}) {
  const { field, parentName } = props;

  let { name } = field;
  if (parentName) {
    name = _.trim(`${parentName}.${name}`, '.');
  }

  if (field.isArray) {
    return <FieldArray name={name} component={GenericArrayField} field={field} />;
  }

  return <Field name={name} component={GenericField} field={field} />;
}
