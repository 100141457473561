import { ReactNode, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { Can, ReduxField, DrawerFormSection, FieldFactory } from '@/admin';
import { ITEM_CLASSIFICATIONS } from '@/constants';

export default function BehaviorSection({ children }: { children?: ReactNode }) {
  const [showAdvanced, setShowAdvanced] = useState(false);

  return (
    <DrawerFormSection title="Behavior">
      <ReduxField field={FieldFactory.boolean('is_purchasable', 'Purchasable')} />
      <ReduxField field={FieldFactory.boolean('is_customer_supplied', 'Customer Supplied')} />
      <ReduxField field={FieldFactory.boolean('can_apply_designs', 'Can Apply Designs')} />
      <ReduxField field={FieldFactory.boolean('is_shippable', 'Shippable')} />
      <ReduxField field={FieldFactory.boolean('show_on_invoice', 'Show on Invoice')} />
      <Can permission="orders:tax_exempt">
        <ReduxField field={FieldFactory.boolean('is_tax_exempt', 'Tax Exempt')} />
      </Can>

      {showAdvanced && (
        <Stack spacing={3} sx={{ mt: 2 }}>
          <ReduxField field={FieldFactory.select('classification', ITEM_CLASSIFICATIONS)} />
          <ReduxField field={FieldFactory.textarea('note')} />
          <ReduxField field={FieldFactory.image('image')} />
          <ReduxField field={FieldFactory.text('upc').withLabel('UPC')} />
          <ReduxField field={FieldFactory.text('bin')} />
        </Stack>
      )}

      <Button onClick={() => setShowAdvanced((p) => !p)} sx={{ mt: 2 }} size="small">
        {showAdvanced ? 'Hide' : 'Show'} Advanced
      </Button>

      {children}
    </DrawerFormSection>
  );
}
