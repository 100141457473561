import { ReactNode } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { WrappedFieldMetaProps } from 'redux-form';
import Field from '../../models/Field';

export default function InputWrapper({
  meta,
  field,
  children,
  withoutLabel = false,
}: {
  meta: WrappedFieldMetaProps;
  field: Field;
  children: ReactNode;
  withoutLabel?: boolean;
}) {
  const error = !meta.valid && meta.touched && meta.error;
  const helperText = field.getHelperText(meta);
  return (
    <FormControl
      fullWidth={field.fullWidth}
      error={!!error}
      size={field.size}
      margin={field.margin}
      required={field.required}
    >
      {!withoutLabel && field.label && <InputLabel>{field.label}</InputLabel>}
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
