import { FormControlLabel, RadioGroup, Radio, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  FieldFactory,
  numString,
  ReduxField,
  useFormActions,
  useSpecificFormValues,
} from '@/admin';
import { InventoryLevel, InventoryLocation } from '@/models';

export default function MoveInventoryLocation({ level }: { level: InventoryLevel }) {
  const endLocation = useSpecificFormValues(
    'PromptDialog',
    (v: { end_location?: InventoryLocation | null }) => v.end_location,
  );
  const { change } = useFormActions('PromptDialog');
  const setEndLocation = (loc: InventoryLocation | null) => change('end_location', loc);
  const endLocationId = endLocation?.id;

  const { data: levels = [] } = useQuery(['otherLevelsForVariant', level.variant_id], () =>
    axios
      .get<{ data: InventoryLevel[] }>(
        `/api/inventory-levels?filter[variant_id]=${level.variant_id}&count=10`,
      )
      .then(({ data }) => data.data.filter((l) => l.location_id !== level.location_id)),
  );

  const { data: locationPreview } = useQuery(
    ['locationPreview', endLocationId],
    () =>
      axios
        .get<{ data: InventoryLevel[] }>(
          `/api/inventory-levels?filter[location_id]=${endLocationId}`,
        )
        .then(({ data }) => data.data),
    {
      enabled: Boolean(endLocationId),
    },
  );

  let somethingSelected = false;

  return (
    <div>
      <p>
        <span>
          Moving <b>{level.variant.sku}</b> from <b>{level.location.path}</b>.
        </span>
        <span>
          There is currently <b>{level.qty}</b> in this location.
        </span>
      </p>

      {levels.length > 0 && (
        <RadioGroup>
          {levels.map((l) => {
            const isChecked = endLocation?.id === l.location_id;
            if (!somethingSelected && isChecked) {
              somethingSelected = true;
            }
            return (
              <FormControlLabel
                key={l.id}
                control={<Radio checked={isChecked} onClick={() => setEndLocation(l.location)} />}
                label={l.location.path}
              />
            );
          })}

          <FormControlLabel
            control={
              <Radio
                checked={!somethingSelected && endLocation !== undefined}
                onClick={() => setEndLocation(null)}
              />
            }
            label="Other"
          />
        </RadioGroup>
      )}

      <Stack spacing={3}>
        <div>
          {!somethingSelected && (
            <ReduxField
              field={FieldFactory.belongsTo('end_location', 'inventoryLocations').withRequestParams(
                { count: 100 },
              )}
            />
          )}

          {locationPreview && endLocation && (
            <Typography variant="body2">
              <span>
                There is currently {numString(locationPreview.length, 'SKUs')} in{' '}
                <b>{endLocation.path}</b>
              </span>
              {locationPreview.length > 0 && (
                <span>: {locationPreview.map((l) => l.variant.sku).join(', ')}</span>
              )}
            </Typography>
          )}
        </div>

        <ReduxField field={FieldFactory.number('qty')} />
      </Stack>
    </div>
  );
}
