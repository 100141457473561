import {
  CircularProgress,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Radio,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FieldProps, useSpecificFormValues } from '@/admin';
import { INVENTORY_LOCATION_TYPES } from '@/constants';
import { InventoryLevel } from '@/models';
import InventoryLocationLabel from './InventoryLocationLabel';

export default function ChooseLocationForVariant({ input, meta }: FieldProps) {
  const variantId = useSpecificFormValues(meta.form, (s: { variant_id: number }) => s.variant_id);

  if (!variantId) {
    throw new Error('Please initialize variant_id');
  }

  const { data: levels } = useQuery(['variantLevels', variantId], () =>
    axios
      .get<{ data: InventoryLevel[] }>(
        `/api/inventory-levels?filter[variant_id]=${variantId}&with=location&sort=location.sort,location.path`,
      )
      .then(({ data }) => data.data),
  );

  if (!levels) {
    return <CircularProgress />;
  }

  if (levels.length === 0) {
    return (
      <Typography variant="body2" color="textSecondary">
        There are no alternate locations for this SKU.
      </Typography>
    );
  }

  return (
    <Table size="small" style={{ whiteSpace: 'nowrap' }}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>Location</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Qty</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {levels.map((level) => (
          <TableRow key={level.id}>
            <TableCell>
              <Radio
                checked={input.value?.id === level.location.id}
                onClick={() => input.onChange(level.location)}
              />
            </TableCell>
            <TableCell>
              <InventoryLocationLabel location={level.location} />
            </TableCell>
            <TableCell>{INVENTORY_LOCATION_TYPES[level.location.type]}</TableCell>
            <TableCell>{level.qty}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
