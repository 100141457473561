import { CSSProperties } from 'react';
import { Typography } from '@mui/material';
import { StatusChip } from '@/admin';
import { ORDER_STATUS_COLORS, ORDER_STATUS_DETAILS } from '@/constants';

export default function OrderStatus({
  status,
  style = {},
  size,
  withDetails,
}: {
  status: string;
  style?: CSSProperties;
  withDetails?: boolean;
  size?: 'small' | 'medium';
}) {
  if (!withDetails) {
    return <StatusChip status={status} colors={ORDER_STATUS_COLORS} style={style} size={size} />;
  }
  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        marginBottom: 16,
      }}
    >
      <StatusChip
        status={status}
        colors={ORDER_STATUS_COLORS}
        size={size}
        style={{
          ...style,
          width: '100%',
          marginBottom: 8,
        }}
      />

      {withDetails ? (
        <Typography variant="body2" color="textSecondary">
          {ORDER_STATUS_DETAILS[status]}
        </Typography>
      ) : null}
    </div>
  );
}
