import _ from 'lodash';
import { curr, useFormValues } from '@/admin';
import { JournalEntry } from '@/models';

export default function JournalEntryTotals() {
  const items = useFormValues((je: JournalEntry) => je.items);

  return (
    <div>
      <div>
        Debit Total: <b>{curr(_.sumBy(items, (v) => Number(v.debit_amount || 0)))}</b>
      </div>
      <div>
        Credit Total: <b>{curr(_.sumBy(items, (v) => Number(v.credit_amount || 0)))}</b>
      </div>
    </div>
  );
}
