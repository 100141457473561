import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemBaseProps,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { WrappedFieldProps } from 'redux-form';
import InputWrapper from '../../components/Form/InputWrapper';
import Text from '../../components/Text/Text';
import { useAdminConfig } from '../../contexts/AdminConfigContext';
import { useAppContext } from '../../contexts/AppContext';
import useDebounce from '../../hooks/useDebounce';
import useGetResource, { useResource } from '../../hooks/useGetResource';
import RelationTableField from '../../models/Fields/RelationTableField';
import { CellProps, FieldProps } from '../../types';
import wrap from '../../utils/wrap';
import Field from '../Field';
import Resource from '../Resource';

function Relation({ input, field, meta }: FieldProps<RelationField>) {
  const multiple = field.hasMany;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const [optionsFromApi, setOptions] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const otherQueryParams = field.requestParams;
  const value = useMemo(() => (multiple ? wrap(input.value) : input.value), [input.value]);
  const getResource = useGetResource();
  const { setCreateResource } = useAppContext();
  const { getRecordsFromResponse } = useAdminConfig();

  // Overrides on resource for this field
  const overrides = _.pickBy(
    _.pick(field, [
      'getIndexRequest',
      'getLabel',
      'getValue',
      'getSubtitle',
      'getSecondaryAction',
      'getAvatar',
    ]),
  );

  const resource = getResource(field.resource).cloneWith(overrides);
  const { staticOptions } = resource;
  const pk = resource.primaryKey;
  const isAdvancedDisplay =
    !multiple && (resource.getSubtitle || resource.getAvatar || resource.getSecondaryAction);

  useEffect(() => {
    let unmounted = false;
    const source = axios.CancelToken.source();

    if (open && !staticOptions) {
      setLoading(true);
      resource
        .getIndexRequest(
          { query: debouncedQuery, ...otherQueryParams },
          { cancelToken: source.token },
        )
        .then((res) => {
          if (!unmounted) {
            setOptions(getRecordsFromResponse(res));
            setLoading(false);
          }
        })
        .catch(() => {
          if (!unmounted) {
            setLoading(false);
          }
        });
    }

    return function () {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, [debouncedQuery, open]);

  const onCreate = () => {
    setCreateResource({
      form: meta.form,
      name: input.name,
      resourceName: field.resource,
      initialValues: { name: query },
      multiple,
    });
  };

  const renderOption = (listItemProps: Partial<ListItemBaseProps>, option: Record<string, any>) => {
    if (option.id === 'create') {
      return (
        <ListItemButton onClick={onCreate} key="create">
          <ListItemText>{option.label}</ListItemText>
        </ListItemButton>
      );
    }
    return (
      <ListItem {...listItemProps} component="div" key={option[pk]} dense disableGutters>
        {resource.getAvatar && (
          <ListItemAvatar
            sx={{
              '& .MuiAvatar-root': {
                width: 36,
                height: 36,
              },
            }}
          >
            {resource.getAvatar(option)}
          </ListItemAvatar>
        )}
        <ListItemText
          sx={{ my: 0 }}
          primary={resource.getLabel(option)}
          secondary={resource.getSubtitle && resource.getSubtitle(option)}
        />
        {resource.getSecondaryAction && (
          <ListItemSecondaryAction>{resource.getSecondaryAction(option)}</ListItemSecondaryAction>
        )}
      </ListItem>
    );
  };

  const handleChange = (e: SyntheticEvent, newValue: any) => {
    input.onChange(newValue);
  };

  const renderTextField = (params: Partial<TextFieldProps>) => (
    <TextField
      {...field.getTextFieldProps(meta)}
      {...params}
      inputRef={inputRef}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading && <CircularProgress color="inherit" size={20} />}
            {params.InputProps?.endAdornment}
          </>
        ),
      }}
    />
  );

  const renderSelect = () => (
    <InputWrapper meta={meta} field={field}>
      <Select
        open={false}
        label={field.label}
        onOpen={(e) => {
          e.preventDefault();
          setOpen(true);
          setTimeout(() => {
            inputRef.current?.select();
          }, 100);
        }}
        sx={{
          '& .MuiSelect-select': {
            py: 0,
          },
        }}
        value={input.value}
        renderValue={(v) =>
          renderOption(
            {
              sx: {
                py: 0,
                minHeight: 44,
              },
            },
            v,
          )
        }
      />
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          right: 32,
          top: 'calc(50% - 15px)',
        }}
        onClick={() => input.onChange(null)}
      >
        <Close fontSize="small" />
      </IconButton>
    </InputWrapper>
  );

  const renderInput = open || !input.value || !isAdvancedDisplay ? renderTextField : renderSelect;

  const getOptionLabel = useCallback(
    (option: Record<string, any>) => {
      if (!option) {
        return '';
      }
      if (option.id === 'create') {
        return option.label;
      }
      return resource.getLabel(option);
    },
    [resource],
  );

  const filterOptions = staticOptions ? undefined : (array: Record<string, any>[]) => array;

  let options = staticOptions;

  if (!staticOptions) {
    if (!loading && optionsFromApi.length === 0 && field.createsOptions && query) {
      options = [
        {
          id: 'create',
          label: `Create "${query}"`,
        },
      ];
    } else {
      options = optionsFromApi;
    }
  }

  const onInputChange = useCallback(
    (e: SyntheticEvent, q: string) => {
      setQuery(q);
    },
    [setQuery],
  );

  return (
    <Autocomplete
      open={open}
      multiple={multiple}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, v) => option[pk] === v[pk]}
      options={options || []}
      filterOptions={filterOptions}
      noOptionsText={loading ? 'Loading...' : `No ${resource.name.toLowerCase()} found`}
      onChange={handleChange}
      value={value || null}
      placeholder={field.placeholder || 'Start typing to search...'}
      disabled={field.disabled}
      readOnly={Boolean(field.readOnly)}
      inputValue={query}
      onInputChange={onInputChange}
      renderInput={renderInput}
      size={isAdvancedDisplay ? 'medium' : field.size}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
    />
  );
}

export function RelationCell({ value, field }: CellProps<RelationField | RelationTableField>) {
  const resource = useResource(field.resource);
  if (value == null || value === '') {
    return null;
  }
  if (field instanceof RelationField && field.hasMany) {
    return (
      <span>
        {wrap(value)
          .map((v) => {
            const label = resource.getLabel(v);
            return typeof label === 'object' ? resource.getTitle(v) : label;
          })
          .join(', ')}
      </span>
    );
  }
  if (resource.getSubtitle) {
    return <Text primary={resource.getLabel(value)} secondary={resource.getSubtitle(value)} />;
  }
  return <span>{resource.getLabel(value)}</span>;
}

export default class RelationField<T extends object = Record<string, any>> extends Field {
  resource: string;
  hasMany = false;
  createsOptions = false;
  requestParams?: Record<string, any>;
  getIndexRequest?: Resource<T>['getIndexRequest'];
  getLabel?: Resource<T>['getLabel'];
  getValue?: Resource<T>['getValue'];
  getSubtitle?: Resource<T>['getSubtitle'];
  getSecondaryAction?: Resource<T>['getSecondaryAction'];
  getAvatar?: Resource<T>['getAvatar'];

  constructor(name: string, resource: string) {
    super(name);
    this.resource = resource;
  }

  getDefaultFilterKey(): string {
    if (this.hasMany) {
      return `filter[${this.name}.id][in]`;
    }
    return `filter[${this.name}.id]`;
  }

  renderEditComponent(props: WrappedFieldProps) {
    // @ts-ignore
    return <Relation {...props} field={this} />;
  }

  renderCell(value: any) {
    // @ts-ignore
    return <RelationCell value={value} field={this} />;
  }

  withRequestParams(params: Record<string, any>) {
    this.requestParams = params;
    return this;
  }
}
