import { SyntheticEvent, useState } from 'react';
import { CalendarToday, Refresh } from '@mui/icons-material';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
  Checkbox,
  Box,
  Tooltip,
  IconButton,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {
  Can,
  StatusChip,
  SubMenu,
  useDialogs,
  Text,
  TextButton,
  useHasPermission,
  ButtonAction,
  useOnReloadRecord,
} from '@/admin';
import { PRODUCTION_EVENT_STATUS_COLORS } from '@/constants';
import { useSchedulingState } from '@/contexts/SchedulingContext';
import { ProductionEvent } from '@/models';
import OrderDesignLabel from '../Designs/OrderDesignLabel';
import ScheduleDrawer from './ScheduleDrawer';
import ScreenLocation from './ScreenLocation';

class ProdAction extends ButtonAction {
  declare onClick: () => void;
  declare label: string;
  canBePrimary = false;
}

export default function ProductionEventList({
  orderId,
  events,
  selected,
  onSelect,
  onPerformAction,
  onDuplicate,
  onDelete,
  onUpdateQty,
}: {
  orderId: number;
  events: ProductionEvent[];
  selected?: ProductionEvent;
  onSelect: (e: ProductionEvent) => void;
  onPerformAction: (e: ProductionEvent, k: string) => void;
  onDuplicate: (e: ProductionEvent) => void;
  onDelete: (e: ProductionEvent) => void;
  onUpdateQty: (e: ProductionEvent) => void;
}) {
  const { setScheduling } = useSchedulingState();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { confirm } = useDialogs();
  const hasPermission = useHasPermission();
  const onReload = useOnReloadRecord();

  const resetEvents = () => {
    confirm(
      'Reset Production Events',
      'Are you sure you want to reset the production events for this order? This will delete and recreate all production events.',
      'error',
    ).then(() => {
      axios.post(`/api/orders/${orderId}/reset-production`).then(() => {
        onReload();
      });
    });
  };

  const handleSelectAll = (e: SyntheticEvent) => {
    e.stopPropagation();
    setSelectedIds((prev) => {
      if (prev.length > 0) {
        return [];
      }
      return events.map((e) => e.id);
    });
  };

  const handleSelectOne = (e: SyntheticEvent, eventId: number) => {
    e.stopPropagation();
    setSelectedIds((prev) => {
      if (prev.includes(eventId)) {
        return prev.filter((p) => p !== eventId);
      }
      return [...prev, eventId];
    });
  };

  const isScreenprint = (event: ProductionEvent) => event.event_type.unit === 'colors';

  const hasScreenprintEvents = events.some(isScreenprint);

  const eventActions = (
    <div>
      {selectedIds.length > 0 && (
        <Tooltip title="Schedule Events">
          <IconButton
            onClick={() => {
              setScheduling({
                eventIds: selectedIds,
                onSuccess: onReload,
              });
            }}
            size="large"
          >
            <CalendarToday />
          </IconButton>
        </Tooltip>
      )}
      <Can permission="production_events:admin">
        <Tooltip title="Reset Production Events">
          <IconButton onClick={resetEvents} size="large">
            <Refresh />
          </IconButton>
        </Tooltip>
      </Can>
    </div>
  );

  return (
    <Card>
      <CardHeader title="Events" action={eventActions} />
      {!events || events.length === 0 ? (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There are no events for this order
          </Typography>
        </CardContent>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <Can permission="write:production_events">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIds.length > 0 && selectedIds.length === events.length}
                      indeterminate={selectedIds.length > 0 && selectedIds.length < events.length}
                      onClick={handleSelectAll}
                    />
                  </TableCell>
                </Can>
                <TableCell>Machine</TableCell>
                <TableCell>Design</TableCell>
                {hasScreenprintEvents && <TableCell>Scr Loc</TableCell>}
                <TableCell>Quantity</TableCell>
                <TableCell>Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => {
                const isSelected = event.id === selected?.id;
                const actions: ProdAction[] = [];

                _.forOwn(event.actions, (v, k) => {
                  const action = new ProdAction(v, () => onPerformAction(event, k));
                  // @ts-ignore
                  action.canBePrimary = !['undo', 'restart'].includes(k);
                  actions.push(action);
                });

                actions.push(
                  new ProdAction('Duplicate', () => onDuplicate(event)).withPermission(
                    'production_events:admin',
                  ),
                  new ProdAction('Delete', () => onDelete(event)).withPermission(
                    'production_events:admin',
                  ),
                );

                const getPrimaryAction = () => {
                  if (actions[0]?.canBePrimary) {
                    const action = actions.shift();
                    if (action) {
                      return (
                        <Button onClick={action.onClick} variant="contained" size="small">
                          {action.label}
                        </Button>
                      );
                    }
                  }
                  return null;
                };

                return (
                  <TableRow
                    key={event.id}
                    selected={isSelected}
                    hover
                    onClick={() => onSelect(event)}
                  >
                    <Can permission="write:production_events">
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={_.includes(selectedIds, event.id)}
                          onClick={(e) => handleSelectOne(e, event.id)}
                        />
                      </TableCell>
                    </Can>
                    <TableCell>
                      <Text
                        primary={_.get(event, 'machine.name', '(none)')}
                        secondary={_.get(event, 'event_type.name')}
                      />
                    </TableCell>
                    <TableCell>
                      <Text
                        primary={<OrderDesignLabel orderDesign={event.order_design} />}
                        secondary={event.order_design.design.location}
                      />
                    </TableCell>
                    {hasScreenprintEvents && (
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        <ScreenLocation event={event} onSuccess={() => onReload()} />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextButton
                        disabled={!hasPermission('production_events:admin')}
                        onClick={() => onUpdateQty(event)}
                      >
                        {event.quantity}
                      </TextButton>
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: 1 }}>
                      <StatusChip
                        status={event.status}
                        size="small"
                        colors={PRODUCTION_EVENT_STATUS_COLORS}
                      />
                      <br />
                      {event.scheduled_at && (
                        <Can permission="write:production_events">
                          <Typography variant="caption" color="textSecondary">
                            {moment(event.scheduled_at).format('l')}
                          </Typography>
                        </Can>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ height: 80, whiteSpace: 'nowrap' }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Can permission="write:production_events">
                        <Box mr={2} display="inline-block">
                          {isSelected && getPrimaryAction()}
                        </Box>
                        {actions.length > 0 && <SubMenu items={actions} size="small" />}
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ScheduleDrawer />
    </Card>
  );
}
