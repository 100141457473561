import { HTMLAttributes, SyntheticEvent, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardMedia,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Can } from '@/admin';
import { useConfig } from '@/contexts/AppConfigContext';
import { User } from '@/models';
import { getBusinessNameById } from '@/utils/getBusinessName';
import { maybeAddQueryParams } from '@/utils/query';

interface UserLabelProps extends HTMLAttributes<HTMLSpanElement> {
  user: User | undefined | null;
}

const BlankButton = styled('button')({
  display: 'inline',
  background: 'none',
  border: 'none',
  textAlign: 'inherit',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  fontWeight: 'inherit',
  color: 'inherit',
  cursor: 'pointer',
  padding: 0,
  paddingRight: 2,
});

export default function UserLabel({ user, ...props }: UserLabelProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { businesses } = useConfig();

  const onOpenPopover = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <BlankButton type="button" onClick={onOpenPopover} {...props}>
        {user.name}
      </BlankButton>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Card>
          {user.avatar && !user.is_disabled && (
            <CardMedia
              component="img"
              height="194"
              image={maybeAddQueryParams(user.avatar, { w: 400 })}
            />
          )}
          {user.is_disabled ? (
            <CardMedia
              sx={{
                backgroundColor: 'action.disabled',
                py: 2,
                textAlign: 'center',
              }}
            >
              <Typography variant="overline" color="textSecondary" fontSize="medium">
                Account Disabled
              </Typography>
            </CardMedia>
          ) : null}
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">Name</TableCell>
                <TableCell>{user.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Email</TableCell>
                <TableCell>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </TableCell>
              </TableRow>
              {user.title && (
                <TableRow>
                  <TableCell variant="head">Title</TableCell>
                  <TableCell>{user.title}</TableCell>
                </TableRow>
              )}
              {businesses.length > 1 && (
                <TableRow>
                  <TableCell variant="head">Business</TableCell>
                  <TableCell>
                    {getBusinessNameById(businesses, user.business_id, 'None - Avail HQ')}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell variant="head">User Since</TableCell>
                <TableCell>{moment(user.created_at).format('ll')}</TableCell>
              </TableRow>
              {user.account_managers && (
                <TableRow>
                  <TableCell variant="head">
                    Account {user.account_managers.length > 1 ? 'Managers' : 'Manager'}
                  </TableCell>
                  <TableCell>
                    {user.account_managers.map((u) => (
                      <div key={u.id}>
                        <UserLabel user={u} />
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Can permission="write:users">
            <CardActions>
              <Button component={Link} to={`/users/${user.id}`} size="small">
                Edit
              </Button>
            </CardActions>
          </Can>
        </Card>
      </Popover>
    </>
  );
}
