import { useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { GoogleLogin } from '@react-oauth/google';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AuthLayout from '@/pages/auth/AuthLayout';

export function useAfterLogin() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  return useCallback(() => {
    const redirectTo = searchParams.get('redirect_to');
    if (redirectTo) {
      window.location.href = redirectTo;
    } else {
      queryClient.invalidateQueries(['appConfig']);
      navigate('/');
    }
  }, [queryClient, navigate, searchParams]);
}

export default function Login() {
  const afterLogin = useAfterLogin();

  const loginRequest = useMutation<
    unknown,
    AxiosError<{ errors: Record<string, string[]>; message: string }>,
    { email: string; password: string; remember: boolean }
  >((v) => axios.post(`/api/login`, v).then(afterLogin));

  const devLogin = useMutation((v) => axios.post(`/api/oauth/dev`, v).then(afterLogin));

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    loginRequest.mutate({
      email: data.get('email') as string,
      password: data.get('password') as string,
      remember: data.get('remember') === 'on',
    });
  };

  return (
    <AuthLayout title="Sign in">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {loginRequest.error && (
          <Alert severity="error" sx={{ my: 1 }}>
            {loginRequest.error.response?.data.message || 'An unknown error occurred'}
          </Alert>
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <FormControlLabel
            control={<Checkbox name="remember" color="primary" />}
            label="Remember me"
          />

          <Link to="/forgot-password">Forgot password?</Link>
        </Box>

        <LoadingButton
          loading={loginRequest.isLoading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </LoadingButton>

        <Box py={4}>
          <Divider />
        </Box>

        {import.meta.env.VITE_VERSION === 'local' ? (
          <LoadingButton
            variant="outlined"
            fullWidth
            loading={devLogin.isLoading}
            onClick={() => devLogin.mutate()}
          >
            Dev Login
          </LoadingButton>
        ) : (
          <GoogleLogin
            hosted_domain="availlabs.com"
            onSuccess={(credentialResponse) => {
              axios
                .post('/api/oauth/google', {
                  credential: credentialResponse.credential,
                })
                .then(afterLogin);
            }}
          />
        )}
      </Box>
    </AuthLayout>
  );
}
