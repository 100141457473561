import { Typography, Button } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import {
  useDialogs,
  useFormActions,
  useFormValues,
  getDirtyProperties,
  useGetResource,
  FieldFactory,
  useOnReloadRecord,
  useShowLoading,
  useHasPermission,
} from '@/admin';
import { requiredFields } from '@/helpers';
import { PurchaseOrder } from '@/models';
import store from '../../store';

export default function PurchaseOrderActions() {
  const [submittedAt, integrationName, id, invoiceNumber, submitMethod] = useFormValues(
    (p: PurchaseOrder) => [
      p.submitted_at,
      p.integration_name,
      p.id,
      p.invoice_number,
      p.submit_method,
    ],
  );
  const { initialize } = useFormActions();
  const { confirm, prompt } = useDialogs();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();
  const getResource = useGetResource();
  const hasPermission = useHasPermission();

  const maybeSaveFirst = () => {
    const { values, initial } = store.getState().form[getResource('purchaseOrders').getFormName()];
    const dirty = getDirtyProperties(values, initial);

    if (_.isEmpty(dirty)) {
      return Promise.resolve();
    }
    return axios.put<PurchaseOrder>(`/api/purchase-orders/${id}`, dirty).then(({ data }) => {
      initialize({ ...(values as PurchaseOrder), ...data });
    });
  };

  const markUnsubmitted = () => {
    confirm('Mark PO Unsubmitted', 'Are you sure?', 'error').then(() => {
      showLoading(
        maybeSaveFirst()
          .then(() => axios.post(`/api/purchase-orders/${id}/mark-unsubmitted`))
          .then(() => {
            onReload();
          }),
      );
    });
  };
  const markSubmitted = () => {
    prompt({
      title: 'Mark PO Submitted',
      fields: [
        FieldFactory.select('submit_method', {
          online: 'Online',
          email: 'Email',
          phone: 'Phone',
          other: 'Other',
        }).withHelp('How did you submit this PO?'),
        FieldFactory.text('invoice_number'),
      ],
      validation: requiredFields(['submit_method']),
      initialValues: {
        submit_method: submitMethod,
        invoice_number: invoiceNumber,
      },
      onSubmit: (values) =>
        maybeSaveFirst().then(() =>
          axios.post(`/api/purchase-orders/${id}/mark-submitted`, values),
        ),
    }).then(() => {
      onReload();
    });
  };

  const submitUsingIntegration = () => {
    confirm(
      'Submit PO Automatically',
      <div>
        <span>Are you sure you want to submit this PO? This will use</span>
        <i> {integrationName} </i>
        <span>
          to submit the PO to the vendor. Please make sure you have checked inventory first.
        </span>
      </div>,
    ).then(() => {
      showLoading(
        maybeSaveFirst()
          .then(() => axios.post(`/api/purchase-orders/${id}/submit`))
          .then(() => {
            onReload();
          }),
      );
    });
  };

  if (submittedAt) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button onClick={markUnsubmitted} size="small">
          Mark Unsubmitted
        </Button>
      </div>
    );
  }

  if (integrationName && hasPermission('purchase_orders:auto_submit')) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Typography variant="body2" gutterBottom>
          This PO can be submitted automatically.
        </Typography>
        <Button onClick={submitUsingIntegration} variant="outlined" size="small" sx={{ mr: 1 }}>
          Auto Submit
        </Button>
        <Button onClick={markSubmitted} size="small">
          Mark Submitted
        </Button>
      </div>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="body2" gutterBottom>
        This PO needs to be manually submitted.
      </Typography>
      <Button onClick={markSubmitted}>Mark Submitted</Button>
    </div>
  );
}
