import _ from 'lodash';
import { AnyAction } from 'redux';
import { FormStateMap, reducer } from 'redux-form';

export default function (state: FormStateMap, action: AnyAction) {
  switch (action.type) {
    case 'FORM_SILENT_CHANGE':
      const { form, field, value } = action;

      const formState = _.cloneDeep(state[form]);

      if (_.isObject(field)) {
        _.forOwn(field, (v, k) => {
          _.set(formState, `initial.${k}`, v);
          _.set(formState, `values.${k}`, v);
        });
      } else {
        _.set(formState, `initial.${field}`, value);
        _.set(formState, `values.${field}`, value);
      }

      return {
        ...state,
        [form]: formState,
      };
    default:
      return reducer(state, action);
  }
}
