import { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { Table as ReactTable, flexRender } from '@tanstack/react-table';
import _ from 'lodash';

export default function DataTable<T>({ table }: { table: ReactTable<T> }) {
  const { pagination } = table.getState();
  const total = table.getCoreRowModel().rows.length;

  const hasFooter = useMemo(() => {
    return table.getFooterGroups().some((fg) => fg.headers.some((h) => h.column.columnDef.footer));
  }, [table]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((headCell) => (
                <TableCell key={headCell.id} sortDirection={headCell.column.getIsSorted()}>
                  <TableSortLabel
                    disabled={!headCell.column.getCanSort()}
                    active={Boolean(headCell.column.getIsSorted())}
                    direction={headCell.column.getIsSorted() || 'asc'}
                    onClick={headCell.column.getToggleSortingHandler()}
                  >
                    {headCell.isPlaceholder
                      ? null
                      : flexRender(headCell.column.columnDef.header, headCell.getContext())}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {table.getRowModel().rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={table.getAllColumns().length}>
                <Typography color="textSecondary">No results were found.</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {hasFooter && (
          <TableFooter>
            {table.getFooterGroups().map((footerGroup) => (
              <TableRow key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <TableCell key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableFooter>
        )}
      </Table>
      <TablePagination
        component="div"
        count={total}
        page={pagination.pageIndex}
        onPageChange={(e, p) => table.setPageIndex(p)}
        onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={_.uniq([table.getState().pagination.pageSize, 25, 50, 100])}
      />
    </TableContainer>
  );
}
