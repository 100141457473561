import { red, green } from '@mui/material/colors';
import { curr } from '@/admin';

export default function ColoredCurrency({
  amount,
  bold,
  onlyNegative,
  opposite,
}: {
  amount: number;
  bold?: boolean;
  onlyNegative?: boolean;
  opposite?: boolean;
}) {
  const amountToCompare = opposite ? amount * -1 : amount;

  let color = 'inherit';
  if (amountToCompare > 0 && !onlyNegative) {
    // eslint-disable-next-line prefer-destructuring
    color = green[500];
  } else if (amountToCompare < 0) {
    // eslint-disable-next-line prefer-destructuring
    color = red[500];
  }
  return <span style={{ color, fontWeight: bold ? 600 : 'inherit' }}>{curr(amount)}</span>;
}
