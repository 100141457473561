import PromptDialog from '../../components/Dialogs/PromptDialog';
import { useAppContext } from '../../contexts/AppContext';
import ClosableDrawer from '../ClosableDrawer';

export default function PromptDrawerContainer() {
  const { promptDrawer, setPromptDrawer } = useAppContext();

  if (!promptDrawer) {
    return null;
  }

  const { options, open, promise } = promptDrawer;
  const { fields, ...nonFields } = options;

  const onClose = () => {
    setPromptDrawer({ ...promptDrawer, open: false });
  };

  const onSuccess = (v: any) => {
    promise.resolve(v);
    onClose();
  };

  const onCancel = () => {
    promise.reject();
    onClose();
  };

  return (
    <ClosableDrawer open={open} onClose={onCancel} title={options.title} width={options.width}>
      <PromptDialog
        {...nonFields}
        promptFields={fields}
        onSuccess={onSuccess}
        onCancel={onCancel}
        mode="drawer"
      />
    </ClosableDrawer>
  );
}
