import { useRecordId } from '@/admin';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';

export default function OrderShipmentsCard({ showActions }: { showActions?: boolean }) {
  const orderId = useRecordId();
  return (
    <ShipmentsCard
      shippableId={orderId as number}
      shippableType="order"
      showActions={showActions}
    />
  );
}
