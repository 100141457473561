import { useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';

export default function KitItemActionMenu({ onBackorder }: { onBackorder: () => void }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        aria-controls="kit-action-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Actions <ArrowDropDown />
      </Button>
      <Menu
        id="kit-action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={onBackorder}>Create Backorder</MenuItem>
      </Menu>
    </div>
  );
}
