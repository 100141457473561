import { CSSProperties } from 'react';
import { Warning } from '@mui/icons-material';
import { Box, IconButton, SxProps, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';

export default function WarningIcon({
  message,
  onClick,
  style = {},
  sx = {},
}: {
  message: string;
  onClick?: () => void;
  style?: CSSProperties;
  sx?: SxProps;
}) {
  const icon = <Warning style={{ color: red[500], verticalAlign: 'middle', ...style }} />;
  return (
    <Tooltip title={message}>
      <Box display="inline-block" mx={0.5} sx={sx}>
        {onClick ? (
          <IconButton onClick={onClick} size="large">
            {icon}
          </IconButton>
        ) : (
          icon
        )}
      </Box>
    </Tooltip>
  );
}
