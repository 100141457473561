import _ from 'lodash';

interface CurrencyOptions {
  minDecimals: number;
  maxDecimals: number;
  divideBy: number;
  locale: 'en-US';
  currency: 'USD';
}

const defaults: CurrencyOptions = {
  minDecimals: 2,
  maxDecimals: 2,
  divideBy: 1,
  locale: 'en-US',
  currency: 'USD',
};

export default function curr(amount: number, opt: Partial<CurrencyOptions> = {}) {
  const options: CurrencyOptions = _.defaults(opt, defaults);

  const { minDecimals, maxDecimals, locale, currency, divideBy } = options;

  const double = amount / divideBy;
  if (currency === 'USD' && (minDecimals !== 2 || maxDecimals !== 2)) {
    return `$${double.toLocaleString(locale, {
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: maxDecimals,
    })}`;
  }
  return double.toLocaleString(locale, {
    style: 'currency',
    currency,
  });
}
