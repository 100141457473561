import { useEffect } from 'react';
import axios from 'axios';
import {
  FieldFactory,
  FieldProps,
  generateFormName,
  GenericField,
  useSpecificFormValues,
} from '@/admin';
import { ArtRequest, Order, OrderApproval } from '@/models';

export default function ArtRequestNoteField({ input, meta, field }: FieldProps) {
  const orderId = useSpecificFormValues(generateFormName('orders'), (o: Order) => o.id);
  const type = useSpecificFormValues(meta.form, (t: { type: ArtRequest['type'] }) => t.type);

  useEffect(() => {
    if (type === 'proof') {
      axios
        .get<{ data: OrderApproval[] }>(`/api/orders/${orderId}/approvals?sort=-rejected_at`)
        .then(({ data }) => {
          const withNotes = data.data.find((a) => a.rejection_note && a.rejection_note.length > 1);
          if (withNotes) {
            input.onChange(`From Customer:\n${withNotes.rejection_note}`);
          }
        });
    }
  }, [orderId, type]);

  return (
    <div
      style={{
        width: 500,
        maxWidth: '100%',
      }}
    >
      <GenericField
        input={input}
        meta={meta}
        field={FieldFactory.textarea(field.name).withLabel(field.label as string)}
      />
    </div>
  );
}
