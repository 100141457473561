import { CompareArrows } from '@mui/icons-material';
import axios from 'axios';
import Joi from 'joi';
import _ from 'lodash';
import { Resource, curr, FieldFactory, LinkAction, ButtonAction, CardLayout } from '@/admin';
import PlaidConnection from '@/components/Accounting/PlaidConnection';
import { ACCOUNT_TYPES } from '@/constants';
import { Account } from '@/models';
import { AppConfig } from '@/responses';
import { getSingleDisableAction } from '@/utils/disabled';

export default function accounts(appConfig: AppConfig) {
  return new Resource<Account>('Accounts')
    .getTitleUsing((a) => a.path)
    .getLabelUsing((a) => a.path)
    .getSubtitleUsing((a) => (
      <div>
        <span>
          #{a.number} - {_.startCase(a.type)}
        </span>
        {['bank', 'asset', 'liability', 'equity'].includes(a.type) && (
          <span> - Balance: {curr(a.cached_balance)}</span>
        )}
      </div>
    ))
    .withDefaultSort('number')
    .withDefaultValues({
      type: 'expense',
    })
    .withDefaultFilters({
      hierarchy: true,
    })
    .withFilters([
      FieldFactory.boolean('hierarchy', 'Show as Hierarchy').withFilterKey('hierarchy'),
    ])
    .getSingleActionsUsing((values) => [
      getSingleDisableAction('Account', 'accounts', values),
      new LinkAction('Reconcile', `/reconciliation?account_id=${values.id}`).withIcon(
        CompareArrows,
      ),
      new ButtonAction('Create Child Account', ({ dialogs, navigate }) => {
        dialogs
          .prompt({
            title: 'Create Child Account',
            fields: [FieldFactory.text('name').sortable(), FieldFactory.text('number')],
            onSubmit: (v: Partial<Account>) =>
              axios.post('/api/accounts', {
                ...v,
                type: values.type,
                parent_id: values.id,
              }),
          })
          .then(({ data }) => {
            navigate(`/accounts/${data.id}`);
          });
      }),
      new ButtonAction('Move Account Under', ({ dialogs, onReloadRecord }) => {
        dialogs
          .prompt({
            title: 'Move Account Under',
            fields: [FieldFactory.belongsTo('account', 'accounts').withLabel('New Parent Account')],
            validation: Joi.object({
              account: Joi.object().required(),
            }),
            onSubmit: (v: { account: Account }) =>
              axios.put(`/api/accounts/${values.id}`, { parent_id: v.account.id }),
          })
          .then(() => {
            onReloadRecord();
          });
      }),
    ])
    .withColumns([
      FieldFactory.text('name')
        .renderCellUsing((value, row: Account) => (
          <span style={{ paddingLeft: row.depth * 16 }}>{value}</span>
        ))
        .sortable(),
      FieldFactory.text('number').sortable(),
      FieldFactory.select('type', ACCOUNT_TYPES).sortable(),
      FieldFactory.select('cost_category', appConfig.costCategories)
        .withLabel('Job Cost Category')
        .sortable(),
      FieldFactory.curr('cached_balance').withLabel('Balance').sortable(),
      FieldFactory.boolean('is_disabled').sortable('disabled_at').filterable('is_disabled'),
    ])
    .withFields([
      new CardLayout('Account Setup', [
        FieldFactory.text('name').sortable(),
        FieldFactory.text('number'),
        FieldFactory.select('type', ACCOUNT_TYPES),
        FieldFactory.select('cost_category', appConfig.costCategories).withLabel(
          'Job Cost Category',
        ),
      ]),
      new CardLayout('Bank Info', [
        FieldFactory.text('routing_number'),
        FieldFactory.text('account_number'),
        FieldFactory.custom('plaid_item_id', PlaidConnection).withColumnSpan(7),
      ]),
    ]);
}
