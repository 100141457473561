import { useEffect, useState } from 'react';
import { Grid, Button, Box } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import {
  FieldFactory,
  Paper,
  ReduxField,
  useSpecificFormValues,
  useTitle,
  validateUsingRules,
} from '@/admin';
import { requiredFields } from '@/helpers';
import { Account, Reconciliation } from '@/models';

interface FormShape {
  account: Account;
  end_date: string;
  end_balance: number;
}

function ReconciliationForm(props: InjectedFormProps<FormShape>) {
  const { change, dirty, submitting, handleSubmit } = props;
  useTitle('Reconciliation');

  const [existing, setExisting] = useState<Reconciliation>();
  const account = useSpecificFormValues(props.form, (a) => a.account as Account);
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('account_id');
  const navigate = useNavigate();

  useEffect(() => {
    setExisting(undefined);
    if (account) {
      const params = { account_id: account.id };
      axios.get('/api/reconciliations', { params }).then(({ data }) => {
        setExisting(data.data[0]);
      });
    }
  }, [account?.id]);

  useEffect(() => {
    if (accountId && account === undefined) {
      axios
        .get(`/api/accounts/${accountId}`)
        .then(({ data }) => {
          change('account', data || null);
        })
        .catch(() => {
          change('account', null);
        });
    }
  }, [accountId]);

  const onSubmit: FormSubmitHandler<FormShape> = (values) => {
    const params: Record<string, any> = _.pick(values, ['end_date', 'end_balance']);
    params.account_id = values.account.id;
    axios.post('/api/reconciliations', params).then(({ data }) => {
      navigate(`/reconciliation/${data.id}`);
    });
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item md={4}>
            <ReduxField
              field={FieldFactory.belongsTo('account', 'accounts').withRequestParams({
                'filter[type][in]': 'bank,credit_card',
              })}
            />
          </Grid>
          <Grid item md={4}>
            <ReduxField field={FieldFactory.date('end_date').withHelp('The statement end date')} />
          </Grid>
          <Grid item md={4}>
            <ReduxField
              field={FieldFactory.curr('end_balance').withHelp('The statement ending balance')}
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 2 }}>
          {existing && (
            <Button
              variant="contained"
              component={Link}
              to={`/reconciliation/${existing.id}`}
              sx={{ mr: 2 }}
            >
              Continue Reconciliation
            </Button>
          )}

          {dirty && (
            <Button type="submit" disabled={submitting}>
              Start New Reconciliation
            </Button>
          )}
        </Box>
      </form>
    </Paper>
  );
}

export default reduxForm<FormShape>({
  form: 'reconcile',
  validate: (v) => validateUsingRules(v, requiredFields(['account', 'end_date', 'end_balance'])),
})(ReconciliationForm);
