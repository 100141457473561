import { Box, Checkbox, FormControlLabel, Switch as BaseSwitch, Typography } from '@mui/material';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import StatusChip from '../../components/StatusChip';
import SelectField from '../../models/Fields/SelectField';
import { FieldProps } from '../../types';
import Field from '../Field';

function Toggle({ input, field }: FieldProps<ToggleField>) {
  const ToggleInput = field.switch ? BaseSwitch : Checkbox;

  const Control = (
    <ToggleInput
      checked={!!input.value}
      onChange={input.onChange}
      value="1"
      disabled={field.disabled}
      size={field.size}
    />
  );

  if (!field.label) {
    return Control;
  }

  return (
    <Box width="100%">
      <FormControlLabel
        control={Control}
        label={
          <Typography fontSize={field.size === 'small' ? 'inherit' : undefined} component="span">
            {field.label}
          </Typography>
        }
      />
    </Box>
  );
}

export default class ToggleField extends Field {
  switch = false;

  getFilterFieldForType(): Field {
    return new SelectField(this.name, {
      0: 'No',
      1: 'Yes',
    }).with({
      includeBlank: true,
    });
  }

  renderEditComponent(props: WrappedFieldProps) {
    return <Toggle {...props} field={this} />;
  }

  renderCell(value: any) {
    return (
      <StatusChip
        status={value ? 'Yes' : 'No'}
        colors={{
          Yes: 'green',
          No: 'red',
        }}
      />
    );
  }
}
