import { ListItemIcon, MenuItem, MenuItemProps } from '@mui/material';
import { Link } from 'react-router-dom';
import useOnClickProps from '../../hooks/useOnClickProps';
import Action from '../../models/Action';
import AnchorAction from '../../models/Actions/AnchorAction';
import ButtonAction from '../../models/Actions/ButtonAction';
import LinkAction from '../../models/Actions/LinkAction';

export default function ActionMenuItem({ action }: { action: Action }) {
  const Icon = action.icon;
  const toPass = useOnClickProps();

  const props: MenuItemProps = {
    color: action.color,
    disabled: action.disabled,
  };

  if (action instanceof ButtonAction) {
    Object.assign(props, {
      onClick: () => action.onClick(toPass),
    });
  } else if (action instanceof AnchorAction) {
    Object.assign(props, {
      component: 'a',
      href: action.href,
      target: action.openInNew ? '_blank' : '_self',
    });
  } else if (action instanceof LinkAction) {
    Object.assign(props, {
      component: Link,
      to: action.to,
    });
  }

  return (
    <MenuItem {...props}>
      {Icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      {action.label}
    </MenuItem>
  );
}
