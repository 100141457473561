import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import {
  ColumnDef,
  ColumnSort,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import DataTable from '@/components/Shared/DataTable';
import searchCollection from '@/utils/searchCollection';
import DebouncedTextField from './DebouncedTextField';

export default function PaginatedTable<T extends Record<string, any>>({
  rows,
  columns,
  searchable,
  initialRowsPerPage = 25,
  initialSortBy,
}: {
  rows: T[];
  columns: ColumnDef<T>[];
  searchable?: string[];
  initialRowsPerPage?: number;
  initialSortBy?: ColumnSort;
}) {
  const [query, setQuery] = useState('');
  const prevLength = useRef<number>();

  const filteredItems = searchable ? searchCollection(rows, query, searchable) : rows;

  const table = useReactTable({
    data: filteredItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      sorting: initialSortBy ? [initialSortBy] : [],
      pagination: {
        pageSize: initialRowsPerPage,
      },
    },
  });

  // Go to last page when item added
  const numItems = rows.length;
  useEffect(() => {
    if (prevLength.current && numItems > prevLength.current) {
      table.setPageIndex(table.getPageCount() - 1);
    }
    prevLength.current = numItems;
  }, [numItems]);

  return (
    <>
      {rows.length > table.getState().pagination.pageSize && searchable && (
        <Box px={2}>
          <DebouncedTextField
            initialValue={query}
            onChange={(value: string) => {
              setQuery(value);
              table.setPageIndex(0);
            }}
            size="small"
            type="search"
            label="Search"
            fullWidth
            style={{ maxWidth: 300 }}
          />
        </Box>
      )}

      <DataTable table={table} />
    </>
  );
}
