import { Grid } from '@mui/material';
import EditFormFields, { EditFormFieldsProps } from '../../components/EditFormFields';
import { LayoutProps } from '../../types';
import Layout from '../Layout';

function Component({ model, forwardProps = {} }: LayoutProps<GridLayout>) {
  return (
    <Grid container spacing={model.spacing} columns={model.columns}>
      {model.fields.map((field) => (
        <Grid item xs={12} lg={field.columnSpan} key={field.name}>
          <EditFormFields fields={[field]} defaultLayout={model.defaultLayout} {...forwardProps} />
        </Grid>
      ))}
    </Grid>
  );
}

export default class GridLayout extends Layout {
  columns = 12;

  withColumns(c: number) {
    this.columns = c;
    return this;
  }

  renderLayout(props: Partial<EditFormFieldsProps>): React.ReactElement | null {
    return <Component model={this} forwardProps={props} />;
  }
}
