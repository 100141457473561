import { CSSProperties, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  List,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  LinearProgress,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { startCase } from 'lodash';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useDialogs, useToast } from '@/admin';
import { Kit } from '@/models';
import searchCollection from '@/utils/searchCollection';
import useMutateQueryData from '@/utils/useMutateQueryData';
import KitListItem from '../Kitting/KitListItem';
import KitSkeletonListItem from '../Kitting/KitSkeletonListItem';

export default function OrderKitsList({ orderId }: { orderId: number }) {
  const [query, setQuery] = useState('');
  const [group, setGroup] = useState('');
  const toast = useToast();
  const { confirm } = useDialogs();

  const setKits = useMutateQueryData<Kit[]>(['kitsForOrder', orderId]);
  const { data: kits = [], isLoading } = useQuery(['kitsForOrder', orderId], () =>
    axios.get<{ data: Kit[] }>(`/api/kits?order_id=${orderId}`).then(({ data }) => data.data),
  );

  const performKitAction = (kit: Kit) => (action: 'start' | 'complete' | 'reset') => {
    confirm(
      `${startCase(action)} Kit: ${kit.number || kit.name}`,
      'Are you sure you want to take this action?',
    ).then(() => {
      axios.post<Kit>(`/api/kits/${kit.id}/${action}`).then(({ data }) => {
        setKits((prev) =>
          prev.map((p) => {
            if (p.id === data.id) {
              return data;
            }
            return p;
          }),
        );
        toast(`Kit: ${data.number || data.name} is now ${data.pick_status}`);
      });
    });
  };

  const uniqueGroups = kits.reduce<string[]>((prev, curr) => {
    if (curr.group && !prev.includes(curr.group)) {
      return [...prev, curr.group];
    }
    return prev;
  }, []);

  const filteredKits = searchCollection(kits, query, ['name', 'number', 'bin_number']).filter(
    (kit) => !group || kit.group === group,
  );

  const renderKit = ({ index, style }: { index: number; style: CSSProperties }) => {
    return (
      <KitListItem
        style={style}
        key={index}
        kit={filteredKits[index]}
        performKitAction={performKitAction(filteredKits[index])}
      />
    );
  };

  return (
    <Card>
      <CardHeader title="Kits in Order" titleTypographyProps={{ variant: 'h6' }} />

      {isLoading && <LinearProgress />}

      <CardContent style={{ paddingTop: 2, paddingBottom: 4, display: 'flex' }}>
        {kits.length > 1 && (
          <TextField
            fullWidth
            variant="outlined"
            type="search"
            value={query}
            size="small"
            onChange={(e) => setQuery(e.target.value)}
            label="Search"
          />
        )}
        {uniqueGroups.length > 1 && (
          <FormControl fullWidth sx={{ ml: 2 }} size="small">
            <InputLabel>Group</InputLabel>
            <Select value={group} onChange={(e) => setGroup(e.target.value)} label="Group">
              <MenuItem value="">All Groups</MenuItem>
              {uniqueGroups.map((g) => (
                <MenuItem key={g} value={g}>
                  {g}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </CardContent>

      {kits ? (
        <div style={{ height: 600 }}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={Number(height)}
                width={Number(width)}
                itemSize={72}
                itemCount={filteredKits.length}
                itemKey={(index) => filteredKits[index].id}
              >
                {renderKit}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      ) : (
        <List>
          <KitSkeletonListItem />
          <KitSkeletonListItem />
          <KitSkeletonListItem />
        </List>
      )}
    </Card>
  );
}
