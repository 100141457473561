import { ComponentType } from 'react';
import { green, red, yellow } from '@mui/material/colors';
import _ from 'lodash';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
  Line,
  LineChart,
  AreaChart,
  Area,
  LineProps,
} from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { drawerColor, primaryColor } from '@/MuiProvider';
import { formatNumber } from '@/admin';
import { ReportResultsRow, ReportTypeProps } from '@/responses';

const COLORS = [
  primaryColor,
  drawerColor,
  red[600],
  yellow[400],
  green[600],
  '#fe6d00',
  '#46bdc5',
  '#ab30c4',
  '#c1bc1f',
  '#3949ab',
  '#f975a8',
  '#05695c',
  '#c2185b',
  '#4285f4',
  '#db4437',
];

const getColor = (index: number) => COLORS[index % (COLORS.length - 1)];

export default function ReportGraph({ results, type, columns: columnSettings }: ReportTypeProps) {
  let data = results;
  const columns = _.chain(results).first().omit(['href']).keys().value() as string[];
  const nameKey = columns.shift();
  const valueKey = columns.pop();

  if (!valueKey || !nameKey) {
    return null;
  }

  let lines = [valueKey];

  // { month: 2, year: 2019, amount: 0 }
  // { month: 2, 2019_amount: 0, 2020_amount: 20 }
  // This means we have a series
  if (columns.length > 0) {
    const series = columns.pop() as string;
    lines = [];
    data = _.chain(data)
      .groupBy(nameKey)
      .map((rows, key) => {
        const newRow: ReportResultsRow = { [nameKey]: key };
        rows.forEach((r) => {
          const seriesKey = r[series] as string;
          newRow[seriesKey] = Number(r[valueKey]);
          lines.push(seriesKey);
        });
        return newRow;
      })
      .value();
  } else {
    data = data.map((row) => ({
      ...row,
      [valueKey]: Number(row[valueKey]),
    }));
  }

  lines = _.uniq(lines);

  let GraphChartComponent: ComponentType<CategoricalChartProps> = AreaChart;
  // @ts-ignore
  let GraphLineComponent: ComponentType<LineProps> = Area;

  if (type === 'bar') {
    GraphChartComponent = BarChart;
    // @ts-ignore
    GraphLineComponent = Bar;
  }

  if (type === 'line') {
    GraphChartComponent = LineChart;
    // @ts-ignore
    GraphLineComponent = Line;
  }

  const formatter = (v: string | number) => formatNumber(v, columnSettings?.[valueKey]?.format);

  return (
    <ResponsiveContainer height={400}>
      <GraphChartComponent
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey={nameKey} />
        <YAxis tickFormatter={formatter} />
        <Tooltip formatter={formatter} />

        {lines.length > 1 && <Legend />}

        {lines.map((c, index) => (
          <GraphLineComponent
            key={c}
            type="monotone"
            dataKey={c}
            stroke={getColor(index)}
            fill={getColor(index)}
          />
        ))}
      </GraphChartComponent>
    </ResponsiveContainer>
  );
}
