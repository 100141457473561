import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Button, Grid } from '@mui/material';
import { FieldArrayFieldsProps } from 'redux-form';
import ListField from '../../models/Fields/ListField';
import ReduxField from './ReduxField';

export default function ListElement({
  field,
  fields,
}: {
  field: ListField;
  fields: FieldArrayFieldsProps<any>;
}) {
  return (
    <div>
      {fields.map((f, index) => (
        <Grid container key={f} spacing={2} sx={{ mb: 1 }} alignItems="start">
          {(field.children || []).map((child) => (
            <Grid item xs={12} lg={child.columnSpan} key={child.name}>
              <ReduxField field={child} parentName={f} />
            </Grid>
          ))}
          <Grid item>
            <IconButton
              size="small"
              onClick={() => fields.remove(index)}
              sx={{ marginTop: '10.5px' }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Button color="secondary" onClick={() => fields.push(field.addNewValue)} size="small">
        {field.addNewLabel}
      </Button>
    </div>
  );
}
