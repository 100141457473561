import { Print } from '@mui/icons-material';
import { ButtonAction, FieldFactory, getApiUrl, Resource } from '@/admin';
import { INVENTORY_LOCATION_TYPES } from '@/constants';
import { InventoryLocation } from '@/models';
import { AppConfig } from '@/responses';

const FIELDS = [
  FieldFactory.text('path').sortable().filterable(),
  FieldFactory.select('type', INVENTORY_LOCATION_TYPES).sortable().filterable(),
  FieldFactory.text('description').sortable(),
  FieldFactory.belongsTo('location_group', 'inventoryLocationGroups')
    .sortable('location_group.name')
    .filterable(),
];

export default function inventoryLocations(appConfig: AppConfig) {
  const printable = appConfig.printables.find((p) => p.model === 'location');

  return new Resource<InventoryLocation>('Inventory Locations')
    .getTitleUsing((v) => v.path)
    .getLabelUsing((v) => v.path)
    .getSubtitleUsing((v) => v.location_group?.name)
    .withBulkActions([
      new ButtonAction(
        'Print',
        ({ selected }) => {
          window.open(getApiUrl(`/api/printables/${printable?.id}/render/${selected.join(',')}`));
        },
        Print,
      ),
    ])
    .withFieldsAndColumns(FIELDS);
}
