import _ from 'lodash';

export function tryJsonParse(str: string): any {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export function addSearchParams(url: string, object: Record<string, any>): string {
  const [path, search] = url.split('?');
  const searchParams = new URLSearchParams(search);
  _.forOwn(object, (v, k) => {
    if (v) {
      searchParams.set(k, v);
    }
  });
  return `${path}?${searchParams.toString()}`;
}

export function removeSearchParams(url: string, ...params: string[]): string {
  const [path, search] = url.split('?');
  const searchParams = new URLSearchParams(search);
  params.forEach((k) => {
    searchParams.delete(k);
  });
  return _.trimEnd(`${path}?${searchParams.toString()}`, '?');
}
