import { Chip, List, ListItem, ListItemText } from '@mui/material';
import { PoTrackingItem } from '@/models';

export default function PurchaseOrderTrackingItems({ items }: { items: PoTrackingItem[] }) {
  return (
    <List>
      {items.map((i) => (
        <ListItem key={i.id} dense>
          <ListItemText
            primary={i.sku}
            secondary={i.sku !== i.style_number && i.style_number && `Style: ${i.style_number}`}
          />
          <ListItemText sx={{ ml: 'auto', flexGrow: 0 }}>
            <Chip label={i.qty} size="small" />
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
