import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import { WrappedFieldProps } from 'redux-form';
import DateRangeField from '../../models/Fields/DateRangeField';
import { FieldProps } from '../../types';
import Field from '../Field';

function Date({ input, meta, field }: FieldProps<DateField>) {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label={field.label}
          value={input.value}
          onChange={(v) => input.onChange(v)}
          readOnly={Boolean(field.readOnly)}
          disabled={field.disabled}
          renderInput={({ error, ...props }) => (
            <TextField {...field.getTextFieldProps(meta)} {...props} />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}

export default class DateField extends Field {
  format = 'll';

  getFilterFieldForType() {
    return new DateRangeField(this.name);
  }

  renderEditComponent(props: WrappedFieldProps) {
    return <Date {...props} field={this} />;
  }

  renderCell(value: any) {
    if (value) {
      return <span>{moment(value).local().format(this.format)}</span>;
    }
    return null;
  }
}
