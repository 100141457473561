import { OpenInNew } from '@mui/icons-material';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import { OrderItem } from '@/models';

type DesignLayoutItem = Pick<
  OrderItem,
  'id' | 'is_custom' | 'number' | 'color' | 'size' | 'description' | 'image' | 'sizes' | 'vendor'
>;

export default function ProofItemDialog({
  open,
  onClose,
  layoutId,
  orderId,
}: {
  open: boolean;
  onClose: () => void;
  layoutId: number;
  orderId: number;
}) {
  const { data: items } = useQuery(['proofItems', layoutId, orderId], () =>
    axios
      .get<{ data: DesignLayoutItem[] }>(`/api/design-layouts/${layoutId}/items`)
      .then(({ data }) => data.data),
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Products for Layout</DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        {!items ? (
          <CircularProgress />
        ) : (
          <List>
            {items.map((item) => (
              <ListItem key={item.id} style={{ width: 600, maxWidth: '100%' }}>
                <OrderItemProduct item={item}>
                  {item.vendor && (
                    <Typography variant="body2" color="textSecondary">
                      Vendor: {item.vendor.name}
                    </Typography>
                  )}
                </OrderItemProduct>

                {item.image && (
                  <ListItemSecondaryAction>
                    <Tooltip title="View/Download Image">
                      <IconButton component="a" target="_blank" href={item.image} size="large">
                        <OpenInNew />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
            {items.length === 0 && (
              <ListItem>
                <Typography color="textSecondary" variant="body2">
                  There are no items attached to this layout in the order&apos;s production spec.
                </Typography>
              </ListItem>
            )}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
}
