import { SyntheticEvent, useState } from 'react';
import { Search, AddCircle, Delete, Edit } from '@mui/icons-material';
import {
  List,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  ListItemSecondaryAction,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import {
  ClosableDrawer,
  useFormValues,
  Can,
  StatusChip,
  useDialogs,
  BulletSeparatedList,
  generateFormName,
  FieldFactory,
  useOnReloadRecord,
  useHasPermission,
} from '@/admin';
import OrderEmail from '@/components/Orders/OrderEmail';
import WarningIcon from '@/components/Shared/WarningIcon';
import { Contact, Order } from '@/models';
import useMutateQueryData from '@/utils/useMutateQueryData';
import useOrderableApiUrl from '@/utils/useOrderableApiUrl';

function ContactListItemText({ contact: c, linkEmail }: { contact: Contact; linkEmail?: boolean }) {
  return (
    <div style={{ margin: '2px 0' }}>
      <Typography style={{ marginBottom: 2 }}>
        <span style={{ fontWeight: 500 }}>{c.name}</span>
        {c.title && (
          <>
            <span>&nbsp;&bull;&nbsp;</span>
            <Typography variant="body2" component="span">
              {c.title}
            </Typography>
          </>
        )}
      </Typography>

      <Typography variant="body2" color="textSecondary">
        <BulletSeparatedList>
          {c.email && (
            <li>
              {linkEmail ? (
                <OrderEmail orderStatePath={`form.${generateFormName('orders')}.values`}>
                  {c.email}
                </OrderEmail>
              ) : (
                c.email
              )}
            </li>
          )}
          {c.phone && <li>{c.phone}</li>}
        </BulletSeparatedList>
      </Typography>
    </div>
  );
}

export default function ContactChooser({ isReadOnly }: { isReadOnly?: boolean }) {
  const [customerId, name, firstName, lastName, email, phone, title] = useFormValues((o: Order) => [
    o.customer_id,
    o.contact_name,
    o.contact_first_name,
    o.contact_last_name,
    o.contact_email,
    o.contact_phone,
    o.contact_title,
  ]);

  const currentContact: Contact = {
    name,
    first_name: firstName,
    last_name: lastName,
    email,
    phone,
    title,
  };
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState<Contact>();
  const [submitting, setSubmitting] = useState(false);
  const { confirm, prompt } = useDialogs();
  const baseUrl = useOrderableApiUrl();

  const setContacts = useMutateQueryData<Contact[]>(['customerContacts', customerId]);
  const { data: contacts = [], isLoading } = useQuery(
    ['customerContacts', customerId],
    () =>
      axios.get(`/api/customers/${customerId}/contacts?count=200`).then(({ data }) => data.data),
    {
      enabled: open,
    },
  );

  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);
  const onReload = useOnReloadRecord();
  const hasPermission = useHasPermission();

  const contactFields = [
    FieldFactory.text('first_name'),
    FieldFactory.text('last_name'),
    FieldFactory.text('title'),
    FieldFactory.belongsTo('department', 'departments').withRequestParams({
      customer: customerId,
      with: 'set',
    }),
    FieldFactory.email('email'),
    FieldFactory.phone('phone'),
    FieldFactory.custom('is_invoice_contact', ({ input }) => {
      if (input.value) {
        return <StatusChip status="AP Contact" colors={{ 'AP Contact': 'red' }} />;
      }
      return null;
    }),
    FieldFactory.custom('is_invoice_contact', ({ input }) => {
      if (input.value) {
        return <StatusChip status="Approval Contact" colors={{ 'Approval Contact': 'green' }} />;
      }
      return null;
    }),
  ];

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!contact) {
      return null;
    }

    setSubmitting(true);
    const values = {
      contact_first_name: contact.first_name,
      contact_last_name: contact.last_name,
      contact_email: contact.email,
      contact_phone: contact.phone,
      contact_title: contact.title,
    };
    return axios
      .put(baseUrl, values)
      .then(() => {
        setContact(undefined);
        setQuery('');
        onClose();
        onReload();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const createContact = () => {
    prompt({
      title: 'Create Contact',
      fields: contactFields,
      initialValues: {},
      onSubmit: (v) => axios.post(`/api/customers/${customerId}/contacts/`, v),
    }).then(({ data }) => {
      setContacts((prev) => [...prev, data]);
    });
  };

  const editContact = (c: Contact) => {
    prompt({
      title: 'Edit Contact',
      fields: contactFields,
      initialValues: c,
      onSubmit: (v) => axios.put(`/api/customers/${customerId}/contacts/${c.id}?with=`, v),
    }).then(({ data }) => {
      setContacts((prev) =>
        prev.map((p) => {
          if (p.id === data.id) {
            return data;
          }
          return p;
        }),
      );
    });
  };

  const deleteContact = (c: Contact) => {
    confirm('Delete Contact', 'Are you sure you want to delete this contact?').then(() => {
      axios.delete(`/api/customers/${customerId}/contacts/${c.id}`);
      setContacts((prev) => _.reject(prev, ['id', c.id]));
      if (contact?.id === c.id) {
        setContact(undefined);
      }
    });
  };

  const toggleSelection = (c: Contact) => {
    setContact((prev) => (prev?.id === c.id ? undefined : c));
  };

  const getInner = () => {
    if (isLoading) {
      return <CircularProgress />;
    }
    if (contacts.length === 0) {
      return <Alert severity="warning">There are no contacts set up for this customer.</Alert>;
    }

    const search = (c: Contact) => {
      if (!query) {
        return true;
      }
      return _.chain(c).values().join(' ').lowerCase().includes(query).value();
    };

    return (
      <div>
        <TextField
          label="Search"
          variant="outlined"
          type="search"
          size="small"
          fullWidth
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps={{
            type: 'search',
          }}
        />
        <Box mx={-1} py={2} style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 188px)' }}>
          {_.chain(contacts)
            .filter(search)
            .orderBy(['department.name', 'first_name'], ['asc', 'asc'])
            .groupBy((c) => _.get(c, 'department.name', '(No Department)'))
            .map((groupedContacts, department) => (
              <List
                key={department}
                subheader={<Typography variant="subtitle2">{department}</Typography>}
              >
                {groupedContacts.map((c) => (
                  <ListItemButton key={c.id} dense onClick={() => toggleSelection(c)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={_.get(contact, 'id') === c.id}
                        tabIndex={-1}
                        disableRipple
                        size="small"
                      />
                    </ListItemIcon>
                    <ContactListItemText contact={c} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => editContact(c)} size="small">
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => deleteContact(c)} size="small">
                        <Delete fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                ))}
              </List>
            ))
            .value()}
        </Box>
      </div>
    );
  };

  const icons = (
    <Tooltip title="Create Contact">
      <IconButton onClick={createContact} size="large">
        <AddCircle />
      </IconButton>
    </Tooltip>
  );

  return (
    <Box display="flex" alignItems="center">
      {currentContact.name ? (
        <ContactListItemText contact={currentContact} linkEmail />
      ) : (
        <WarningIcon
          message="No Contact Specified"
          onClick={hasPermission('write:orders') ? onOpen : undefined}
        />
      )}

      {!isReadOnly && (
        <Can permission="write:orders">
          <Box ml="auto" pl={1}>
            <Tooltip title="Choose from Customer">
              <IconButton onClick={onOpen} size="large">
                <Search />
              </IconButton>
            </Tooltip>
          </Box>
        </Can>
      )}

      <ClosableDrawer
        title="Choose Contact"
        open={open}
        onClose={onClose}
        icons={icons}
        width={650}
      >
        <form onSubmit={handleSubmit}>
          {getInner()}
          <Button
            type="submit"
            disabled={!contact || submitting}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Choose
          </Button>
        </form>
      </ClosableDrawer>
    </Box>
  );
}
