import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  CircularProgress,
  ListItemButton,
  ListItemSecondaryAction,
  List,
  Checkbox,
  ListItemText,
  Typography,
  Stack,
  TextField,
  FormLabel,
  styled,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment, { Moment } from 'moment';
import { ClosableDrawer, numString } from '@/admin';
import { useSchedulingState } from '@/contexts/SchedulingContext';
import { ProductionEvent, ProductionMachine } from '@/models';

const ListContainer = styled('div')(({ theme }) => ({
  maxHeight: 400,
  overflowY: 'auto',
  border: `1px solid ${theme.palette.action.disabled}`,
  borderRadius: 4,
}));

export default function ScheduleDrawer() {
  const { eventIds, onSuccess, setScheduling } = useSchedulingState();
  const [machine, setMachine] = useState<ProductionMachine | null>(null);
  const [date, setDate] = useState<Moment | null>(null);

  const open = eventIds?.length > 0;
  const onClose = () => setScheduling({ eventIds: [] });
  const dateString = date?.format('YYYY-MM-DD');
  const getPayload = () => ({
    date: dateString,
    machine_id: machine?.id,
    event_ids: eventIds,
  });

  useEffect(() => {
    setDate(null);
    setMachine(null);
  }, [open]);

  const { data: machines, isLoading: isLoadingMachines } = useQuery(
    ['eventMachines', eventIds.join('|'), dateString],
    () =>
      axios
        .get<{ data: ProductionMachine[] }>('/api/production-machines', {
          params: { date: dateString, event_ids: eventIds.join(',') },
        })
        .then(({ data }) => data.data),
    {
      enabled: Boolean(date && open),
      onSuccess: (res) => {
        if (machine && !res.some((r) => r.id === machine.id)) {
          setMachine(null);
        }
      },
    },
  );

  const { data: review, isLoading: isLoadingReview } = useQuery(
    ['schedulingReview', JSON.stringify(getPayload())],
    () =>
      axios
        .post<{
          additional_minutes: number;
          total_minutes: number;
        }>('/api/production-events/review', getPayload())
        .then(({ data }) => data),
    {
      enabled: Boolean(date && machine && open),
    },
  );

  const { mutate, isLoading: isSubmitting } = useMutation(
    () =>
      axios
        .post<{ data: ProductionEvent[] }>('/api/production-events/schedule', getPayload())
        .then(({ data }) => data.data),
    {
      onSuccess: () => {
        onClose();
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );

  const eventsString = numString(eventIds.length || 0, 'Events');

  return (
    <ClosableDrawer title={`Schedule ${eventsString}`} open={open} onClose={onClose}>
      <Stack spacing={2}>
        <div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Date"
              value={date}
              onChange={setDate}
              renderInput={(p) => <TextField {...p} />}
            />
          </LocalizationProvider>
        </div>

        {machines && (
          <div>
            <FormLabel>Machine</FormLabel>
            <ListContainer>
              <List dense disablePadding>
                {machines.map((m) => (
                  <ListItemButton key={m.id} onClick={() => setMachine(m)}>
                    <ListItemText
                      primary={m.name}
                      secondary={`Scheduled for ${m.minutes_scheduled} minutes`}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox checked={machine?.id === m.id} />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                ))}
              </List>
            </ListContainer>
          </div>
        )}

        {(isLoadingMachines || isLoadingReview) && (
          <div>
            <CircularProgress size="small" />
          </div>
        )}

        {review && machine && (
          <Typography>
            Scheduling {eventsString} on <i>{machine.name}</i> will take
            <b> {review.additional_minutes} minutes</b> and bring the {moment(date).format('l')}{' '}
            total for the machine to
            <b> {review.total_minutes} minutes</b>.
          </Typography>
        )}
      </Stack>

      <LoadingButton
        loading={isSubmitting}
        type="button"
        variant="contained"
        onClick={() => mutate()}
        sx={{ mt: 3 }}
      >
        Schedule
      </LoadingButton>
    </ClosableDrawer>
  );
}
