import { Grid } from '@mui/material';
import { useFormValues } from '@/admin';
import EventableCard from '@/components/Notes/EventableCard';
import POMatchingCard from '@/components/Transactions/POMatchingCard';
import { Transaction } from '@/models';
import BillPaymentsCard from './BillPaymentsCard';
import TransactionFields from './TransactionFields';

export default function TransactionEditor({ isCreate }: { isCreate: boolean }) {
  const [id, type] = useFormValues((t: Transaction) => [t.id, t.type]);

  if (isCreate || type === 'bill_payment') {
    return <TransactionFields />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={9}>
        <TransactionFields />
        <EventableCard resource="transactions" resourceId={id} />
      </Grid>
      <Grid item xs={12} lg={3}>
        <POMatchingCard />
        {type === 'bill' && <BillPaymentsCard />}
      </Grid>
    </Grid>
  );
}
