import { ReactNode } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Design } from '@/models';
import ComplexityLabel from './ComplexityLabel';
import DesignColorsTable from './DesignColorsTable';

function Label({ children }: { children: ReactNode }) {
  return (
    <TableCell variant="head">
      <Box color="text.secondary" whiteSpace="nowrap">
        {children}
      </Box>
    </TableCell>
  );
}

export default function DesignCard({ design, sx = {} }: { design: Design; sx?: SxProps }) {
  return (
    <Card sx={sx}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {design.image && (
              <a href={design.image} target="_blank" rel="noreferrer">
                <img
                  src={design.image}
                  alt=""
                  style={{ width: '100%', maxHeight: 260, objectFit: 'contain' }}
                />
              </a>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <Table size="small">
        <TableBody>
          <TableRow>
            <Label>Dec Type</Label>
            <TableCell>{design.decoration_type?.name}</TableCell>
          </TableRow>
          <TableRow>
            <Label>
              <ComplexityLabel design={design} />
            </Label>
            <TableCell>{design.complexity}</TableCell>
          </TableRow>
          <TableRow>
            <Label>Design Code</Label>
            <TableCell>{design.design_code}</TableCell>
          </TableRow>
          <TableRow>
            <Label>Height</Label>
            <TableCell>{design.height}</TableCell>
          </TableRow>
          <TableRow>
            <Label>Width</Label>
            <TableCell>{design.width}</TableCell>
          </TableRow>
          {design.attachments && (
            <TableRow>
              <Label>Files</Label>
              <TableCell component="th">
                {design.attachments
                  .filter((a) => !a.group || a.group === 'files')
                  .map((a) => (
                    <div key={a.id}>
                      <a href={a.url} target="_blank" rel="noreferrer">
                        {a.name}
                      </a>
                    </div>
                  ))}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <CardContent>
        <Typography variant="subtitle2" color="textSecondary">
          Colors
        </Typography>
      </CardContent>
      <DesignColorsTable
        colors={design.colors}
        hasUnderbase={design.has_underbase}
        decorationUnit={design.decoration_type?.unit}
      />
    </Card>
  );
}
