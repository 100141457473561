import { useSelector } from 'react-redux';
import { getFormValues, WrappedFieldProps } from 'redux-form';
import { Field, FieldProps, GenericField } from '@/admin';

function ConditionallyRender({ input, meta, field }: FieldProps<ConditionallyRenderField>) {
  const shouldRender = useSelector((state: any) =>
    field.renderIf(getFormValues(meta.form)(state) || {}),
  );

  if (shouldRender) {
    return <GenericField input={input} meta={meta} field={field.subField} />;
  }
  return null;
}

export default class ConditionallyRenderField extends Field {
  subField: Field;
  renderIf: (s: any) => boolean;

  constructor(name: string, subField: Field, renderIf: (s: any) => boolean) {
    super(name);
    this.subField = subField;
    this.renderIf = renderIf;
  }

  renderEditComponent(props: WrappedFieldProps): React.ReactElement | null {
    return <ConditionallyRender {...props} field={this} />;
  }
}
