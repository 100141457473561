import { FieldFactory, Resource } from '@/admin';
import { requiredFields } from '@/helpers';
import { StockColor } from '@/models';

export default function stockColors() {
  return new Resource<StockColor>('Stock Colors')
    .withValidation(requiredFields(['unit', 'name', 'code', 'hex']))
    .withColumns([FieldFactory.text('id').withLabel('#').sortable()])
    .addFieldsAndColumns([
      FieldFactory.select('unit', ['colors', 'stiches']).sortable().filterable(),
      FieldFactory.text('name').sortable(),
      FieldFactory.text('code').sortable(),
      FieldFactory.color('hex'),
      FieldFactory.number('priority').sortable(),
      FieldFactory.number('sort').withHelp('Higher numbers will show up first').sortable(),
    ]);
}
