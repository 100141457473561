import { List, ListItemButton, ListItemText, Grid, Card, CardHeader } from '@mui/material';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useTitle, useRequiresPermissionAndRole } from '@/admin';
import { direct } from '@/pages';

export default function Settings() {
  useTitle('Settings');

  const requiresPermissionAndRole = useRequiresPermissionAndRole();
  return (
    <Grid container spacing={3}>
      {_.chain(direct)
        .filter(requiresPermissionAndRole)
        .groupBy('group')
        .map((pages, group) => (
          <Grid item lg={3} md={6} xs={12} key={group}>
            <Card>
              <CardHeader title={group} />
              <List component="nav" dense>
                {pages.map((page) => (
                  <ListItemButton key={page.href} to={page.href} component={Link}>
                    <ListItemText primary={page.name} />
                  </ListItemButton>
                ))}
              </List>
            </Card>
          </Grid>
        ))
        .value()}
    </Grid>
  );
}
