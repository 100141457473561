import { useEffect, useState } from 'react';
import { Typography, Button, List, CircularProgress, TextField, Stack, Box } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { Moment } from 'moment';
import { ClosableDrawer, FieldFactory, NonReduxField, useDebounce } from '@/admin';
import BankTransactionListItem from '@/components/Banking/BankTransactionListItem';
import { TRANSACTION_TYPES } from '@/constants';
import { BankTransaction, Deposit, Transaction } from '@/models';
import TransactionListItem from './TransactionListItem';

export default function FindMatchDrawer({
  reviewing,
  setReviewing,
  onSuccess,
}: {
  reviewing: BankTransaction | null;
  setReviewing: (t: BankTransaction | null) => void;
  onSuccess: (t: Transaction) => void;
}) {
  const [type, setType] = useState<Transaction['type'] | 'deposit'>('expense');
  const [dates, setDates] = useState<[Moment | null, Moment | null]>([null, null]);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [selected, setSelected] = useState<Transaction | Deposit>();

  useEffect(() => {
    if (reviewing) {
      setType('expense');
      setQuery('');
      setDates([null, null]);
    }
  }, [reviewing?.id]);

  const { data: results } = useQuery(
    ['findMatchResults', reviewing?.account_id, type, debouncedQuery, JSON.stringify(dates)],
    () => {
      let url = '/api/transactions';
      let params = _.pickBy({
        query,
        'filter[transaction_date][gte]': dates[0]?.format('YYYY-MM-DD'),
        'filter[transaction_date][lte]': dates[1]?.format('YYYY-MM-DD'),
        'filter[type]': type,
        'filter[bank_account_id]': reviewing?.account_id,
        no_bank_transactions: 1,
      });
      if (type === 'deposit') {
        url = '/api/deposits';
        params = _.pickBy({
          query,
          'filter[bank_account_id]': reviewing?.account_id,
          'filter[deposit_date][gte]': dates[0]?.format('YYYY-MM-DD'),
          'filter[deposit_date][lte]': dates[1]?.format('YYYY-MM-DD'),
          no_bank_transactions: 1,
        });
      }

      return axios
        .get<{ data: Transaction[] | Deposit[] }>(url, { params })
        .then(({ data }) => data.data);
    },
    {
      enabled: Boolean(reviewing),
    },
  );

  const { mutate, isLoading: isSubmitting } = useMutation(() => {
    if (!selected || !reviewing) {
      return Promise.reject();
    }
    const matchKey = type === 'deposit' ? 'deposit_id' : 'transaction_id';
    return axios
      .post(`/api/bank-transactions/${reviewing.id}/match`, {
        [matchKey]: selected.id,
      })
      .then(({ data }) => {
        onSuccess(data);
      });
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ClosableDrawer open={!!reviewing} onClose={() => setReviewing(null)} title="Find Match">
        {reviewing && (
          <List subheader="Finding Match For" sx={{ mb: 2 }}>
            <BankTransactionListItem transaction={reviewing} />
          </List>
        )}

        <Stack spacing={2}>
          <NonReduxField
            value={type}
            onChange={(t) => setType(t as Transaction['type'])}
            field={FieldFactory.select('type', TRANSACTION_TYPES)}
          />

          <DateRangePicker
            onChange={setDates}
            value={dates}
            renderInput={(startProps, endProps) => (
              <>
                <TextField size="small" {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField size="small" {...endProps} />
              </>
            )}
          />

          <TextField
            label="Query"
            size="small"
            type="search"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            fullWidth
          />
        </Stack>

        {!results ? (
          <CircularProgress />
        ) : (
          <List>
            {results.map((result) => (
              <TransactionListItem
                key={result.id}
                transaction={result}
                onSelect={() => setSelected(result)}
                selected={result.id === selected?.id}
              />
            ))}
          </List>
        )}

        {results?.length === 0 && (
          <Typography variant="body2" color="textSecondary">
            No results found.
          </Typography>
        )}

        <Button
          sx={{ mt: 2 }}
          type="submit"
          variant="contained"
          disabled={!selected || isSubmitting}
          onClick={() => mutate()}
        >
          Link Transaction
        </Button>
      </ClosableDrawer>
    </LocalizationProvider>
  );
}
