import {
  CircularProgress,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Radio,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { curr, FieldProps, useSpecificFormValues } from '@/admin';
import InventoryText from '@/components/Products/InventoryText';
import { SkuResource } from '@/models';

export default function CompareVendors({ input, meta }: FieldProps) {
  const variant = useSpecificFormValues(meta.form, (f: { variant: SkuResource }) => f.variant);

  const { data: variants } = useQuery(['variantsForProduct', variant.product.id], () =>
    axios
      .get<{ data: SkuResource[] }>(`/api/variants/${variant.id}/other-vendors`)
      .then(({ data }) => data.data),
  );

  if (!variants) {
    return <CircularProgress />;
  }

  return (
    <Table size="small" style={{ whiteSpace: 'nowrap' }}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>Vendor</TableCell>
          <TableCell>SKU</TableCell>
          <TableCell>Cost</TableCell>
          <TableCell>Vendor Inventory</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {variants.map((variant) => (
          <TableRow key={variant.id}>
            <TableCell>
              <Radio
                checked={_.get(input.value, 'id') === variant.id}
                onClick={() => input.onChange(variant)}
              />
            </TableCell>
            <TableCell>{variant.product.vendor.name}</TableCell>
            <TableCell>{variant.sku}</TableCell>
            <TableCell>{curr(variant.cost)}</TableCell>
            <TableCell>
              <InventoryText variant={variant} hideAvail />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
