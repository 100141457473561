import { ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';

export default function KitSkeletonListItem() {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant="circular" width={48} height={48} />
      </ListItemAvatar>
      <ListItemText>
        <Skeleton variant="text" />
      </ListItemText>
    </ListItem>
  );
}
