import { ReactNode } from 'react';
import { Grid, Typography, Divider, Card, CardHeader, CardContent, Box } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import {
  Can,
  ReduxField,
  StatusChip,
  TextButton,
  useDialogs,
  EditFormFields,
  useRecord,
  FieldFactory,
  GridLayout,
  useOnReloadRecord,
  useUpdateRecord,
} from '@/admin';
import PurchaseOrderDesignLayoutsCard from '@/components/Purchasing/PurchaseOrderDesignLayoutsCard';
import PurchaseOrderInboundShipmentsCard from '@/components/Purchasing/PurchaseOrderInboundShipmentsCard';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import { PURCHASE_ORDER_STATUS_COLORS } from '@/constants';
import { PurchaseOrderWithItems, Vendor } from '@/models';
import GenericAddressField from '../Addresses/GenericAddressField';
import EventableCard from '../Notes/EventableCard';
import PurchaseOrderActions from './PurchaseOrderActions';
import PurchaseOrderItems from './PurchaseOrderItems';
import PurchaseOrderMatchingCard from './PurchaseOrderMatchingCard';
import PurchaseOrderStatuses from './PurchaseOrderStatuses';

function VendorField({ key, value }: { key: keyof Vendor; value: ReactNode }) {
  return (
    <Grid item md={3} xs={6}>
      <Typography variant="subtitle2" color="textSecondary">
        {key === 'url' ? 'Website' : _.startCase(key)}
      </Typography>
      <Typography>{value || '(none)'}</Typography>
    </Grid>
  );
}

export default function PurchaseOrderPage() {
  const po = useRecord<PurchaseOrderWithItems>();
  const onUpdated = useUpdateRecord();
  const onReload = useOnReloadRecord();
  const { prompt } = useDialogs();

  const orderIds = po.items.reduce<number[]>((orderIds, poItem) => {
    if (poItem.order_id && orderIds.includes(poItem.order_id)) {
      return [...orderIds, poItem.order_id];
    }
    return [];
  }, []);

  const changeVendor = () => {
    prompt({
      title: 'Change Vendor',
      description: `If there are database products on this PO, it will attempt to switch them the database product under the new vendor.
       Otherwise, they will become custom products.`,
      fields: [FieldFactory.belongsTo('vendor', 'vendors')],
      initialValues: { vendor: po.vendor },
      onSubmit: (v) => axios.put(`/api/purchase-orders/${po.id}`, v),
    }).then(() => {
      onReload();
    });
  };

  const title = (
    <Typography variant="h5">
      <TextButton onClick={changeVendor}>{po.vendor.name}</TextButton> {po.increment_id}
    </Typography>
  );
  return (
    <Grid container spacing={3}>
      <Grid item lg={9} xs={12}>
        <Box mb={2}>
          <Card>
            <CardHeader title={title} action={<StatusChip status={_.startCase(po.type)} />} />
            <CardContent>
              <Grid container spacing={3}>
                <VendorField key="account_number" value={po.vendor.account_number} />
                <VendorField key="email" value={po.vendor.email} />
                <VendorField key="phone" value={po.vendor.phone} />
                <VendorField key="url" value={po.vendor.url} />
              </Grid>
            </CardContent>
            <Divider />
            <CardContent>
              <EditFormFields
                fields={[
                  FieldFactory.text('description'),
                  FieldFactory.text('invoice_number'),
                  FieldFactory.date('expected_date'),
                ]}
                defaultLayout={GridLayout}
              />
            </CardContent>
          </Card>
        </Box>
        <Card>
          <CardContent>
            <Grid container spacing={3} alignItems="center">
              <Grid item md={4} xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Dates on PO
                </Typography>
                <ReduxField field={FieldFactory.date('issued_date')} />
                <ReduxField field={FieldFactory.date('requested_ship_date')} />
                <ReduxField field={FieldFactory.date('drop_dead_date')} />
              </Grid>
              <Grid item md={3} xs={0} />
              <Grid item md={5} xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Shipping To
                </Typography>
                <ReduxField field={new GenericAddressField('address', orderIds, true)} />
              </Grid>
            </Grid>
          </CardContent>
          <PurchaseOrderItems purchaseOrder={po} onUpdated={onUpdated} onReload={onReload} />
        </Card>
      </Grid>
      <Grid item lg={3} xs={12}>
        <CardWithGutter>
          <CardContent>
            <StatusChip
              status={po.status}
              style={{ display: 'flex' }}
              colors={PURCHASE_ORDER_STATUS_COLORS}
            />
            <Can permission="write:purchase_orders">
              <Box my={1}>
                <PurchaseOrderActions />
              </Box>
            </Can>
          </CardContent>

          <EventableCard resource="purchase-orders" resourceId={po.id} elevation={0} size="small" />
        </CardWithGutter>

        <Can permission="write:purchase_orders">
          <PurchaseOrderDesignLayoutsCard poId={po.id} />
        </Can>

        <Card sx={{ mb: 2 }}>
          <PurchaseOrderStatuses purchaseOrder={po} />
        </Card>

        <PurchaseOrderInboundShipmentsCard poId={po.id} />

        <Can permission="write:transactions">
          <PurchaseOrderMatchingCard poId={po.id} vendorId={po.vendor.id} />
        </Can>

        <Card sx={{ mb: 2 }}>
          <CardHeader title="Advanced" />
          <CardContent>
            <ReduxField
              field={FieldFactory.belongsTo('business', 'businesses').withHelp(
                'This is the logo that will display on the PO',
              )}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
