import { useEffect } from 'react';
import { Delete } from '@mui/icons-material';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  IconButton,
  TableContainer,
} from '@mui/material';
import { WrappedFieldArrayProps } from 'redux-form';
import { curr, FieldFactory, ReduxField } from '@/admin';

export default function AccountItems({ fields, sum }: WrappedFieldArrayProps & { sum: number }) {
  useEffect(() => {
    if (fields.length === 0) {
      fields.push({});
    }
  }, []);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((f, i) => (
            <TableRow key={f}>
              <TableCell style={{ minWidth: 250, paddingTop: 0, paddingBottom: 4 }}>
                <ReduxField
                  field={FieldFactory.belongsTo('transactable', 'accounts').withoutLabel()}
                  parentName={f}
                />
              </TableCell>
              <TableCell style={{ minWidth: 150 }}>
                <ReduxField
                  field={FieldFactory.text('description').withoutLabel()}
                  parentName={f}
                />
              </TableCell>
              <TableCell style={{ minWidth: 150 }}>
                <ReduxField field={FieldFactory.curr('amount').withoutLabel()} parentName={f} />
              </TableCell>
              <TableCell>
                {i > 0 && (
                  <IconButton onClick={() => fields.remove(i)} size="large">
                    <Delete fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <Button onClick={() => fields.push({})}>Add Item</Button>
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>Total</TableCell>
            <TableCell>
              <b>{curr(sum)}</b>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
