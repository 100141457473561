import { useState } from 'react';
import { CloudDownload, Delete, Edit, InfoOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import {
  AnchorAction,
  ButtonAction,
  getIconFromFileName,
  numString,
  SubMenu,
  useDialogs,
} from '@/admin';
import UserLabel from '@/components/Users/UserLabel';
import { Attachment } from '@/models';

export default function AttachmentsList({
  attachments,
  onEdit,
  onRemove,
  compact,
}: {
  attachments: Attachment[];
  onEdit?: (a: Attachment) => void;
  onRemove?: (a: Attachment) => void;
  compact?: boolean;
}) {
  const { alert } = useDialogs();
  const [expanded, setExpanded] = useState(false);

  const onInfo = (a: Attachment) => {
    alert(
      'Attachment Info',
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Name</TableCell>
            <TableCell>{a.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">File</TableCell>
            <TableCell>{a.file}</TableCell>
          </TableRow>
          {a.group && (
            <TableRow>
              <TableCell variant="head">Group</TableCell>
              <TableCell>{a.group}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell variant="head">Date</TableCell>
            <TableCell>{moment(a.created_at).format('lll')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">User</TableCell>
            <TableCell>
              <UserLabel user={a.created_by_user} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>,
    );
  };

  if (compact && !expanded) {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="body2">{numString(attachments.length, 'Files')} Uploaded</Typography>
        <Box ml="auto">
          <Button onClick={() => setExpanded(true)} size="small">
            Show All
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <List>
      {attachments.map((a) => {
        const Icon = getIconFromFileName(a.file);
        let renderableImage = a.image;
        if (!renderableImage) {
          const fileIsImage = ['.png', '.svg', '.jpg', '.jpeg', '.gif', '.webp'].reduce(
            (prev, curr) => prev || a.file.endsWith(curr),
            false,
          );
          if (fileIsImage) {
            renderableImage = a.file;
          }
        }

        return (
          <ListItemButton dense key={a.id || a.url} component="a" target="_blank" href={a.url}>
            <ListItemAvatar style={{ minWidth: 48 }}>
              <Avatar
                sx={{ height: 32, width: 32, fontSize: '1rem', bgcolor: 'grey.400' }}
                src={
                  renderableImage
                    ? `${renderableImage.split('?')[0]}?w=100&h=100&fit=cover`
                    : undefined
                }
                variant="rounded"
              >
                <Icon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                title={a.name || a.file}
              >
                {a.name || a.file}
              </div>
            </ListItemText>
            <ListItemSecondaryAction>
              <SubMenu
                items={_.filter([
                  new AnchorAction('Download', a.download_url, true).withIcon(CloudDownload),
                  new ButtonAction('View Info', () => onInfo(a)).withIcon(InfoOutlined),
                  onEdit && new ButtonAction('Rename', () => onEdit(a)).withIcon(Edit),
                  onRemove && new ButtonAction('Delete', () => onRemove(a)).withIcon(Delete),
                ])}
              />
            </ListItemSecondaryAction>
          </ListItemButton>
        );
      })}
    </List>
  );
}
