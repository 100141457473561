import { createElement } from 'react';
import { Tooltip, useMediaQuery } from '@mui/material';
import ActionIconButton from '../../components/Actions/ActionIconButton';
import SubMenu from '../../components/SubMenu';
import useOnClickProps from '../../hooks/useOnClickProps';
import CustomAction from '../../models/Actions/CustomAction';
import Resource from '../../models/Resource';
import { Actions } from '../../types';

export default function ActionsRenderer({
  actions,
  resource,
}: {
  actions: Actions;
  resource: Resource;
}) {
  const toPass = useOnClickProps();
  Object.assign(toPass, { resource });
  const isMobile = useMediaQuery('(max-width: 600px)');

  if (!Array.isArray(actions)) {
    return createElement(actions, toPass);
  }

  const actionsArray = actions.filter((a) => !(a instanceof CustomAction));
  const primaryActions = actions.filter((a) => a instanceof CustomAction);

  if (!isMobile) {
    while (primaryActions.length < 2) {
      const nextAction = actionsArray.shift();
      if (nextAction) {
        primaryActions.push(nextAction);
      } else {
        break;
      }
    }
  }

  return (
    <>
      {primaryActions.map((action) => {
        if (action instanceof CustomAction) {
          return createElement(action.renderFunc, {
            ...toPass,
            key: action.label,
          });
        }

        return (
          <Tooltip key={action.label} title={action.label}>
            <ActionIconButton model={action} />
          </Tooltip>
        );
      })}

      {actionsArray.length > 0 && <SubMenu items={actionsArray} />}
    </>
  );
}
