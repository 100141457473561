import { Button } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { reduxForm, InjectedFormProps } from 'redux-form';
import {
  EditFormFields,
  FieldFactory,
  StackLayout,
  useFormActions,
  useSpecificFormValues,
} from '@/admin';
import ChooseSavedCard from '@/components/Payments/ChooseSavedCard';
import { curr } from '@/helpers';
import { Customer, Order, Payment } from '@/models';

export interface MakePaymentPayload {
  payment_method: string;
  customer: Customer;
  amount: number;
  email: string | null;
  orders: Order[];
}

function PaymentForm({ form, handleSubmit, submitting }: InjectedFormProps<MakePaymentPayload>) {
  const navigate = useNavigate();
  const values = useSpecificFormValues(form, (f: Partial<MakePaymentPayload>) => f);
  const { change } = useFormActions(form);
  const stripe = useStripe();
  const elements = useElements();

  const orderTotal = _.sumBy(values.orders, (o) => Number(o.balance));

  const onSubmit = async (values: MakePaymentPayload) => {
    let paymentMethodString = values.payment_method;

    if (paymentMethodString === 'new') {
      if (!elements || !stripe) {
        return null;
      }

      const card = elements.getElement(CardElement);
      if (!card) {
        throw new Error('Unable to get card element');
      }
      const res = await stripe.createToken(card, {});
      if (res.error) {
        throw new Error(res.error.message);
      }
      paymentMethodString = res.token.id;
    }

    try {
      const { data } = await axios.post<Payment>(`/api/customers/${values.customer.id}/charge`, {
        amount: values.amount,
        orders: values.orders.map((o) => o.id),
        email: values.email,
        payment_method: paymentMethodString,
      });
      navigate(`/payments/${data.id}`);
    } catch (e) {
      if (e instanceof AxiosError) {
        alert(e.response?.data.message);
      } else if (e instanceof Error) {
        alert(e.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: 600 }}>
      <EditFormFields
        defaultLayout={StackLayout}
        fields={[
          FieldFactory.belongsTo('customer', 'customers').withLabel(
            'Which customer is this payment for?',
          ),
          FieldFactory.curr('amount')
            .withLabel('How much would you like to charge?')
            .withHelp(orderTotal ? `Order Total Balance: ${curr(orderTotal)}` : ''),
          FieldFactory.hasMany('orders', 'orders').with({
            label: 'Which orders would you like to apply this payment to?',
            requestParams: _.pickBy({
              has_balance: 1,
              'filter[customer_id]': _.get(values, 'customer.id'),
            }),
          }),
          FieldFactory.email('email').withLabel('Email Receipt To (optional)'),
        ]}
      />

      {values.customer && (
        <ChooseSavedCard
          customerId={values.customer.id}
          selected={values.payment_method}
          setSelected={(s) => change('payment_method', s)}
          allowNew
        />
      )}

      <Button disabled={submitting} type="submit" variant="contained" sx={{ mt: 2 }}>
        Charge {values.amount ? curr(values.amount) : '?'}
      </Button>
    </form>
  );
}

const validate = (v: MakePaymentPayload) => {
  const errors: Record<string, string> = {};
  if (!v.payment_method) {
    errors.payment_method = 'Please select a payment method';
  }
  if (!v.customer) {
    errors.customer = 'Please select a customer';
  }
  if (!v.amount) {
    errors.amount = 'Please enter an amount';
  }
  return errors;
};

export default reduxForm<MakePaymentPayload>({
  form: 'MakePaymentForm',
  validate,
  initialValues: {
    orders: [],
  },
})(PaymentForm);
