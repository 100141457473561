import { Menu } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { LinkAction, SubMenu } from '@/admin';

const isCurrentPath = (path: string) => window.location.pathname.startsWith(path);

export function getLinksForPoId(poId: number, state?: object) {
  return [
    ['Purchase Order', `/purchase-orders/${poId}`],
    ['Receiving', `/receiving-po/${poId}`],
  ].map(([label, to]) => new LinkAction(label, to, state).setDisabled(isCurrentPath(to)));
}

export default function PurchaseOrderNavigation({ poId }: { poId: number }) {
  const { state } = useLocation();
  return <SubMenu primaryIcon={Menu} items={getLinksForPoId(poId, state)} />;
}
