import { SyntheticEvent, useState } from 'react';
import { Palette } from '@mui/icons-material';
import { Box, IconButton, Popover, Tooltip } from '@mui/material';
import StockColorCard from '@/components/Designs/StockColorCard';
import { StockColor } from '@/models';

export default function StockColorIcon({ stockColor }: { stockColor: StockColor }) {
  const [anchorEl, setAnchorEl] = useState<Element>();

  const onOpenPopover = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(undefined);
  };

  return (
    <Box ml={1} pt={1}>
      <Tooltip title={`Color Match: ${stockColor.name}`}>
        <IconButton size="small" onClick={onOpenPopover}>
          <Palette />
        </IconButton>
      </Tooltip>

      <Popover
        id="order-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <StockColorCard stockColor={stockColor} />
      </Popover>
    </Box>
  );
}
