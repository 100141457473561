import { Close } from '@mui/icons-material';
import {
  Button,
  CardActions,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import {
  curr,
  FieldProps,
  TextLink,
  useDialogs,
  useFormValues,
  useOnReloadRecord,
  wrap,
} from '@/admin';
import CommissionHitIcon from '@/components/Payments/CommissionHitIcon';
import { Account, Order, OrderPayment, Payment } from '@/models';
import OrderStatus from '../Orders/OrderStatus';
import PaymentApplicationFields from './PaymentApplicationFields';

export default function PaymentApplication({ input }: FieldProps) {
  const [id, amountToApply, customerId, paymentType, commissionHit] = useFormValues(
    (p: Payment) => [p.id, p.amount_to_apply, p.customer_id, p.payment_type, p.commission_hit],
  );

  const orderPayments = wrap(input.value) as OrderPayment[];
  const { confirm, prompt } = useDialogs();
  const onReload = useOnReloadRecord();

  const onDelete = (op: OrderPayment) => {
    confirm('Unapply Payment', 'Are you sure you want to unapply this payment?', 'error').then(
      () => {
        axios.delete(`/api/order-payments/${op.id}`).then(() => {
          onReload();
        });
      },
    );
  };

  const applyToOrder = () => {
    prompt({
      title: 'Apply Payment',
      description: (
        <PaymentApplicationFields
          form="PromptDialog"
          amountToApply={amountToApply}
          paymentType={paymentType}
          customerId={customerId}
        />
      ),
      initialValues: {
        amount: amountToApply,
        commission_hit: commissionHit,
      },
      fields: [],
      onSubmit: (v: { order?: Order; account?: Account; amount: number; commission_hit: number }) =>
        axios.post('/api/order-payments', {
          payment_id: id,
          order_id: v.order?.id,
          account_id: v.account?.id,
          amount: v.amount,
          commission_hit: v.commission_hit,
        }),
    }).then(() => {
      onReload();
    });
  };

  return (
    <>
      <CardContent>
        <Typography variant="body2" gutterBottom>
          <span>To Apply: </span>
          <b>{curr(amountToApply)}</b>
        </Typography>
      </CardContent>

      {orderPayments.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order/Account</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Amount Applied</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {orderPayments.map((op) => (
              <TableRow key={op.id}>
                {op.account && (
                  <>
                    <TableCell>
                      <TextLink to={`/accounts/${op.account.id}`}>{op.account.name}</TextLink>
                    </TableCell>
                    <TableCell colSpan={4} />
                  </>
                )}

                {op.order && (
                  <>
                    <TableCell>
                      <TextLink to={`/orders/${op.order.id}`}>{op.order.increment_id}</TextLink>
                    </TableCell>
                    <TableCell>{op.order.customer.name}</TableCell>
                    <TableCell>
                      <OrderStatus status={op.order.status} />
                    </TableCell>
                    <TableCell>{curr(op.order.total)}</TableCell>
                    <TableCell>{curr(op.order.balance)}</TableCell>
                  </>
                )}

                <TableCell>
                  <CommissionHitIcon hit={op.commission_hit} />
                  {curr(op.amount_applied)}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => onDelete(op)}>
                    <Close />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {amountToApply !== 0 && (
        <CardActions>
          <Button onClick={applyToOrder}>Apply To Order</Button>
        </CardActions>
      )}
    </>
  );
}
