import { useState } from 'react';
import { List } from '@mui/icons-material';
import { Box, CardContent, CardHeader, IconButton } from '@mui/material';
import {
  FieldFactory,
  generateFormName,
  ReduxField,
  StatusChip,
  useFormMeta,
  useFormValues,
} from '@/admin';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import POMatchingDrawer from '@/components/Transactions/POMatchingDrawer';
import { MATCH_STATUS_COLORS, MATCH_STATUS_LABELS } from '@/constants';
import { Transaction } from '@/models';

export default function POMatchingCard() {
  const { dirty } = useFormMeta(generateFormName('transactions'));
  const [purchaseOrderId, matchStatus] = useFormValues((t: Transaction) => [
    t.purchase_order_id,
    t.match_status,
  ]);
  const [open, setOpen] = useState(false);

  const canShow = !dirty && purchaseOrderId;

  return (
    <CardWithGutter>
      <CardHeader
        title="PO Matching"
        action={
          canShow && (
            <IconButton onClick={() => setOpen(true)}>
              <List />
            </IconButton>
          )
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <Box mb={2}>
          <StatusChip
            sx={{ width: '100%' }}
            status={matchStatus}
            colors={MATCH_STATUS_COLORS}
            labels={MATCH_STATUS_LABELS}
          />
        </Box>

        <ReduxField
          field={FieldFactory.belongsTo('purchase_order', 'purchaseOrders').withLabel('Matched PO')}
        />
      </CardContent>

      {canShow && (
        <POMatchingDrawer
          open={open}
          onClose={() => setOpen(false)}
          purchaseOrderId={purchaseOrderId}
        />
      )}
    </CardWithGutter>
  );
}
