import { CSSProperties } from 'react';
import { Check, LockReset } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Box,
} from '@mui/material';
import moment from 'moment';
import { StatusChip } from '@/admin';
import PrintMenu from '@/components/Print/PrintMenu';
import { PICK_STATUS_COLORS } from '@/constants';
import { Kit } from '@/models';

export default function KitListItem({
  kit,
  performKitAction,
  style = {},
}: {
  kit: Kit;
  performKitAction: (action: 'start' | 'reset') => void;
  style?: CSSProperties;
}) {
  return (
    <ListItem style={style} component="div">
      <ListItemAvatar>
        <Avatar>{kit.bin_string || '?'}</Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{ flexGrow: 1 }}
        primary={kit.number || kit.name}
        secondary={
          kit.pick_started_at && `Began packing at ${moment(kit.pick_started_at).format('lll')}`
        }
      />
      <Box ml="auto">
        <StatusChip status={kit.pick_status} colors={PICK_STATUS_COLORS} size="small" />
        <PrintMenu model="kit" id={kit.id} />
        {kit.pick_status === 'issued' ? (
          <Tooltip title="Mark as Packing">
            <IconButton onClick={() => performKitAction('start')}>
              <Check />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Reset Pick">
            <IconButton onClick={() => performKitAction('reset')}>
              <LockReset />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </ListItem>
  );
}
