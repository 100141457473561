import { useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { AddReaction } from '@mui/icons-material';
import { Popover, styled } from '@mui/material';
import { indigo } from '@mui/material/colors';
import { useMutation } from '@tanstack/react-query';

export const ReactionButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'me',
})<{ me?: boolean }>(({ theme, me }) => ({
  cursor: 'pointer',
  borderRadius: '1rem',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  justifyItems: 'center',
  borderWidth: 1,
  borderStyle: 'solid',
  fontFamily: 'inherit',
  borderColor: me ? indigo[400] : 'transparent',
  background: me ? indigo[50] : theme.palette.action.selected,
  padding: `0 ${theme.spacing(1.25)}`,
  height: '1.75rem',
  fontSize: '1rem',
  '&:hover': {
    background: theme.palette.action.hover,
  },
  '& > strong': {
    color: me ? indigo[400] : theme.palette.text.secondary,
    fontWeight: me ? 600 : 400,
    fontSize: '0.9rem',
  },
}));

export default function AddReactionButton({
  onReaction,
}: {
  onReaction: (reaction: string) => Promise<unknown>;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { mutate, isLoading } = useMutation(onReaction);

  const onEmojiSelect = (emoji: { native: string }) => {
    mutate(emoji.native);
    setAnchorEl(null);
  };

  return (
    <>
      <ReactionButton
        type="button"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={isLoading}
      >
        <AddReaction sx={{ fontSize: '1rem' }} color="action" />
      </ReactionButton>
      <Popover open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <Picker data={data} onEmojiSelect={onEmojiSelect} />
      </Popover>
    </>
  );
}
