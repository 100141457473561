import { CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { sumBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useTitle } from '@/admin';
import BankList from '@/components/Banking/BankList';
import BankTransactions from '@/components/Banking/BankTransactions';
import { curr } from '@/helpers';
import { Account } from '@/models';

export default function Banking() {
  useTitle('Banking');

  const [searchParams, setSearchParams] = useSearchParams();
  const accountId = searchParams.get('account');
  const setAccount = (account: Account) => setSearchParams({ account: account.id.toString() });

  const { data: accounts = [], isLoading } = useQuery(['bankAccounts'], () =>
    axios
      .get<{ data: Account[] }>(
        '/api/accounts?filter[type][in]=bank,credit_card&is_disabled=0&sort=number',
      )
      .then(({ data }) => data.data),
  );

  let account: Account | undefined;
  if (accounts.length > 0) {
    account = accountId ? accounts.find((a) => a.id === Number(accountId)) : accounts[0];
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Typography variant="subtitle1">
        Cash Balance:{' '}
        <b>
          {curr(
            sumBy(accounts, (a) =>
              a.type === 'credit_card' ? a.cached_balance * -1 : a.cached_balance,
            ),
          )}
        </b>
      </Typography>
      <BankList accounts={accounts} onSelect={setAccount} selected={account} />
      {account && <BankTransactions account={account} />}
    </div>
  );
}
