import { FieldFactory, Resource } from '@/admin';
import { Terms } from '@/models';
import { AppConfig } from '@/responses';

export default function terms(appConfig: AppConfig) {
  return new Resource<Terms>('Terms')
    .setUseDrawer()
    .withStaticOptions(appConfig.terms)
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.number('days').sortable(),
      FieldFactory.boolean('is_upfront', 'Requires Payment Upfront').sortable(),
      FieldFactory.boolean('is_po_required', 'Requires a PO').sortable(),
      FieldFactory.number('sort').withHelp('Higher numbers will show up first').sortable(),
    ]);
}
