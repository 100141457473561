import { Typography } from '@mui/material';
import moment from 'moment';
import { formatNumber } from '@/admin';
import { ProductVariant } from '@/models';

function InvQty({ qty }: { qty: number | null }) {
  return (
    <span style={{ fontWeight: 500 }}>{qty != null ? formatNumber(qty, 'number') : null}</span>
  );
}

export default function InventoryText({
  variant,
  hideAvail,
}: {
  variant: Pick<ProductVariant, 'inventory_qty' | 'vendor_inventory_qty' | 'stock_updated_at'>;
  hideAvail?: boolean;
}) {
  if (!variant) {
    return null;
  }

  const fromAvail =
    !hideAvail && variant.inventory_qty ? (
      <Typography variant="body2" color="primary">
        <InvQty qty={variant.inventory_qty} /> in Avail Inventory
      </Typography>
    ) : null;

  const fromVendor = variant.stock_updated_at && (
    <Typography variant="body2" style={{ marginTop: fromAvail ? 2 : 0 }}>
      <InvQty qty={variant.vendor_inventory_qty} /> in Vendor Inventory
      <Typography
        color="textSecondary"
        component="span"
        style={{ fontSize: '.75rem', paddingLeft: 8 }}
      >
        (Updated {moment(variant.stock_updated_at).fromNow()})
      </Typography>
    </Typography>
  );

  return (
    <>
      {fromAvail}
      {fromVendor}
    </>
  );
}
