import { ValidationError, Schema } from 'joi';
import _ from 'lodash';

export function transformLaravelErrors(all: Record<string, string[]>) {
  const errors = {};
  Object.keys(all).forEach((k) => {
    _.set(errors, k, all[k][0]);
  });
  return errors;
}

export function transformJoiErrors(error: ValidationError) {
  const errors = {};
  error.details.forEach((item) => {
    if (item.context?.label) {
      _.set(errors, item.context.label, item.message);
    }
  });
  return errors;
}

export function validateUsingRules(values: object, schema: Schema) {
  const { error } = schema.validate(values, { abortEarly: false });
  if (error) {
    return transformJoiErrors(error);
  }
  return {};
}

export const memoizedValidateUsingRules = _.memoize(validateUsingRules, (...args) =>
  JSON.stringify(args),
);
