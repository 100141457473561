import { Card, Grid } from '@mui/material';
import { useFormValues } from '@/admin';
import OrderDetails from '@/components/Orders/Dashboard/OrderDetails';
import OrderShippingAddresses from '@/components/Orders/Shipping/OrderShippingAddresses';
import { Quote } from '@/models';

export default function QuoteDashboard() {
  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <Card>
          <OrderDetails order={useFormValues((q: Quote) => q)} isReadOnly={false} />
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <OrderShippingAddresses />
      </Grid>
    </Grid>
  );
}
