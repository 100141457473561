import { useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { startCase } from 'lodash';
import moment, { Moment } from 'moment/moment';
import { WrappedFieldProps } from 'redux-form';
import SubMenu from '../../components/SubMenu';
import { FieldProps } from '../../types';
import ButtonAction from '../Actions/ButtonAction';
import Divider from '../Actions/Divider';
import Field from '../Field';

interface PredefinedRange {
  key: string;
  label: string;
  startDate: Moment;
  endDate: Moment;
}

function DateRangeComponent({ input, field, meta }: FieldProps<DateRangeField>) {
  const predefined = useMemo(() => {
    const ranges: PredefinedRange[] = [
      {
        key: 'thisyear',
        label: 'This Year',
        startDate: moment().startOf('year'),
        endDate: moment().endOf('year'),
      },
      {
        key: 'thisquarter',
        label: 'This Quarter',
        startDate: moment().startOf('quarter'),
        endDate: moment().endOf('quarter'),
      },
      {
        key: 'thismonth',
        label: 'This Month',
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      },
      {
        key: 'thisweek',
        label: 'This Week',
        startDate: moment().startOf('week'),
        endDate: moment().endOf('week'),
      },
      {
        key: 'lastyear',
        label: 'Last Year',
        startDate: moment().subtract(1, 'year').startOf('year'),
        endDate: moment().subtract(1, 'year').endOf('year'),
      },
      {
        key: 'lastquarter',
        label: 'Last Quarter',
        startDate: moment().subtract(1, 'quarter').startOf('quarter'),
        endDate: moment().subtract(1, 'quarter').endOf('quarter'),
      },
      {
        key: 'lastmonth',
        label: 'Last Month',
        startDate: moment().subtract(1, 'month').startOf('month'),
        endDate: moment().subtract(1, 'month').endOf('month'),
      },
      {
        key: 'lastweek',
        label: 'Last Week',
        startDate: moment().subtract(1, 'week').startOf('week'),
        endDate: moment().subtract(1, 'week').endOf('week'),
      },
    ];

    [7, 14].forEach((d) => {
      ranges.push({
        key: `last${d}`,
        label: `Last ${d} Days`,
        startDate: moment()
          .subtract(d - 1, 'days')
          .startOf('day'),
        endDate: moment().endOf('day'),
      });
    });

    ranges.push({
      key: 'today',
      label: 'Today',
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
    });

    return ranges;
  }, []);

  const value = useMemo((): DateRange<Moment> => {
    if (typeof input.value === 'string') {
      const match = predefined.find((p) => p.key === input.value);
      if (match) {
        return [match.startDate, match.endDate];
      }
    }
    if (Array.isArray(input.value) && input.value.length === 2) {
      return [moment(input.value[0]), moment(input.value[1])];
    }
    return [null, null];
  }, [input.value]);

  return (
    <Box display="flex" my={1}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        localeText={{ start: field.startLabel, end: field.endLabel }}
      >
        <DateRangePicker
          value={value}
          onChange={(dates) => input.onChange(dates)}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...field.getTextFieldProps(meta)} {...startProps} margin="none" />
              <Box sx={{ mx: 1.5 }}> to </Box>
              <TextField {...field.getTextFieldProps(meta)} {...endProps} margin="none" />
            </>
          )}
        />
        <SubMenu
          sx={{ ml: 1 }}
          items={[
            ...predefined.map(
              (pre) =>
                new ButtonAction(pre.label, () => input.onChange([pre.startDate, pre.endDate])),
            ),
            new Divider(),
            new ButtonAction('Clear', () => input.onChange(null)),
          ]}
        />
      </LocalizationProvider>
    </Box>
  );
}

export default class DateRangeField extends Field {
  startLabel: string;
  endLabel: string;
  format = 'll';

  constructor(name: string) {
    super(name);
    this.startLabel = `${startCase(name.replace(/_at$/, ''))} From`;
    this.endLabel = 'Until';
  }

  getDefaultFilterKey(): string {
    return `filter[${this.name}][between]`;
  }

  renderEditComponent(props: WrappedFieldProps) {
    return <DateRangeComponent {...props} field={this} />;
  }

  renderCell(value: any) {
    if (value && value.length == 2) {
      return (
        <div>
          <span>{moment(value[0]).local().format(this.format)}</span>
          <span> - </span>
          <span>{moment(value[1]).local().format(this.format)}</span>
        </div>
      );
    }
    return null;
  }
}
