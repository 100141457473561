import { styled } from '@mui/material';
import { keyframes } from '@mui/system';

const shimmer = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

export const ColorBadge = styled('div', {
  shouldForwardProp: (p) => p !== 'size' && p !== 'color',
})<{ color: string; size?: number }>(({ theme, size = 42, color }) => ({
  display: 'inline-block',
  width: size,
  height: size,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: color,
  position: 'relative',
}));

export const MetallicOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background:
    'linear-gradient(100deg,rgba(255,255,255,0) 20%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,0) 80%)',
  animation: `${shimmer} 1s infinite linear`,
});

export default ColorBadge;
