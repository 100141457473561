import { Dialog } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import AlertDialog from './AlertDialog';

export default function AlertDialogContainer() {
  const { alert, setAlertDialog } = useAppContext();

  if (!alert) {
    return null;
  }

  const onClose = () => {
    setAlertDialog({ ...alert, open: false });
  };

  const onSuccess = (v?: any) => {
    alert.promise.resolve(v);
    onClose();
  };

  const onCancel = () => {
    alert.promise.reject();
    onClose();
  };

  const { options } = alert;

  return (
    <Dialog
      open={alert.open}
      onClose={onCancel}
      fullWidth
      maxWidth={alert.options.maxWidth || 'xs'}
    >
      <AlertDialog {...options} onSuccess={onSuccess} />
    </Dialog>
  );
}
