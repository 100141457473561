import { useEffect } from 'react';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import LaravelEcho from 'laravel-echo';
import Pusher from 'pusher-js';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthenticatedApp from '@/AuthenticatedApp';
import { useSessionState } from '@/admin';
import AgreementScreen from '@/components/Agreements/AgreementScreen';
import '@/helpers';
import ForgotPassword from '@/pages/auth/ForgotPassword';
import Login from '@/pages/auth/Login';
import RedirectToLogin from '@/pages/auth/RedirectToLogin';
import Register from '@/pages/auth/Register';
import ResetPassword from '@/pages/auth/ResetPassword';
import { AppConfig } from '@/responses';
import store from '@/store';
import SplashScreen from './SlashScreen';

declare global {
  interface Window {
    Pusher: typeof Pusher;
    Echo: LaravelEcho;
    artRequestMeta:
      | {
          _customerProofCount: number[];
          _productionProofCount: number[];
          _completedAts: string[];
        }
      | undefined;
  }
}

LicenseInfo.setLicenseKey(
  '7ab074fcb21902606e57cca4972ffebeTz01NTM0NyxFPTE3MDE0NDcwNDg4MzUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

window.Pusher = Pusher;

function getEchoWithConfig(config: AppConfig): LaravelEcho {
  return new LaravelEcho({
    broadcaster: 'reverb',
    key: config.reverbKey,
    wsHost: config.reverbHost,
    wsPort: 8080,
    wssPort: 8080,
    forceTLS: config.reverbScheme === 'https',
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel: { name: string }) => ({
      authorize: (socketId: string, callback: (error: boolean, data: any) => void) => {
        axios
          .post('/api/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(false, response.data);
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    }),
  });
}

export default function App() {
  const queryClient = useQueryClient();
  const [businessId, setBusinessId] = useSessionState<number | null>('business_id', null);

  useEffect(() => {
    axios.defaults.headers.common['X-Business-Id'] = businessId;
  }, [businessId]);

  const { data: touched } = useQuery(['xsrf'], () => axios.get('/api/touch').then(() => true));

  const { data: config, isLoading } = useQuery(
    ['appConfig'],
    () =>
      axios.get<AppConfig>('/api/app').then(({ data }) => {
        window.Echo = getEchoWithConfig(data);
        if (data.businesses.length === 1) {
          setBusinessId(data.businesses[0].id);
        }
        return data;
      }),
    {
      enabled: Boolean(touched),
    },
  );

  const setConfig = (config: AppConfig) => {
    queryClient.setQueryData(['appConfig'], config);
  };

  if (isLoading) {
    return <SplashScreen />;
  }

  if (config?.agreement) {
    return (
      <AgreementScreen
        agreement={config.agreement}
        onSuccess={() => setConfig({ ...config, agreement: null })}
      />
    );
  }

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="944741344614-o51br5nmah6uc6k07r00i3onlukjvn4b.apps.googleusercontent.com">
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register/:code" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route
              path="*"
              element={
                config ? (
                  <AuthenticatedApp
                    config={config}
                    businessId={businessId}
                    onBusinessChange={(newId: number | null) => {
                      setBusinessId(newId);
                      window.location.reload();
                    }}
                  />
                ) : (
                  <RedirectToLogin />
                )
              }
            />
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </Provider>
  );
}
