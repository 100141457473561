import { CloudDownload, AddCircle } from '@mui/icons-material';
import { Resource, getApiUrl, LinkAction, AnchorAction, ButtonAction, FieldFactory } from '@/admin';
import UserLabel from '@/components/Users/UserLabel';
import { BusinessPayout } from '@/models';

export default function businessPayouts() {
  return new Resource<BusinessPayout>('Business Payouts')
    .withExportable({
      format: 'xlsx',
    })
    .getTitleUsing((p) => `${p.business.name} Payout #${p.id}`)
    .withDefaultSort('-id')
    .withIndexActions([new LinkAction('Perform Payouts', '/perform-payouts').withIcon(AddCircle)])
    .getSingleActionsUsing((values) => [
      new AnchorAction(
        'Download Excel',
        getApiUrl(`/api/business-payouts/${values.id}/download`),
      ).withIcon(CloudDownload),
    ])
    .withBulkActions([
      new ButtonAction('Export', ({ selected }) => {
        window.open(
          getApiUrl(`/api/business-payouts?format=xlsx&filter[id][in]=${selected.join(',')}`),
        );
      }).withIcon(CloudDownload),
    ])
    .withFieldsAndColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.belongsTo('business', 'businesses').filterable().sortable('business.name'),
      FieldFactory.curr('amount').sortable(),
      FieldFactory.curr('credits').sortable(),
      FieldFactory.curr('deductions').sortable(),
      FieldFactory.curr('purchase_orders').sortable(),
      FieldFactory.curr('net_amount').sortable(),
      FieldFactory.timestamp('created_at').withLabel('Date').filterable(),
      FieldFactory.belongsTo('created_by_user', 'users').renderCellUsing((u) => (
        <UserLabel user={u} />
      )),
    ])
    .setReadOnly();
}
