import { useEffect, useState } from 'react';
import { Alert, Button, CircularProgress } from '@mui/material';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import axios from 'axios';
import { isEqual } from 'lodash';
import { DrawerFormSection } from '@/admin';
import AddressBlock from '@/components/Addresses/AddressBlock';
import ParcelFields from '@/components/Shipping/ParcelFields';
import RateList from '@/components/Shipping/RateList';
import { Address, Parcel, ShippoRate } from '@/models';
import useOrderableApiUrl from '@/utils/useOrderableApiUrl';

export default function EstimateShippingCost({
  updateRequest,
}: {
  updateRequest: UseMutationResult<unknown, unknown, { shipping: number }>;
}) {
  const [selectedRateId, setSelectedRateId] = useState<string>();
  const [parcels, setParcels] = useState<Parcel[]>();
  const apiUrl = useOrderableApiUrl('estimate-shipping');

  const { data, isLoading, mutate } = useMutation(
    (v: { parcels?: Parcel[] }) =>
      axios
        .post<{
          from_address: Address;
          to_address: Address;
          parcels: Parcel[];
          rates: ShippoRate[];
        }>(apiUrl, v)
        .then(({ data }) => data),
    {
      onSuccess: (res) => {
        if (!parcels) {
          setParcels(res.parcels);
        }
      },
    },
  );

  useEffect(() => {
    mutate({});
  }, [apiUrl]);

  const selectedRate = data?.rates.find((r) => r.id === selectedRateId);

  if (!data || !parcels) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Alert severity="info" sx={{ mt: -2, mb: 2 }}>
        This tool is meant to assist in estimating domestic outbound shipping costs. It is purely an
        estimate, and the final outbound shipping cost may vary.
      </Alert>
      <DrawerFormSection title="From Address">
        <AddressBlock address={data.from_address} />
      </DrawerFormSection>
      <DrawerFormSection title="To Address">
        <AddressBlock address={data.to_address} />
      </DrawerFormSection>
      <ParcelFields parcels={parcels} onChange={setParcels} />

      {!isEqual(parcels, data.parcels) && (
        <Button variant="contained" disabled={isLoading} onClick={() => mutate({ parcels })}>
          Get Rates
        </Button>
      )}

      <DrawerFormSection title="Rates">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <RateList
            rates={data.rates}
            selectedRate={selectedRateId}
            setSelectedRate={setSelectedRateId}
          />
        )}
      </DrawerFormSection>

      {selectedRate && (
        <Button
          variant="contained"
          disabled={updateRequest.isLoading}
          onClick={() => updateRequest.mutate({ shipping: selectedRate.cost })}
        >
          Set as Shipping Price
        </Button>
      )}
    </div>
  );
}
