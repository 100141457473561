import { SyntheticEvent, useEffect } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import _ from 'lodash';
import {
  FieldFactory,
  FieldProps,
  getValueFromEvent,
  NonReduxField,
  useSpecificFormValues,
} from '@/admin';

export default function ColumnSettings({ input, meta }: FieldProps) {
  const columns = input.value || {};
  const newColumns = useSpecificFormValues(meta.form, (c) => c._columns as string[]);

  useEffect(() => {
    if (newColumns && _.size(newColumns) > 0) {
      input.onChange(
        _.chain(newColumns)
          .keyBy(_.identity)
          .mapValues((k) =>
            columns[k]
              ? columns[k]
              : {
                  label: _.startCase(k),
                },
          )
          .value(),
      );
    }
  }, [JSON.stringify(newColumns)]);

  const createHandler = (key: string, name: string) => (e: SyntheticEvent) => {
    const payload = _.cloneDeep(columns);
    const value = getValueFromEvent(e);
    _.set(payload, `${key}.${name}`, value);
    input.onChange(payload);
  };

  if (!columns || _.size(columns) === 0) {
    return null;
  }

  return (
    <div>
      {_.map(columns, (settings, key) => (
        <Accordion key={key}>
          <AccordionSummary expandIcon={<ExpandMore />}>{key}</AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <NonReduxField
                  value={settings.format}
                  onChange={createHandler(key, 'format')}
                  field={FieldFactory.select('format', [
                    { value: '', label: 'None' },
                    { value: 'curr', label: 'Currency ($)' },
                    { value: 'short_curr', label: 'Short Currency ($1.1k)' },
                    { value: 'percent', label: 'Percent (%)' },
                    { value: 'number', label: 'Number (1,000)' },
                  ])}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!settings.total_row}
                      onChange={createHandler(key, 'total_row')}
                    />
                  }
                  label="Has Total Row"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
