import { Card, CardHeader, Grid } from '@mui/material';
import axios from 'axios';
import {
  FieldFactory,
  TextButton,
  useDialogs,
  useFormValues,
  useHasPermission,
  useUpdateRecord,
} from '@/admin';
import EventableCard from '@/components/Notes/EventableCard';
import { ArtRequestActivity, User } from '@/models';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import ArtActivityCompleteForm from './ArtActivityCompleteForm';
import DesignCard from './DesignCard';

export default function ArtRequestActivityPage() {
  const { prompt } = useDialogs();
  const updateRecord = useUpdateRecord();
  const [id, title, order, design, assignee] = useFormValues((a: ArtRequestActivity) => [
    a.id,
    a.label,
    a.art_request?.order,
    a.design,
    a.assignee,
  ]);
  const hasPermission = useHasPermission();

  const onAssign = () => {
    prompt({
      title: 'Assign Activity',
      fields: [
        FieldFactory.belongsTo('assignee', 'users').withRequestParams({
          permission: 'read:art_request_activities',
        }),
      ],
      onSubmit: (v: { assignee: User | null }) =>
        axios.put(`/api/art-request-activities/${id}`, {
          assigned_to: v.assignee?.id,
        }),
    }).then(({ data }: { data: ArtRequestActivity }) => {
      updateRecord(data);
      console.log(data.assignee);
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader
            title={title}
            subheader={
              <div>
                Assigned to{' '}
                <TextButton disabled={!hasPermission('read:users')} onClick={onAssign}>
                  {assignee?.name || '(none)'}
                </TextButton>
              </div>
            }
          />

          <ArtActivityCompleteForm />
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title={design.increment_id} />
          <DesignCard design={design} />
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        {order && <OrderSummaryCard order={order} />}
      </Grid>
      <Grid item xs={12}>
        <EventableCard resource="art-request-activities" resourceId={id} />
      </Grid>
    </Grid>
  );
}
