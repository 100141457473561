import { createContext, ReactNode, useContext } from 'react';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import Resource from '../models/Resource';
import { User } from '../types';

interface FileModel {
  name: string;
  url: string;
}

export interface AdminConfigProp {
  user?: User;
  logoutHref?: string;
  roles: string[];
  permissions: string[];
  onUpload: (
    files: FileList | File[],
    uploadType?: string,
    handleProgress?: (p: number) => void,
  ) => Promise<FileModel[]>;
  getTotalFromResponse: (res: AxiosResponse) => number;
  getRecordsFromResponse: (res: AxiosResponse) => Record<string, any>[];
  getMetaFromResponse: (res: AxiosResponse) => Record<string, any> | undefined;
  resources: Record<string, Resource<any> | ((config: AdminConfigProp) => Resource<any>)>;
}

interface AdminConfig extends AdminConfigProp {
  resources: Record<string, Resource>;
}

const DEFAULT_CONFIG: AdminConfig = {
  resources: {},
  roles: [],
  permissions: [],
  onUpload: () => Promise.resolve([{ url: '', name: '' }]),
  getTotalFromResponse: (res) => res.data.meta?.total,
  getRecordsFromResponse: (res) => res.data.data,
  getMetaFromResponse: (res) => res.data.meta,
};

export const AdminConfigContext = createContext<AdminConfig>(DEFAULT_CONFIG);

export function useAdminConfig(): AdminConfig {
  return useContext(AdminConfigContext);
}

export function AdminProvider({
  children,
  config,
}: {
  children: ReactNode;
  config: AdminConfigProp;
}) {
  const resources = _.mapValues(config.resources, (v, k) => {
    const resource = typeof v === 'function' ? v(config) : v;
    resource.key = k;
    return resource;
  });
  const value = { ...config, resources };

  return <AdminConfigContext.Provider value={value}>{children}</AdminConfigContext.Provider>;
}
