import { AccountBalance } from '@mui/icons-material';
import { ListItemAvatar, ListItemText } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { PaymentMethod } from '@stripe/stripe-js';
import _ from 'lodash';

export default function StripeBankAccountListItem({
  usBankAccount,
}: {
  usBankAccount: PaymentMethod.UsBankAccount;
}) {
  return (
    <>
      <ListItemAvatar>
        <Avatar>
          <AccountBalance />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${usBankAccount.bank_name} ****${usBankAccount.last4}`}
        secondary={`${_.startCase(usBankAccount.account_type)} Account`}
      />
    </>
  );
}
