import { SyntheticEvent, useState } from 'react';
import {
  Card,
  CircularProgress,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { TextButton, TextLink } from '@/admin';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import OrderLabel from '@/components/Orders/OrderLabel';
import { OrderItem, PurchaseOrderItem } from '@/models';

export default function OnOrderCount({
  variantId,
  children,
}: {
  variantId: number;
  children: React.ReactNode;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { data: onOrderItems, isLoading } = useQuery(
    ['onOrderItems', variantId],
    () =>
      axios
        .get<{
          po_items: PurchaseOrderItem[];
          order_items: OrderItem[];
        }>(`/api/variants/${variantId}/on-order-items`)
        .then(({ data }) => data),
    {
      enabled: !!anchorEl,
    },
  );

  const onOpenPopover = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <TextButton onClick={onOpenPopover}>{children}</TextButton>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Card>
          {isLoading && <CircularProgress />}

          {onOrderItems && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Qty Rec/Shp</TableCell>
                  <TableCell>On Order Qty</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {onOrderItems.order_items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <OrderLabel orderId={item.order_id} />
                    </TableCell>
                    <TableCell>
                      <OrderItemProduct item={item} showSize />
                    </TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>{item.qty_shipped}</TableCell>
                    <TableCell>{item.qty - item.qty_shipped}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableBody>
                {onOrderItems.po_items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <TextLink to={`/purchase-orders/${item.purchase_order_id}`}>
                        PO #{item.purchase_order_id}
                      </TextLink>
                    </TableCell>
                    <TableCell>{item.variant!.sku}</TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>{item.qty_received}</TableCell>
                    <TableCell>{item.qty - item.qty_received}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Card>
      </Popover>
    </>
  );
}
