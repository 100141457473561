import { SyntheticEvent, useState } from 'react';
import { Close } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import {
  ListItemText,
  List,
  IconButton,
  Typography,
  Button,
  CardContent,
  CardActions,
  Card,
  CardHeader,
  LinearProgress,
  ListItemSecondaryAction,
  ListItem,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { curr, FieldFactory, TextLink, useDialogs } from '@/admin';
import POMatchingDrawer from '@/components/Transactions/POMatchingDrawer';
import { requiredFields } from '@/helpers';
import { Bill, Transaction } from '@/models';

export default function PurchaseOrderMatchingCard({
  poId,
  vendorId,
}: {
  poId: number;
  vendorId: number;
}) {
  const { confirm, prompt } = useDialogs();
  const [open, setOpen] = useState(false);
  const {
    data: transactions,
    isFetching,
    refetch,
  } = useQuery(['poMatchedTransactions', poId], () =>
    axios
      .get<{ data: Transaction[] }>(`/api/purchase-orders/${poId}/transactions`)
      .then(({ data }) => data.data),
  );

  const onLink = () => {
    prompt({
      title: 'Link Transaction',
      fields: [
        FieldFactory.belongsTo('transaction', 'transactions').withRequestParams({
          'filter[purchase_order_id][null]': 1,
          'filter[vendor_id]': vendorId,
          'filter[type]': 'bill',
        }),
      ],
      validation: requiredFields(['transaction']),
      onSubmit: (v: { transaction: Bill }) =>
        axios.post(`/api/purchase-orders/${poId}/transactions/${v.transaction.id}`),
    }).then(refetch);
  };

  const handleRemove = (trans: Transaction) => (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    confirm('Unlink Transaction', 'Are you sure?').then(() => {
      axios.delete(`/api/purchase-orders/${poId}/transactions/${trans.id}`).then(() => refetch());
    });
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title="PO Matching"
        action={
          <IconButton onClick={() => setOpen(true)}>
            <ListIcon />
          </IconButton>
        }
      />
      {isFetching && <LinearProgress />}
      {transactions?.length === 0 && (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There are no bills linked with this PO.
          </Typography>
        </CardContent>
      )}
      {transactions && transactions.length > 0 && (
        <List>
          {transactions.map((trans) => (
            <ListItem key={trans.id}>
              <ListItemText
                primary={
                  <TextLink to={`/transactions/${trans.id}`}>
                    {'vendor' in trans && trans.vendor && <span>{trans.vendor.name} </span>}
                    <span>{_.startCase(trans.type)} </span>
                    <span>#{trans.ref_number || trans.id}</span>
                  </TextLink>
                }
                secondary={curr(trans.total)}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={handleRemove(trans)}>
                  <Close fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      <CardActions>
        <Button color="secondary" size="small" onClick={onLink}>
          Link Transaction
        </Button>
      </CardActions>

      <POMatchingDrawer open={open} onClose={() => setOpen(false)} purchaseOrderId={poId} />
    </Card>
  );
}
