import Joi from 'joi';
import { FieldFactory, Resource } from '@/admin';
import { CustomerContact } from '@/models';

export default function customerDomains() {
  return new Resource<CustomerContact>('Domains')
    .getSubtitleUsing((v) => v.customer.name)
    .withValidation(
      Joi.object({
        domain: Joi.string().domain({ tlds: false }),
        is_auto: Joi.boolean(),
      }),
    )
    .withFieldsAndColumns([
      FieldFactory.text('domain').withColumnSpan(2),
      FieldFactory.select('is_auto', [
        {
          value: true,
          label: 'Auto-Join',
        },
        {
          value: false,
          label: 'Request to Join',
        },
      ]).withLabel('Mode'),
    ]);
}
