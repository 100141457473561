import { ReactNode } from 'react';
import TagIcon from '@mui/icons-material/LocalOffer';
import { Typography, Avatar, Badge, Stack } from '@mui/material';
import { ArrayBullets } from '@/admin';
import { OrderItem } from '@/models';
import { maybeAddQueryParams } from '@/utils/query';

export default function OrderItemProduct({
  onClick,
  item,
  disabled,
  showSize = false,
  children,
  actions,
}: {
  item: Pick<
    OrderItem,
    'is_custom' | 'product_image' | 'image' | 'description' | 'number' | 'color' | 'size' | 'sizes'
  >;
  onClick?: () => void;
  disabled?: boolean;
  showSize?: boolean;
  children?: ReactNode;
  actions?: ReactNode;
}) {
  const image = item.image || item.product_image || null;
  const elements: Record<string, ReactNode> = {
    number: item.number,
    color: item.color,
  };
  if (showSize) {
    elements.size = item.size;
  }

  const inner = (
    <Stack spacing={2} direction="row" sx={{ minWidth: 300, alignItems: 'center' }}>
      <Badge
        badgeContent={item.is_custom ? 'C' : 'D'}
        color={item.is_custom ? 'secondary' : 'primary'}
      >
        <Avatar src={maybeAddQueryParams(image, { w: 200 })} variant="rounded">
          <TagIcon />
        </Avatar>
      </Badge>
      <div>
        <Typography variant="body2">{item.description}</Typography>
        <Typography color="textSecondary" variant="body2">
          <ArrayBullets elements={elements} />
          {item.sizes && item.sizes.length > 0 && <div>Sizes: {item.sizes.join(', ')}</div>}
        </Typography>
        {children}
      </div>
      {actions}
    </Stack>
  );

  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        disabled={disabled}
        style={{
          background: 'none',
          border: 'none',
          textAlign: 'left',
          outline: 'none',
          cursor: disabled ? 'not-allowed' : 'pointer',
          color: 'inherit',
        }}
      >
        {inner}
      </button>
    );
  }

  return inner;
}
