import { useState } from 'react';
import {
  FileDownload,
  Refresh,
  FilterList,
  ViewColumn,
  Search as SearchIcon,
  Delete,
} from '@mui/icons-material';
import {
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
  Badge,
  Box,
  alpha,
  styled,
  useMediaQuery,
} from '@mui/material';
import _ from 'lodash';
import ActionsRenderer from '../../components/Actions/ActionsRenderer';
import { useAppContext } from '../../contexts/AppContext';
import useDialogs from '../../hooks/useDialogs';
import useGetParamsForIndexRequest from '../../hooks/useGetParamsForIndexRequest';
import ButtonAction from '../../models/Actions/ButtonAction';
import Resource from '../../models/Resource';
import getApiUrl from '../../utils/getApiUrl';
import getValueFromEvent from '../../utils/getValueFromEvent';
import numString from '../../utils/numString';
import wrap from '../../utils/wrap';
import { NonReduxField } from '../Form/ReduxField';
import ColumnsDialog from './ColumnsDialog';
import { useDataTable } from './DataTableContext';
import FilterDrawer from './FilterDrawer';
import Search from './Search';

const Toolbar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<{ highlight?: boolean }>(({ theme, highlight }) => ({
  minHeight: theme.spacing(10),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  backgroundColor: highlight
    ? alpha(theme.palette.primary.main, 0.08)
    : theme.palette.background.paper,
}));

export default function DataTableToolbar({ resource }: { resource: Resource }) {
  const {
    columns,
    onColumnsChange,
    selected,
    isFetching,
    onReload,
    filters,
    onSetFilters,
    query,
    onQuery,
    sort,
    onFilter,
  } = useDataTable();
  const [searchOpen, setSearchOpen] = useState(!!query);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const actionsArray = Array.isArray(resource.bulkActions)
    ? [...resource.bulkActions]
    : resource.bulkActions;
  const { setIsLoading } = useAppContext();
  const { confirm } = useDialogs();
  const getParamsForIndexRequest = useGetParamsForIndexRequest();

  const showSearch = () => setSearchOpen(true);
  const onSearchBlur = () => {
    if (!query) {
      setSearchOpen(false);
    }
  };

  const toggleColumn = (fieldName: string) => {
    if (columns.indexOf(fieldName) > -1) {
      onColumnsChange(_.without(columns, fieldName));
    } else {
      onColumnsChange([...columns, fieldName]);
    }
  };

  const numSelected = wrap(selected).length;
  const showToolbar = numSelected > 0;
  const filterable = resource.getFilterableFields();
  const numActiveFilters = _.keys(filters).filter(
    (f) => !_.find(filterable, ['name', f])?.shouldQuickFilter(isMobile),
  ).length;

  const getUrlForExport = () => {
    const params = getParamsForIndexRequest(
      resource,
      {
        filters,
        sort,
      },
      query,
    );
    const searchParams = new URLSearchParams({
      ...(params as Record<string, string>),
      ...resource.exportable,
    });
    return getApiUrl(resource.getApiEndpoint(), searchParams);
  };

  const handleDelete = () => {
    if (selected.length === 0) {
      return;
    }
    confirm(
      `Delete ${
        selected.length === 1 ? resource.singularName : numString(selected.length, resource.name)
      }`,
      'Are you sure? This action cannot be undone.',
    ).then(() => {
      setIsLoading(true);
      Promise.all(selected.map((id) => resource.getDeleteRequest(id)))
        .then(() => {
          onReload();
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  if (resource.deletable && Array.isArray(actionsArray)) {
    actionsArray.push(new ButtonAction('Delete', handleDelete, Delete));
  }

  if (showToolbar) {
    return (
      <Toolbar highlight>
        <Typography color="inherit">{numSelected} selected</Typography>

        <div>
          <ActionsRenderer actions={actionsArray} resource={resource} />
        </div>
      </Toolbar>
    );
  }

  return (
    <Toolbar>
      <Box display="flex" alignItems="center">
        {isFetching ? (
          <Box p={1.5} display="inline-flex">
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Tooltip title="Reload" placement="top">
            <IconButton onClick={onReload} size="large">
              <Refresh />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Show/Hide Columns" placement="top">
          <IconButton onClick={() => setColumnsOpen(true)} size="large">
            <ViewColumn />
          </IconButton>
        </Tooltip>

        {resource.exportable && (
          <Tooltip title="Export to Excel" placement="top">
            <IconButton component="a" href={getUrlForExport()} size="large">
              <FileDownload />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box display="flex" alignItems="center">
        {filterable
          .filter((i) => i.shouldQuickFilter(isMobile))
          .map((f) => (
            <Box key={f.name} mr={2} minWidth={225}>
              <NonReduxField
                field={f.getFilterField().with({ margin: 'none' })}
                value={filters[f.name]}
                onChange={(e) => onFilter(f.name, getValueFromEvent(e))}
              />
            </Box>
          ))}

        {onQuery && (
          <div>
            {query || searchOpen ? (
              <Search value={query} onChange={onQuery} onBlur={onSearchBlur} />
            ) : (
              <IconButton onClick={showSearch} size="large">
                <SearchIcon />
              </IconButton>
            )}
          </div>
        )}

        {filterable.length > 0 && (
          <div>
            <Tooltip title="Filters" placement="top">
              <IconButton onClick={() => setFiltersOpen(true)} size="large">
                <Badge
                  badgeContent={numActiveFilters}
                  color="secondary"
                  invisible={numActiveFilters === 0}
                >
                  <FilterList />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Box>

      <ColumnsDialog
        open={columnsOpen}
        onClose={() => setColumnsOpen(false)}
        resource={resource}
        toggleColumn={toggleColumn}
        columns={columns}
      />

      <FilterDrawer
        open={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        filterableFields={filterable.filter((i) => !i.shouldQuickFilter(isMobile))}
        onSuccess={onSetFilters}
        initialValues={filters}
      />
    </Toolbar>
  );
}
