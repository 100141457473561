import { Button } from '@mui/material';
import useFormActions from '../../hooks/useFormActions';
import useFormMeta from '../../hooks/useFormMeta';

export default function SaveButton({ form }: { form?: string }) {
  const { dirty, submitting } = useFormMeta(form);
  const { submit } = useFormActions(form);
  if (dirty) {
    return (
      <Button disabled={submitting} type="button" onClick={submit}>
        Save
      </Button>
    );
  }
  return null;
}
