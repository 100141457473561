import { CheckCircle } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { ButtonAction, ClosableDrawer } from '@/admin';
import { Proof } from '@/models';
import ProofCard from './ProofCard';

export default function ExistingProofDrawer({
  open,
  onClose,
  proofs,
  onChoose,
}: {
  open: boolean;
  onClose: () => void;
  proofs: Proof[];
  onChoose?: (p: Proof) => void;
}) {
  return (
    <ClosableDrawer title="Existing Proofs" open={open} onClose={onClose} width={600}>
      <Grid container spacing={1}>
        {proofs.map((proof) => (
          <Grid item xs={6} key={proof.id}>
            <ProofCard
              proof={proof}
              actions={
                onChoose
                  ? [
                      new ButtonAction('Use This Proof', () => onChoose(proof)).withIcon(
                        CheckCircle,
                      ),
                    ]
                  : []
              }
            />
          </Grid>
        ))}
      </Grid>
    </ClosableDrawer>
  );
}
