import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { AlertColor, Breakpoint } from '@mui/material';
import Joi from 'joi';
import { NullableFieldable, Page } from '../types';

interface ToastState {
  message: ReactNode;
  status: AlertColor;
}

interface GenericDialogOptions {
  title: string;
  description?: ReactNode;
  maxWidth?: Breakpoint;
  width?: number;
}

export interface PromptOptions<T = any> extends GenericDialogOptions {
  submitText?: string;
  cancelText?: string;
  fields: NullableFieldable[];
  initialValues?: Partial<T>;
  defaultValue?: any;
  validation?: Joi.Schema;
  onSubmit?: (values: T) => Promise<any>;
}

export interface AlertOptions extends GenericDialogOptions {
  color?: AlertColor;
  okText?: string;
}

export interface ConfirmOptions extends GenericDialogOptions {
  color?: AlertColor;
  cancelText?: string;
  submitText?: string;
}

interface DialogPromise {
  resolve: (value: any) => void;
  reject: () => void;
}

interface BaseDialog {
  promise: DialogPromise;
  open: boolean;
}

interface PromptDialog extends BaseDialog {
  options: PromptOptions;
}

interface AlertDialog extends BaseDialog {
  options: AlertOptions;
}

interface ConfirmDialog extends BaseDialog {
  options: ConfirmOptions;
}

interface CreateResourceOptions {
  resourceName: string;
  name: string;
  form: string;
  initialValues: Record<string, any>;
  multiple?: boolean;
}

interface AppState {
  routes: Page[];
  isLoading: boolean;
  isMenuOpen: boolean;
  createResource?: CreateResourceOptions;
  appBar: {
    title?: ReactNode;
    actions?: ReactNode;
    back?: string | null;
  };
  alert?: AlertDialog;
  confirm?: ConfirmDialog;
  prompt?: PromptDialog;
  promptDrawer?: PromptDialog;
  toast?: ToastState;
  setToast: (t: ToastState | undefined) => void;
  setIsLoading: (t: boolean) => void;
  setAppBarState: (state: AppState['appBar']) => void;
  setIsMenuOpen: (t: boolean) => void;
  setCreateResource: (t: CreateResourceOptions | undefined) => void;
  setAlertDialog: (state: AppState['alert']) => void;
  setConfirmDialog: (state: AppState['confirm']) => void;
  setPromptDialog: (state: AppState['prompt']) => void;
  setPromptDrawer: (state: AppState['promptDrawer']) => void;
}

export const AppContext = createContext<AppState>({
  routes: [],
  isMenuOpen: false,
  isLoading: false,
  appBar: {},
  setIsLoading: () => null,
  setIsMenuOpen: () => null,
  setAppBarState: () => null,
  setToast: () => null,
  setCreateResource: () => null,
  setAlertDialog: () => null,
  setConfirmDialog: () => null,
  setPromptDialog: () => null,
  setPromptDrawer: () => null,
});

export function useAppContext(): AppState {
  return useContext(AppContext);
}

export function AppProvider({
  children,
  routes,
  title,
}: {
  children: ReactNode;
  routes: Page[];
  title: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [appBar, setAppBarState] = useState<AppState['appBar']>({});
  const [createResource, setCreateResource] = useState<CreateResourceOptions | undefined>();
  const [toast, setToast] = useState<ToastState>();
  const [alert, setAlertDialog] = useState<AppState['alert']>();
  const [confirm, setConfirmDialog] = useState<AppState['confirm']>();
  const [prompt, setPromptDialog] = useState<AppState['prompt']>();
  const [promptDrawer, setPromptDrawer] = useState<AppState['promptDrawer']>();

  useEffect(() => {
    if (appBar.title) {
      document.title = `${appBar.title} | ${title}`;
    } else {
      document.title = title;
    }
  }, [appBar.title, title]);

  return (
    <AppContext.Provider
      value={{
        routes,
        isLoading,
        setIsLoading,
        isMenuOpen,
        setIsMenuOpen,
        setAppBarState,
        appBar,
        createResource,
        toast,
        setToast,
        setCreateResource,
        alert,
        setAlertDialog,
        confirm,
        setConfirmDialog,
        prompt,
        setPromptDialog,
        promptDrawer,
        setPromptDrawer,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useSetIsLoading() {
  return useAppContext().setIsLoading;
}

export function useShowLoading() {
  const setIsLoading = useSetIsLoading();

  return useCallback(
    function showLoading<T>(promise: Promise<T>): Promise<T> {
      setIsLoading(true);
      return promise.finally(() => {
        setIsLoading(false);
      });
    },
    [setIsLoading],
  );
}
