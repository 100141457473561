import { ListItemButton, ListItemText } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { FieldFactory, TextInputField, useDialogs, useToast } from '@/admin';
import { useConfig } from '@/contexts/AppConfigContext';

export default function UserActions() {
  const { prompt } = useDialogs();
  const { user } = useConfig();
  const toast = useToast();

  const handleUpdate = () => {
    prompt({
      title: 'Edit Profile',
      fields: [FieldFactory.image('avatar')],
      initialValues: _.pick(user, ['avatar']),
      onSubmit: (v) => axios.put('/api/me', v),
    }).then(() => {
      window.location.reload();
    });
  };

  const handlePasswordUpdate = () => {
    prompt({
      title: 'Update Password',
      fields: [
        new TextInputField('current_password', 'password').setRequired(),
        new TextInputField('password', 'password').setRequired(),
        new TextInputField('password_confirmation', 'password').setRequired(),
      ],
      onSubmit: (v) => axios.post('/api/change-password', v),
    }).then(() => {
      toast('Password updated');
    });
  };

  return (
    <>
      <ListItemButton onClick={handleUpdate}>
        <ListItemText>Edit Profile</ListItemText>
      </ListItemButton>
      <ListItemButton onClick={handlePasswordUpdate}>
        <ListItemText>Update Password</ListItemText>
      </ListItemButton>
    </>
  );
}
