import Joi from 'joi';
import { FieldFactory, Resource } from '@/admin';
import { ThirdPartyShippingAccount } from '@/models';

export default function thirdPartyShippingAccounts() {
  return new Resource<ThirdPartyShippingAccount>('Third Party Shipping Accounts')
    .setUseDrawer()
    .withValidation(
      Joi.object({
        name: Joi.string().required(),
        carrier: Joi.string().required(),
        account_number: Joi.string().required(),
      }).unknown(),
    )
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.belongsTo('customer', 'customers').setRequired(),
      FieldFactory.select('carrier', {
        ups: 'UPS',
        fedex: 'FedEx',
      }).setRequired(),
      FieldFactory.text('account_number'),
      FieldFactory.text('zip').withHelp(
        'ZIP code of account number to be billed (required for UPS if there is a zip on the billing account).',
      ),
      FieldFactory.text('country').withHelp(
        'Country iso2 code of account number to be billed (required for UPS third party billing only).',
      ),
    ]);
}
