import {
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { StatusChip, TextLink } from '@/admin';
import { APPLIED_STATUS_COLORS, APPLIED_STATUS_LABELS } from '@/constants';
import { curr } from '@/helpers';
import { Payment } from '@/models';

export default function RecentPaymentsForCustomer({ customerId }: { customerId: number }) {
  const date = moment().subtract(12, 'month').format('YYYY-MM-DD');
  const { data: recent } = useQuery(['customerPayments', customerId], () =>
    axios
      .get<{ data: Payment[] }>(
        `/api/payments?count=7&sort=-payment_date&filter[payment_date][gt]=${date}&filter[webstore_id][null]=1&filter[customer_id]=${customerId}`,
      )
      .then(({ data }) => data.data),
  );

  if (!recent) {
    return <CircularProgress />;
  }

  if (recent.length === 0) {
    return (
      <CardContent>
        <Typography variant="body2">No recent payments for customer</Typography>
      </CardContent>
    );
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Number</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {recent.map((payment) => (
          <TableRow key={payment.id}>
            <TableCell>{moment(payment.payment_date).format('MM/DD/YYYY')}</TableCell>
            <TableCell>
              <TextLink to={`/payments/${payment.id}`}>{payment.label}</TextLink>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{curr(payment.amount)}</Typography>
            </TableCell>
            <TableCell>
              <StatusChip
                size="small"
                status={payment.applied_status}
                colors={APPLIED_STATUS_COLORS}
                labels={APPLIED_STATUS_LABELS}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
