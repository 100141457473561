import { CloudDownload } from '@mui/icons-material';
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { ClosableDrawer, curr, getApiUrl } from '@/admin';
import { BusinessPayout, PendingPayout } from '@/models';
import getBusinessName from '@/utils/getBusinessName';

export default function PayoutHistoryDrawer({
  open,
  onClose,
  business,
}: {
  open: boolean;
  onClose: () => void;
  business?: PendingPayout;
}) {
  const businessId = business?.id;
  const { data: history, isLoading } = useQuery(
    ['payoutHistory', businessId],
    () =>
      axios
        .get<{ history: BusinessPayout[] }>(`/api/pending-payouts/${businessId}`)
        .then(({ data }) => data.history),
    {
      enabled: Boolean(open && businessId),
    },
  );

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Payout History">
      {business && (
        <Box mt={-2} mb={3}>
          <Typography variant="subtitle1" gutterBottom color="textSecondary">
            Business: {getBusinessName(business)}
          </Typography>
        </Box>
      )}

      {isLoading && <CircularProgress />}

      {history?.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          No payouts have been made to this business.
        </Typography>
      )}

      {history && history.length > 0 && (
        <List>
          {history.map((p) => (
            <ListItem key={p.id}>
              <ListItemText
                primary={`Payout #${p.id}`}
                secondary={moment(p.created_at).format('lll')}
              />
              <ListItemSecondaryAction>
                <Chip label={curr(p.amount)} />
                <Tooltip title="Download Commission Report">
                  <IconButton
                    component="a"
                    href={getApiUrl(`/api/business-payouts/${p.id}/commissions`)}
                    size="large"
                  >
                    <CloudDownload />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </ClosableDrawer>
  );
}
