import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import AuthLayout from '@/pages/auth/AuthLayout';
import { useAfterLogin } from '@/pages/auth/Login';

export default function Register() {
  const { code } = useParams<{ code: string }>();
  const afterLogin = useAfterLogin();
  const navigate = useNavigate();

  if (!code) {
    return <Navigate to="/login" />;
  }

  const { data: invite } = useQuery(['invite', code], () =>
    axios
      .get<{ name: string; email: string }>(`/api/invites/${code}`)
      .then((res) => res.data)
      .catch(() => {
        navigate('/login');
      }),
  );

  const resetRequest = useMutation<
    unknown,
    AxiosError<{ errors: Record<string, string[]>; message: string }>,
    { code: string; password: string; password_confirmation: string }
  >((v) => axios.post(`/api/register`, v).then(afterLogin));

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    resetRequest.mutate({
      code,
      password: data.get('password') as string,
      password_confirmation: data.get('password_confirmation') as string,
    });
  };

  return (
    <AuthLayout title="Register">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {resetRequest.error && (
          <Alert severity="error" sx={{ my: 1 }}>
            {resetRequest.error.response?.data.message || 'An unknown error occurred'}
          </Alert>
        )}
        <TextField
          margin="normal"
          fullWidth
          id="name"
          label="Name"
          disabled
          value={invite?.name || ' '}
        />
        <TextField
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          disabled
          value={invite?.email || ' '}
        />

        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label="Confirm Password"
          type="password"
          id="password-confirmation"
          autoComplete="current-password"
        />

        <LoadingButton
          loading={resetRequest.isLoading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Complete Registration
        </LoadingButton>
      </Box>
    </AuthLayout>
  );
}
