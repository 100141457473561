import { createContext, useContext } from 'react';
import _ from 'lodash';
import { AppConfig } from '@/responses';

export const AppConfigContext = createContext<AppConfig | undefined>(undefined);

export const AppConfigProvider = AppConfigContext.Provider;

export function useConfig(): AppConfig {
  const config = useContext(AppConfigContext);
  if (!config) {
    throw new Error('Config not set');
  }
  return config;
}

export function useAssetUrl(): (path: string) => string {
  const { assetUrl } = useConfig();
  return (path) => `${_.trimEnd(assetUrl, '/')}/${_.trimStart(path, '/')}`;
}
