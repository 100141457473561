import { useState } from 'react';
import { ToggleOn, ToggleOff } from '@mui/icons-material';
import {
  IconButton,
  Tooltip,
  CircularProgress,
  TableCell,
  TableRow,
  Table,
  TableBody,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { startCase } from 'lodash';
import { useGetResource, makeResourceQueryKey, StatusChip, useDialogs } from '@/admin';
import { DEPARTMENT_STATUS_COLORS } from '@/constants';
import { Order } from '@/models';

const STATUS_FIELDS: (keyof Order)[] = [
  'order_approval_status',
  'art_approval_status',
  'purchasing_status',
  'receiving_status',
  'release_status',
  'staged_status',
  'production_status',
  'subcontract_status',
  'shipping_status',
  'invoiced_at',
  'payment_status',
];

export default function OrderStatuses({
  disabled,
  orderId,
}: {
  disabled?: boolean;
  orderId: number;
}) {
  const [loading, setLoading] = useState<keyof Order>();
  const getResource = useGetResource();
  const { confirm } = useDialogs();

  const { data: order, refetch } = useQuery(
    makeResourceQueryKey('orders', orderId),
    () =>
      getResource('orders')
        .getShowRequest(orderId)
        .then(({ data }) => data),
    {
      staleTime: 5 * 1000,
    },
  );

  const onApprove = (type: 'art' | 'order') => {
    confirm(
      `Toggle ${startCase(type)} Approval`,
      'Are you sure you want to manually toggle this approval?',
    ).then(() => {
      setLoading(type === 'art' ? 'art_approved_at' : 'order_approved_at');
      axios
        .post(`/api/orders/${orderId}/approve?type=${type}`)
        .then(() => {
          refetch();
        })
        .finally(() => {
          setLoading(undefined);
        });
    });
  };

  const onUnrelease = () => {
    confirm(
      'Unrelease Order',
      'Are you sure you want to unrelease this order? This will delete all production events and require a new release request to be sent to art.',
      'warning',
    ).then(() => {
      setLoading('release_status');
      axios
        .post(`/api/orders/${orderId}/unrelease`)
        .then(() => {
          refetch();
        })
        .catch(() => {
          setLoading(undefined);
        });
    });
  };

  if (!order) {
    return <CircularProgress />;
  }

  return (
    <Table size="small">
      <TableBody>
        {STATUS_FIELDS.map((field) => {
          let value = order[field];
          if (field === 'invoiced_at') {
            value = value ? 'yes' : 'no';
          }
          if (typeof value !== 'string' || value === 'none') {
            return null;
          }
          return (
            <TableRow key={field}>
              <TableCell variant="head">{startCase(field.split('_')[0])}</TableCell>
              <TableCell sx={{ width: '50%' }}>
                <StatusChip status={value} colors={DEPARTMENT_STATUS_COLORS} />
              </TableCell>
              {!disabled && (
                <TableCell>
                  {['order_approval_status', 'art_approval_status'].includes(field) && !loading && (
                    <Tooltip title="Toggle Approval">
                      <IconButton
                        size="small"
                        onClick={() => onApprove(field === 'art_approval_status' ? 'art' : 'order')}
                      >
                        {value === 'yes' ? <ToggleOn /> : <ToggleOff />}
                      </IconButton>
                    </Tooltip>
                  )}

                  {field === 'release_status' && value === 'yes' && !loading && (
                    <Tooltip title="Unrelease and Cancel Existing Production Events">
                      <IconButton size="small" onClick={onUnrelease}>
                        <ToggleOn />
                      </IconButton>
                    </Tooltip>
                  )}

                  {loading && field === loading && <CircularProgress size={16} />}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
