import { Avatar } from '@mui/material';
import { ArrayBullets, FieldFactory, Resource } from '@/admin';
import { DesignLayout } from '@/models';
import { maybeAddQueryParams } from '@/utils/query';

export default function designLayouts() {
  return new Resource<DesignLayout>('Design Layouts')
    .getSubtitleUsing((d) => <ArrayBullets elements={[d.increment_id, d.order.increment_id]} />)
    .getAvatarUsing((d) => (
      <Avatar src={maybeAddQueryParams(d.image, { w: 200 })}>{d.increment_id.substring(-1)}</Avatar>
    ))
    .setReadOnly()
    .withQueryParams({
      index: { with: 'order' },
    })
    .withDefaultSort('-increment_id')
    .withFilters([FieldFactory.belongsTo('customer', 'customers').withFilterKey('customer_id')])
    .withFieldsAndColumns([
      FieldFactory.text('increment_id').withLabel('#').sortable(),
      FieldFactory.image('image'),
      FieldFactory.text('name').sortable(),
      FieldFactory.belongsTo('order', 'orders').filterable('order_id').sortable('order_id'),
    ]);
}
