import { Print } from '@mui/icons-material';
import {
  Typography,
  CardContent,
  Grid,
  CardHeader,
  Button,
  IconButton,
  Box,
  useMediaQuery,
  Stack,
  AlertColor,
} from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  useDialogs,
  SubMenu,
  StatusChip,
  getApiUrl,
  useHasPermission,
  ButtonAction,
  useRecord,
  useOnReloadRecord,
  useShowLoading,
  useUpdateRecord,
} from '@/admin';
import FulfillmentOrderCard from '@/components/FulfillmentOrders/FulfillmentOrderCard';
import EventableCard from '@/components/Notes/EventableCard';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import WithLabel from '@/components/Shared/WithLabel';
import ShipActions from '@/components/Shipping/ShipActions';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import { PARTY_COLORS, PICK_STATUS_COLORS } from '@/constants';
import { useConfig } from '@/contexts/AppConfigContext';
import { InventoryPick } from '@/models';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import InventoryPickItems from './InventoryPickItems';

type PickAction =
  | 'start'
  | 'complete'
  | 'ship'
  | 'hold'
  | 'unhold'
  | 'request_cancellation'
  | 'reset'
  | 'delete'
  | 'reship';

const SHIPMENT_STATUSES: InventoryPick['status'][] = [
  'complete',
  'pending_shipment',
  'cancellation_requested',
];

export default function InventoryPickPage() {
  const pick = useRecord<InventoryPick>();
  const { items, address } = pick;
  const printableId = useConfig().printables.find((p) => p.model === 'pick')?.id;
  const navigate = useNavigate();
  const { confirm } = useDialogs();
  const isMobile = useMediaQuery('(max-width: 1000px)');
  const hasPermission = useHasPermission();
  const onReload = useOnReloadRecord();
  const updateRecord = useUpdateRecord();
  const showLoading = useShowLoading();

  const actions: Record<PickAction, [title: string, desc?: string, color?: AlertColor]> = {
    start: [
      'Start Picking',
      'This will specify bins to pull from and move the status to "Picking". Are you sure you want to proceed?',
    ],
    complete: [
      'Complete Picking',
      pick.party === 'avail'
        ? 'The items in this order are part of an Avail sales order. Shipping is not required. Rather, items should be placed on the production shelf. This action will move the pick to the "Completed" status. Are you sure you want to proceed?'
        : 'The items in this pick need to be shipped. This action will move the status to "Pending Shipment". Are you sure you want to proceed?',
    ],
    hold: ['Put on Hold'],
    unhold: ['Remove Hold'],
    request_cancellation: [
      'Request Cancellation',
      'This will move the status to "Cancellation Requested" and give the picker the opportunity to put items back in the bins before deleting the pick. Are you sure you want to proceed?',
      'warning',
    ],
    reset: [
      'Reset',
      'This will revert any inventory entries and move the pick back to the "Issued" status. Are you sure you want to proceed?',
      'warning',
    ],
    delete: [
      'Delete',
      'This will permanently delete the pick. Are you sure you want to proceed?',
      'error',
    ],
    reship: [
      'Reship',
      'This will set the status back to "Pending Shipment" so you can ship it out again',
      'warning',
    ],
    ship: ['Ship'],
  };

  const transitions: Record<InventoryPick['status'], (keyof typeof actions)[]> = {
    issued: ['start', 'hold', 'delete'],
    picking: ['complete', 'reset', 'request_cancellation'],
    on_hold: ['unhold'],
    complete: ['request_cancellation', 'reset', 'reship'],
    pending_shipment: ['ship', 'request_cancellation', 'reset'],
    cancellation_requested: ['delete'],
  };

  const onMethod = (method: PickAction) => () => {
    confirm(
      actions[method][0],
      actions[method][1] || 'Are you sure you want to proceed?',
      actions[method][2],
    ).then(() => {
      showLoading(
        axios.post(`/api/inventory-picks/${pick.id}/${method}`).then(({ data }) => {
          if (method === 'delete') {
            navigate('/inventory-picks');
          } else {
            updateRecord(data);
          }
        }),
      );
    });
  };

  const printButton = printableId && pick.status !== 'issued' && (
    <IconButton
      component="a"
      target="_blank"
      href={getApiUrl(`/api/printables/${printableId}/render/${pick.id}`)}
    >
      <Print />
    </IconButton>
  );

  const getButtonForPrimaryAction = (action: PickAction) => {
    if (action === 'ship') {
      if (pick.party === 'avail') {
        return (
          <Button variant="contained" onClick={onMethod('complete')}>
            Complete
          </Button>
        );
      }
      if (address) {
        return (
          <ShipActions
            shippableId={pick.pickable_id}
            shippableType={pick.pickable_type}
            customerId={pick.pickable.customer?.id}
            title={`Shipping ${pick.number}`}
            qty={_.sumBy(items, 'qty')}
            onSuccess={onReload}
            addressId={address.id}
            addressMethod={address.method}
            requestParams={{
              inventory_pick_id: pick.id,
            }}
          />
        );
      }
    }
    return (
      <Button variant="contained" onClick={onMethod(action)}>
        {actions[action][0]}
      </Button>
    );
  };

  const [primary, ...other] = transitions[pick.status];

  const action = (
    <Stack direction="row" spacing={1} alignItems="center">
      {getButtonForPrimaryAction(primary)}
      {printButton}
      {other.length > 0 && (
        <SubMenu items={other.map((m) => new ButtonAction(actions[m][0], onMethod(m)))} />
      )}
    </Stack>
  );

  return (
    <Grid container spacing={3}>
      <Grid item lg={9} xs={12}>
        <CardWithGutter>
          <CardHeader
            title={pick.number}
            subheader={`Location Group: ${pick.location_group?.name}`}
            action={!isMobile && hasPermission('write:inventory_picks') && action}
          />
          {isMobile && <Box px={2}>{action}</Box>}
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <WithLabel label="Pick Status">
                  <StatusChip status={pick.status} colors={PICK_STATUS_COLORS} />
                </WithLabel>
              </Grid>

              <Grid item xs={12} sm={3}>
                <WithLabel label="Destination">
                  <StatusChip status={pick.party} colors={PARTY_COLORS} />
                </WithLabel>
              </Grid>

              <Grid item xs={12} sm={3}>
                <WithLabel label="Order Date">
                  <Typography>{moment(pick.ordered_at).format('lll')}</Typography>
                </WithLabel>
              </Grid>

              <Grid item xs={12} sm={3}>
                <WithLabel label="Order">
                  <Typography>
                    {pick.pickable_type === 'fulfillment_order'
                      ? pick.pickable.number
                      : pick.pickable.increment_id}
                  </Typography>
                </WithLabel>
              </Grid>
            </Grid>
          </CardContent>
        </CardWithGutter>
        <CardWithGutter>
          <InventoryPickItems items={items} status={pick.status} />
        </CardWithGutter>

        {pick.party === 'customer' && SHIPMENT_STATUSES.includes(pick.status) && (
          <ShipmentsCard
            shippableType={pick.pickable_type}
            shippableId={pick.pickable.id}
            showActions
          />
        )}
      </Grid>
      <Grid item lg={3} xs={12}>
        {pick.pickable_type === 'fulfillment_order' ? (
          <FulfillmentOrderCard order={pick.pickable} />
        ) : (
          <OrderSummaryCard order={pick.pickable} />
        )}

        <EventableCard resourceId={pick.id} resource="picks" size="small" />
      </Grid>
    </Grid>
  );
}
