import { useState } from 'react';
import { Grid, styled, Typography } from '@mui/material';
import { useRecordId } from '@/admin';
import OrderShippingAddresses from '@/components/Orders/Shipping/OrderShippingAddresses';
import { Address, Kit } from '@/models';
import KitsForAddress from './KitsForAddress';
import OrderKitItems from './OrderKitItems';

const EmptyStateContainer = styled('div')({
  border: '1px dashed #aaa',
  padding: 24,
  textAlign: 'center',
  borderRadius: 4,
});

export default function OrderKitting() {
  const orderId = useRecordId() as number;
  const [address, setAddress] = useState<Address>();
  const [kit, setKit] = useState<Kit>();

  return (
    <Grid container spacing={1}>
      <Grid item lg={3} xs={12}>
        <OrderShippingAddresses isKit onSelect={setAddress} />
      </Grid>
      <Grid item lg={4} xs={12}>
        {address ? (
          <KitsForAddress address={address} selected={kit} setSelected={setKit} />
        ) : (
          <EmptyStateContainer>
            <Typography variant="body2">
              Select an address to view the kits associated with it.
            </Typography>
          </EmptyStateContainer>
        )}
      </Grid>
      <Grid item lg={5} xs={12}>
        <OrderKitItems orderId={orderId} kit={kit} />
      </Grid>
    </Grid>
  );
}
