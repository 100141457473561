import { useState, useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useDebounce } from '@/admin';

interface DebouncedTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (s: string) => void;
  initialValue?: string;
}

export default function DebouncedTextField({
  onChange,
  initialValue = '',
  ...props
}: DebouncedTextFieldProps) {
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  return <TextField {...props} onChange={(e) => setValue(e.target.value)} value={value} />;
}
