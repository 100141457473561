import { Delete, Email, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import {
  ButtonAction,
  StatusChip,
  SubMenu,
  useDialogs,
  useOnReloadRecord,
  useShowLoading,
} from '@/admin';
import { OrderApproval } from '@/models';

export default function OrderApprovals({ approvals }: { approvals: OrderApproval[] }) {
  const { confirm } = useDialogs();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();

  const onDelete = (a: OrderApproval) => {
    confirm('Delete Approval', 'Are you sure you want to delete this approval?').then(() => {
      showLoading(axios.delete(`/api/orders/${a.order_id}/approvals/${a.id}`)).then(() => {
        onReload();
      });
    });
  };

  const onSendReminder = (a: OrderApproval) => {
    showLoading(axios.post(`/api/orders/${a.order_id}/approvals/${a.id}/reminder`));
  };

  return (
    <Card>
      <CardHeader title="Approvals Sent" />

      {approvals.length === 0 ? (
        <CardContent>
          <Typography color="textSecondary" variant="body2">
            No approvals have been sent for this order.
          </Typography>
        </CardContent>
      ) : (
        <List>
          {approvals.map((r) => (
            <ListItem key={r.id}>
              <Box flexGrow={1}>
                <Typography variant="body1">{r.label}</Typography>
                <Typography variant="body2" sx={{ mt: 0.5 }}>
                  {r.to}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                  Sent {moment(r.created_at).format('lll')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <StatusChip
                  status={r.status}
                  colors={{
                    Approved: 'green',
                    Declined: 'red',
                    Pending: 'gray',
                  }}
                  sx={{ mr: 1 }}
                />
                <IconButton component="a" target="_blank" href={r.portal_url}>
                  <OpenInNew />
                </IconButton>
                {r.status === 'Pending' && (
                  <SubMenu
                    items={[
                      new ButtonAction('Send Reminder', () => onSendReminder(r)).withIcon(Email),
                      new ButtonAction('Delete', () => onDelete(r)).withIcon(Delete),
                    ]}
                  />
                )}
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </Card>
  );
}
