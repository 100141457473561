import { useState, SyntheticEvent } from 'react';
import {
  Typography,
  Grid,
  Button,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Hidden,
  Box,
  CardHeader,
  CardContent,
  CircularProgress,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {
  Paper,
  useTitle,
  getValueFromEvent,
  useSessionState,
  ClosableDrawer,
  numString,
  Text,
  getApiUrl,
  FieldFactory,
  NonReduxField,
} from '@/admin';
import ProductionEventTable from '@/components/Production/ProductionEventTable';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import MinutesText from '@/components/Shared/MinutesText';
import { ProductionEvent } from '@/models';

interface Ink {
  color: string;
  qty: number;
}

export default function ProductionSchedule() {
  useTitle('Production Schedule');

  const [filters, setFilters] = useSessionState<Record<string, any>>('prodScheduleFilters', {
    date: new Date(),
    eventType: undefined,
  });

  const handleFilterChange = (name: string) => (e: SyntheticEvent) => {
    const value = getValueFromEvent(e);
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [showInks, setShowInks] = useState(false);

  const getEventsUrl = (other: Record<string, string> = {}) => {
    const params = _.pickBy({
      with: 'machine,order_design.design,order',
      sort: '-priority,order.committed_ship_date',
      count: '500',
      'filter[scheduled_at][date]': moment(filters.date).format('YYYY-MM-DD'),
      'filter[event_type_id]': filters.eventType ? String(filters.eventType.id) : undefined,
      'filter[machine_id]': filters.machine ? String(filters.machine.id) : undefined,
      unit: filters.unit,
      incomplete: filters.incomplete ? '1' : '0',
      ...other,
    }) as Record<string, string>;
    return `/api/production-events?${new URLSearchParams(params).toString()}`;
  };

  const { data, isLoading, refetch } = useQuery(
    [getEventsUrl()],
    () =>
      axios.get<{ data: ProductionEvent[]; inks: Ink[] }>(getEventsUrl()).then(({ data }) => data),
    {
      enabled: Boolean(filters.date),
    },
  );

  const events = data?.data || [];
  const inks = data?.inks;
  const grouped = _.groupBy(events, 'machine.id');

  return (
    <Box>
      <Paper>
        <Grid container spacing={3}>
          <Grid item lg={3} md={6} xs={12}>
            <NonReduxField
              onChange={handleFilterChange('date')}
              value={filters.date}
              field={FieldFactory.date('date')}
            />
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <NonReduxField
              onChange={handleFilterChange('eventType')}
              value={filters.eventType}
              field={FieldFactory.belongsTo('event_type', 'productionEventTypes')}
            />
          </Grid>
          <Hidden lgDown>
            <Grid item lg={3} md={6} xs={12}>
              <NonReduxField
                onChange={handleFilterChange('machine')}
                value={filters.machine}
                field={FieldFactory.belongsTo('machine', 'productionMachines')}
              />
            </Grid>
          </Hidden>
          <Hidden lgDown>
            <Grid item lg={3} md={6} xs={12}>
              <NonReduxField
                onChange={handleFilterChange('unit')}
                value={filters.unit}
                field={FieldFactory.select('unit', [
                  { value: 'colors', label: 'Screenprint' },
                  { value: 'stitches', label: 'Embroidery' },
                ]).with({
                  includeBlank: true,
                  label: 'Department',
                })}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <NonReduxField
              onChange={handleFilterChange('incomplete')}
              value={filters.incomplete}
              field={FieldFactory.boolean('incomplete', 'Show Only Incomplete')}
            />
          </Grid>
        </Grid>
      </Paper>
      {!filters.date ? (
        <Typography>Please select a date</Typography>
      ) : (
        <div>
          <Box mb={2}>
            <Button
              component="a"
              href={getApiUrl(getEventsUrl({ format: 'pdf' }))}
              target="_blank"
              variant="contained"
              size="small"
              color="secondary"
              sx={{ mr: 2 }}
            >
              Download Schedule
            </Button>
            {inks && (
              <Button
                onClick={() => setShowInks(true)}
                variant="contained"
                size="small"
                color="secondary"
              >
                See Ink List
              </Button>
            )}
          </Box>

          {isLoading && <CircularProgress />}

          {Object.values(grouped).map((groupedEvents) => {
            const { machine } = groupedEvents[0];
            return (
              <CardWithGutter key={machine?.id || 'none'}>
                <CardHeader title={machine?.name || '(Unassigned)'} />
                <CardContent>
                  <Text
                    primary={`Total: ${numString(_.sumBy(groupedEvents, 'quantity'), 'imprints')}`}
                    secondary={<MinutesText minutes={_.sumBy(groupedEvents, 'job_minutes')} />}
                  />
                </CardContent>

                <ProductionEventTable events={groupedEvents} onReload={refetch} />
              </CardWithGutter>
            );
          })}

          {Object.keys(grouped).length === 0 && !isLoading && (
            <Typography>There are no events scheduled.</Typography>
          )}
        </div>
      )}

      {inks && (
        <ClosableDrawer title="Ink List" open={showInks} onClose={() => setShowInks(false)}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Color</TableCell>
                <TableCell>Imprint Qty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inks.map((ink) => (
                <TableRow key={ink.color}>
                  <TableCell>{ink.color}</TableCell>
                  <TableCell>{ink.qty}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClosableDrawer>
      )}
    </Box>
  );
}
