import { Mail, MailOutline } from '@mui/icons-material';
import axios from 'axios';
import { ButtonAction, CustomAction, FieldFactory, Resource } from '@/admin';
import PrintMenu from '@/components/Print/PrintMenu';
import PurchaseOrderNavigation from '@/components/Purchasing/PurchaseOrderNavigation';
import PurchaseOrderPage from '@/components/Purchasing/PurchaseOrderPage';
import UserLabel from '@/components/Users/UserLabel';
import { PURCHASE_ORDER_STATUS_COLORS } from '@/constants';
import { POAttachmentMode, PurchaseOrder } from '@/models';

export default function purchaseOrders() {
  return new Resource<PurchaseOrder>('Purchase Orders')
    .withDefaultSort('-increment_id')
    .getLabelUsing((p) => p.label)
    .getTitleUsing((p) => p.label)
    .withExportable({ format: 'xlsx' })
    .getSingleActionsUsing((values) => [
      new CustomAction('Print', () => <PrintMenu model="po" id={values.id} />),
      new CustomAction('Navigation', () => <PurchaseOrderNavigation poId={values.id} />),
      new ButtonAction('Email PO to Myself', ({ setIsLoading }) => {
        setIsLoading(true);
        axios.post(`/api/purchase-orders/${values.id}/email-to-me`).finally(() => {
          setIsLoading(false);
        });
      }).withIcon(MailOutline),
      new ButtonAction('Email PO to Others', ({ dialogs, onReloadRecord }) => {
        dialogs
          .prompt({
            title: 'Email PO to Others',
            description: 'You will be copied on the email.',
            submitText: 'Send',
            fields: [
              FieldFactory.list('emails', [FieldFactory.email('email').withColumnSpan(10)]),
              FieldFactory.text('subject'),
              FieldFactory.textarea('text'),
              FieldFactory.radio('attachment_mode', {
                zip: 'Single Zip File',
                attachments: 'Individual Attachments',
                links: 'Links in Email Body',
              }).withHelp('This is how art files or any attachments will be sent'),
            ],
            initialValues: {
              emails: [{ email: '' }],
              subject: `New Order for Entry: ${values.increment_id}`,
              text: 'Please see the attached order for entry.',
              attachment_mode: 'zip',
            },
            onSubmit: (v: {
              emails: { email: string }[];
              subject: string;
              text: string;
              attachment_mode: POAttachmentMode;
            }) =>
              axios.post(`/api/purchase-orders/${values.id}/email-to-others`, {
                ...v,
                emails: v.emails.map((e) => e.email).filter((e) => !!e),
              }),
          })
          .then(() => {
            onReloadRecord();
          });
      }).withIcon(Mail),
    ])
    .withInitialColumns([
      'increment_id',
      'vendor',
      'description',
      'invoice_number',
      'status',
      'total',
      'issued_date',
      'type',
      'party',
    ])
    .withColumns([
      FieldFactory.text('increment_id').withLabel('#').sortable(),
      FieldFactory.select('type', {
        general: 'General',
        blanks: 'Blanks',
        subcontract: 'Subcontract',
      })
        .sortable()
        .filterable(),
      FieldFactory.belongsTo('vendor', 'vendors').sortable('vendor.name').filterable(),
      FieldFactory.text('description').sortable(),
      FieldFactory.belongsTo('business', 'businesses')
        .filterable('filter[business_id]')
        .sortable('business.name'),
      FieldFactory.text('invoice_number').filterable().sortable(),
      FieldFactory.curr('total').withLabel('Total').sortable().setAggregatable(),
      FieldFactory.select('party', {
        avail: 'Avail',
        customer: 'Customer (Drop-Ship)',
        subcontractor: 'Subcontractor',
      })
        .withLabel('Ship To Party')
        .filterable(),
      FieldFactory.text('vendor_contact'),
      FieldFactory.date('issued_date').sortable().filterable(),
      FieldFactory.date('requested_ship_date').sortable().filterable(),
      FieldFactory.date('drop_dead_date').sortable().filterable(),
      FieldFactory.date('expected_date').sortable().filterable(),
      FieldFactory.belongsTo('created_by_user', 'users')
        .withLabel('Purchased By')
        .filterable('filter[created_by]')
        .renderCellUsing((u) => <UserLabel user={u} />),
      FieldFactory.status('status', PURCHASE_ORDER_STATUS_COLORS).quickFilterable(),
      FieldFactory.curr('subtotal').withLabel('Subtotal').setAggregatable().sortable(),
      FieldFactory.curr('shipping').sortable().setAggregatable(),
      FieldFactory.curr('tax').sortable().setAggregatable(),
      FieldFactory.curr('fee').sortable().setAggregatable(),
    ])
    .withFields([FieldFactory.belongsTo('vendor', 'vendors'), FieldFactory.text('description')])
    .editUsing(PurchaseOrderPage);
}
