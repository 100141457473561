import { styled } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import StaticFormControl from '../../components/Form/StaticFormControl';
import { FieldProps } from '../../types';
import renderCell from '../../utils/renderCell';
import Field from '../Field';

const ReadOnlyWrapper = styled('div')({
  fontSize: 14,
  padding: '0 14px 10.5px 14px',
  whiteSpace: 'pre-line',
});

export function Read({ input, field, meta }: FieldProps) {
  return (
    <StaticFormControl field={field} meta={meta}>
      <ReadOnlyWrapper style={field.style}>
        {renderCell(field, input.value, {}, '(none)')}
      </ReadOnlyWrapper>
    </StaticFormControl>
  );
}

export default class ReadField extends Field {
  readOnly = true;

  renderEditComponent(props: WrappedFieldProps) {
    return <Read {...props} field={this} />;
  }
}
