import { ChangeEvent, useState } from 'react';
import { Refresh } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
  CircularProgress,
  IconButton,
  CardHeader,
  CardContent,
  TableContainer,
  LinearProgress,
  Card,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { numString } from '@/admin';
import AddressTitle from '@/components/Addresses/AddressTitle';
import ShipActions from '@/components/Shipping/ShipActions';
import { Address, OrderItem } from '@/models';
import { canShipOrderItem, orderItemToSelectedItem, SelectedItem } from '@/utils/shipping';

export default function OrderShippingSimple({
  orderId,
  address,
  customerId,
}: {
  orderId: number;
  customerId: number;
  address: Address;
}) {
  const [selected, setSelected] = useState<SelectedItem[]>([]);

  const {
    data: items = [],
    isLoading,
    isFetching,
    refetch,
  } = useQuery(['shippableItems', orderId], () =>
    axios
      .get<{ data: OrderItem[] }>(`/api/orders/${orderId}/items?filter[is_shippable]=1`)
      .then(({ data }) => data.data),
  );

  const hasScrap = items.some((i) => i.qty_scrapped > 0);
  const shippableItems = items.filter(canShipOrderItem);

  const onShipSuccess = () => {
    setSelected([]);
    refetch();
  };

  const handleCheck = (item: OrderItem) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected([...selected, orderItemToSelectedItem(item)]);
    } else {
      setSelected(selected.filter((s) => s.id !== item.id));
    }
  };

  const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(shippableItems.map(orderItemToSelectedItem));
    } else {
      setSelected([]);
    }
  };

  const handleSelectedChange =
    (id: number, field: keyof SelectedItem) => (e: ChangeEvent<HTMLInputElement>) => {
      setSelected(
        selected.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              [field]: e.target.value,
            };
          }
          return item;
        }),
      );
    };

  if (isLoading) {
    return <CircularProgress />;
  }

  const qty = _.sumBy(selected, (s) => Number(s.qty_shipped));
  const actions = (
    <div>
      <IconButton onClick={() => refetch()} size="large">
        <Refresh />
      </IconButton>
      {selected.length > 0 && address && (
        <ShipActions
          shippableId={orderId}
          shippableType="order"
          customerId={customerId}
          title={`Shipping ${numString(qty, 'Items')}`}
          qty={qty}
          onSuccess={onShipSuccess}
          addressId={address.id}
          requestParams={{ items: selected }}
        />
      )}
    </div>
  );

  return (
    <Card>
      <CardHeader title="Items to Ship" action={actions} />
      {isFetching && <LinearProgress />}
      <CardContent>
        <AddressTitle address={address} />
      </CardContent>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {shippableItems.length > 0 && (
                  <Checkbox
                    size="small"
                    onChange={(e) => handleCheckAll(e)}
                    checked={selected.length === shippableItems.length}
                    indeterminate={selected.length > 0 && selected.length < shippableItems.length}
                  />
                )}
              </TableCell>
              <TableCell>Item #</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Qty Req</TableCell>
              {hasScrap && <TableCell>Qty Scrapped</TableCell>}
              <TableCell>Qty Shipped</TableCell>
              {selected.length > 0 ? <TableCell>Qty to Ship</TableCell> : <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => {
              const checked = selected.find((i) => i.id === item.id);
              return (
                <TableRow key={item.id}>
                  <TableCell padding="checkbox">
                    {canShipOrderItem(item) && (
                      <Checkbox size="small" onChange={handleCheck(item)} checked={!!checked} />
                    )}
                  </TableCell>
                  <TableCell>{item.number}</TableCell>
                  <TableCell>{item.color}</TableCell>
                  <TableCell>{item.size}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.qty}</TableCell>
                  {hasScrap && <TableCell>{item.qty_scrapped}</TableCell>}
                  <TableCell>{item.qty_shipped}</TableCell>
                  {checked ? (
                    <TableCell>
                      <TextField
                        size="small"
                        style={{ width: 80 }}
                        onChange={handleSelectedChange(item.id, 'qty_shipped')}
                        value={checked.qty_shipped}
                        type="number"
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      <div style={{ width: 100 }}>&nbsp;</div>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
