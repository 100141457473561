import { ChangeEvent } from 'react';
import { Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import { FieldProps, useRecord } from '@/admin';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import { OrderItem } from '@/models';

export default function ScrapItemsField({ input }: FieldProps) {
  const rows = (input.value || []) as { order_item_id: number; qty: number }[];
  const { items } = useRecord<{ items: OrderItem[] }>();

  const handleChange = (itemId: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newRows = structuredClone(rows);
    const existingRow = newRows.find((r) => r.order_item_id === itemId);
    if (existingRow) {
      existingRow.qty = Number(e.target.value);
    } else {
      newRows.push({ order_item_id: itemId, qty: Number(e.target.value) });
    }
    input.onChange(newRows);
  };

  return (
    <Table>
      <TableBody>
        {items
          .filter((i) => i.can_apply_designs)
          .map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <OrderItemProduct item={item} showSize />
              </TableCell>
              <TableCell>
                <TextField
                  value={rows.find((r) => r.order_item_id === item.id)?.qty || 0}
                  onChange={handleChange(item.id)}
                  type="number"
                  size="small"
                />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
