import { Box, CardContent, CardHeader, Typography } from '@mui/material';
import axios from 'axios';
import {
  SubMenu,
  TextLink,
  useDialogs,
  StatusChip,
  ButtonAction,
  useHasPermission,
  MappedRow,
  useOnReloadRecord,
  useShowLoading,
} from '@/admin';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import { PICK_STATUS_COLORS } from '@/constants';
import { InventoryPick } from '@/models';
import InventoryPickItems from '../Inventory/InventoryPickItems';

export default function InventoryPickCard({
  pick,
  otherRows,
}: {
  pick: InventoryPick;
  otherRows: MappedRow[];
}) {
  const { confirm } = useDialogs();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();
  const hasPermission = useHasPermission();

  const status = <StatusChip status={pick.status} colors={PICK_STATUS_COLORS} />;

  const onDelete = () => {
    confirm(`Delete ${pick.number}`, 'Are you sure? This action is not reversible.', 'error').then(
      () => {
        showLoading(axios.post(`/api/inventory-picks/${pick.id}/delete`)).then(() => {
          onReload();
        });
      },
    );
  };

  const onCancel = () => {
    confirm(
      `Request Cancellation of ${pick.number}`,
      'The warehouse has already started picking and it will take them time to put the items back in inventory. Are you sure you want to proceed?',
      'warning',
    ).then(() => {
      showLoading(axios.post(`/api/inventory-picks/${pick.id}/request-cancellation`)).then(() => {
        onReload();
      });
    });
  };

  const actions = [];
  if (pick.status === 'issued') {
    actions.push(new ButtonAction('Delete Pick', onDelete));
  } else {
    actions.push(new ButtonAction('Request Cancellation', onCancel));
  }

  return (
    <CardWithGutter key={pick.id}>
      <CardContent sx={{ pb: 0, pt: 1 }}>
        <Typography variant="overline" color="primary">
          Inventory Pick
        </Typography>
      </CardContent>
      <CardHeader
        sx={{ pt: 0 }}
        title={
          <TextLink
            to={`/inventory-picks/${pick.id}`}
            disabled={!hasPermission('read:purchase_orders')}
            otherRows={otherRows}
          >
            {pick.number}
          </TextLink>
        }
        subheader={<Box mt={1}>Shipping To: {pick.address?.name}</Box>}
        action={
          <>
            {status}
            {actions.length > 0 && <SubMenu items={actions} />}
          </>
        }
      />
      <InventoryPickItems items={pick.items} status={pick.status} showItemStatus={false} />
    </CardWithGutter>
  );
}
