import {
  Archive,
  ArtTrack,
  ContentCopy,
  FileCopy,
  Link,
  LocalAtm,
  Mail,
  Merge,
  MoneyOff,
  OpenInBrowser,
  Receipt,
  Refresh,
  Send,
  SwapHoriz,
  Unarchive,
  Undo,
  Warning,
} from '@mui/icons-material';
import {
  Alert,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import Joi from 'joi';
import _ from 'lodash';
import {
  Action,
  AnchorAction,
  ButtonAction,
  curr,
  CustomAction,
  FieldFactory,
  FieldProps,
  GroupLayout,
  OnClickProps,
} from '@/admin';
import HubspotIcon from '@/assets/hubspot.svg?react';
import { requiredFields } from '@/helpers';
import { ArtRequest, Order, OrderWithDetails } from '@/models';
import { AppConfig, InvoicePreviewRes } from '@/responses';
import ModelLedgerEntries from '../Accounting/ModelLedgerEntries';
import NoteAlert from '../Notes/NoteAlert';
import PrintMenu from '../Print/PrintMenu';
import ArtRequestNoteField from './ArtRequestNoteField';
import OrderNavigation from './OrderNavigation';

function AfterProofCompletion({ input, field }: FieldProps) {
  return (
    <FormControl fullWidth margin="dense">
      <InputLabel>{field.label}</InputLabel>
      <Select {...input} label={field.label}>
        <MenuItem value="both_approval">
          <ListItemText
            primary="Send Art & Order Approval"
            secondary="Customer can approve the art and the items on the order"
          />
        </MenuItem>
        <MenuItem value="art_approval">
          <ListItemText
            primary="Send Art Approval Only"
            secondary="Customer can only approve art; items will remain unpurchasable until order is approved"
          />
        </MenuItem>
        <MenuItem value="no_approval">
          <ListItemText
            primary="Do Not Send Approval"
            secondary="Approval for art & order will be handled manually"
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default function OrderActions(order: OrderWithDetails, appConfig: AppConfig) {
  const onRestore = ({ setIsLoading, dialogs, onReloadRecord }: OnClickProps) => {
    dialogs.confirm('Restore Order', 'Are you sure you want to restore this order?').then(() => {
      setIsLoading(true);
      axios
        .post(`/api/orders/${order.id}/restore`)
        .then(() => {
          onReloadRecord();
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const onCancel = ({ dialogs, setIsLoading, onReloadRecord }: OnClickProps) => {
    dialogs
      .confirm('Cancel Order', 'Are you sure you want to cancel this order?', 'error')
      .then(() => {
        setIsLoading(true);
        axios
          .post(`/api/orders/${order.id}/cancel`)
          .then(() => {
            onReloadRecord();
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  };

  const onInvoice = async ({ dialogs, toast, onReloadRecord, setIsLoading }: OnClickProps) => {
    setIsLoading(true);
    const { data: invoicePreview } = await axios
      .get<InvoicePreviewRes>(`/api/orders/${order.id}/invoice`)
      .finally(() => {
        setIsLoading(false);
      });

    const { notes, analysis, invoice_contacts: contacts, addresses } = invoicePreview;

    const description = (
      <div
        style={{
          minWidth: 500,
          marginTop: -8,
        }}
      >
        {notes.map((n) => (
          <NoteAlert note={n} key={n.id} />
        ))}
        <Table size="small" sx={{ my: 2 }}>
          <TableRow>
            <TableCell variant="head">Order Type</TableCell>
            <TableCell>{invoicePreview.order_type.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Shipping Method</TableCell>
            <TableCell>
              {_.startCase(invoicePreview.shipping_mode)}: {addresses[0]?.method || '(No Method)'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Total</TableCell>
            <TableCell>{curr(order.total)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Tax</TableCell>
            <TableCell>
              {curr(order.total_tax)}
              {order.total_exempt > 0 && <span>{` (Exempt: ${curr(order.total_exempt)})`}</span>}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Margin</TableCell>
            <TableCell>{analysis.margin.formatted}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Balance</TableCell>
            <TableCell>{curr(order.balance)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Order Contact</TableCell>
            <TableCell>{order.contact_email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">AP Contacts</TableCell>
            <TableCell>{contacts.join(', ')}</TableCell>
          </TableRow>
        </Table>
      </div>
    );
    dialogs
      .prompt({
        title: `Invoice ${order.increment_id}`,
        description,
        submitText: 'Invoice',
        maxWidth: 'sm',
        initialValues: {
          invoice_date: invoicePreview.initially_invoiced_at,
          send_invoice: order.balance > 0,
        },
        fields: [
          new GroupLayout('Options', [
            FieldFactory.boolean('send_invoice', 'Send Invoice Email'),
            // eslint-disable-next-line react-hooks/rules-of-hooks
            FieldFactory.useDefault(
              'invoice_date',
              FieldFactory.date('invoice_date').withLabel('Invoice Date'),
            ).with({
              useDefaultLabel: 'Invoice Today',
            }),
          ]),
        ],
      })
      .then((values) => {
        setIsLoading(true);
        axios
          .post(`/api/orders/${order.id}/invoice`, values)
          .then(() => {
            onReloadRecord();
            toast('Order has been invoiced');
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  };

  const onUninvoice = ({ dialogs, setIsLoading, toast, onReloadRecord }: OnClickProps) => {
    dialogs
      .confirm('Uninvoice Order', 'Are you sure you want to uninvoice this order?', 'error')
      .then(() => {
        setIsLoading(true);
        axios
          .post(`/api/orders/${order.id}/uninvoice`)
          .then(() => {
            onReloadRecord();
            toast('Order has been uninvoiced');
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  };

  const onPaymentReminder = ({ dialogs, setIsLoading, onReloadRecord }: OnClickProps) => {
    dialogs
      .confirm(
        'Send Payment Reminder',
        'Are you sure you want to send a payment reminder for this order?',
      )
      .then(() => {
        setIsLoading(true);
        axios
          .post(`/api/orders/${order.id}/reminder`)
          .then(() => {
            onReloadRecord();
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  };

  const onMailInvoice = ({ dialogs, setIsLoading, onReloadRecord }: OnClickProps) => {
    dialogs
      .confirm(
        'Mail Invoice',
        'This will send a physical copy of the invoice to the billing address on file via USPS. The cost for this service is $1.50 per invoice. Are you sure you want to proceed?',
      )
      .then(() => {
        setIsLoading(true);
        axios
          .post(`/api/orders/${order.id}/letters`)
          .then(() => {
            onReloadRecord();
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  };

  const onDuplicate = ({ dialogs, navigate, toast, setIsLoading }: OnClickProps) => {
    dialogs
      .prompt({
        title: 'Duplicate Order',
        fields: [
          new GroupLayout('options', [
            FieldFactory.boolean('use_customer_defaults', 'Use Customer Defaults'),
            FieldFactory.boolean('notes', 'Notes'),
            FieldFactory.boolean('attachments', 'Attachments'),
          ]).withLabel('Data to copy to new order:'),
        ],
        initialValues: {
          use_customer_defaults: true,
          notes: false,
          attachments: true,
        },
      })
      .then((values) => {
        setIsLoading(true);
        axios
          .post(`/api/orders/${order.id}/duplicate`, values)
          .then(({ data }) => {
            toast('Order has been duplicated');
            navigate(`/orders/${data.id}`);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  };

  const onScrap = ({ dialogs, onReloadRecord }: OnClickProps) => {
    dialogs
      .prompt({
        title: 'Order Scrap Settings',
        fields: [
          FieldFactory.boolean('is_scrap', 'Is Scrap?'),
          FieldFactory.select(
            'scrap_owner',
            _.pick(appConfig.costCategories, ['screenprint', 'embroidery', 'other_decoration']),
          )
            .with({ includeBlank: true })
            .withLabel('Responsible Department'),
        ],
        initialValues: _.pick(order, ['is_scrap', 'scrap_owner']),
        onSubmit: (v) => axios.put(`/api/orders/${order.id}/scrap`, v),
      })
      .then(() => {
        onReloadRecord();
      });
  };

  const onArtRequest =
    (type: ArtRequest['type']) =>
    ({ dialogs, toast, onReloadRecord }: OnClickProps) => {
      const fields = [FieldFactory.custom('note', ArtRequestNoteField).withLabel('Note to Art')];
      const initialValues: {
        type: ArtRequest['type'];
        after?: 'art_approval' | 'order_approval' | 'no_approval' | 'both_approval';
        note?: string;
      } = {
        type,
      };
      if (type === 'proof') {
        if (order.contact_email) {
          fields.push(
            FieldFactory.custom('after', AfterProofCompletion).withLabel('After Proof Completion'),
          );
          initialValues.after = order.order_approved_at ? 'art_approval' : 'both_approval';
        } else {
          fields.push(
            FieldFactory.custom('after', () => (
              <Alert severity="warning">There is no contact email for this order</Alert>
            )),
          );
          initialValues.after = 'no_approval';
        }
      }
      dialogs
        .prompt({
          title: `Request Art ${_.startCase(type)}`,
          fields,
          maxWidth: 'md',
          initialValues,
          onSubmit: (v) =>
            axios.post(`/api/orders/${order.id}/art-requests`, {
              type,
              note: v.note,
              send_approval_on_complete: v.after !== 'no_approval',
              only_art_approval: v.after === 'art_approval',
            }),
        })
        .then(() => {
          onReloadRecord();
          toast(`A ${type} request has been sent to art`);
        });
    };

  const onApproval = ({ dialogs, toast, onReloadRecord }: OnClickProps) => {
    dialogs
      .prompt({
        title: 'Send Approval to Customer',
        fields: [
          FieldFactory.email('to'),
          FieldFactory.textarea('note').withLabel('Note to Customer'),
          new GroupLayout('What to Approve (if applicable)', [
            FieldFactory.boolean('is_art', 'Art'),
            FieldFactory.boolean('is_order', 'Order'),
          ]),
        ],
        initialValues: {
          to: order.contact_email,
          is_order: !order.order_approved_at,
          is_art: !order.art_approved_at,
        },
        validation: Joi.object({
          to: Joi.string().email({ tlds: false }).required(),
        }).unknown(),
        onSubmit: (v) => axios.post(`/api/orders/${order.id}/approvals`, v),
      })
      .then(({ data }) => {
        onReloadRecord();
        toast(`Order Approval has been sent to ${data.to}`);
      });
  };

  const onWriteOff = ({ dialogs, onReloadRecord }: OnClickProps) =>
    dialogs
      .confirm(
        'Write Off Bad Debt',
        'Are you sure you want to write off the remaining balance on this order?',
      )
      .then(() => {
        axios.post(`/api/orders/${order.id}/write-off`).then(() => {
          onReloadRecord();
        });
      });

  const onViewGl = ({ dialogs }: OnClickProps) => {
    dialogs.prompt({
      title: 'GL Entries for Order',
      description: <ModelLedgerEntries resource="orders" resourceId={order.id} />,
      fields: [],
      submitText: 'OK',
      maxWidth: 'md',
    });
  };

  const onLink = ({ dialogs, onReloadRecord }: OnClickProps) => {
    dialogs
      .prompt({
        title: 'Link Existing Webstore',
        fields: [FieldFactory.number('webstore_id')],
        initialValues: _.pick(order, 'webstore_id'),
        onSubmit: (v) => axios.put(`/api/orders/${order.id}`, v),
      })
      .then(() => {
        onReloadRecord();
      });
  };

  const onSummarize = ({ onReloadRecord }: OnClickProps) => {
    axios.post(`/api/orders/${order.id}/summarize`).then(() => {
      onReloadRecord();
    });
  };

  const onMerge = ({ dialogs, navigate }: OnClickProps) => {
    dialogs
      .prompt<{ into_order: Order }>({
        title: 'Merge Order Into',
        description:
          'The current items and art will be moved to the new order, and the current order will be deleted.',
        fields: [
          FieldFactory.belongsTo('into_order', 'orders').withRequestParams({
            filter: {
              customer_id: order.customer.id,
            },
          }),
        ],
        validation: requiredFields(['into_order']),
        onSubmit: (v) =>
          axios.post(`/api/orders/${order.id}/merge`, { into_order_id: v.into_order.id }),
      })
      .then(({ data }) => {
        navigate(`/orders/${data.id}`);
      });
  };

  if (order.cancelled_at) {
    return [
      new ButtonAction('Restore', onRestore).withIcon(Unarchive).withPermission('write:orders'),
    ];
  }

  const actions: Action[] = [
    new CustomAction('Nav', () => <OrderNavigation orderId={order.id} />),
    new CustomAction('Print', () => <PrintMenu model="order" id={order.id} />),
  ];

  if (!appConfig.permissions.includes('write:orders')) {
    return actions;
  }

  if (!order.invoiced_at) {
    if (order.art_approval_status === 'no') {
      actions.push(new ButtonAction('Request Proof', onArtRequest('proof')).withIcon(ArtTrack));
    }

    if (order.art_approval_status === 'yes' && order.release_status === 'no') {
      actions.push(new ButtonAction('Request Release', onArtRequest('release')).withIcon(ArtTrack));
    }

    if (order.order_approval_status === 'no' || order.art_approval_status === 'no') {
      actions.push(new ButtonAction('Send Approval', onApproval).withIcon(Send));
    }
  }

  if (order.order_approval_status === 'yes') {
    if (order.invoiced_at) {
      actions.push(
        new ButtonAction('Uninvoice', onUninvoice).withIcon(Undo).withPermission('orders:invoice'),
      );

      if (order.balance > 0) {
        actions.push(new ButtonAction('Send Payment Reminder', onPaymentReminder).withIcon(Send));
        actions.push(new ButtonAction('Mail Invoice', onMailInvoice).withIcon(Mail));
      }
    } else {
      actions.push(
        new ButtonAction('Invoice', onInvoice).withIcon(LocalAtm).withPermission('orders:invoice'),
      );
    }
  }

  if (order.status.startsWith('Waiting on Customer P')) {
    actions.push(new ButtonAction('Send Payment Reminder', onPaymentReminder).withIcon(Send));
  }

  if (order.invoiced_at && order.balance > 0) {
    actions.push(
      new ButtonAction('Write Off Bad Debt', onWriteOff)
        .withIcon(MoneyOff)
        .withPermission('write:transactions'),
    );
  }

  if (order.order_type.is_webstore && appConfig.permissions.includes('write:customers')) {
    actions.push(new ButtonAction('Link Existing Webstore', onLink).withIcon(Link));
  }

  actions.push(
    new ButtonAction('Refresh Status', onSummarize).withIcon(Refresh),
    new ButtonAction('View GL', onViewGl).withIcon(Receipt).withPermission('read:transactions'),
    new ButtonAction('Duplicate Order', onDuplicate)
      .withIcon(FileCopy)
      .withPermission('write:orders'),
    new ButtonAction('Convert to Scrap', onScrap)
      .withIcon(SwapHoriz)
      .withPermission('orders:scrap'),
    new AnchorAction('View in the Portal', order.portal_url).withIcon(OpenInBrowser),
    new ButtonAction(`Copy Portal Link`, ({ toast }) => {
      navigator.clipboard
        .writeText(order.portal_url)
        .then(() => {
          toast('Link copied to clipboard');
        })
        .catch(() => {
          toast('Failed to copy link to clipboard', 'error');
        });
    }).withIcon(ContentCopy),
  );

  if (!order.portal_url.includes('portal')) {
    actions.push(
      new ButtonAction(`${order.hubspot_id ? 'Update' : 'Create'} Deal in Hubspot`, ({ toast }) => {
        axios.post(`/api/orders/${order.id}/hubspot`).then(() => {
          toast('Order is syncing to Hubspot');
        });
      }).withIcon(HubspotIcon),
    );
  }

  if (!order.invoiced_at) {
    actions.push(
      new ButtonAction('Create Backorder', ({ navigate }) =>
        navigate('?tab=items&action=backorder'),
      )
        .withIcon(Warning)
        .withPermission('write:orders'),
      new ButtonAction('Merge Order', onMerge).withIcon(Merge).withPermission('orders:scrap'),
      new ButtonAction('Cancel', onCancel).withIcon(Archive).withPermission('write:orders'),
    );
  }
  return actions;
}
