import { useSelector } from 'react-redux';
import { isDirty, isSubmitting, isValid } from 'redux-form';
import { useMaybeCurrentForm } from '../utils/genericResource';

export default function useFormMeta(formName?: string) {
  const currentForm = useMaybeCurrentForm();
  const form = formName || currentForm || '';
  const dirty = useSelector((s) => isDirty(form)(s as any));
  const submitting = useSelector((s) => isSubmitting(form)(s as any));
  const valid = useSelector((s) => isValid(form)(s as any));

  return {
    dirty,
    submitting,
    valid,
  };
}
