import { Dispatch, SetStateAction, useState } from 'react';
import useOnChangeEffect from '../hooks/useOnChangeEffect';

const getSavedState = (storageKey: string) => {
  const string = sessionStorage.getItem(storageKey);
  if (string == null) {
    return null;
  }
  if (string === 'undefined') {
    return undefined;
  }
  return JSON.parse(string);
};

export default function useSessionState<T>(
  storageKey: string,
  initialState: T | (() => T),
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    if (sessionStorage.getItem(storageKey)) {
      return getSavedState(storageKey) as T;
    }
    // @ts-ignore
    return typeof initialState === 'function' ? initialState() : initialState;
  });

  useOnChangeEffect(() => {
    sessionStorage.setItem(storageKey, JSON.stringify(value));
  }, [value]);

  return [value, setValue];
}
