import { useEffect, useMemo } from 'react';
import { Delete, OpenInNew } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import { WrappedFieldArrayProps } from 'redux-form';
import { curr, FieldFactory, ReduxField, useDialogs } from '@/admin';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import { PaymentTransactionItem, Vendor } from '@/models';

export default function PaymentItems({
  fields,
  items,
  vendor,
}: WrappedFieldArrayProps & {
  vendor: Vendor | null;
  items: PaymentTransactionItem[];
}) {
  const { prompt } = useDialogs();

  useEffect(() => {
    if (fields.length === 0) {
      fields.push({});
    }
  }, []);

  const transactableField = FieldFactory.belongsTo('transactable', 'transactions')
    .withRequestParams({ bucket: 'to_pay', 'filter[vendor_id]': vendor?.id })
    .withoutLabel();

  const onAdd = () => {
    prompt({
      title: 'Add Bill',
      fields: [transactableField],
    }).then((row) => {
      fields.push({ ...row, amount: row.transactable.balance });
    });
  };

  const rows = useMemo(
    () =>
      items.map((i, index) => ({
        parentName: `${fields.name}[${index}]`,
        ...i,
      })),
    [items],
  );

  const columns = useMemo(
    () =>
      [
        {
          header: 'Bill',
          cell: ({
            row: {
              original: { parentName, transactable },
            },
          }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Box flexGrow={1}>
                <ReduxField field={transactableField} parentName={parentName} />
              </Box>

              {transactable && (
                <Box ml={1}>
                  <Tooltip title="Go To Bill">
                    <IconButton
                      component="a"
                      target="_blank"
                      href={`/transactions/${transactable.id}`}
                      size="small"
                    >
                      <OpenInNew fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </div>
          ),
          footer: () => <Button onClick={onAdd}>Add Bill</Button>,
        },
        {
          header: 'Amount Owed',
          accessorKey: 'transactable.balance',
          cell: ({ getValue }) => curr(getValue<number>()),
          footer: 'Total',
        },
        {
          header: 'Amount to Pay',
          accessorKey: 'amount',
          cell: ({
            row: {
              original: { parentName },
            },
          }) => (
            <ReduxField
              field={FieldFactory.curr('amount').withoutLabel()}
              parentName={parentName}
            />
          ),
          footer: () => <b>{curr(_.sumBy(items, (r) => Number(r.amount || 0)))}</b>,
        },
        {
          id: 'actions',
          header: '',
          cell: ({ row }) => (
            <IconButton onClick={() => fields.remove(row.index)} size="small">
              <Delete fontSize="small" />
            </IconButton>
          ),
        },
      ] as ColumnDef<PaymentTransactionItem & { parentName: string }>[],
    [],
  );

  return <PaginatedTable rows={rows} columns={columns} />;
}
