import { FieldFactory, Resource } from '@/admin';
import { BusinessCredit } from '@/models';

export default function businessCredits() {
  return new Resource<BusinessCredit>('Business Credits')
    .setUseDrawer()
    .withDefaultSort('-created_at')
    .withColumns([FieldFactory.timestamp('created_at').sortable()])
    .addFieldsAndColumns([
      FieldFactory.belongsTo('business', 'businesses').filterable('filter[business_id]'),
      FieldFactory.text('description'),
      FieldFactory.curr('amount').sortable(),
    ]);
}
