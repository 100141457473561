import { ReactNode } from 'react';
import { AlertColor } from '@mui/material';
import { AlertOptions, ConfirmOptions, PromptOptions, useAppContext } from '../contexts/AppContext';

export interface UseDialogsResult {
  prompt: <T>(options: PromptOptions<T>) => Promise<any>;
  drawerPrompt: <T>(options: PromptOptions<T>) => Promise<any>;
  confirm: (
    title: string,
    description?: ReactNode,
    color?: AlertColor,
    options?: Partial<ConfirmOptions>,
  ) => Promise<any>;
  alert: (
    title: string,
    description?: ReactNode,
    color?: AlertColor,
    options?: Partial<AlertOptions>,
  ) => Promise<any>;
}

export default function useDialogs(): UseDialogsResult {
  const { setPromptDialog, setConfirmDialog, setAlertDialog, setPromptDrawer } = useAppContext();

  const prompt = <T = unknown>(options: PromptOptions<T>) =>
    new Promise((resolve, reject) => {
      setPromptDialog({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  const drawerPrompt = <T = unknown>(options: PromptOptions<T>) =>
    new Promise((resolve, reject) => {
      setPromptDrawer({
        options,
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  const alert = (
    title: string,
    description?: ReactNode,
    color?: AlertColor,
    options: Partial<AlertOptions> = {},
  ) =>
    new Promise((resolve, reject) => {
      setAlertDialog({
        options: {
          title,
          description,
          color,
          ...options,
        },
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  const confirm = (
    title: string,
    description?: ReactNode,
    color?: AlertColor,
    options: Partial<ConfirmOptions> = {},
  ) =>
    new Promise((resolve, reject) => {
      setConfirmDialog({
        options: {
          title,
          description,
          color,
          ...options,
        },
        promise: {
          resolve,
          reject,
        },
        open: true,
      });
    });

  return {
    prompt,
    confirm,
    alert,
    drawerPrompt,
  };
}
