import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { amber, green } from '@mui/material/colors';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {
  ButtonAction,
  FieldFactory,
  numString,
  StatusChip,
  SubMenu,
  Text,
  useDialogs,
  useGetResource,
  usePushWithContext,
} from '@/admin';
import OrderLabel from '@/components/Orders/OrderLabel';
import MinutesText from '@/components/Shared/MinutesText';
import { PRODUCTION_EVENT_STATUS_COLORS } from '@/constants';
import { requiredFields } from '@/helpers';
import { ProductionEvent } from '@/models';
import { maybeAddQueryParams } from '@/utils/query';
import ProductionDates from './CommittedDate';
import ScreenLocation from './ScreenLocation';

export const getPriorityColor = (priority: number) => {
  if (priority > 0) {
    return green[500];
  }
  if (priority < 0) {
    return amber[500];
  }
  return 'transparent';
};

export default function ProductionEventTable({
  events,
  onReload,
}: {
  events: ProductionEvent[];
  onReload: () => void;
}) {
  const hasScreenprintEvents = events.some((event) => event.event_type.unit === 'colors');
  const getResource = useGetResource();
  const { prompt, confirm } = useDialogs();

  const onUnschedule = (e: ProductionEvent) => {
    confirm(
      'Remove From Schedule',
      "Are you sure you want to remove this event from the day's schedule?",
    ).then(() => {
      axios.post(`/api/production-events/${e.id}/unschedule`).then(onReload);
    });
  };

  const push = usePushWithContext(
    events.map((e) => ({
      key: e.id,
      href: `/production/${e.order_id}?event_id=${e.id}`,
      label: getResource('productionEvents').getTitle(e),
    })),
  );

  const onSetPriority = (e: ProductionEvent) => {
    prompt({
      title: 'Set Priority',
      initialValues: _.pick(e, ['priority']),
      fields: [
        FieldFactory.select(
          'priority',
          [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => ({
            label: v,
            value: v,
          })),
        ).withHelp('Larger numbers will show up first'),
      ],
      onSubmit: (v) => axios.put(`/api/production-events/${e.id}`, v),
    }).then(() => onReload());
  };

  const onUpdateEvent = (e: ProductionEvent) => {
    prompt({
      title: 'Update Event',
      initialValues: _.pick(e, ['machine', 'scheduled_at']),
      fields: [
        FieldFactory.belongsTo('machine', 'productionMachines').withRequestParams({
          event_ids: e.id,
        }),
        FieldFactory.date('scheduled_at').withLabel('Date Scheduled'),
      ],
      validation: requiredFields(['machine', 'scheduled_at']),
      onSubmit: (v) =>
        axios.post('/api/production-events/schedule', {
          event_ids: [e.id],
          date: v.scheduled_at,
          machine_id: v.machine?.id,
        }),
    }).then(() => {
      onReload();
    });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Design</TableCell>
            <TableCell>Order</TableCell>
            {hasScreenprintEvents && <TableCell>Scr Loc</TableCell>}
            <TableCell>Quantity</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Committed</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((e) => (
            <TableRow
              key={e.id}
              onClick={() => push(`/production/${e.order_id}?event_id=${e.id}`)}
              hover
            >
              <TableCell>
                <Avatar
                  src={maybeAddQueryParams(e.order_design.design.image, { w: 200 })}
                  alt={e.order_design.design.increment_id}
                />
              </TableCell>
              <TableCell style={{ paddingLeft: 0 }}>
                <Text
                  primary={e.order_design.design?.name}
                  secondary={e.order_design.design.location}
                />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <OrderLabel orderId={e.order.id}>{e.order.increment_id}</OrderLabel>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {e.order.customer?.name}
                </Typography>
              </TableCell>
              {hasScreenprintEvents && (
                <TableCell>
                  <ScreenLocation event={e} onSuccess={() => onReload()} />
                </TableCell>
              )}
              <TableCell>
                <Text
                  primary={numString(e.quantity, 'imprints')}
                  secondary={<MinutesText minutes={e.job_minutes} />}
                />
              </TableCell>
              <TableCell>
                <StatusChip
                  status={e.status}
                  colors={PRODUCTION_EVENT_STATUS_COLORS}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <Text
                  primary={<ProductionDates order={e.order} />}
                  secondary={
                    e.order.staged_at
                      ? `Staged ${moment(e.order.staged_at).fromNow()}`
                      : 'Not Staged'
                  }
                />
              </TableCell>
              <TableCell
                onClick={(ev) => ev.stopPropagation()}
                style={{ borderRight: `8px solid ${getPriorityColor(e.priority)}` }}
              >
                <SubMenu
                  items={[
                    new ButtonAction('Update Event', () => onUpdateEvent(e)),
                    new ButtonAction('Set Priority', () => onSetPriority(e)),
                    new ButtonAction('Remove from Schedule', () => onUnschedule(e)),
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
