import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { FieldProps } from '../../types';
import Field from '../Field';

function Qty({ field, meta, input }: FieldProps<QtyField>) {
  return (
    <TextField
      {...input}
      {...field.getTextFieldProps(meta)}
      type="number"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={() => input.onChange(Number(input.value) - 1)} size="small">
              <MinusIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={() => input.onChange(Number(input.value) + 1)} size="small">
              <PlusIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default class QtyField extends Field {
  renderEditComponent(props: WrappedFieldProps) {
    return <Qty {...props} field={this} />;
  }
}
