import { useDispatch } from 'react-redux';
import { arrayPush, change } from 'redux-form';
import { useAppContext } from '../contexts/AppContext';
import useGetResource from '../hooks/useGetResource';
import StackLayout from '../models/Layouts/StackLayout';
import ClosableDrawer from './ClosableDrawer';
import EditForm from './EditForm';

export default function CreateResourceDrawer() {
  const dispatch = useDispatch();
  const { createResource, setCreateResource } = useAppContext();
  const getResource = useGetResource();

  if (!createResource) {
    return null;
  }
  const { resourceName, name, form, initialValues = {}, multiple } = createResource;
  const resource = getResource(resourceName, false) || {};

  const onClose = () => setCreateResource(undefined);

  const onSuccess = (data: object) => {
    if (multiple) {
      dispatch(arrayPush(form, name, data));
    } else {
      dispatch(change(form, name, data, true));
    }
    onClose();
  };

  return (
    <ClosableDrawer open onClose={onClose} title={`Create ${resource.singularName}`}>
      {name && (
        <EditForm
          resource={resource}
          form="createSubResource"
          isCreate
          onSuccess={onSuccess}
          initialValues={initialValues}
          defaultLayout={StackLayout}
        />
      )}
    </ClosableDrawer>
  );
}
