import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { useMutation } from '@tanstack/react-query';
import groupBy from 'lodash/groupBy';
import AddReactionButton, { ReactionButton } from '@/components/Notes/AddReactionButton';
import { useConfig } from '@/contexts/AppConfigContext';
import { Note } from '@/models';

export function NoteReactions({
  reactions,
  onReaction,
}: {
  reactions: Note['reactions'];
  onReaction: (reaction: string) => Promise<unknown>;
}) {
  const { user } = useConfig();
  const grouped = groupBy(reactions, (r) => r.reaction);

  const { mutate, isLoading } = useMutation(onReaction);

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap={0.5} data-reactions>
      {Object.entries(grouped).map(([reaction, items]) => (
        <Tooltip title={items.map((i) => i.created_by_user.name).join(', ')} key={reaction}>
          <ReactionButton
            type="button"
            onClick={() => mutate(reaction)}
            disabled={isLoading}
            me={items.some((i) => i.created_by_user.id === user.id)}
          >
            <span>{reaction}</span>
            <strong>{items.length}</strong>
          </ReactionButton>
        </Tooltip>
      ))}
      <AddReactionButton onReaction={onReaction} />
    </Box>
  );
}
