import { SyntheticEvent, useState } from 'react';
import { Edit } from '@mui/icons-material';
import {
  Grid,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  Paper as RawPaper,
  Box,
  Stack,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import {
  getValueFromEvent,
  NonReduxField,
  Paper,
  useHasPermission,
  useQueryStringState,
} from '@/admin';
import { coerceReportResults } from '@/helpers';
import { Report as ReportModel } from '@/models';
import { CompareTo, ReportResultsRow } from '@/responses';
import { getFilterDefaults, getFilterFields } from '@/utils/reports';
import EditReportDrawer from './EditReportDrawer';
import ReportResults from './ReportResults';

export default function ReportPage({
  report,
  onUpdated,
}: {
  report: ReportModel;
  onUpdated: (r: ReportModel) => void;
}) {
  const [filters, setFilters] = useState<Record<string, any>>(() =>
    getFilterDefaults(report.filters || []),
  );
  const [isEditing, setIsEditing] = useQueryStringState('editing', '0');
  const hasPermission = useHasPermission();

  const { data, isLoading, mutate } = useMutation((download: boolean) => {
    const payload = _.pickBy({
      download,
      filters,
    });
    return axios
      .post<{
        results: ReportResultsRow[];
        compare_to?: CompareTo;
        compare_to_results?: ReportResultsRow[];
        url?: string;
      }>(`/api/reports/${report.id}`, payload)
      .then(({ data }) => {
        if (data.url) {
          window.open(data.url);
        }
        return {
          ...data,
          results: coerceReportResults(data.results),
          compare_to_results:
            data.compare_to_results && coerceReportResults(data.compare_to_results),
        };
      });
  });

  const createHandler = (fieldName: string) => (e: SyntheticEvent) => {
    const value = getValueFromEvent(e);
    setFilters((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} xs={12}>
        <Paper
          title="Filters"
          action={
            hasPermission('write:reports') ? (
              <IconButton onClick={() => setIsEditing('1')} size="large">
                <Edit fontSize="small" />
              </IconButton>
            ) : null
          }
        >
          <Stack spacing={2}>
            {getFilterFields(report.filters || []).map((field) => (
              <NonReduxField
                key={field.name}
                value={filters[field.name]}
                onChange={createHandler(field.name)}
                field={field}
              />
            ))}
          </Stack>

          {_.size(report.filters) === 0 && (
            <Typography variant="body2" color="textSecondary">
              There are no filters for this report.
            </Typography>
          )}

          <Box mt={3} display="flex" justifyContent="space-between">
            <Button type="button" variant="contained" onClick={() => mutate(false)}>
              Run Report
            </Button>
            <Button type="button" onClick={() => mutate(true)}>
              Download
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid item lg={9} xs={12}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div>
            {!data ? (
              <Typography color="textSecondary">
                Please click &quot;Run Report&quot; to see results
              </Typography>
            ) : (
              <RawPaper>
                <ReportResults
                  report={report}
                  results={data.results}
                  compareTo={data.compare_to}
                  compareToResults={data.compare_to_results}
                />
              </RawPaper>
            )}
          </div>
        )}
      </Grid>

      <EditReportDrawer
        open={isEditing === '1'}
        onClose={() => setIsEditing('0')}
        report={report}
        onSuccess={(updated) => {
          setIsEditing('0');
          onUpdated(updated);
        }}
      />
    </Grid>
  );
}
