import { FileCopy } from '@mui/icons-material';
import axios from 'axios';
import { ButtonAction, CardLayout, FieldFactory, Resource } from '@/admin';
import JournalEntryTotals from '@/components/JournalEntries/JournalEntryTotals';
import { getEventableConfigForResource } from '@/helpers';
import { JournalEntry } from '@/models';

const getTitle = (e: JournalEntry) => `Journal Entry #${e.id}`;

export default function journalEntries() {
  return new Resource<JournalEntry>('Journal Entries')
    .getTitleUsing(getTitle)
    .getLabelUsing(getTitle)
    .withExportable({ format: 'xlsx' })
    .withDefaultValues({
      journal_date: new Date(),
      items: [{}, {}],
    })
    .withDefaultSort('-id')
    .getSingleActionsUsing((values) => [
      new ButtonAction(
        'Duplicate',
        ({ dialogs, navigate, toast }) => {
          dialogs
            .confirm(`Duplicate Journal Entry #${values.id}`, 'Are you sure you want to duplicate?')
            .then(() => {
              axios.post(`/api/journal-entries/${values.id}/duplicate`).then(({ data }) => {
                toast('Journal Entry has been duplicated');
                navigate(`/journal-entries/${data.id}`);
              });
            });
        },
        FileCopy,
      ),
    ])
    .withColumns([FieldFactory.text('id').withLabel('#').sortable()])
    .addFieldsAndColumns([
      new CardLayout('details')
        .withoutLabel()
        .withFields([FieldFactory.date('journal_date').sortable(), FieldFactory.textarea('memo')]),
      new CardLayout('Debits & Credits', [
        FieldFactory.list('items', [
          FieldFactory.belongsTo('account', 'accounts').withColumnSpan(3),
          FieldFactory.curr('debit_amount').withColumnSpan(2),
          FieldFactory.curr('credit_amount').withColumnSpan(2),
          FieldFactory.text('description'),
        ]).withColumnSpan(12),
        FieldFactory.custom('totals', JournalEntryTotals).withoutLabel().withColumnSpan(12),
      ]),
      getEventableConfigForResource('journal-entries'),
    ]);
}
