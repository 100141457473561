import { BrokenImage, Redo, Refresh } from '@mui/icons-material';
import {
  Button,
  CardActions,
  CardContent,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Joi from 'joi';
import { Can, FieldFactory, TextLink, useDialogs, useHasPermission } from '@/admin';
import InventoryLocationLabel from '@/components/Inventory/InventoryLocationLabel';
import MoveInventoryLocation from '@/components/Inventory/MoveInventoryLocation';
import SkuLabel from '@/components/Products/SkuLabel';
import { requiredFields } from '@/helpers';
import { Account, InventoryLevel, InventoryLocation, ProductVariant } from '@/models';

export const CYCLE_COUNT_FIELDS = [
  FieldFactory.number('qty').withSize('medium'),
  FieldFactory.belongsTo('expense_account', 'accounts')
    .withHelp('This will override the default "Inventory Defects" account')
    .withPermission('read:accounts'),
  FieldFactory.textarea('note'),
];

export default function InventoryLevels({
  filterKey,
  filterValue,
  label,
}: {
  filterKey: string;
  filterValue: any;
  label: string;
}) {
  const hasPermission = useHasPermission();

  const {
    data: levels,
    isFetching,
    refetch,
  } = useQuery(
    ['inventoryLevels', filterKey, filterValue],
    () =>
      axios
        .get<{ data: InventoryLevel[] }>(
          `/api/inventory-levels?filter[${filterKey}]=${filterValue}&count=250`,
        )
        .then(({ data }) => data.data),
    {
      enabled: Boolean(filterKey && filterValue),
    },
  );

  const { prompt } = useDialogs();

  const onAdjust = (l: InventoryLevel) => {
    prompt({
      title: 'Cycle Count',
      description: `How many ${l.variant.sku} are in ${l.location.path}?`,
      initialValues: { qty: l.qty },
      fields: CYCLE_COUNT_FIELDS,
      onSubmit: (v: { qty: number; expense_account?: Account | null }) =>
        axios.post('/api/inventory-adjustments/cycle', {
          ...v,
          location_id: l.location.id,
          variant_id: l.variant.id,
          expense_account_id: v.expense_account?.id,
        }),
    }).then(() => {
      refetch();
    });
  };

  const onMove = (l: InventoryLevel) => {
    prompt({
      title: 'Move Inventory',
      description: <MoveInventoryLocation level={l} />,
      fields: [],
      initialValues: { qty: l.qty },
      validation: Joi.object({
        end_location: Joi.any().required(),
        qty: Joi.number().integer().required().max(l.qty),
      }),
      onSubmit: (v) =>
        axios.post('/api/inventory-adjustments', {
          ...v,
          variant: l.variant,
          start_location: l.location,
        }),
    }).then(() => {
      refetch();
    });
  };

  const onScrap = (l: InventoryLevel) => {
    prompt({
      title: 'Scrap Items',
      description: `How many of the ${l.qty} ${l.variant.sku} in ${l.location.path} should be scrapped?`,
      fields: [FieldFactory.number('qty').withSize('medium'), FieldFactory.textarea('note')],
      validation: requiredFields(['qty']),
      onSubmit: (v: { qty: number }) =>
        axios.post('/api/inventory-adjustments', {
          ...v,
          start_location: l.location,
          variant: l.variant,
          is_scrap: true,
        }),
    }).then(() => {
      refetch();
    });
  };

  const onAddLocation = () => {
    prompt({
      title: `Add Location for ${label}`,
      fields: [FieldFactory.belongsTo('location', 'inventoryLocations'), ...CYCLE_COUNT_FIELDS],
      validation: requiredFields(['location', 'qty']),
      onSubmit: (v: {
        location: InventoryLocation;
        qty: number;
        expense_account?: Account | null;
      }) =>
        axios.post('/api/inventory-adjustments/cycle', {
          ...v,
          variant_id: filterValue,
          location_id: v.location.id,
          expense_account_id: v.expense_account?.id,
        }),
    }).then(() => {
      refetch();
    });
  };

  const onAddVariant = () => {
    prompt({
      title: `Add SKU to ${label}`,
      fields: [
        FieldFactory.belongsTo('variant', 'inventoryVariants'),
        FieldFactory.number('qty'),
        FieldFactory.textarea('note'),
      ],
      validation: requiredFields(['variant', 'qty']),
      onSubmit: (v: { variant: ProductVariant; qty: number }) =>
        axios.post('/api/inventory-adjustments/cycle', {
          ...v,
          location_id: filterValue,
          variant_id: v.variant.id,
        }),
    }).then(() => {
      refetch();
    });
  };

  return (
    <>
      {isFetching && <LinearProgress />}

      {levels?.length === 0 && (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There is no inventory{' '}
            {filterKey === 'location_id' ? 'in this location' : 'for this SKU'}.
          </Typography>
        </CardContent>
      )}

      {levels && levels.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              {filterKey !== 'variant_id' && <TableCell>SKU</TableCell>}
              {filterKey !== 'location_id' && <TableCell>Location</TableCell>}
              <TableCell>Qty</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {levels.map((l) => (
              <TableRow key={l.id}>
                {filterKey !== 'variant_id' && (
                  <TableCell>
                    <SkuLabel variant={l.variant} />
                  </TableCell>
                )}
                {filterKey !== 'location_id' && (
                  <TableCell>
                    <TextLink
                      to={`/inventory-locations/${l.location.id}`}
                      disabled={!hasPermission('read:inventory_locations')}
                    >
                      <InventoryLocationLabel location={l.location} />
                    </TextLink>
                  </TableCell>
                )}
                <TableCell>{l.qty}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  <Can permission="write:inventory_adjustments">
                    <Tooltip title="Move to a different location">
                      <IconButton onClick={() => onMove(l)}>
                        <Redo fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Can>
                  <Can permission="inventory_adjustments:add_subtract">
                    <Tooltip title="Scrap">
                      <IconButton onClick={() => onScrap(l)}>
                        <BrokenImage fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cycle Count">
                      <IconButton onClick={() => onAdjust(l)}>
                        <Refresh fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Can>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Can permission="inventory_adjustments:add_subtract">
        {filterKey === 'location_id' && (
          <CardActions>
            <Button onClick={onAddVariant} sx={{ mt: 1 }}>
              Add Variant
            </Button>
          </CardActions>
        )}
        {filterKey === 'variant_id' && (
          <CardActions>
            <Button onClick={onAddLocation} sx={{ mt: 1 }}>
              Add Location
            </Button>
          </CardActions>
        )}
      </Can>
    </>
  );
}
