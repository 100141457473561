import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import { ClosableDrawer, ReduxField, useRecordId, FieldFactory, useOnReloadRecord } from '@/admin';
import { useConfig } from '@/contexts/AppConfigContext';
import { OrderCost } from '@/models';

interface FormShape {
  costs: OrderCost[];
}

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

function EditOrderCostsDrawer({
  open,
  onClose,
  handleSubmit,
  initialize,
  submitting,
}: InjectedFormProps<FormShape, DrawerProps> & DrawerProps) {
  const { costCategories } = useConfig();
  const orderId = useRecordId();
  const onReload = useOnReloadRecord();

  useEffect(() => {
    if (open) {
      axios.get<{ data: OrderCost[] }>(`/api/orders/${orderId}/costs`).then(({ data }) => {
        initialize({ costs: data.data });
      });
    }
  }, [open, orderId]);

  const onSubmit: FormSubmitHandler<FormShape, DrawerProps> = (values) =>
    axios.put(`/api/orders/${orderId}/costs`, values).then(() => {
      onClose();
      onReload();
    });

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Edit Custom Order Costs" width={700}>
      <ReduxField
        field={FieldFactory.list('costs', [
          FieldFactory.text('description').withColumnSpan(5),
          FieldFactory.curr('cost').withColumnSpan(2),
          FieldFactory.select('category', costCategories).withColumnSpan(4),
        ])}
      />

      <LoadingButton
        type="button"
        variant="contained"
        onClick={handleSubmit(onSubmit)}
        loading={submitting}
        sx={{ mt: 2 }}
      >
        Save
      </LoadingButton>
    </ClosableDrawer>
  );
}

export default reduxForm<FormShape, DrawerProps>({
  form: 'OrderCostsForm',
})(EditOrderCostsDrawer);
