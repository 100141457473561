import { ChangeEvent, useCallback, useState } from 'react';
import { Inventory, Undo } from '@mui/icons-material';
import {
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Button,
  Checkbox,
  TextField,
  IconButton,
  CardHeader,
  CardContent,
} from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import {
  ArrayBullets,
  Can,
  FieldFactory,
  numString,
  TextLink,
  useDialogs,
  useHasPermission,
  useOnReloadRecord,
} from '@/admin';
import OrderLabel from '@/components/Orders/OrderLabel';
import DescriptionCell from '@/components/Purchasing/DescriptionCell';
import PurchaseOrderItemQtyReceived from '@/components/Receiving/PurchaseOrderItemQtyReceived';
import GroupedPaginatedTable from '@/components/Shared/GroupedPaginatedTable';
import { PurchaseOrderItem } from '@/models';
import PrintMenu from '../Print/PrintMenu';

export const receivingFields = [
  FieldFactory.images('images'),
  FieldFactory.list('barcodes', [
    FieldFactory.text('').withLabel('Barcode').withColumnSpan(10),
  ]).with({
    addNewLabel: 'Add Barcode +',
    addNewValue: '',
  }),
];

function PoItemColumns({ item }: { item: PurchaseOrderItem }) {
  return (
    <>
      <DescriptionCell title={item.description}>{item.description}</DescriptionCell>
      <TableCell>{item.size}</TableCell>
      <DescriptionCell title={[item.number, item.color, item.size].join(' - ')}>
        {item.variant ? (
          <Tooltip title={<ArrayBullets elements={[item.number, item.color, item.size]} />}>
            <span>{item.variant.sku}</span>
          </Tooltip>
        ) : (
          <ArrayBullets elements={[item.number, item.color, item.size]} />
        )}
      </DescriptionCell>
    </>
  );
}

export default function ReceivePurchaseOrder({
  title,
  items,
  toReceive,
  id,
  type,
}: {
  title: string;
  items: PurchaseOrderItem[];
  toReceive?: boolean;
  id?: number;
  type?: 'order' | 'purchase_order';
}) {
  const [selected, setSelected] = useState<Record<number, number>>({});
  const { prompt, confirm } = useDialogs();
  const onReload = useOnReloadRecord();

  const hasPermission = useHasPermission();

  const toggleAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(
        _.chain(items)
          .keyBy('id')
          .mapValues((i) => i.qty - i.qty_received)
          .value(),
      );
    } else {
      setSelected({});
    }
  };

  const toggleOne = (item: PurchaseOrderItem, e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected((prev) => ({
        ...prev,
        [item.id]: item.qty - item.qty_received,
      }));
    } else {
      setSelected((prev) => _.omit(prev, item.id));
    }
  };

  const handleQtyChange = (item: PurchaseOrderItem, value: number | string) => {
    setSelected((prev) => ({
      ...prev,
      [item.id]: Number(value),
    }));
  };

  const numSelected = _.keys(selected).length;

  const receive = () => {
    const payload = {
      [`${type}_id`]: id,
      items: _.map(selected, (v, k) => ({
        purchase_order_item_id: k,
        qty_received: v,
      })),
    };
    prompt({
      title: `Receive ${numSelected} Items`,
      description:
        'Optionally upload image(s) of the packing list and/or scan the carrier barcode(s)',
      submitText: 'Receive',
      initialValues: {
        images: [],
      },
      fields: receivingFields,
      onSubmit: (v: { images: { url: string }[] }) =>
        axios.post('/api/receivings', { ...payload, ...v, images: v.images.map((i) => i.url) }),
    }).then(() => {
      setSelected([]);
      onReload();
    });
  };

  const unreceive = (item: PurchaseOrderItem) => {
    confirm(
      `Unreceive ${item.qty_received} Items`,
      'Are you sure you want to unreceive these items?',
    ).then(() => {
      axios.post(`/api/purchase-order-items/${item.id}/unreceive`).then(() => {
        onReload();
      });
    });
  };

  const canReceive = toReceive && hasPermission('write:receivings');

  const renderRow = (item: PurchaseOrderItem, key: number, groupLength: number) => (
    <TableRow key={item.id}>
      {canReceive && (
        <TableCell padding="checkbox">
          <Checkbox checked={_.has(selected, item.id)} onChange={(e) => toggleOne(item, e)} />
        </TableCell>
      )}
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <OrderLabel orderId={item.order_id}>{item.order_increment_id}</OrderLabel>
        {item.order_id && <PrintMenu id={item.order_id} model="order" size="small" />}
      </TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        <TextLink to={`/purchase-orders/${item.purchase_order_id}`}>
          {item.purchase_order_id}
        </TextLink>
        {item.is_inventory && (
          <Tooltip title="This item is going into inventory">
            <Inventory color="primary" fontSize="small" sx={{ verticalAlign: 'bottom', ml: 1 }} />
          </Tooltip>
        )}
      </TableCell>
      {groupLength > 1 ? <TableCell colSpan={3} /> : <PoItemColumns item={item} />}
      <TableCell>{item.qty}</TableCell>
      <TableCell>
        {item.qty_received !== 0 ? <PurchaseOrderItemQtyReceived item={item} /> : item.qty_received}
      </TableCell>
      <TableCell>
        {item.id in selected && (
          <TextField
            onChange={(e) => handleQtyChange(item, e.target.value)}
            value={selected[item.id]}
            style={{ minWidth: 50 }}
            size="small"
            inputProps={{
              type: 'number',
              step: 1,
              [item.qty < 0 ? 'min' : 'max']: item.qty - item.qty_received,
              [item.qty < 0 ? 'max' : 'min']: 0,
            }}
          />
        )}
        {Number(item.qty_received) !== 0 && (
          <Tooltip title="Unreceive Items">
            <IconButton size="small" onClick={() => unreceive(item)}>
              <Undo fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );

  const groupItemsBy = useCallback(
    (i: PurchaseOrderItem) => [i.number, i.color, i.size, i.description].join('|'),
    [],
  );

  return (
    <>
      <CardHeader
        title={title}
        action={
          numSelected > 0 && (
            <Button onClick={receive} variant="contained">
              Receive
            </Button>
          )
        }
      />

      {items.length === 0 ? (
        <CardContent>
          <Typography variant="body1" color="textSecondary">
            No items found
          </Typography>
        </CardContent>
      ) : (
        <GroupedPaginatedTable
          items={items}
          searchable={['number', 'color', 'size', 'description']}
          header={
            <TableRow>
              {toReceive && (
                <Can permission="write:receivings">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={numSelected === items.length}
                      indeterminate={numSelected > 0 && numSelected < items.length}
                      onChange={toggleAll}
                    />
                  </TableCell>
                </Can>
              )}

              <TableCell>Order</TableCell>
              <TableCell>PO</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Qty Pur</TableCell>
              <TableCell>Qty Rec</TableCell>
              <TableCell />
            </TableRow>
          }
          renderRow={renderRow}
          groupBy={groupItemsBy}
          renderGroupHeader={(items) => (
            <TableRow sx={{ bgcolor: 'action.hover' }}>
              {canReceive && <TableCell padding="checkbox" />}
              <TableCell colSpan={2}>{numString(items.length, 'items')}</TableCell>
              <PoItemColumns item={items[0]} />
              <TableCell>{_.sumBy(items, 'qty')}</TableCell>
              <TableCell>{_.sumBy(items, 'qty_received')}</TableCell>
              <TableCell />
            </TableRow>
          )}
        />
      )}
    </>
  );
}
