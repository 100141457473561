import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { createRoot } from 'react-dom/client';
import MuiProvider from '@/MuiProvider';
import App from './App';
import './app.css';
import './helpers';

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: 'https://1d00f19e9b3cd80ea47672383d48a871@o4507416470028288.ingest.us.sentry.io/4507431878524928',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^http:\/\/avail\.test\/api/,
      /^https:\/\/avail\.brandrpm\.io\/api/,
      /^https:\/\/availerp\.com\/api/,
      /^https:\/\/api\.availswag\.com\/api/,
    ],
    replaysOnErrorSampleRate: 1.0,
  });
}

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.baseURL = import.meta.env.VITE_BASE_URL || 'http://avail.test/';

const root = createRoot(document.getElementById('root')!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: unknown) => {
        if (axios.isAxiosError(error)) {
          const status = error?.response?.status;
          return (!status || status >= 500) && failureCount < 4;
        }
        return failureCount < 4;
      },
    },
  },
});

root.render(
  <MuiProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </MuiProvider>,
);
