import Joi from 'joi';
import _ from 'lodash';
import { FieldFactory, generateFormName, GroupLayout, Resource } from '@/admin';
import { CustomerContact } from '@/models';
import store from '../store';

export default function customerContacts() {
  return new Resource<CustomerContact>('Contacts')
    .getSubtitleUsing((v) => v.customer.name)
    .withValidation(
      Joi.object({
        first_name: Joi.string().required(),
        last_name: Joi.string().required(),
        email: Joi.string().email({ tlds: false }),
      }).unknown(),
    )
    .withInitialColumns([
      'first_name',
      'last_name',
      'department',
      'email',
      'is_approval_contact',
      'is_invoice_contact',
    ])
    .withFieldsAndColumns([
      FieldFactory.text('first_name').withColumnSpan(2),
      FieldFactory.text('last_name').withColumnSpan(2),
      FieldFactory.belongsTo('department', 'departments').with({
        requestParams: {
          customer: _.get(store.getState(), `form.${generateFormName('customers')}.values.id`),
          with: 'set',
        },
      }),
      FieldFactory.text('title').withColumnSpan(2),
      FieldFactory.email('email').withColumnSpan(2),
      FieldFactory.phone('phone').withColumnSpan(2),
      new GroupLayout('Settings').withFields([
        FieldFactory.boolean('is_approval_contact', 'CC on Approvals'),
        FieldFactory.boolean('is_invoice_contact', 'Send Invoices To'),
        FieldFactory.boolean('is_statement_contact', 'Sent Statements To'),
      ]),
    ]);
}
