import { CSSProperties, ReactElement, ReactNode } from 'react';
import { AlertColor, InputProps, SxProps, TextFieldProps } from '@mui/material';
import _ from 'lodash';
import { WrappedFieldArrayProps, WrappedFieldMetaProps } from 'redux-form';
import { WrappedFieldProps } from 'redux-form/lib/Field';

type RenderCellFunc = (v: any, row: any) => ReactNode;

export default class Field {
  name: string;
  label?: string;
  editable: boolean | number;
  creatable: boolean | number;
  readOnly: boolean | number;
  columnSpan: number;
  isFilterable: boolean;
  fullWidth: boolean;
  sortableBy?: string;
  filterKey?: string;
  disabled: boolean;
  inputProps: InputProps['inputProps'];
  size: InputProps['size'];
  margin: InputProps['margin'];
  InputProps?: InputProps;
  required: boolean;
  isQuickFilterable?: boolean;
  aggregatable?: boolean;
  row?: boolean;
  help?: string;
  placeholder?: string;
  requiresPermission?: string;
  requiresRole?: string;
  color?: AlertColor;
  style?: CSSProperties;
  sx?: SxProps;
  isArray = false;
  filterField?: Field;
  cachedFilterField?: Field;
  customRenderCellFunc?: RenderCellFunc;

  constructor(name: string) {
    this.name = name;
    this.label = _.startCase(name);
    this.editable = 1;
    this.creatable = 1;
    this.readOnly = 0;
    this.columnSpan = 4;
    this.isFilterable = false;
    this.fullWidth = true;
    this.disabled = false;
    this.inputProps = {};
    this.size = 'small';
    this.margin = 'dense';
    this.required = false;
  }

  getNameForFilterForm() {
    return this.name.replaceAll('.', '->');
  }

  withLabel(label: string) {
    this.label = label;
    return this;
  }

  withoutLabel() {
    this.label = undefined;
    return this;
  }

  withColumnSpan(columnSpan: number) {
    this.columnSpan = columnSpan;
    return this;
  }

  setEditable(bool = true) {
    this.editable = bool;
    return this;
  }

  setCreatable(bool = true) {
    this.creatable = bool;
    return this;
  }

  sortable(column = this.name) {
    this.sortableBy = column;
    return this;
  }

  setDisabled(bool = true) {
    this.disabled = bool;
    return this;
  }

  setReadOnly(bool = true) {
    this.readOnly = bool;
    return this;
  }

  setFullWidth(bool = true) {
    this.fullWidth = bool;
    return this;
  }

  setRequired(bool = true) {
    this.required = bool;
    return this;
  }

  withMargin(margin: InputProps['margin']) {
    this.margin = margin;
    return this;
  }

  withSize(size: InputProps['size']) {
    this.size = size;
    return this;
  }

  withHelp(help: string) {
    this.help = help;
    return this;
  }

  withPlaceholder(placeholder: string) {
    this.placeholder = placeholder;
    return this;
  }

  withInputProps(inputProps: InputProps['inputProps']) {
    this.inputProps = inputProps;
    return this;
  }

  with(config: Partial<typeof this>) {
    Object.assign(this, config);
    return this;
  }

  getHelperText(meta: WrappedFieldMetaProps) {
    if (meta.touched && meta.error) {
      if (typeof meta.error === 'object') {
        return Object.values(meta.error).join(', ');
      }
      return meta.error;
    }
    return this.help;
  }

  setAggregatable(bool = true) {
    this.aggregatable = bool;
    return this;
  }

  renderCellUsing(func: RenderCellFunc) {
    this.customRenderCellFunc = func;
    return this;
  }

  withPermission(permission: string) {
    this.requiresPermission = permission;
    return this;
  }

  withRole(role: string) {
    this.requiresRole = role;
    return this;
  }

  getTextFieldProps(meta?: WrappedFieldMetaProps): TextFieldProps {
    return {
      fullWidth: this.fullWidth,
      disabled: this.disabled,
      label: this.label,
      required: this.required,
      size: this.size,
      margin: this.margin,
      inputProps: this.inputProps,
      error: meta ? meta.error && meta.touched : false,
      helperText: meta ? this.getHelperText(meta) : undefined,
    };
  }

  shouldQuickFilter(isMobile: boolean) {
    return this.isQuickFilterable && !isMobile;
  }

  clone() {
    return _.cloneDeep(this);
  }

  cloneTo(field: Field): Field {
    const props = Object.getOwnPropertyNames(new Field(field.name));
    return field.with(_.omitBy(_.pick(this, props), (v) => v == null));
  }

  getFilterFieldForType(): Field | undefined {
    return undefined;
  }

  getClonedFilterField() {
    if (this.filterField) {
      return this.cloneTo(this.filterField);
    }
    const typeFilterField = this.getFilterFieldForType();
    if (typeFilterField) {
      return this.cloneTo(typeFilterField);
    }
    return this.clone();
  }

  getFilterField(): Field {
    if (!this.cachedFilterField) {
      this.cachedFilterField = this.getClonedFilterField();
    }
    this.cachedFilterField.name = this.getNameForFilterForm();
    this.cachedFilterField.required = false;
    this.cachedFilterField.disabled = false;
    this.cachedFilterField.readOnly = false;
    return this.cachedFilterField;
  }

  filterable(filter?: string | Field, quick = false) {
    this.isFilterable = true;
    if (quick) {
      this.isQuickFilterable = true;
    }
    if (typeof filter === 'string') {
      this.filterKey = filter;
    } else if (filter) {
      this.filterField = filter;
    }
    return this;
  }

  quickFilterable(filter?: string | Field) {
    return this.filterable(filter, true);
  }

  withFilterKey(filterKey: string) {
    this.filterKey = filterKey;
    return this;
  }

  asQuickFilter() {
    this.isFilterable = true;
    this.isQuickFilterable = true;
    // This makes the default filter the name
    // (ie. bucket instead of filter[bucket])
    this.filterKey = this.filterKey || this.name;
    return this;
  }

  getDefaultFilterKey() {
    return `filter[${this.name.replaceAll('->', '.')}]`;
  }

  getFilterKey() {
    return this.filterKey || this.getDefaultFilterKey();
  }

  renderEditComponent(props: WrappedFieldProps): ReactElement | null {
    return null;
  }

  renderArrayComponent(props: WrappedFieldArrayProps): ReactElement | null {
    return null;
  }

  renderCell(value: any, row?: Record<string, any>): ReactNode {
    return String(value);
  }
}
