import { ReactElement } from 'react';
import { Alert } from '@mui/material';
import useWindowSize from '@/utils/useWindowSize';

export default function RequiresScreenWidth({
  width,
  children,
}: {
  width: number;
  children: ReactElement;
}) {
  const { width: windowWidth } = useWindowSize();

  if (!windowWidth) {
    return null;
  }

  if (windowWidth < width) {
    return (
      <Alert severity="info">
        This feature is only supported on large screen sizes. Please use a desktop computer.
      </Alert>
    );
  }

  return children;
}
