import { EditFormFields, DrawerFormSection, StackLayout, FieldFactory } from '@/admin';

export default function PriceSection({ minQty, maxQty }: { minQty?: number; maxQty?: number }) {
  return (
    <DrawerFormSection title="Pricing and Quantities">
      <EditFormFields
        defaultLayout={StackLayout}
        fields={[
          FieldFactory.number('qty').withInputProps({ min: minQty, max: maxQty }),
          FieldFactory.curr('price').with({ maxDecimals: 4 }),
          FieldFactory.percent('discount'),
        ]}
      />
    </DrawerFormSection>
  );
}
