import { ChevronRight } from '@mui/icons-material';
import {
  CircularProgress,
  ListItemButton,
  ListItemText,
  List,
  Grid,
  ListItemSecondaryAction,
  Typography,
  Card,
  CardHeader,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Can, CreateButton, FieldFactory, useDialogs, useTitle } from '@/admin';
import ReportPage from '@/components/Reports/ReportPage';
import { Report } from '@/models';
import useMutateQueryData from '@/utils/useMutateQueryData';

export default function Reports() {
  const navigate = useNavigate();
  const params = useParams();
  const { prompt } = useDialogs();

  const setReports = useMutateQueryData<Report[]>(['reports']);
  const { data: reports } = useQuery(['reports'], () =>
    axios.get<{ data: Report[] }>('/api/reports?count=1000').then(({ data }) => data.data),
  );

  let report = null;
  if (reports && params.id) {
    report = reports.find((r) => r.id === Number(params.id));
  }

  useTitle(report ? report.name : 'Reports', report ? '/reports' : undefined);

  const createReport = () => {
    prompt({
      title: 'Create Report',
      fields: [FieldFactory.text('name').withSize('medium')],
      onSubmit: (v) => axios.post<Report>('/api/reports', v).then(({ data }) => data),
    }).then((report) => {
      setReports((prev) => [...prev, report]);
      navigate(`/reports/${report.id}?editing=true`);
    });
  };

  if (!reports) {
    return <CircularProgress />;
  }

  if (report) {
    return (
      <ReportPage
        report={report}
        onUpdated={(updatedReport) => {
          setReports((prev) =>
            prev.map((p) => {
              if (p.id === updatedReport.id) {
                return { ...p, ...updatedReport };
              }
              return p;
            }),
          );
        }}
      />
    );
  }

  if (reports.length === 0) {
    return (
      <Typography color="textSecondary">
        Your account does not have any reports available right now.
      </Typography>
    );
  }

  const grouped = _.groupBy(
    _.sortBy(reports, (r) => (r.group == null ? 'ZZZZ' : r.group)),
    (v) => v.group || 'Other',
  );

  return (
    <Grid container spacing={3}>
      {Object.entries(grouped).map(([group, groupedReports]) => (
        <Grid item md={6} xs={12} key={group}>
          <Card>
            <CardHeader title={group} />
            <List>
              {groupedReports.map((r) => (
                <ListItemButton key={r.id} component={Link} to={`/reports/${r.id}`}>
                  <ListItemText primary={r.name} secondary={r.description} />
                  <ListItemSecondaryAction>
                    <Typography color="textSecondary">
                      <ChevronRight />
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
            </List>
          </Card>
        </Grid>
      ))}

      <Can permission="write:reports">
        <CreateButton onClick={createReport} />
      </Can>
    </Grid>
  );
}
