import { ChangeEvent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import Joi from 'joi';
import _ from 'lodash';
import { WrappedFieldProps } from 'redux-form';
import StaticFormControl from '../../components/Form/StaticFormControl';
import useDialogs from '../../hooks/useDialogs';
import { FieldProps } from '../../types';
import Field from '../Field';
import TextAreaField from './TextAreaField';
import TextInputField from './TextInputField';

type InputEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const getValueFromEvent = (e: InputEvent) => e.target.value;

const sortObject = (unordered: Record<string, string>) => {
  const ordered: Record<string, string> = {};
  Object.keys(unordered)
    .sort()
    .forEach((key) => {
      ordered[key] = unordered[key];
    });
  return ordered;
};

function KeyValue({ input, field, meta }: FieldProps<KeyValueField>) {
  const value = (input.value || {}) as Record<string, string>;

  const { keyLabel, valueLabel } = field;
  const { prompt } = useDialogs();

  const changeAndSort = (newValue: Record<string, string>) => {
    input.onChange(sortObject(newValue));
  };

  const onKeyChange = (e: InputEvent, oldKey: string) => {
    const { [oldKey]: oldValue, ...other } = value;
    other[getValueFromEvent(e)] = oldValue;
    changeAndSort(other);
  };

  const onValueChange = (e: InputEvent, key: string) => {
    changeAndSort({
      ...value,
      [key]: getValueFromEvent(e),
    });
  };

  const onAdd = () => {
    prompt({
      title: 'New Key Value Pair',
      fields: [
        new TextInputField('key').withLabel(keyLabel),
        new TextAreaField('value').withLabel(valueLabel),
      ],
      validation: Joi.object({
        key: Joi.string()
          .required()
          .invalid(...Object.keys(value)),
        value: Joi.any(),
      }),
    }).then((values) => {
      changeAndSort({
        ...value,
        [values.key]: values.value,
      });
    });
  };

  const onRemove = (key: string) => {
    const { [key]: ignore, ...other } = value;
    changeAndSort(other);
  };

  return (
    <StaticFormControl field={field} meta={meta}>
      <Table style={{ maxWidth: 1300 }}>
        <TableHead>
          <TableRow>
            <TableCell>{keyLabel}</TableCell>
            <TableCell>{valueLabel}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(value, (v, k) => (
            <TableRow key={Object.keys(value).findIndex((c) => c === k)}>
              <TableCell style={{ width: '33%' }}>
                <TextField
                  inputProps={{ style: { fontFamily: 'monospace' } }}
                  size="small"
                  fullWidth
                  value={k}
                  onChange={(e) => onKeyChange(e, k)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  inputProps={{ style: { fontFamily: 'monospace' } }}
                  size="small"
                  fullWidth
                  value={v}
                  onChange={(e) => onValueChange(e, k)}
                  multiline
                  maxRows={10}
                />
              </TableCell>
              <TableCell>
                <IconButton size="small" onClick={() => onRemove(k)}>
                  <CloseIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CardActions>
        <Button onClick={onAdd}>Add</Button>
      </CardActions>
    </StaticFormControl>
  );
}

export default class KeyValueField extends Field {
  keyLabel = 'Key';
  valueLabel = 'Value';

  renderEditComponent(props: WrappedFieldProps) {
    return <KeyValue {...props} field={this} />;
  }
}
