import { SyntheticEvent } from 'react';
import { FormSubmitHandler, SubmitHandler } from 'redux-form';

export default function stopProp<FormData = object, P = object, ErrorType = string>(
  handleSubmit: SubmitHandler<FormData, P, ErrorType>,
) {
  return (func: FormSubmitHandler<FormData, P, ErrorType>) => (e: SyntheticEvent) => {
    e.stopPropagation();
    return handleSubmit(func)(e);
  };
}
