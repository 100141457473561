import { Resource, FieldFactory } from '@/admin';
import DesignLibraryEditor from '@/components/DesignLibrary/DesignLibraryEditor';
import { DesignLibraryItem } from '@/models';
import { AppConfig } from '@/responses';

export default function designLibraryItems(appConfig: AppConfig) {
  return new Resource<DesignLibraryItem>('Design Library Items')
    .withDefaultSort('-increment_id')
    .setReadOnly(!appConfig.permissions.includes('write:design_library'))
    .getTitleUsing((v) => v.increment_id)
    .withColumns([
      FieldFactory.text('increment_id').withLabel('#').sortable(),
      FieldFactory.image('image'),
      FieldFactory.text('name').sortable(),
      FieldFactory.text('legacy_id').filterable().withLabel('Legacy ID'),
      FieldFactory.timestamp('created_at').sortable(),
    ])
    .withFields([
      FieldFactory.text('name').sortable(),
      FieldFactory.text('legacy_id'),
      FieldFactory.textarea('description'),
      FieldFactory.image('image'),
    ])
    .editUsing(DesignLibraryEditor);
}
