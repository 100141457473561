import { Typography } from '@mui/material';
import moment from 'moment';
import { Resource, FieldFactory } from '@/admin';
import ArtRequestActivityPage from '@/components/Designs/ArtRequestActivityPage';
import DueDateBar from '@/components/Shared/DueDateBar';
import UserLabel from '@/components/Users/UserLabel';
import { ART_REQUEST_ACTIVITY_TYPES } from '@/constants';
import { ArtRequestActivity } from '@/models';

export default function artRequestActivities() {
  return new Resource<ArtRequestActivity>('Art Request Activities')
    .getTitleUsing((a) => a.label)
    .setCreatable(false)
    .withDefaultSort('due_at')
    .withDefaultFilters({
      bucket: 'open',
    })
    .withFilters([
      FieldFactory.radio('bucket', {
        open: 'Open',
        mine: 'Assigned to Me',
        unassigned: 'Unassigned',
        all: 'All',
      }).asQuickFilter(),
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.select('type', ART_REQUEST_ACTIVITY_TYPES).filterable(),
      FieldFactory.belongsTo('design', 'designs').sortable('design.name'),
      FieldFactory.belongsTo('art_request.order', 'orders')
        .withLabel('Order')
        .sortable('art_request.order_id'),
      FieldFactory.belongsTo('assignee', 'users')
        .sortable('assignee.name')
        .filterable()
        .renderCellUsing((u) => <UserLabel user={u} />),
      FieldFactory.belongsTo('requested_user', 'users')
        .withLabel('Requested By')
        .sortable('requested_user.name')
        .filterable()
        .renderCellUsing((u) => <UserLabel user={u} />),
      FieldFactory.timestamp('due_at')
        .sortable()
        .withLabel('Due Date')
        .renderCellUsing((v: ArtRequestActivity['due_at'], r: ArtRequestActivity) => (
          <div>
            <Typography variant="body2">{v ? moment(v).format('L') : '(none)'}</Typography>
            {v && !r.completed_at && <DueDateBar end={v} start={r.created_at} />}
          </div>
        )),
      FieldFactory.timestamp('created_at').sortable().filterable(),
      FieldFactory.timestamp('completed_at').sortable().filterable(),
    ])
    .editUsing(ArtRequestActivityPage);
}
