import { Box } from '@mui/material';
import { FieldFactory, Resource } from '@/admin';
import { CustomerSegment } from '@/models';

export default function customerSegments() {
  return new Resource<CustomerSegment>('Customer Segments')
    .setUseDrawer()
    .getLabelUsing((s) => s.path)
    .withDefaultFilters({
      hierarchy: true,
    })
    .withFilters([
      FieldFactory.boolean('hierarchy', 'Show as Hierarchy').withFilterKey('hierarchy'),
    ])
    .withFieldsAndColumns([
      FieldFactory.text('name')
        .renderCellUsing((value, row: CustomerSegment) => (
          <Box sx={{ pl: row.depth * 2 }}>{value}</Box>
        ))
        .sortable(),
      FieldFactory.belongsTo('department_set', 'departmentSets')
        .withHelp('The list of departments to choose from')
        .sortable('department_set.name'),
      FieldFactory.number('sort').withHelp('Higher numbers will show up first').sortable(),
    ])
    .addFields([FieldFactory.belongsTo('parent', 'customerSegments').sortable('parent.name')]);
}
