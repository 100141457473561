import { Delete } from '@mui/icons-material';
import ActionsRenderer from '../../components/Actions/ActionsRenderer';
import { useAppContext } from '../../contexts/AppContext';
import useDialogs from '../../hooks/useDialogs';
import ButtonAction from '../../models/Actions/ButtonAction';
import Resource from '../../models/Resource';
import SaveButton from '../Buttons/SaveButton';

export default function SingleAppBarActions({
  resource,
  values,
  onDeleted,
}: {
  resource: Resource;
  values: Record<string, any>;
  onDeleted: () => void;
}) {
  const { setIsLoading } = useAppContext();
  const { confirm } = useDialogs();

  const handleDelete = () => {
    confirm(`Delete ${resource.singularName}`, 'Are you sure? This action cannot be undone.').then(
      () => {
        setIsLoading(true);
        resource
          .getDeleteRequest(values[resource.primaryKey])
          .then(() => {
            onDeleted();
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
    );
  };

  const actionsArray = resource.getSingleActionsFunction(values);
  if (resource.deletable && Array.isArray(actionsArray)) {
    actionsArray.push(new ButtonAction('Delete', handleDelete, Delete));
  }

  return (
    <>
      <SaveButton form={resource.getFormName()} />

      <ActionsRenderer resource={resource} actions={actionsArray} />
    </>
  );
}
