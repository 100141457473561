import { Payment, ImportExport, Money, OfflineBolt, PlaylistAddCheck } from '@mui/icons-material';
import axios from 'axios';
import Joi from 'joi';
import moment from 'moment';
import { ButtonAction, curr, FieldFactory, LinkAction, Resource } from '@/admin';
import WarningIcon from '@/components/Shared/WarningIcon';
import BatchPay from '@/components/Transactions/BatchPay';
import QuickAccountEditor from '@/components/Transactions/QuickAccountEditor';
import QuickPay from '@/components/Transactions/QuickPay';
import TransactionActions from '@/components/Transactions/TransactionActions';
import TransactionEditor from '@/components/Transactions/TransactionEditor';
import TransactionFields from '@/components/Transactions/TransactionFields';
import {
  MATCH_STATUS_COLORS,
  MATCH_STATUS_LABELS,
  TRANSACTION_PAID_COLORS,
  TRANSACTION_PAID_LABELS,
  TRANSACTION_TYPES,
} from '@/constants';
import { Transaction, Vendor } from '@/models';

export default function transactions() {
  return new Resource<Transaction>('Transactions')
    .withExportable({ format: 'xlsx' })
    .getTitleUsing((v) => v.label)
    .getLabelUsing((v) => {
      const hold = v.is_disabled ? <WarningIcon message="On Hold" /> : null;
      return (
        <span>
          {hold}
          {v.label}
        </span>
      );
    })
    .getSubtitleUsing((v) => `${curr(v.total)} - ${moment(v.transaction_date).format('L')}`)
    .withIndexActions([
      new LinkAction('Print Checks', '/print-checks').withIcon(Money),
      new LinkAction('Import', '/import-transactions').withIcon(ImportExport),
    ])
    .withBulkActions([
      new ButtonAction('Quick Pay', ({ selected, dialogs, navigate }) => {
        dialogs
          .prompt({
            title: 'Quick Pay Bills',
            description: <QuickPay ids={selected.map((s) => Number(s))} />,
            onSubmit: (v: { vendor: Vendor }) =>
              axios.post('/api/transactions/quick-pay', { ids: selected, preview: false, ...v }),
            maxWidth: 'xs',
            fields: [],
          })
          .then(({ data }) => {
            navigate(`/transactions/${data.id}`);
          });
      }).withIcon(OfflineBolt),
      new ButtonAction('Batch Pay', ({ selected, dialogs, onReloadTable }) => {
        dialogs
          .prompt({
            title: 'Batch Pay Bills',
            description: <BatchPay ids={selected.map((s) => Number(s))} />,
            onSubmit: () =>
              axios.post('/api/transactions/batch-pay', { ids: selected, preview: false }),
            fields: [],
            maxWidth: 'sm',
          })
          .then(() => {
            onReloadTable();
          });
      }).withIcon(PlaylistAddCheck),
      new ButtonAction('Pay Bills', ({ selected, navigate }) => {
        navigate(`/transactions/create?type=bill_payment&bill_id=${selected.join(',')}`);
      }).withIcon(Payment),
    ])
    .getSingleActionsUsing(TransactionActions)
    .withDefaultSort('-id')
    .withDefaultFilters({
      bucket: 'all',
    })
    .withDefaultValues({
      transaction_date: new Date(),
    })
    .withInitialColumns([
      'id',
      'transaction_date',
      'type',
      'vendor',
      'account',
      'total',
      'ref_number',
      'paid_status',
      'match_status',
    ])
    .withFilters([
      FieldFactory.radio('bucket', {
        all: 'All',
        to_pay_now: 'To Pay',
        to_match: 'To Match',
      }).asQuickFilter(),
      FieldFactory.boolean('sports_inc', 'Sports Inc').withFilterKey('sports_inc'),
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.select('type', TRANSACTION_TYPES).filterable().sortable(),
      FieldFactory.belongsTo('vendor', 'vendors').filterable().sortable('vendor.name'),
      FieldFactory.belongsTo('bank_account', 'accounts').filterable().sortable('bank_account.name'),
      FieldFactory.curr('total').setAggregatable().sortable(),
      FieldFactory.curr('balance'),
      FieldFactory.status('paid_status', TRANSACTION_PAID_COLORS, TRANSACTION_PAID_LABELS)
        .filterable()
        .sortable(),
      FieldFactory.status('match_status', MATCH_STATUS_COLORS, MATCH_STATUS_LABELS)
        .filterable()
        .sortable(),
      FieldFactory.curr('paid').setAggregatable().sortable(),
      FieldFactory.date('transaction_date').sortable().filterable(),
      FieldFactory.date('due_date').sortable().filterable(),
      FieldFactory.belongsTo('account', 'accounts')
        .renderCellUsing((value, row) => <QuickAccountEditor transaction={row as Transaction} />)
        .filterable('account'),
      FieldFactory.text('ref_number')
        .withLabel('Ref #')
        .filterable('filter[ref_number][contains]')
        .sortable(),
      FieldFactory.text('po_number')
        .withLabel('PO #')
        .filterable('filter[po_number][contains]')
        .sortable(),
      FieldFactory.boolean('is_disabled')
        .withLabel('On Hold')
        .sortable('disabled_at')
        .filterable('is_disabled'),
    ])
    .withValidation(
      Joi.object({
        type: Joi.string().required(),
        vendor: Joi.any().required(),
        transaction_date: Joi.any().required(),
        items: Joi.array()
          .required()
          .items(
            Joi.object({
              transactable: Joi.any().required(),
              description: Joi.any(),
              amount: Joi.any().required(),
            }),
          ),
      }).unknown(),
    )
    .createUsing(TransactionFields)
    .editUsing(TransactionEditor);
}
