import { List, Typography, Card, CardHeader, CardContent } from '@mui/material';
import ArtRequestListItem from '@/components/ArtRequests/ArtRequestListItem';
import { ArtRequest } from '@/models';

export default function OrderArtRequests({ artRequests }: { artRequests: ArtRequest[] }) {
  return (
    <Card>
      <CardHeader title="Art Requests" />

      {artRequests.length === 0 ? (
        <CardContent>
          <Typography color="textSecondary" variant="body2">
            No art requests have been created for this order.
          </Typography>
        </CardContent>
      ) : (
        <List>
          {artRequests.map((r) => (
            <ArtRequestListItem request={r} key={r.id} />
          ))}
        </List>
      )}
    </Card>
  );
}
