import { useState } from 'react';
import { Search, Edit } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { WrappedFieldProps } from 'redux-form';
import { Field, FieldProps } from '@/admin';
import AddressBlock from '@/components/Addresses/AddressBlock';
import { Address } from '@/models';
import AddressDrawer from './AddressDrawer';
import GenericAddressChooser from './GenericAddressChooser';

function GenericAddressFieldComponent({ input, field }: FieldProps<GenericAddressField>) {
  const address = input.value as Address | null;

  const [isChoosing, setIsChoosing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const excludeFields = ['is_billing', 'is_taxable_address'];
  if (!field.showMethod) {
    excludeFields.push('method');
  }
  return (
    <div
      style={{
        display: 'flex',
        padding: '8px 0',
        minHeight: 56,
        alignItems: 'center',
      }}
    >
      {address ? (
        <AddressBlock address={address} showMethod={field.showMethod} />
      ) : (
        <Typography color="textSecondary">No Address</Typography>
      )}
      <div style={{ marginLeft: 'auto' }}>
        <Tooltip title="Edit Address">
          <IconButton onClick={() => setIsEditing(true)} size="large">
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Choose Address">
          <IconButton onClick={() => setIsChoosing(true)} size="large">
            <Search fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>

      <GenericAddressChooser
        open={isChoosing}
        orderIds={field.orderIds}
        showMethod={field.showMethod}
        onClose={() => setIsChoosing(false)}
        onChoose={(a) => {
          setIsChoosing(false);
          input.onChange(_.omit(a, 'id'));
        }}
      />

      <AddressDrawer
        open={isEditing}
        onClose={() => setIsEditing(false)}
        initialValues={address || {}}
        onSubmit={(a: Partial<Address>) => {
          setIsEditing(false);
          input.onChange(a);
          return Promise.resolve();
        }}
        excludeFields={excludeFields}
      />
    </div>
  );
}

export default class GenericAddressField extends Field {
  orderIds?: number[];
  showMethod?: boolean;

  constructor(name: string, orderIds?: number[], showMethod?: boolean) {
    super(name);
    this.orderIds = orderIds;
    this.showMethod = showMethod;
  }

  renderEditComponent(props: WrappedFieldProps): React.ReactElement | null {
    return <GenericAddressFieldComponent {...props} field={this} />;
  }
}
