import CloseIcon from '@mui/icons-material/Close';
import DuplicateIcon from '@mui/icons-material/FileCopy';
import {
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import _ from 'lodash';
import { WrappedFieldArrayProps } from 'redux-form';
import { FieldFactory, ReduxField } from '@/admin';
import InventoryText from '@/components/Products/InventoryText';

const ItemsBody = styled(TableBody)({
  '& tr:not(:last-child) td': {
    borderBottom: 'none',
    paddingBottom: 0,
  },
  '& tr:last-child td': {
    paddingTop: 2,
  },
  '& td': {
    padding: '6px 12px 6px 0',
    verticalAlign: 'middle',
  },
  '& td:first-child': {
    textAlign: 'center',
  },
});

export default function ItemsSection({
  fields,
  isCustom,
  useDatabaseCost,
}: WrappedFieldArrayProps & {
  isCustom: boolean;
  useDatabaseCost: boolean;
}) {
  const onDuplicate = (i: number) => {
    fields.push(fields.get(i));
  };

  return (
    <>
      {fields.map((f, index) => {
        const { variant } = fields.get(index);
        return (
          <ItemsBody key={f}>
            <TableRow>
              <TableCell>
                {isCustom ? (
                  <ReduxField field={FieldFactory.text('size').withoutLabel()} parentName={f} />
                ) : (
                  _.get(variant, 'size')
                )}
              </TableCell>
              <TableCell>
                <ReduxField field={FieldFactory.number('qty').withoutLabel()} parentName={f} />
              </TableCell>
              <TableCell>
                <ReduxField
                  field={FieldFactory.curr('price').withoutLabel().with({ maxDecimals: 4 })}
                  parentName={f}
                />
              </TableCell>
              <TableCell>
                <ReduxField
                  field={FieldFactory.curr('cost')
                    .withoutLabel()
                    .with({ maxDecimals: 4 })
                    .setDisabled(!isCustom && useDatabaseCost)}
                  parentName={f}
                />
              </TableCell>
              {isCustom ? (
                <TableCell style={{ width: 80 }}>
                  <IconButton onClick={() => onDuplicate(index)} size="small">
                    <DuplicateIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => fields.remove(index)} size="small">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              ) : (
                <TableCell />
              )}
            </TableRow>
            {!isCustom && variant && (
              <TableRow>
                <TableCell />
                <TableCell colSpan={4}>
                  <InventoryText variant={variant} />
                </TableCell>
              </TableRow>
            )}
          </ItemsBody>
        );
      })}

      {isCustom && (
        <TableRow>
          <TableCell colSpan={4} style={{ borderBottom: 0 }}>
            <Button onClick={() => fields.push({ qty: 0 })}>Add Size</Button>
          </TableCell>
        </TableRow>
      )}

      {!isCustom && fields.length === 0 && (
        <TableRow>
          <TableCell colSpan={4} style={{ borderBottom: 0 }}>
            <Typography variant="body2" color="textSecondary">
              Choose a product to get started
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
