import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Alert,
} from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { useDialogs } from '@/admin';
import AddressBlock from '@/components/Addresses/AddressBlock';
import TableCardContainer from '@/components/Shared/TableCardContainer';
import { KitWithAddress } from '@/models';
import { BatchShipment } from '@/responses';

export default function ReviewBatchShipment({
  orderId,
  batch,
  onDiscard,
  onPurchasing,
}: {
  orderId: number;
  batch: BatchShipment;
  onDiscard: () => void;
  onPurchasing: (b: BatchShipment) => void;
}) {
  const { confirm } = useDialogs();
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState<KitWithAddress[]>([]);
  const results = batch.shippo.object_results;

  useEffect(() => {
    if (results.creation_failed > 0) {
      setLoading(true);
      axios
        .get<{ data: KitWithAddress[] }>(
          `/api/orders/${orderId}/shipment-batches/${batch.id}/to-fix`,
        )
        .then(({ data }) => {
          setFailed(data.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [batch.id, results.creation_failed]);

  const onPurchase = () => {
    confirm(
      'Purchase Labels',
      `Are you sure you are ready to purchase ${results.creation_succeeded} labels?`,
    ).then(() => {
      axios
        .post(`/api/orders/${orderId}/shipment-batches/${batch.id}/purchase`)
        .then(({ data }) => {
          onPurchasing(data);
        });
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: 8 }}>
        <Typography variant="subtitle1">Review Batch</Typography>
        <Button size="small" onClick={onDiscard} style={{ marginLeft: 'auto' }}>
          Back to List
        </Button>
      </div>

      <TableCardContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell variant="head">Service</TableCell>
              <TableCell>{_.startCase(batch.shippo.default_servicelevel_token)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Valid Shipments</TableCell>
              <TableCell>{results.creation_succeeded}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Invalid Addresses</TableCell>
              <TableCell>{results.creation_failed}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableCardContainer>

      <div style={{ marginTop: 16 }}>
        {batch.shippo.status === 'VALID' && (
          <Button onClick={onPurchase} variant="contained">
            Purchase
          </Button>
        )}

        {batch.shippo.status === 'INVALID' && (
          <Alert severity="error">You cannot purchase labels until all addresses are valid.</Alert>
        )}
      </div>

      <Box mt={2}>
        {loading && <LinearProgress color="primary" />}

        {failed.length > 0 && (
          <>
            <Typography gutterBottom>The following addresses were invalid:</Typography>
            <List>
              {failed.map((kit) => (
                <ListItem key={kit.id}>
                  <AddressBlock address={kit.address} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
    </div>
  );
}
