import { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { FieldFactory, ReduxField, useFormActions, useSpecificFormValues } from '@/admin';
import { REPORT_FILTER_TYPES } from '@/constants';
import { useConfig } from '@/contexts/AppConfigContext';
import resources from '@/resources';
import ColumnSettings from './ColumnSettings';
import ReportQueryBuilder from './ReportQueryBuilder';

export default function ReportEditor({ form }: { form: string }) {
  const className = useSpecificFormValues(form, (c: { class: string | null }) => c.class);
  const { change } = useFormActions(form);
  const [isClass, setIsClass] = useState(!!className);

  useEffect(() => {
    if (!isClass) {
      change('class', null);
    }
  }, [isClass]);
  const { reportClasses } = useConfig();

  return (
    <div>
      <FormControlLabel
        control={<Checkbox checked={isClass} onChange={(e) => setIsClass(e.target.checked)} />}
        label="Use Built-In Report"
      />

      {isClass ? (
        <div style={{ maxWidth: 400 }}>
          <ReduxField field={FieldFactory.select('class', reportClasses)} />
        </div>
      ) : (
        <>
          <Box mb={1}>
            <ReduxField field={FieldFactory.custom('query', ReportQueryBuilder)} />
          </Box>
          <Box mb={1}>
            <ReduxField field={FieldFactory.custom('columns', ColumnSettings)} />
          </Box>

          <ReduxField
            field={FieldFactory.table('filters', [
              FieldFactory.text('name').setRequired().withHelp('No spaces (e.g CustomerName)'),
              FieldFactory.select('type', REPORT_FILTER_TYPES).setRequired(),
              FieldFactory.text('label'),
              FieldFactory.text('default'),
              FieldFactory.select('resource', Object.keys(resources)).withHelp(
                'Only if using "belongsTo"',
              ),
            ])}
          />
        </>
      )}
    </div>
  );
}
