import { AddShoppingCart, FileCopy, Merge } from '@mui/icons-material';
import axios from 'axios';
import {
  Action,
  ButtonAction,
  CustomAction,
  FieldFactory,
  LinkAction,
  OnClickProps,
} from '@/admin';
import { UseDialogsResult } from '@/admin/hooks/useDialogs';
import { requiredFields } from '@/helpers';
import { Customer } from '@/models';
import { getSingleHeldAction } from '@/utils/disabled';
import PrintMenu from '../Print/PrintMenu';

export const onCustomerHold = (
  dialogs: UseDialogsResult,
  customer: { id: number },
): Promise<Customer> =>
  dialogs
    .prompt({
      title: 'Put Hold on Account',
      description: 'Why is this account going on hold?',
      fields: [FieldFactory.textarea('reason')],
      onSubmit: (v) => axios.post(`/api/customers/${customer.id}/disable`, v),
    })
    .then(({ data }) => data);

export const onCustomerRemoveHold = (
  dialogs: UseDialogsResult,
  customer: { id: number },
): Promise<Customer> =>
  dialogs
    .confirm('Remove Hold on Account', 'Are you sure?')
    .then(() => axios.post(`/api/customers/${customer.id}/disable`))
    .then(({ data }) => data);

export default function CustomerActions(customer: Customer) {
  const onMerge = ({ dialogs, navigate }: OnClickProps) => {
    dialogs
      .prompt<{ into_customer: Customer }>({
        title: 'Merge Customer Into',
        description:
          "The customer's orders, projects, design, products, and more will be moved to the new customer and the current customer will be deleted.",
        fields: [
          FieldFactory.belongsTo('into_customer', 'customers').withRequestParams({
            filter: {
              business_id: customer.business_id,
            },
          }),
        ],
        validation: requiredFields(['into_customer']),
        onSubmit: (v) =>
          axios.post(`/api/customers/${customer.id}/merge`, {
            into_customer_id: v.into_customer.id,
          }),
      })
      .then(({ data }) => {
        navigate(`/customers/${data.id}`);
      });
  };

  const actions: Action[] = [
    new CustomAction('Print', () => <PrintMenu model="customer" id={customer.id} />),
  ];

  if (!customer.is_disabled) {
    actions.push(
      new LinkAction('New Order', `/orders/create?customer_id=${customer.id}`)
        .withPermission('write:orders')
        .withIcon(AddShoppingCart),
    );
  }

  actions.push(
    new ButtonAction('Duplicate', ({ dialogs, navigate }) => {
      dialogs
        .confirm('Duplicate Customer', 'Are you sure you want to create a copy of this customer?')
        .then(() => {
          axios.post(`/api/customers/${customer.id}/duplicate`).then(({ data }) => {
            navigate(`/customers/${data.id}`);
          });
        });
    })
      .withIcon(FileCopy)
      .withPermission('write:customers'),
    new ButtonAction('Merge Customer', onMerge).withIcon(Merge).withPermission('write:customers'),
    getSingleHeldAction('Customer', 'customers', customer),
  );

  return actions;
}
