import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody } from '@mui/material';
import moment from 'moment';
import { curr, FieldProps, wrap } from '@/admin';
import { Payment } from '@/models';

export default function DepositPayments({ input }: FieldProps) {
  const payments = wrap(input.value) as Payment[];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Payment Number</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Method</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((p) => (
            <TableRow key={p.id}>
              <TableCell>{moment(p.payment_date).format('l')}</TableCell>
              {p.customer ? (
                <TableCell>
                  <span>{p.customer.name} </span>
                  <span>(#{p.customer.id})</span>
                </TableCell>
              ) : (
                <TableCell>none</TableCell>
              )}
              <TableCell>{p.payment_type}</TableCell>
              <TableCell>{p.payment_number}</TableCell>
              <TableCell>{curr(p.amount)}</TableCell>
              <TableCell>{p.method}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
