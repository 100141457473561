import { Alert } from '@mui/material';
import { OrderItem } from '@/models';
import { getKitQty } from '@/utils/shipping';

export default function InventoryKitAlert({ items }: { items: OrderItem[] }) {
  const kits = getKitQty(
    items.map((item) => ({ id: item.id, order_item_id: item.id, qty_shipped: item.qty })),
    items,
  );

  return (
    <>
      {typeof kits === 'object' ? (
        <Alert severity="warning">
          {kits.item} {kits.reason}
        </Alert>
      ) : (
        <Alert severity="success">Quantities and quantities per kit look good!</Alert>
      )}
    </>
  );
}
