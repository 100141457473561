import Input from '@mui/material/OutlinedInput';
import MaskedInput from 'react-text-mask';
import { WrappedFieldProps } from 'redux-form';
import InputWrapper from '../../components/Form/InputWrapper';
import { FieldProps } from '../../types';
import Field from '../Field';

function Phone({ field, meta, input }: FieldProps<PhoneField>) {
  return (
    <InputWrapper meta={meta} field={field}>
      <MaskedInput
        {...input}
        mask={field.mask}
        guide={false}
        placeholder={field.placeholder}
        render={(ref, props) => (
          <Input
            {...props}
            type="text"
            inputRef={ref}
            label={field.label}
            size={field.size}
            margin={field.margin}
            disabled={field.disabled}
            required={field.required}
            fullWidth={field.fullWidth}
          />
        )}
      />
    </InputWrapper>
  );
}

export default class PhoneField extends Field {
  mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  renderEditComponent(props: WrappedFieldProps) {
    return <Phone {...props} field={this} />;
  }
}
