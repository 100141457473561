import { useState } from 'react';
import { ArrowRightAlt, Edit } from '@mui/icons-material';
import {
  Alert,
  Box,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Can, makeResourceQueryKey, Paper, useFormValues, useRecordId } from '@/admin';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import Label from '@/components/Shared/Label';
import { Order, OrderAnalysisElement, OrderAnalysisResponse } from '@/models';
import useApiSegment from '@/utils/useApiSegment';
import useOrderableApiUrl from '@/utils/useOrderableApiUrl';
import AnalysisDetails from './AnalysisDetails';
import EditOrderCostsDrawer from './EditOrderCostsDrawer';
import OrderCommissions from './OrderCommissions';
import OrderPayouts from './OrderPayouts';

const getColorForItem = (key: string, total: number) => {
  if (key === 'profit') {
    if (total < 0) {
      return red[500];
    }
    if (total === 0) {
      return 'inherit';
    }
    return green[500];
  }

  if (key === 'margin') {
    if (total < 0) {
      return red[500];
    }
    if (total < 0.3) {
      return orange[500];
    }
    return green[500];
  }

  return 'inherit';
};

function AnalysisCard({
  item,
  itemKey,
  setViewing,
  setEditingCosts,
}: {
  item: OrderAnalysisElement;
  itemKey: string;
  setViewing: (key: string) => void;
  setEditingCosts: (bool: boolean) => void;
}) {
  const segment = useApiSegment(1);
  return (
    <CardWithGutter>
      <CardHeader
        title={_.startCase(itemKey)}
        action={
          segment === 'orders' &&
          itemKey === 'costs' && (
            <Can permission="orders:costs">
              <IconButton onClick={() => setEditingCosts(true)} size="small">
                <Edit fontSize="small" />
              </IconButton>
            </Can>
          )
        }
      />

      <CardContent>
        <Typography variant="h3" style={{ color: getColorForItem(itemKey, item.total) }}>
          {item.formatted}
        </Typography>
      </CardContent>

      {'items' in item && (
        <List>
          <Divider />
          {_.map(item.items, (i, key) => {
            const hasItems = 'items' in i;
            const onClick = hasItems ? () => setViewing(`${itemKey}.items.${key}`) : () => null;
            return (
              <ListItemButton key={key} onClick={onClick}>
                <ListItemText>
                  <span>{_.startCase(key)} </span>
                  {'is_estimate' in i && i.is_estimate ? <Label>Estimate</Label> : null}
                </ListItemText>
                <ListItemSecondaryAction>{i.formatted}</ListItemSecondaryAction>
              </ListItemButton>
            );
          })}
        </List>
      )}
    </CardWithGutter>
  );
}

export default function Analysis() {
  const orderId = useRecordId();
  const webstoreParentId = useFormValues((o: Order) => o.webstore_parent_id);
  const [viewing, setViewing] = useState<string>();
  const [isEditingCosts, setEditingCosts] = useState(false);

  const baseUrl = useOrderableApiUrl('analysis');
  const segment = useApiSegment(1);
  const { data: analysis = {}, isLoading } = useQuery(
    makeResourceQueryKey(segment, orderId, 'analysis'),
    () => axios.get<OrderAnalysisResponse>(baseUrl).then(({ data }) => data),
  );

  const toPass = {
    setViewing,
    setEditingCosts,
  };

  const SkeletonColumn = (
    <>
      <Paper>
        <Skeleton variant="text" />
        <Skeleton variant="rounded" width={100} height={50} />
      </Paper>
      <Paper>
        <Skeleton variant="text" />
        <Skeleton variant="rounded" width={100} height={50} />
      </Paper>
    </>
  );

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} xs={12}>
        {webstoreParentId && (
          <Box mb={1}>
            <Alert
              severity="info"
              action={
                <IconButton
                  component={Link}
                  color="inherit"
                  size="small"
                  to={`/orders/${webstoreParentId}?tab=analysis`}
                >
                  <ArrowRightAlt />
                </IconButton>
              }
            >
              This order is part of a webstore. All revenue and costs are pushed up to the webstore
              order.
            </Alert>
          </Box>
        )}
        <Grid container spacing={2}>
          {[0, 1].map((columnIndex) => (
            <Grid item sm={6} xs={12} key={columnIndex}>
              {isLoading
                ? SkeletonColumn
                : Object.keys(analysis)
                    .filter((key, i) => i % 2 === columnIndex)
                    .map((key) => (
                      <AnalysisCard key={key} itemKey={key} item={analysis[key]} {...toPass} />
                    ))}
            </Grid>
          ))}
        </Grid>
      </Grid>

      {segment === 'orders' ? (
        <Grid item lg={6} xs={12}>
          <OrderPayouts />
          <OrderCommissions />
        </Grid>
      ) : null}

      <AnalysisDetails
        open={!!viewing}
        onClose={() => setViewing(undefined)}
        item={viewing ? _.get(analysis, viewing) : undefined}
        itemKey={viewing ? _.startCase(viewing.split('.').pop()) : undefined}
      />

      <EditOrderCostsDrawer open={isEditingCosts} onClose={() => setEditingCosts(false)} />
    </Grid>
  );
}
