import { SyntheticEvent, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import _ from 'lodash';
import { reduxForm, SubmissionError, InjectedFormProps, FormSubmitHandler } from 'redux-form';
import Layout from '../models/Layout';
import Resource from '../models/Resource';
import getDirtyProperties from '../utils/getDirtyProperties';
import { memoizedValidateUsingRules, transformLaravelErrors } from '../utils/validator';
import EditFormFields from './EditFormFields';
import PrevNextNavigation from './Form/PrevNextNavigation';

type EditFormShape = Record<string, any>;

interface EditFormProps {
  resource: Resource;
  allowPrevNext?: boolean;
  defaultLayout?: typeof Layout;
  isCreate?: boolean;
  onSuccess: (values: EditFormShape) => void;
}

function EditForm({
  resource,
  submitting,
  handleSubmit,
  submitFailed,
  isCreate,
  defaultLayout,
  initialize,
  initialized,
  initialValues = {},
  reset,
  dirty,
  allowPrevNext = false,
  form,
}: InjectedFormProps<EditFormShape, EditFormProps> & EditFormProps) {
  const resourceId = _.get(initialValues, resource.primaryKey);

  // When primary key changes, reinit form
  useEffect(() => {
    initialize(initialValues);
  }, [resourceId]);

  const stopPropSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    return handleSubmit(e);
  };

  const renderInner = () => {
    if (!initialized) {
      return null;
    }
    if (isCreate && resource.createComponent) {
      const Component = resource.createComponent;
      return <Component formName={form} />;
    }
    if (!isCreate && resource.editComponent) {
      const Component = resource.editComponent;
      return <Component formName={form} />;
    }

    return (
      <EditFormFields fields={resource.fields} isCreate={isCreate} defaultLayout={defaultLayout} />
    );
  };

  return (
    <form onSubmit={stopPropSubmit} style={{ marginBottom: 200 }}>
      {allowPrevNext && <PrevNextNavigation />}

      {renderInner()}

      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'flex',
          },
        }}
        justifyContent="space-between"
      >
        <Box my={2}>
          {submitFailed && <Typography color="error">Please check form for errors</Typography>}
        </Box>

        <Box my={2}>
          {(dirty || isCreate) && (
            <>
              <Button type="button" onClick={reset} sx={{ mr: 2 }}>
                Reset
              </Button>
              <Button type="submit" disabled={submitting} variant="contained">
                Save
              </Button>
            </>
          )}
        </Box>
      </Box>
    </form>
  );
}

const onSubmit: FormSubmitHandler<EditFormShape, EditFormProps> = (values, dispatch, props) => {
  const { isCreate, initialize, onSuccess, resource, initialValues } = props;
  // Only get keys that are dirty to update
  const body = isCreate
    ? values
    : getDirtyProperties(values, initialValues || {}, [resource.primaryKey]);
  const promise = isCreate ? resource.getStoreRequest(body) : resource.getUpdateRequest(body);

  return promise
    .then(({ data }) => {
      if (initialize) {
        initialize({ ...values, ...data }, false);
      }
      onSuccess(data);
    })
    .catch((e) => {
      const errors = transformLaravelErrors(_.get(e, 'response.data.errors', {}));
      throw new SubmissionError(errors);
    });
};

const validate = (values: object, { resource }: { resource: Resource }) => {
  if (resource.validation) {
    return memoizedValidateUsingRules(values, resource.validation);
  }
  return {};
};

export default reduxForm<EditFormShape, EditFormProps>({
  validate,
  onSubmit,
})(EditForm);
