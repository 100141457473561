import { ClosableDrawer } from '@/admin';
import RosterForm from '@/components/Orders/Art/RosterForm';
import { DesignLayoutWithProofs } from '@/models';

export default function RosterDrawer({
  onClose,
  layout,
  onSaved,
}: {
  onClose: () => void;
  layout?: DesignLayoutWithProofs;
  onSaved: () => void;
}) {
  return (
    <ClosableDrawer open={!!layout} onClose={onClose} title="Roster for Names/Numbers" width={800}>
      {layout && <RosterForm layout={layout} onSaved={onSaved} />}
    </ClosableDrawer>
  );
}
