import { FieldFactory, Resource } from '@/admin';
import { requiredFields } from '@/helpers';
import { SpecialDesignType } from '@/models';
import { AppConfig } from '@/responses';

export default function specialDesignTypes(appConfig: AppConfig) {
  return new Resource<SpecialDesignType>('Special Design Types')
    .withStaticOptions(appConfig.specialDesignTypes)
    .setUseDrawer()
    .withValidation(requiredFields(['name', 'cost']))
    .withFieldsAndColumns([FieldFactory.text('name').sortable(), FieldFactory.curr('cost')]);
}
