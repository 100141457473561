import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { DrawerFormSection, getValueFromEvent } from '@/admin';
import AddressTitle from '@/components/Addresses/AddressTitle';
import ChooseAccount from '@/components/Shipping/ChooseAccount';
import ChooseServiceLevel from '@/components/Shipping/ChooseServiceLevel';
import CustomsDeclarationBuilder from '@/components/Shipping/CustomsDeclarationBuilder';
import ParcelFields from '@/components/Shipping/ParcelFields';
import { ThirdPartyShippingAccount } from '@/models';
import { CreateShipmentPayload } from '@/responses';

export default function CreateShipmentForm({
  values,
  errors,
  setValues,
  onSubmit,
  thirdPartyAccounts,
}: {
  values: CreateShipmentPayload;
  setValues: Dispatch<SetStateAction<CreateShipmentPayload | undefined>>;
  errors: Record<string, any>;
  onSubmit: () => void;
  thirdPartyAccounts: ThirdPartyShippingAccount[];
}) {
  const handleChange = (key: keyof CreateShipmentPayload) => (e: any) => {
    const value = getValueFromEvent(e);
    setValues((prev) => {
      if (!prev) return undefined;
      return { ...prev, [key]: value };
    });
  };

  const handleShipFromChange = (e: SelectChangeEvent) => {
    const newFrom = values.from_address_options.find((a) => String(a.id) === e.target.value);
    if (newFrom) {
      setValues({
        ...values,
        from_address: newFrom,
      });
    }
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <DrawerFormSection title="Ship From">
        {values.from_address_options.length === 1 ? (
          <AddressTitle address={values.from_address} hideMethod />
        ) : (
          <Select
            value={String(values.from_address.id)}
            onChange={handleShipFromChange}
            fullWidth
            variant="standard"
          >
            {values.from_address_options.map((a) => (
              <MenuItem key={a.id} value={String(a.id)}>
                <AddressTitle address={a} hideMethod />
              </MenuItem>
            ))}
          </Select>
        )}
      </DrawerFormSection>

      <DrawerFormSection title="Ship To">
        <AddressTitle address={values.address} />
      </DrawerFormSection>

      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={values.require_signature === true}
            onChange={handleChange('require_signature')}
          />
        }
        label="Requires Signature Confirmation"
      />
      <ChooseAccount
        accountId={values.third_party_account_id}
        onChange={handleChange('third_party_account_id')}
        accounts={thirdPartyAccounts}
      />
      <ChooseServiceLevel
        serviceLevel={values.service_level}
        onChange={handleChange('service_level')}
      />
      <CustomsDeclarationBuilder customs={values.customs} onChange={handleChange('customs')} />
      <ParcelFields parcels={values.parcels} errors={errors} onChange={handleChange('parcels')} />

      <Button type="submit" variant="contained" sx={{ mt: 3 }}>
        Get Rates
      </Button>
    </form>
  );
}
