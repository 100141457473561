import { useState } from 'react';
import { RequestQuote, Warning } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { round } from 'lodash';
import {
  ClosableDrawer,
  curr,
  DrawerFormSection,
  useHasPermission,
  TextButton,
  useRecord,
} from '@/admin';
import { TotalLabel, TotalTable, TotalValue } from '@/components/Shared/Label';
import EstimateShippingCost from '@/components/Shipping/EstimateShippingCost';
import { Order, OrderTotals } from '@/models';
import useApiSegment from '@/utils/useApiSegment';
import useOrderableApiUrl from '@/utils/useOrderableApiUrl';
import OrderTaxCalculation from './OrderTaxCalculation';

export default function OrderTotalsBlock({
  handleUpdatedOrder,
  totals,
  isOrderLocked,
  onEditShipping,
  onEditInbound,
}: {
  handleUpdatedOrder: (o: Order) => void;
  totals: OrderTotals;
  isOrderLocked: boolean;
  onEditShipping: () => void;
  onEditInbound: () => void;
}) {
  const [showingTax, setShowingTax] = useState(false);
  const [estimating, setEstimating] = useState(false);
  const segment = useApiSegment(1);
  const hasPermission = useHasPermission();
  const invoicedAt = useRecord<Order>().invoiced_at;
  const baseUrl = useOrderableApiUrl();

  const taxNeedsRecalc = totals.last_tax_hash !== totals.current_tax_hash || !totals.last_tax_hash;
  const subtotalForTax = totals.subtotal + totals.shipping + totals.inbound_freight;
  const totalTaxable = subtotalForTax - totals.total_exempt;

  const { isLoading, mutate } = useMutation(
    () => axios.post(`${baseUrl}/tax`).then(({ data }) => data),
    {
      onSuccess: handleUpdatedOrder,
    },
  );

  const updateRequest = useMutation(
    (payload: { shipping: number }) => axios.put(baseUrl, payload),
    {
      onSuccess: (res) => {
        handleUpdatedOrder(res.data);
        setEstimating(false);
      },
    },
  );

  return (
    <Box width={350} flexShrink={0} flexGrow={0}>
      <TotalTable>
        <tbody>
          <tr>
            <TotalLabel>Subtotal</TotalLabel>
            <TotalValue>{curr(totals.subtotal)}</TotalValue>
          </tr>
          <tr>
            <TotalLabel>Shipping</TotalLabel>
            <TotalValue>
              <Tooltip title="Estimate Outbound Shipping">
                <IconButton size="small" sx={{ mr: 1 }} onClick={() => setEstimating(true)}>
                  <RequestQuote fontSize="small" />
                </IconButton>
              </Tooltip>
              <TextButton onClick={onEditShipping} disabled={isOrderLocked}>
                {curr(totals.shipping || 0)}
              </TextButton>
            </TotalValue>
          </tr>
          <tr>
            <TotalLabel>Inbound Freight</TotalLabel>
            <TotalValue>
              <TextButton onClick={onEditInbound} disabled={isOrderLocked}>
                {curr(totals.inbound_freight || 0)}
              </TextButton>
            </TotalValue>
          </tr>
          {totals.precollected_tax ? (
            <tr>
              <TotalLabel>Tax Collected on Store</TotalLabel>
              <TotalValue>{curr(totals.precollected_tax)}</TotalValue>
            </tr>
          ) : null}
          <tr>
            <TotalLabel>Tax</TotalLabel>
            <TotalValue>
              {isLoading && <CircularProgress size={8} />}
              {taxNeedsRecalc && (
                <Tooltip title="The calculated tax is not up-to-date.">
                  <IconButton size="small" sx={{ mr: 1 }} onClick={() => mutate()}>
                    <Warning color="warning" fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <TextButton
                disabled={!hasPermission('orders:tax_exempt')}
                onClick={() => setShowingTax(true)}
              >
                {curr(totals.tax)}
              </TextButton>
            </TotalValue>
          </tr>
          <tr>
            <TotalLabel>Total</TotalLabel>
            <TotalValue>{curr(totals.total)}</TotalValue>
          </tr>

          {Number(totals.paid) ? (
            <>
              <tr>
                <td colSpan={2} style={{ height: 12 }} />
              </tr>
              <tr>
                <TotalLabel>Paid</TotalLabel>
                <TotalValue>{curr(totals.paid)}</TotalValue>
              </tr>
              <tr>
                <TotalLabel>Balance</TotalLabel>
                <TotalValue>{curr(totals.balance)}</TotalValue>
              </tr>
            </>
          ) : null}
        </tbody>
      </TotalTable>
      <ClosableDrawer
        open={showingTax}
        onClose={() => setShowingTax(false)}
        title="Sales Tax for Order"
      >
        {segment === 'orders' && (
          <DrawerFormSection title="Tax Exemptions">
            <OrderTaxCalculation />
          </DrawerFormSection>
        )}

        <DrawerFormSection title="Taxable Amount">
          <TotalTable style={{ background: 'none' }}>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Subtotal &amp; Shipping</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>{curr(subtotalForTax)}</TotalValue>
            </tr>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Exempt</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>
                {totals.total_exempt ? curr(totals.total_exempt * -1) : <span>&mdash;</span>}
              </TotalValue>
            </tr>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Total</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>{curr(totalTaxable)}</TotalValue>
            </tr>
          </TotalTable>
        </DrawerFormSection>

        <DrawerFormSection title="Sales Tax Breakdown">
          <TotalTable style={{ background: 'none' }}>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Pre-collected Tax</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>
                {totals.precollected_tax ? curr(totals.precollected_tax) : <span>&mdash;</span>}
              </TotalValue>
            </tr>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Calculated Tax</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>
                {curr(totals.tax)}
                {totals.tax > 0 ? (
                  <Chip sx={{ ml: 1 }} label={`${round((totals.tax / totalTaxable) * 100, 2)}%`} />
                ) : null}
              </TotalValue>
            </tr>
            <tr>
              <TotalLabel style={{ textAlign: 'left' }}>Total</TotalLabel>
              <TotalValue style={{ textAlign: 'left' }}>{curr(totals.total_tax)}</TotalValue>
            </tr>
          </TotalTable>

          {!invoicedAt && (
            <Button onClick={() => mutate()} disabled={isLoading}>
              Recalculate
            </Button>
          )}
        </DrawerFormSection>
      </ClosableDrawer>

      <ClosableDrawer
        open={estimating}
        onClose={() => setEstimating(false)}
        title="Estimate Shipping"
      >
        {estimating && <EstimateShippingCost updateRequest={updateRequest} />}
      </ClosableDrawer>
    </Box>
  );
}
