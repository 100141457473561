import { Grid, Hidden, Box, Card, CardContent } from '@mui/material';
import { useRecord } from '@/admin';
import IssuesCard from '@/components/Issues/IssuesCard';
import EventableCard from '@/components/Notes/EventableCard';
import OrderStatus from '@/components/Orders/OrderStatus';
import ProjectCard from '@/components/Projects/ProjectCard';
import PurchaseOrderStatuses from '@/components/Purchasing/PurchaseOrderStatuses';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import { OrderWithDetails } from '@/models';
import OrderApprovals from './OrderApprovals';
import OrderArtRequests from './OrderArtRequests';
import OrderDesignTiles from './OrderDesignTiles';
import OrderDetails from './OrderDetails';
import OrderPackouts from './OrderPackouts';
import OrderProductionApproval from './OrderProductionApproval';
import OrderStatuses from './OrderStatuses';
import OrderTiming from './OrderTiming';
import RelatedOrders from './RelatedOrders';

export default function OrderDashboard() {
  const order = useRecord<OrderWithDetails>();
  const orderId = order.id;
  const isReadOnly = Boolean(order.invoiced_at || order.cancelled_at);

  return (
    <Grid container spacing={2}>
      {order.status === 'Pending Production Approval' && (
        <Grid item xs={12}>
          <OrderProductionApproval orderId={orderId} />
        </Grid>
      )}
      <Grid item lg={8} xs={12}>
        <Box mb={2}>
          <Card>
            <Grid container>
              <Grid item lg={7} xs={12}>
                <OrderDetails order={order} isReadOnly={isReadOnly} />
              </Grid>
              <Hidden lgDown>
                <Grid item lg={5}>
                  <OrderDesignTiles orderId={order.id} />
                </Grid>
              </Hidden>
            </Grid>
          </Card>
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            {order.approvals && <OrderApprovals approvals={order.approvals} />}
          </Grid>
          <Grid item lg={6} xs={12}>
            {order.art_requests && <OrderArtRequests artRequests={order.art_requests} />}
          </Grid>
          <Grid item xs={12}>
            <EventableCard resourceId={order.id} resource="orders" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} xs={12}>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <OrderStatus status={order.status} withDetails />
          </CardContent>
          <OrderStatuses orderId={orderId} disabled={isReadOnly} />
        </Card>

        {order.shipping_mode === 'kits' && (
          <Box mb={2}>
            <OrderPackouts orderId={orderId} invoicedAt={order.invoiced_at} />
          </Box>
        )}

        {order.project_id && <ProjectCard projectId={order.project_id} />}

        {!order.order_type.is_webstore && <OrderTiming orderId={orderId} disabled={isReadOnly} />}

        <CardWithGutter>
          {order.owned_purchase_orders.map((po) => (
            <PurchaseOrderStatuses key={po.id} purchaseOrder={po} />
          ))}
        </CardWithGutter>

        <IssuesCard resource="orders" resourceId={orderId} />

        <RelatedOrders orderId={orderId} />
      </Grid>
    </Grid>
  );
}
