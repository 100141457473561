import { ReactNode } from 'react';
import { Box, Card, CardHeader } from '@mui/material';
import EditFormFields, { EditFormFieldsProps } from '../../components/EditFormFields';
import { LayoutProps, NullableFieldable } from '../../types';
import Layout from '../Layout';
import GridLayout from './GridLayout';

function Component({ model, forwardProps = {} }: LayoutProps<CardLayout>) {
  return (
    <Card sx={{ mb: 2 }}>
      {model.label && (
        <CardHeader title={model.label} subheader={model.subheader} action={model.action} />
      )}
      <Box px={model.padding ? 2 : 0} py={2}>
        <EditFormFields
          fields={model.fields}
          defaultLayout={model.defaultLayout}
          {...forwardProps}
        />
      </Box>
    </Card>
  );
}

export default class CardLayout extends Layout {
  subheader?: ReactNode;
  action?: ReactNode;

  constructor(name: string, fields: NullableFieldable[] = []) {
    super(name, fields);
    this.defaultLayout = GridLayout;
    this.padding = true;
  }

  withSubheader(subheader: ReactNode) {
    this.subheader = subheader;
    return this;
  }

  withAction(action: ReactNode) {
    this.action = action;
    return this;
  }

  renderLayout(forwardProps: Partial<EditFormFieldsProps>): React.ReactElement | null {
    return <Component model={this} forwardProps={forwardProps} />;
  }
}
