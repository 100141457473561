import { Close } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  curr,
  FieldProps,
  useDialogs,
  useFormValues,
  useOnReloadRecord,
  useShowLoading,
} from '@/admin';
import { Account } from '@/models';
import PlaidLink from './PlaidLink';

export default function PlaidConnection({ input }: FieldProps) {
  const [id, type, plaidName, plaidBalance, plaidLogo] = useFormValues((a: Account) => [
    a.id,
    a.type,
    a.plaid_name,
    a.plaid_balance,
    a.plaid_logo,
  ]);

  const isBank = ['bank', 'credit_card'].includes(type);
  const { confirm } = useDialogs();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();

  const { data: plaidToken } = useQuery(
    ['plaidToken', id],
    () => axios.get(`/api/accounts/${id}/plaid`).then(({ data }) => data.link_token),
    {
      enabled: isBank,
    },
  );

  const onDisconnect = () => {
    confirm(
      'Disconnect From Plaid',
      'Are you sure you want to disconnect this account from Plaid?',
    ).then(() => {
      showLoading(axios.delete(`/api/accounts/${id}/plaid`)).then(() => {
        onReload();
      });
    });
  };

  if (!isBank && !input.value) {
    return null;
  }

  return (
    <div>
      {input.value && (
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={`data:image/png;base64, ${plaidLogo}`}>
                {plaidName ? plaidName[0] : 'A'}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <span>
                  Connected to <i>{plaidName}</i> via Plaid
                </span>
              }
              secondary={`Balance: ${plaidBalance ? curr(plaidBalance) : '?'}`}
            />
            <ListItemSecondaryAction>
              <Tooltip title="Disconnect">
                <IconButton onClick={onDisconnect} size="large">
                  <Close />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}

      {plaidToken && <PlaidLink token={plaidToken} id={id} isUpdate={!!input.value} />}
    </div>
  );
}
