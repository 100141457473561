import { Tooltip } from '@mui/material';
import { ArrayBullets, CustomAction, FieldFactory, Resource } from '@/admin';
import SkuPage from '@/components/Inventory/SkuPage';
import PrintMenu from '@/components/Print/PrintMenu';
import ProductImage from '@/components/Shared/ProductImage';
import { SkuResource } from '@/models';

export default function inventoryVariants() {
  return new Resource<SkuResource>('Variants')
    .withQueryParams({
      index: {
        inventory: '1',
      },
      single: {
        with: 'inventory_totals',
      },
    })
    .getLabelUsing((v) => v.sku)
    .getSubtitleUsing((v) => (
      <ArrayBullets
        elements={[
          v.product.vendor?.name || '(?vendor)',
          v.product.style_number,
          v.product.color,
          v.size,
        ]}
      />
    ))
    .getAvatarUsing((v) => (
      <Tooltip title={v.product.name}>
        <ProductImage product={v.product} />
      </Tooltip>
    ))
    .getSingleActionsUsing((v) => [
      new CustomAction('Print', () => <PrintMenu model="variant" id={v.id} />),
    ])
    .withExportable({ format: 'xlsx' })
    .setReadOnly()
    .getLabelUsing((v) => v.sku)
    .getTitleUsing((v) => v.sku)
    .withColumns([
      FieldFactory.belongsTo('product', 'products').sortable('product.name'),
      FieldFactory.text('sku').withLabel('SKU').sortable(),
      FieldFactory.text('size').sortable(),
      FieldFactory.curr('cost').sortable().setAggregatable(),
      FieldFactory.number('inventory_qty')
        .withLabel('Avail Inventory')
        .sortable()
        .setAggregatable(),
      FieldFactory.number('vendor_inventory_qty')
        .withLabel('Vendor Inventory')
        .sortable()
        .setAggregatable(),
      FieldFactory.number('weight').sortable(),
      FieldFactory.curr('price').sortable(),
      FieldFactory.curr('value').sortable(),
      FieldFactory.text('customs_descriptor').sortable(),
      FieldFactory.hasMany('product.tags', 'tags'),
      FieldFactory.text('product.customer.name')
        .filterable(
          FieldFactory.belongsTo('customer', 'customers').withFilterKey('filter[customer_id]'),
        )
        .withLabel('Customer'),
      FieldFactory.text('product.business.name')
        .filterable(
          FieldFactory.belongsTo('business', 'businesses').withFilterKey('filter[business_id]'),
        )
        .withLabel('Business'),
      FieldFactory.text('product.vendor.name')
        .filterable(FieldFactory.belongsTo('vendor', 'vendors').withFilterKey('filter[vendor_id]'))
        .withLabel('Vendor'),
    ])
    .editUsing(SkuPage);
}
