import _ from 'lodash';
import moment from 'moment';
import {
  DateField,
  DateRangeField,
  Field,
  FieldFactory,
  RelationField,
  SelectField,
  TextInputField,
  ToggleField,
} from '@/admin';
import { ReportFilter } from '@/models';
import { ReportResultsRow } from '@/responses';

function getFieldTypeForFilter(f: ReportFilter): Field {
  switch (f.type) {
    case 'belongsTo':
    case 'hasMany':
      return new RelationField(f.name, f.resource || 'users').with({
        hasMany: f.type === 'hasMany',
      });
    case 'date':
      return new DateField(f.name);
    case 'daterange':
      return new DateRangeField(f.name);
    case 'select':
      return new SelectField(f.name, f.options || []);
    case 'boolean':
      return new ToggleField(f.name);
    case 'text':
    default:
      return new TextInputField(f.name);
  }
}

export function getFieldForFilter(f: ReportFilter): Field {
  const field = getFieldTypeForFilter(f);
  if (f.label) {
    field.label = f.label;
  } else if (f.name === 'daterange' && field instanceof DateRangeField) {
    field.startLabel = 'Start Date';
    field.endLabel = 'End Date';
  }
  field.with(_.pick(f, ['columnSpan', 'includeBlank']));
  return field;
}

export function getDefaultForFilter(filter: ReportFilter): any {
  if (filter.type === 'daterange') {
    if (filter.default === 'thisyear') {
      return [moment().startOf('year'), moment().endOf('year')];
    }
    if (filter.default === 'thisquarter') {
      return [moment().startOf('quarter'), moment().endOf('quarter')];
    }
    if (filter.default === 'thismonth') {
      return [moment().startOf('month'), moment().endOf('month')];
    }
    if (filter.default === 'thisweek') {
      return [moment().startOf('week'), moment().endOf('week')];
    }
    if (
      filter.default === 'today' ||
      filter.default === 'CURDATE()' ||
      filter.default === 'NOW()'
    ) {
      return [moment().startOf('day'), moment().endOf('day')];
    }
  }
  if (filter.type === 'date') {
    if (
      filter.default === 'today' ||
      filter.default === 'CURDATE()' ||
      filter.default === 'NOW()'
    ) {
      return moment();
    }
  }
  if (filter.default) {
    return filter.default;
  }

  return undefined;
}

export function getFilterFields(filters: ReportFilter[]): Field[] {
  const fields = filters.map(getFieldForFilter);
  if (filters.some((f) => f.name === 'daterange')) {
    fields.push(
      FieldFactory.select('compare_to', {
        last_year: 'Last Year',
        prev_period: 'Previous Period',
      }).with({
        includeBlank: 'None',
        columnSpan: 3,
      }),
    );
  }
  return fields;
}

export function getFilterDefaults(filters: ReportFilter[]): Record<string, any> {
  const defaults: Record<string, any> = {};

  filters.forEach((filter) => {
    const defaultValue = getDefaultForFilter(filter);
    if (defaultValue !== undefined) {
      defaults[filter.name] = defaultValue;
    }
  });

  if (filters.some((f) => f.name === 'daterange')) {
    defaults['compare_to'] = 'last_year';
  }

  return defaults;
}

export function getCompareToRow(
  row: ReportResultsRow,
  compareToResults?: ReportResultsRow[],
  key?: string,
): ReportResultsRow | undefined {
  if (!compareToResults) {
    return undefined;
  }
  const keyName = key || Object.keys(row)[0];
  const keyValue = row[keyName];
  return compareToResults.find((r) => r[keyName] === keyValue);
}
