import { OpenInNew } from '@mui/icons-material';
import {
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { useFormActions, useFormValues } from '@/admin';
import { InboundShipment, PurchaseOrder } from '@/models';

export default function InboundShipmentMatches() {
  const [id, purchaseOrder] = useFormValues((is: InboundShipment) => [is.id, is.purchase_order]);
  const { change } = useFormActions();

  const { data: matches = [], isLoading } = useQuery(
    ['inboundShipmentMatches', id],
    () =>
      axios
        .get<{ data: PurchaseOrder[] }>(`/api/inbound-shipments/${id}/matches`)
        .then(({ data }) => data.data),
    {
      enabled: Boolean(id && !purchaseOrder),
    },
  );

  if (purchaseOrder) {
    return null;
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  if (matches.length === 0) {
    return (
      <Typography variant="body2" color="textSecondary">
        No possible matches found.
      </Typography>
    );
  }

  return (
    <List dense>
      {matches.map((match) => (
        <ListItemButton key={match.id} onClick={() => change('purchase_order', match)}>
          <ListItemText primary={match.label} secondary={moment(match.issued_date).format('l')} />
          <ListItemSecondaryAction>
            <IconButton
              component="a"
              target="_blank"
              href={`/purchase-orders/${match.id}`}
              size="large"
            >
              <OpenInNew />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      ))}
    </List>
  );
}
