import { useEffect } from 'react';
import { Alert, Button, Grid, List } from '@mui/material';
import axios from 'axios';
import Joi from 'joi';
import _ from 'lodash';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import {
  ClosableDrawer,
  Field,
  FieldFactory,
  ReduxField,
  useSpecificFormValues,
  validateUsingRules,
} from '@/admin';
import BankTransactionListItem from '@/components/Banking/BankTransactionListItem';
import { TRANSACTION_TYPES } from '@/constants';
import { Account, BankTransaction, Transaction, Vendor } from '@/models';

type FormShape = Partial<{
  transaction_type: 'transfer' | 'expense';
  account: Account;
  vendor: Vendor;
  rule_create: boolean;
}>;

interface BankCreateTransactionDrawerProps {
  reviewing: BankTransaction | null;
  setReviewing: (t: BankTransaction | null) => void;
  onSuccess: (t: Transaction) => void;
}

function BankCreateTransactionDrawer({
  reviewing,
  setReviewing,
  onSuccess,
  initialize,
  handleSubmit,
  submitting,
  change,
}: InjectedFormProps<FormShape, BankCreateTransactionDrawerProps> &
  BankCreateTransactionDrawerProps) {
  const vendor = useSpecificFormValues('BankReview', (f: FormShape) => f.vendor);
  const account = useSpecificFormValues('BankReview', (f: FormShape) => f.account);
  const ruleCreate = useSpecificFormValues('BankReview', (f: FormShape) => f.rule_create);
  const transactionType = useSpecificFormValues('BankReview', (f: FormShape) => f.transaction_type);

  useEffect(() => {
    if (vendor && !account) {
      change('account', vendor.default_accounts[0]);
    }
  }, [vendor?.id]);

  useEffect(() => {
    if (reviewing) {
      initialize({
        transaction_type: reviewing.is_transfer ? 'transfer' : 'expense',
        account: _.get(reviewing, 'rule.account'),
        vendor: _.get(reviewing, 'rule.vendor'),
        rule_create: false,
      });
    }
  }, [reviewing?.id]);

  const onSubmit: FormSubmitHandler<FormShape, BankCreateTransactionDrawerProps> = (values) => {
    if (!reviewing) {
      return;
    }
    const payload = {
      ...values,
      account_id: values.account!.id,
      vendor_id: values.vendor?.id,
    };
    return axios.post(`/api/bank-transactions/${reviewing.id}/create`, payload).then(({ data }) => {
      onSuccess(data);
    });
  };

  let fields: Field[] = [];

  if (transactionType === 'transfer') {
    fields = [
      FieldFactory.belongsTo('account', 'accounts').withHelp('Generally a bank or credit card'),
    ];
  } else if (transactionType === 'expense') {
    fields = [
      FieldFactory.belongsTo('account', 'accounts').withLabel('Account (Category)').setRequired(),
      FieldFactory.belongsTo('vendor', 'vendors').withRequestParams({
        with: 'default_accounts',
      }),
    ];
  }

  return (
    <ClosableDrawer
      open={!!reviewing}
      onClose={() => setReviewing(null)}
      title="Create New Transaction"
    >
      {reviewing && (
        <List subheader="Creating Transaction For" sx={{ mb: 2 }}>
          <BankTransactionListItem transaction={reviewing} />
        </List>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReduxField
              field={FieldFactory.select('transaction_type', TRANSACTION_TYPES).setRequired()}
            />
          </Grid>

          {fields.length === 0 ? (
            <Grid item xs={12}>
              <Alert severity="warning">
                Creating a {_.startCase(transactionType)} is not supported through the banking
                module. Please create it manually, and then come back to match it.
              </Alert>
            </Grid>
          ) : (
            <>
              {fields.map((f) => (
                <Grid item xs={12} key={f.name}>
                  <ReduxField field={f} />
                </Grid>
              ))}
              <Grid item xs={12}>
                <ReduxField
                  field={FieldFactory.boolean('rule_create', 'Create rule using this transaction')}
                />
              </Grid>
              {ruleCreate && (
                <Grid item xs={12}>
                  <ReduxField
                    field={FieldFactory.boolean(
                      'rule_auto_create',
                      'Auto-create expenses if rule matches',
                    )}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Button
          sx={{ mt: 2 }}
          type="submit"
          variant="contained"
          disabled={submitting || fields.length === 0}
        >
          Create Transaction
        </Button>
      </form>
    </ClosableDrawer>
  );
}

export default reduxForm<FormShape, BankCreateTransactionDrawerProps>({
  form: 'BankReview',
  validate: (v) =>
    validateUsingRules(
      v,
      Joi.object({
        account: Joi.any().required(),
      }).unknown(),
    ),
})(BankCreateTransactionDrawer);
