import { ChangeEvent, useState } from 'react';
import {
  CircularProgress,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl,
  MenuItem,
  Select,
  Box,
  LinearProgress,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FieldFactory, numString, useDialogs } from '@/admin';
import ChooseKitItemsField from '@/components/Kitting/ChooseKitItemsField';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import OrderLabel from '@/components/Orders/OrderLabel';
import { requiredFields } from '@/helpers';
import { Kit, OrderKitItem } from '@/models';
import KitItemsActionMenu from './KitItemsActionMenu';
import OrderKitAddItemsDrawer from './OrderKitAddItemsDrawer';
import OrderKitItemRow from './OrderKitItemRow';

export default function OrderKitItems({ orderId, kit }: { orderId: number; kit?: Kit }) {
  const { prompt, confirm } = useDialogs();
  const [isAdding, setAdding] = useState<boolean>(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [fromOrder, setFromOrder] = useState(orderId);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: items = [],
    refetch,
    isFetching,
  } = useQuery(
    ['orderKitItems', orderId],
    () =>
      axios
        .get<{
          items: OrderKitItem[];
          kit_items: OrderKitItem[];
        }>(`/api/orders/${orderId}/kit-items`)
        .then(({ data }) => [...data.items, ...data.kit_items]),
    {
      onSuccess: (newItems) => {
        if (!newItems.some((i) => i.order_id === fromOrder)) {
          setFromOrder(newItems[0]?.order_id);
        }
      },
    },
  );

  const groupedItems = _.groupBy(items, 'order_id');

  const onUpdated = () => {
    refetch();
    queryClient.invalidateQueries(['kitsForAddress']);
  };

  const onAssign = async (item: OrderKitItem) => {
    if (!kit) {
      return;
    }

    let dropsOptions: string[][] = [];
    if (item.drops_count) {
      dropsOptions = await axios
        .get<{ data: string[][] }>(`/api/orders/${item.order_id}/items/${item.id}/drops`)
        .then(({ data }) => data.data);
    }
    prompt({
      title: 'Assign Qty to Selected Kit',
      fields: [
        FieldFactory.number('qty').withSize('medium'),
        item.drops_count
          ? FieldFactory.select(
              'drops',
              dropsOptions.map((arr, idx) => ({
                value: idx,
                label: arr.join(' / '),
              })),
            ).with({
              label: 'Name/Number',
              includeBlank: true,
            })
          : null,
      ],
      initialValues: {
        qty: 1,
        drops: 0,
      },
      onSubmit: (v) =>
        axios.post(
          '/api/kit-items',
          _.pickBy({
            kit_id: kit.id,
            order_item_id: item.id,
            qty: v.qty,
            drops: _.isInteger(v.drops) && dropsOptions.length > 0 ? dropsOptions[v.drops] : null,
          }),
        ),
    }).then(() => {
      onUpdated();
    });
  };

  const onBulkAssign = (item: OrderKitItem) => {
    prompt({
      title: 'Assign Qty to Each Kit',
      fields: [FieldFactory.number('qty').withSize('medium')],
      initialValues: {
        qty: 1,
      },
      onSubmit: (v) =>
        axios.post('/api/kit-items/bulk-add', {
          order_id: orderId,
          order_item_id: item.id,
          ...v,
        }),
    }).then(() => {
      onUpdated();
    });
  };

  const onRemove = (i: OrderKitItem) => {
    axios.delete(`/api/orders/${orderId}/kit-items/${i.id}`).then(() => onUpdated());
  };

  const onRemoveFromAll = (i: OrderKitItem) => {
    confirm('Remove Line Item from All Kits', 'Are you sure?').then(() => {
      axios
        .post('/api/kit-items/bulk-add', {
          order_id: orderId,
          order_item_id: i.id,
          qty: 0,
        })
        .then(() => onUpdated());
    });
  };

  const onSwitch = (i: OrderKitItem) => {
    prompt({
      title: 'Switch Assigned Qty to Different Line Item',
      description: (
        <div>
          <Typography variant="subtitle2">Switching From:</Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>{i.order_id}</TableCell>
                <TableCell>
                  <OrderItemProduct item={i} showSize />
                </TableCell>
                <TableCell>
                  {i.qty_assigned} / {i.qty}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="subtitle2" sx={{ mt: 3 }}>
            Switching To:
          </Typography>
        </div>
      ),
      maxWidth: 'lg',
      fields: [new ChooseKitItemsField('to_id', items)],
      validation: requiredFields(['to_id']),
      onSubmit: (v: { to_id: number }) =>
        axios.post('/api/kit-items/switch-item', {
          order_id: orderId,
          from_id: i.id,
          to_id: v.to_id,
        }),
    }).then(() => onUpdated());
  };

  const handleCheck = (i: OrderKitItem) => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected((prev) => [...prev, i.id]);
    } else {
      setSelected((prev) => prev.filter((id) => id !== i.id));
    }
  };

  const onBackorder = () => {
    prompt({
      title: `Create Kit Backorder for ${numString(selected.length, 'Items')}`,
      initialValues: {
        copy: true,
      },
      fields: [FieldFactory.boolean('copy', 'Keep original kit items and mark as backordered')],
      onSubmit: (v) =>
        axios.post(`/api/orders/${orderId}/kit-backorder`, { order_item_ids: selected, ...v }),
    }).then(({ data }) => {
      navigate(`/orders/${data.id}?tab=shipping`);
    });
  };

  let actions = null;

  if (selected.length > 0) {
    actions = <KitItemsActionMenu onBackorder={onBackorder} />;
  }

  return (
    <Card>
      <CardHeader title="Available Line Items" action={actions} />
      {isFetching && <LinearProgress />}
      {groupedItems ? (
        <>
          <Box p={2} display="flex" alignItems="center">
            <Typography variant="subtitle1">
              Items from Order <OrderLabel orderId={fromOrder}>#{fromOrder}</OrderLabel>
            </Typography>

            <Box ml="auto">
              <FormControl>
                <Select
                  labelId="from-order-label"
                  id="from-order"
                  value={fromOrder}
                  onChange={(e) => setFromOrder(Number(e.target.value))}
                >
                  {_.keys(groupedItems).map((oid) => (
                    <MenuItem key={oid} value={oid}>
                      Order #{oid}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {groupedItems[fromOrder] && (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell>Description</TableCell>
                    <TableCell>Alloc</TableCell>
                    <TableCell padding="checkbox" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedItems[fromOrder].map((i) => (
                    <OrderKitItemRow
                      key={i.id}
                      item={i}
                      orderId={orderId}
                      selectedKit={kit}
                      onSelect={handleCheck(i)}
                      isSelected={selected.indexOf(i.id) > -1}
                      onBulkAssign={_.partial(onBulkAssign, i)}
                      onAssign={_.partial(onAssign, i)}
                      onSwitch={_.partial(onSwitch, i)}
                      onRemove={_.partial(onRemove, i)}
                      onRemoveFromAll={_.partial(onRemoveFromAll, i)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      ) : (
        <CardContent>
          <CircularProgress />
        </CardContent>
      )}

      <CardActions>
        <Button color="secondary" onClick={() => setAdding(true)}>
          Add from Other Orders
        </Button>
      </CardActions>

      <OrderKitAddItemsDrawer open={isAdding} onClose={() => setAdding(false)} orderId={orderId} />
    </Card>
  );
}
