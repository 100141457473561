import { useEffect, useRef, useState } from 'react';
import { FilterList, Search as SearchIcon } from '@mui/icons-material';
import {
  Box,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  List,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { ButtonAction, getApiUrl, SubMenu } from '@/admin';
import Slack from '@/assets/slack.svg?react';
import { useNotificationContext } from '@/components/Notifications/NotificationProvider';
import NotificationListItem from './NotificationListItem';

const Container = styled('div')({
  width: 600,
  height: 600,
  maxWidth: '100%',
  maxHeight: '100%',
  overflowY: 'auto',
});

export default function NotificationList() {
  const {
    notifications,
    getNotifications,
    unreadOnly,
    onToggleUnreadOnly,
    query,
    setQuery,
    loading,
    onMarkRead,
  } = useNotificationContext();
  const [showInput, setShowInput] = useState<boolean>(false);
  const listRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = listRef.current;

    if (!element) {
      return;
    }

    const handleScroll = () => {
      if (element.scrollTop >= element.scrollHeight - element.offsetHeight - 20) {
        getNotifications();
      }
    };

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [getNotifications, listRef]);

  const unreadNotifications = notifications.filter((n) => !n.read_at);

  const toggleUnreadOnlyOption = (
    <Tooltip title="Filter By Unread">
      <IconButton onClick={onToggleUnreadOnly} size="large">
        <FilterList color={unreadOnly ? 'primary' : 'inherit'} />
      </IconButton>
    </Tooltip>
  );

  const menu = unreadNotifications.length > 0 && (
    <SubMenu
      items={[
        new ButtonAction('Mark All Read', () => onMarkRead(unreadNotifications.map((n) => n.id))),
      ]}
    />
  );

  return (
    <Container ref={listRef}>
      <CardHeader
        title="Latest Updates"
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <>
            {query || showInput ? (
              <Input
                autoFocus
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onBlur={() => setShowInput(false)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            ) : (
              <IconButton onClick={() => setShowInput(true)} size="large">
                <SearchIcon />
              </IconButton>
            )}
            <IconButton component="a" href={getApiUrl('/api/slack/redirect')} size="large">
              <Slack style={{ width: 20, height: 20 }} />
            </IconButton>
            {toggleUnreadOnlyOption}
            {menu}
          </>
        }
      />
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={36} />
        </Box>
      ) : (
        <>
          <List disablePadding>
            {(unreadOnly ? unreadNotifications : notifications).map((n) => (
              <NotificationListItem key={n.id} notification={n} />
            ))}
          </List>
          {notifications.length === 0 && (
            <CardContent style={{ paddingTop: 0 }}>
              <Typography color="textSecondary">You have no updates.</Typography>
            </CardContent>
          )}
        </>
      )}
    </Container>
  );
}
