import { FieldFactory, GroupLayout, Resource } from '@/admin';
import { Group } from '@/models';

export default function groups() {
  return new Resource<Group>('Groups')
    .setUseDrawer()
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.belongsTo('business', 'businesses'),
      new GroupLayout('settings', [
        FieldFactory.boolean('taggable', 'Taggable').filterable().sortable(),
      ]),
      FieldFactory.hasMany('users', 'users'),
    ]);
}
