import Joi from 'joi';
import { FieldFactory, Resource } from '@/admin';
import { ShippingMethod } from '@/models';

export default function shippingMethods() {
  return new Resource<ShippingMethod>('Shipping Methods')
    .setUseDrawer()
    .withValidation(
      Joi.object({
        name: Joi.string().required(),
      }).unknown(),
    )
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.number('sort').withHelp('Higher numbers will show up first').sortable(),
    ]);
}
