import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { curr, numString, TextLink } from '@/admin';
import OrderLabel from '@/components/Orders/OrderLabel';
import { Invoice } from '@/models';

export default function InvoiceTable({ invoices }: { invoices: Invoice[] }) {
  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>Invoice</TableCell>
          <TableCell>Due Date</TableCell>
          <TableCell align="right">Balance</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices.map((invoice) => (
          <TableRow key={invoice.id}>
            <TableCell>
              <Typography variant="body2">
                {invoice.source_type === 'order' ? (
                  <OrderLabel orderId={invoice.source_id}>{invoice.label}</OrderLabel>
                ) : (
                  <TextLink to={`/projects/${invoice.source_id}`}>{invoice.label}</TextLink>
                )}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {_.truncate(invoice.description, { length: 24 })}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{moment(invoice.due_date).format('L')}</Typography>
              <Typography variant="body2" color="textSecondary">
                {invoice.terms.name}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2">{curr(invoice.balance)}</Typography>
              <Typography variant="body2" color="textSecondary">
                {numString(invoice.days_past_due, 'days')} past due
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
