import { AlertColor } from '@mui/material';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDataTable } from '../components/DataTable/DataTableContext';
import { useAppContext, useShowLoading } from '../contexts/AppContext';
import Resource from '../models/Resource';
import {
  useOnReloadRecord,
  useMaybeCurrentResource,
  useUpdateRecord,
} from '../utils/genericResource';
import useDialogs, { UseDialogsResult } from './useDialogs';
import useFormActions, { FormActions } from './useFormActions';
import useToast from './useToast';

export interface OnClickProps extends FormActions {
  dialogs: UseDialogsResult;
  navigate: NavigateFunction;
  back?: string | null;
  toast: (message: string, status?: AlertColor) => void;
  setIsLoading: (l: boolean) => void;
  showLoading: <T>(p: Promise<T>) => Promise<T>;

  selected: (string | number)[];
  onReloadTable: () => void;

  resource?: Resource;
  form?: string;
  onReloadRecord: () => void;
  queryClient: QueryClient;
  updateRecord: <T>(updated: Partial<T>) => void;
}

export default function useOnClickProps(): OnClickProps {
  const { appBar, setIsLoading } = useAppContext();
  const showLoading = useShowLoading();
  const dialogs = useDialogs();
  const navigate = useNavigate();
  const toast = useToast();
  const formActions = useFormActions();
  const queryClient = useQueryClient();

  const { selected, onReload: onReloadTable } = useDataTable();

  const resource = useMaybeCurrentResource();
  const onReloadRecord = useOnReloadRecord();
  const updateRecord = useUpdateRecord();

  return {
    ...formActions,
    dialogs,
    navigate,
    back: appBar.back,
    toast,
    setIsLoading,
    showLoading,

    selected,
    onReloadTable,

    resource,
    form: resource?.getFormName(),
    onReloadRecord,

    queryClient,
    updateRecord,
  };
}
