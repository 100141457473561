import {
  Button,
  Grid,
  Typography,
  List,
  Card,
  CardHeader,
  CardContent,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import {
  numString,
  StatusChip,
  useFormActions,
  useFormValues,
  useGetResource,
  usePushWithContext,
  useOnReloadRecord,
  useShowLoading,
} from '@/admin';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import { PACKOUT_STATUS_COLOR } from '@/constants';
import { Order, Packout } from '@/models';
import NoteViewer from '../Notes/NoteViewer';
import OrderListItem from '../Orders/OrderListItem';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import PrintMenu from '../Print/PrintMenu';
import OrderKitsList from './OrderKitsList';

export default function PackoutPage() {
  const [id, order, status, scheduledAt, numUnproduced] = useFormValues((p: Packout) => [
    p.id,
    p.order,
    p.status,
    p.scheduled_at,
    p.unproduced_kit_items,
  ]);
  const { silentChange } = useFormActions();
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();
  const getResource = useGetResource();
  const queryClient = useQueryClient();

  const { data: otherOrders = [] } = useQuery(['otherOrders', order.id], () =>
    axios
      .get<{ data: Order[] }>(`/api/orders?in_kit=${order.id}&count=25`)
      .then(({ data }) => data.data),
  );

  const onSchedule = (date: string | null) => {
    axios.put(`/api/order-packouts/${id}`, { scheduled_at: date });
    silentChange('scheduled_at', date);
  };

  const performAction = (action: string) => {
    showLoading(axios.post(`/api/order-packouts/${id}/${action}`)).then(() => {
      onReload();
      queryClient.invalidateQueries(['kitsForOrder', order.id]);
    });
  };
  const start = () => performAction('start');
  const complete = () => performAction('complete');
  const reset = () => performAction('reset');
  const push = usePushWithContext(
    otherOrders.map((o) => ({
      key: o.id,
      label: getResource('orders').getTitle(o),
      href: `/orders/${o.id}`,
    })),
  );

  const renderForStatus = () => {
    if (status === 'Blocked') {
      return (
        <div>
          <Typography>The items in these kits are not ready to packout.</Typography>
          <Typography variant="body2" color="textSecondary">
            There are {numString(numUnproduced, 'items')} that are either unreceived or not produced
            yet.
          </Typography>
          <Button onClick={start}>Start Packout Anyways</Button>
        </div>
      );
    }

    if (status === 'Misconfigured') {
      return (
        <div>
          <Typography>
            Items have not been assigned to kits properly. Please check the kitting configuration
            for this order.
          </Typography>
          <Button onClick={start}>Start Packout Anyways</Button>
        </div>
      );
    }

    if (status === 'Ready') {
      return (
        <div>
          <Typography>All items are ready to pack out.</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Start the packout to generate bin numbers. This must be done before downloading
            paperwork.
          </Typography>
          <Button variant="contained" onClick={start}>
            Start Packout
          </Button>
        </div>
      );
    }

    if (status === 'Started') {
      return (
        <div>
          <Typography>The packout has started.</Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Download the paperwork below to begin the packout.
            <br />
            Click &quot;Complete&quot; when the packout is finished.
          </Typography>
          <Button variant="contained" onClick={complete}>
            Complete
          </Button>
          <Button onClick={reset} sx={{ mx: 1 }}>
            Reset
          </Button>
          <PrintMenu id={order.id} model="order" />
        </div>
      );
    }

    if (status === 'Completed') {
      return (
        <div>
          <Typography>The packout is complete.</Typography>
          <Button variant="contained" sx={{ mt: 2 }} onClick={reset}>
            Reset
          </Button>
        </div>
      );
    }

    if (status === 'Awaiting Approval') {
      return (
        <div>
          <Typography>The order is not approved.</Typography>
          <Button variant="contained" sx={{ mt: 2 }} onClick={reset}>
            Reset
          </Button>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <NoteViewer tag="packout" resource="orders" resourceId={order.id} />

      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <CardWithGutter>
            <CardContent style={{ textAlign: 'center' }}>
              <StatusChip
                status={status}
                colors={PACKOUT_STATUS_COLOR}
                style={{ marginBottom: 16, minWidth: 200 }}
              />
              {renderForStatus()}
            </CardContent>
          </CardWithGutter>

          <Card>
            <CardHeader title="Scheduling" />
            <CardContent>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Date"
                  value={scheduledAt}
                  onChange={onSchedule}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </CardContent>
            {otherOrders.length > 0 && (
              <>
                <CardHeader subheader="Dependent Orders" />
                <List>
                  {otherOrders.map((o) => (
                    <OrderListItem key={o.id} order={o} push={push} />
                  ))}
                </List>
              </>
            )}
          </Card>
        </Grid>

        <Grid item md={4} xs={12}>
          <OrderKitsList orderId={order.id} />
        </Grid>

        <Grid item md={4} xs={12}>
          <OrderSummaryCard order={order} />
        </Grid>
      </Grid>
    </>
  );
}
