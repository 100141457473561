import { ReactNode } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

export default function OrderEmail({
  children,
  orderStatePath,
}: {
  children?: ReactNode;
  orderStatePath: string;
}) {
  const order = useSelector((s) => _.get(s, orderStatePath));
  if (!children || !order) {
    return <span>{children}</span>;
  }

  const query = new URLSearchParams({
    subject: `Your ${order.business.name} Order #${order.increment_id}`,
    body: `Hi ${order.contact_first_name || 'there'},\n\nYou can view your order here: ${
      order.public_url
    }`,
  });
  const mailTo = `mailto:${children}?${query.toString()}`;

  return (
    <a
      href={mailTo}
      onClick={(e) => e.stopPropagation()}
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      {children}
    </a>
  );
}
