import Joi from 'joi';
import { FieldFactory, Resource } from '@/admin';

export default function tokens() {
  return new Resource('API Tokens')
    .withValidation(
      Joi.object({
        name: Joi.string().required(),
      }).unknown(),
    )
    .withFieldsAndColumns([FieldFactory.text('name')]);
}
