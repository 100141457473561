import { Add } from '@mui/icons-material';
import {
  Box,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  FieldFactory,
  StatusChip,
  useDialogs,
  useOnReloadRecord,
  useQueryStringState,
} from '@/admin';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import { ISSUE_STATUS_COLORS } from '@/constants';
import { Issue } from '@/models';
import useMutateQueryData from '@/utils/useMutateQueryData';
import IssueDialog from './IssueDialog';

export default function IssuesCard({
  resource,
  resourceId,
  createType,
}: {
  resource: string;
  resourceId: number | string;
  createType?: Issue['type'];
}) {
  const [selectedIssue, setSelectedIssue] = useQueryStringState('selected_issue');
  const onReload = useOnReloadRecord();
  const { prompt } = useDialogs();

  const setIssues = useMutateQueryData<Issue[]>(['issues', resource, resourceId]);
  const {
    data: issues = [],
    isLoading,
    refetch,
  } = useQuery(['issues', resource, resourceId], () =>
    axios
      .get<{ data: Issue[] }>(`/api/${resource}/${resourceId}/issues`)
      .then(({ data }) => data.data),
  );

  const handleUpdate = (updated: Issue) => {
    setIssues((prev) =>
      prev.map((i) => {
        if (i.id === updated.id) {
          return { ...i, ...updated };
        }
        return i;
      }),
    );
    onReload();
  };

  const handleCreate = () => {
    prompt({
      title: 'Create Issue',
      fields: [FieldFactory.textarea('details')],
      onSubmit: (v: { details: string }) =>
        axios.post(`/api/${resource}/${resourceId}/issues`, { ...v, type: createType }),
    }).then(() => {
      refetch();
    });
  };

  const action = createType ? (
    <IconButton onClick={handleCreate} size="large">
      <Add />
    </IconButton>
  ) : null;

  return (
    <CardWithGutter>
      <CardHeader title="Issues" action={action} />

      {!isLoading && issues.length === 0 && (
        <Box color="text.secondary" px={2}>
          There are no issues.
        </Box>
      )}

      {!isLoading ? (
        <List>
          {issues.map((issue) => (
            <ListItemButton key={issue.id} onClick={() => setSelectedIssue(String(issue.id))}>
              <ListItemText primary={issue.label} secondary={issue.preview} />
              <ListItemSecondaryAction>
                <StatusChip status={issue.status} colors={ISSUE_STATUS_COLORS} />
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      ) : (
        <CardContent>
          <CircularProgress />
        </CardContent>
      )}

      <IssueDialog
        open={!!selectedIssue}
        onClose={() => setSelectedIssue(undefined)}
        issue={issues.find((i) => i.id === Number(selectedIssue))}
        onUpdate={handleUpdate}
      />
    </CardWithGutter>
  );
}
