import { ReactNode, useEffect, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Button, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import { FormSubmitHandler, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import {
  ClosableDrawer,
  EditFormFields,
  Fieldable,
  FieldFactory,
  GridLayout,
  GroupLayout,
  SelectField,
  stopProp,
  transformLaravelErrors,
  useDialogs,
  useGetResource,
  useSpecificFormValues,
  validateUsingRules,
} from '@/admin';
import FieldsetLayout from '@/admin/models/Layouts/FieldsetLayout';
import AddressBlock from '@/components/Addresses/AddressBlock';
import { GOOGLE_MAPS_KEY } from '@/constants';
import { useConfig } from '@/contexts/AppConfigContext';
import { requiredFields } from '@/helpers';
import { Address } from '@/models';
import { ADDRESS_VALIDATION } from '@/resources/addresses';
import { AddressValidation } from '@/responses';
import GoogleAutocomplete from './GoogleAutocomplete';

import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

const componentForm: Record<string, 'short_name' | 'long_name'> = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'short_name',
  postal_code: 'short_name',
};

const getFromComponents = (components: GeocoderAddressComponent[], types: string[]) =>
  components
    .filter((i) => i.types.some((type) => types.includes(type)))
    .map((i) => i[componentForm[i.types[0]]])
    .join(' ');

const toAddress = (components: GeocoderAddressComponent[]) => ({
  address_1: getFromComponents(components, ['street_number', 'route']),
  city:
    getFromComponents(components, ['locality']) ||
    getFromComponents(components, ['administrative_area_level_2']),
  state: getFromComponents(components, ['administrative_area_level_1']),
  zip: getFromComponents(components, ['postal_code']),
  country: getFromComponents(components, ['country']),
});

interface AddressDrawerProps {
  open: boolean;
  onClose: () => void;
  initialValues: Partial<Address>;
  excludeFields: string[];
  onSubmit: (v: Partial<Address>) => Promise<void>;
  title?: string;
  description?: ReactNode;
  showServiceLevel?: boolean;
}

function AddressDrawer({
  open,
  onClose,
  initialValues,
  handleSubmit,
  initialize,
  change,
  excludeFields = [],
  submitting,
  onSubmit: onSubmitProp,
  title,
  description,
  showServiceLevel,
}: InjectedFormProps<Partial<Address>, AddressDrawerProps> & AddressDrawerProps) {
  const { states, shippoCarrierAccounts } = useConfig();
  const { prompt } = useDialogs();
  const country = useSpecificFormValues('AddressForm', (s) => s.country as string | undefined);
  const hasStates = country && !_.isEmpty(states[country]);
  const getResource = useGetResource();
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [acValue, setAcValue] = useState<AutocompletePrediction | null>(null);
  const placeId = acValue?.place_id;

  useEffect(() => {
    if (open) {
      initialize({
        country: 'US',
        ..._.pickBy(initialValues),
      });
      setIsAutocomplete(!initialValues.address_1);
      setAcValue(null);
    }
  }, [open]);

  useEffect(() => {
    if (!hasStates) {
      change('state', null);
    }
  }, [hasStates]);

  useEffect(() => {
    if (placeId) {
      const places = new google.maps.places.PlacesService(document.createElement('div'));
      places.getDetails(
        {
          placeId,
          fields: ['address_components', 'name', 'formatted_phone_number'],
        },
        (result) => {
          if (!result || !result.address_components) {
            return;
          }
          const { address_components: components, name, formatted_phone_number: phone } = result;
          initialize({
            name,
            phone,
            ...toAddress(components),
          });
          setIsAutocomplete(false);
        },
      );
    }
  }, [placeId]);

  const onSubmitToParent = (values: Partial<Address>) =>
    onSubmitProp(values).catch((e) => {
      const errors = transformLaravelErrors(_.get(e, 'response.data.errors', {}));
      throw new SubmissionError(errors);
    });

  const onSubmit: FormSubmitHandler<Partial<Address>, AddressDrawerProps> = async (values) => {
    try {
      const { data } = await axios.post<AddressValidation>('/api/addresses/validate', values);
      const { validation_results: validation, address: validatedAddress } = data;
      if (validation.is_valid && validation.messages.length === 0) {
        return onSubmitToParent({
          ...values,
          is_residential: validatedAddress.is_residential,
        });
      }

      const corrected = { ...values, ...data.address };

      const { useCorrect } = await prompt({
        title: 'Potential Address Issue',
        description: (
          <div>
            {validation.messages.map((m) => (
              <Typography key={m.text} gutterBottom variant="body2" color="textSecondary">
                {m.text}
              </Typography>
            ))}
          </div>
        ),
        fields: [
          FieldFactory.radio('useCorrect', {
            yes: (
              <div>
                <Typography gutterBottom variant="subtitle1">
                  Use Corrected Address:
                </Typography>
                <AddressBlock address={corrected} sx={{ mb: 2 }} />
              </div>
            ),
            no: (
              <div>
                <Typography gutterBottom variant="subtitle1">
                  Continue With Entered Address:
                </Typography>
                <AddressBlock address={values as Address} />
              </div>
            ),
          }).withoutLabel(),
        ],
        validation: requiredFields(['useCorrect']),
      });

      return onSubmitToParent(useCorrect === 'yes' ? corrected : values);
    } catch (e) {
      if (e instanceof AxiosError && e.response?.data.errors) {
        throw new SubmissionError(transformLaravelErrors(e.response.data.errors));
      }
    }
  };

  const fields = getResource('addresses')
    .fields.filter((i) => !excludeFields.includes(i.name))
    .map((i) => {
      if (i instanceof GroupLayout) {
        const layout = _.clone(i);
        layout.fields = layout.fields.filter((f) => !excludeFields.includes(f.name));
        return layout;
      }
      return i;
    })
    .map((f) => {
      if (f.name === 'state' && f instanceof SelectField) {
        if (hasStates) {
          const countryStates = states[country];
          return new SelectField(
            f.name,
            _.mapValues(_.keyBy(countryStates, 'code'), (s) => `${s.name} (${s.code})`),
          );
        }
        return null;
      }
      return f;
    })
    .filter((f) => !!f) as Fieldable[];

  if (showServiceLevel) {
    const options: Record<string, string> = {};
    shippoCarrierAccounts.forEach((a) => {
      Object.assign(options, a.service_levels);
    });

    fields.push(
      new FieldsetLayout('Fulfillment Options', [
        FieldFactory.select('service_level', options),
        FieldFactory.text('third_party_account'),
      ]).withColumnSpan(12),
    );
  }

  return (
    <ClosableDrawer
      title={title || (initialValues?.id ? 'Edit Address' : 'Add New Address')}
      width={650}
      open={open}
      onClose={onClose}
    >
      {description}
      <form onSubmit={stopProp(handleSubmit)(onSubmit)}>
        {isAutocomplete ? (
          <Wrapper apiKey={GOOGLE_MAPS_KEY} libraries={['places']}>
            <div>
              <GoogleAutocomplete value={acValue} setValue={setAcValue} />
              <Button
                type="button"
                size="small"
                sx={{ mt: 0.5 }}
                onClick={() => setIsAutocomplete(false)}
              >
                Manually Enter Address
              </Button>
            </div>
          </Wrapper>
        ) : (
          <EditFormFields fields={fields} defaultLayout={GridLayout} />
        )}

        <Button type="submit" variant="contained" sx={{ mt: 3 }} disabled={submitting}>
          Submit
        </Button>
      </form>
    </ClosableDrawer>
  );
}

export default reduxForm<Partial<Address>, AddressDrawerProps>({
  form: 'AddressForm',
  validate: (values) => validateUsingRules(values, ADDRESS_VALIDATION),
})(AddressDrawer);
