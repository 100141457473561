import { useState } from 'react';
import { Button } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { Field, FieldProps, GenericField, RelationField } from '@/admin';
import { Product } from '@/models';
import ProductFinderDialog from './ProductFinderDialog';

function ProductInput({ input, field, meta }: FieldProps<ProductField>) {
  const [isAdvanced, setIsAdvanced] = useState(false);

  const search = () => setIsAdvanced(true);

  const onProduct = (product: Product) => {
    setIsAdvanced(false);
    input.onChange(product);
  };

  return (
    <div>
      <GenericField
        input={input}
        meta={meta}
        field={new RelationField(field.name, 'products').with({
          requestParams: field.requestParams,
        })}
      />

      <Button size="small" onClick={search}>
        Advanced Search
      </Button>

      <ProductFinderDialog
        open={isAdvanced}
        mode="product"
        onSuccess={onProduct}
        onCancel={() => setIsAdvanced(false)}
      />
    </div>
  );
}

export default class ProductField extends Field {
  requestParams: Record<string, any> = {};

  renderEditComponent(props: WrappedFieldProps) {
    return <ProductInput {...props} field={this} />;
  }
}
