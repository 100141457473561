import { TableCell, styled } from '@mui/material';

const DescriptionCell = styled(TableCell)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: 300,
});

export default DescriptionCell;
