import { useCallback } from 'react';
import { useAdminConfig } from '../contexts/AdminConfigContext';
import Resource from '../models/Resource';

export default function useGetResource() {
  const { resources } = useAdminConfig();
  return useCallback(
    (resourceName: string, throwIfNotFound = true): Resource => {
      const res = resources[resourceName];

      if (!res && throwIfNotFound) {
        throw new Error(`Resource (${resourceName}) not found`);
      }
      return res;
    },
    [resources],
  );
}

export function useResource(resource: string): Resource {
  return useGetResource()(resource);
}
