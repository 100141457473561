import { ChangeEvent } from 'react';
import {
  Close,
  ImportExport,
  MenuOpen,
  PlaylistAdd,
  RemoveCircleOutline,
} from '@mui/icons-material';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { ButtonAction, SubMenu } from '@/admin';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import { Kit, OrderKitItem } from '@/models';

export default function OrderKitItemRow({
  item: i,
  orderId,
  onSelect,
  isSelected,
  selectedKit,
  onAssign,
  onBulkAssign,
  onSwitch,
  onRemoveFromAll,
  onRemove,
}: {
  item: OrderKitItem;
  orderId: number;
  isSelected: boolean;
  selectedKit?: Kit;
  onSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  onAssign: (i: OrderKitItem) => void;
  onBulkAssign: (i: OrderKitItem) => void;
  onSwitch: (i: OrderKitItem) => void;
  onRemoveFromAll: (i: OrderKitItem) => void;
  onRemove: (i: OrderKitItem) => void;
}) {
  const fromOtherOrder = i.order_id !== orderId;

  return (
    <TableRow key={i.id}>
      <TableCell padding="checkbox">
        <Checkbox size="small" checked={isSelected} onChange={onSelect} />
      </TableCell>
      <TableCell>
        <OrderItemProduct item={i} showSize />
      </TableCell>
      <TableCell>
        {i.qty_assigned} / {i.qty}
      </TableCell>
      <TableCell padding="checkbox">
        <SubMenu
          size="small"
          items={[
            selectedKit &&
              new ButtonAction('Assign Qty to Selected Kit', () => onAssign(i))
                .withIcon(MenuOpen)
                .setDisabled(i.qty === i.qty_assigned),
            i.drops_count === 0 &&
              new ButtonAction('Assign Qty to Each Kit', () => onBulkAssign(i)).withIcon(
                PlaylistAdd,
              ),
            i.qty_assigned > 0 &&
              new ButtonAction('Switch to Different Item', () => onSwitch(i)).withIcon(
                ImportExport,
              ),
            new ButtonAction('Remove from Each Kit', () => onRemoveFromAll(i)).withIcon(
              RemoveCircleOutline,
            ),
            fromOtherOrder &&
              new ButtonAction('Remove Line Item', () => onRemove(i)).withIcon(Close),
          ]}
        />
      </TableCell>
    </TableRow>
  );
}
