import { ArrowForward } from '@mui/icons-material';
import { Card, CardHeader, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { StatusChip } from '@/admin';
import { WEBSTORE_ORDER_STATUS_COLORS } from '@/constants';
import { FulfillmentOrder } from '@/models';

export default function FulfillmentOrderCard({ order }: { order: FulfillmentOrder }) {
  return (
    <Card>
      <CardHeader
        title={order.number}
        action={
          <IconButton component={Link} to={`/fulfillment-orders/${order.id}`}>
            <ArrowForward />
          </IconButton>
        }
      />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Customer</TableCell>
            <TableCell>{order.customer?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Webstore</TableCell>
            <TableCell>{order.webstore_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Status</TableCell>
            <TableCell>
              <StatusChip status={order.status} colors={WEBSTORE_ORDER_STATUS_COLORS} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}
