import { Tooltip } from '@mui/material';
import { formatNumberWithTitle, Formattable, StatFormat } from '../../utils/formatNumber';

export interface StatCardNumberProps {
  number: Formattable;
  format: StatFormat;
}

export default function StatCardNumber({ number, format }: StatCardNumberProps) {
  const res = formatNumberWithTitle(number, format);

  if (!res.title) {
    return <span>{res.number}</span>;
  }
  return (
    <Tooltip title={res.title}>
      <span>{res.number}</span>
    </Tooltip>
  );
}
