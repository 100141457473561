import _ from 'lodash';
import { abscomp } from '@/helpers';
import { OrderItem, ShippingMode } from '@/models';

export interface SelectedItem {
  id: number;
  qty_shipped: number;
  order_item_id: number;
}

export const orderItemToSelectedItem = (i: OrderItem): SelectedItem => ({
  id: i.id,
  qty_shipped: i.qty - i.qty_scrapped - i.qty_shipped,
  order_item_id: i.id,
});

export const canShipOrderItem = (i: OrderItem) =>
  abscomp(i.qty_shipped, '<', i.qty - i.qty_scrapped);

export const getKitQty = (
  selected: SelectedItem[],
  shippableItems: OrderItem[],
): number | { item: string; reason: string } => {
  const error = {
    item: '',
    reason:
      'does not have the right quantities or quantity per kit to create a whole number of kits',
  };
  const groupedItems = _.groupBy(shippableItems, 'inventory_variant_id');
  const kits = Object.entries(groupedItems).map(([key, value]) => {
    const chosenOne = _.sortBy(value, [
      (selectedItem) => {
        const match = selected.find((s) => s.id === selectedItem.id)!;
        const qty = match.qty_shipped;
        const qtyPerKit = selectedItem.qty_per_kit || 1;
        return Math.floor(qty / qtyPerKit);
      },
    ])[0];

    const { qty_shipped } = selected.find((s) => s.id === chosenOne.id)!;
    const { qty_per_kit } = chosenOne;

    if (qty_shipped % (qty_per_kit || 1) > 0) {
      error.item = (chosenOne.variant?.product.name || chosenOne.description).toUpperCase();
      return null;
    }

    return qty_shipped / (qty_per_kit || 1);
  });

  return kits.indexOf(null) > -1
    ? error
    : (kits as number[]).reduce((partialSum, a) => partialSum + a, 0);
};

export const isInventoryKit = (mode: ShippingMode): boolean => mode === 'inventory_kit';

export const isInventory = (mode: ShippingMode): boolean =>
  ['inventory', 'inventory_kit'].includes(mode);
