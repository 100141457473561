import { Grid, styled } from '@mui/material';
import { ReduxField, DrawerFormSection, FieldFactory } from '@/admin';

export const UseDatabaseAdornment = styled('div')(({ theme }) => ({
  width: 160,
  fontSize: theme.typography.body2.fontSize,
  '& .MuiFormControlLabel-root': {
    marginRight: 0,
  },
}));

export default function VendorSection({
  isCustom,
  useDatabaseCost = false,
  hideCost = false,
  title = 'Vendor & Cost',
}: {
  isCustom: boolean;
  useDatabaseCost?: boolean;
  hideCost?: boolean;
  title?: string;
}) {
  return (
    <DrawerFormSection title={title}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReduxField field={FieldFactory.belongsTo('vendor', 'vendors').setDisabled(!isCustom)} />
        </Grid>
        {!hideCost && (
          <Grid item xs={12}>
            <ReduxField
              field={FieldFactory.curr('cost')
                .setDisabled(!isCustom && useDatabaseCost)
                .with({
                  maxDecimals: 4,
                  postfix: isCustom ? undefined : (
                    <UseDatabaseAdornment>
                      <ReduxField
                        field={FieldFactory.boolean('use_database_cost', 'Use Default').withSize(
                          'small',
                        )}
                      />
                    </UseDatabaseAdornment>
                  ),
                })}
            />
          </Grid>
        )}
      </Grid>
    </DrawerFormSection>
  );
}
