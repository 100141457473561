import axios from 'axios';
import _ from 'lodash';
import { useDialogs, TextButton, curr, FieldFactory, useHasPermission } from '@/admin';
import { TotalLabel, TotalTable, TotalValue } from '@/components/Shared/Label';
import { Account, PurchaseOrder } from '@/models';

export default function PurchaseOrderTotals({
  purchaseOrder,
  onUpdated,
}: {
  purchaseOrder: PurchaseOrder;
  onUpdated: (p: Partial<PurchaseOrder>) => void;
}) {
  const { prompt } = useDialogs();
  const hasPermission = useHasPermission();
  const {
    shipping,
    tax,
    discount,
    fee: cachedFee,
    fee_percent: feePercent,
    subtotal: cachedSubtotal,
    total: cachedTotal,
    id,
  } = purchaseOrder;

  const onEdit = (field: 'shipping' | 'tax') => () => {
    prompt({
      title: `Edit ${_.startCase(field)}`,
      fields: [FieldFactory.curr(field).withSize('medium')],
      initialValues: _.pick(purchaseOrder, field),
      onSubmit: (v) => axios.put(`/api/purchase-orders/${id}`, v),
    }).then(({ data }) => {
      onUpdated(_.pick(data, ['shipping', 'tax', 'discount', 'fee', 'subtotal', 'total']));
    });
  };

  const onEditFee = () => {
    prompt({
      title: 'Edit Vendor Fee',
      fields: [
        // eslint-disable-next-line react-hooks/rules-of-hooks
        FieldFactory.useDefault('fee_override', FieldFactory.curr('fee_override')).with({
          useDefaultLabel: `Use Default Fee: ${feePercent || 0}%`,
        }),
      ],
      initialValues: {
        fee_override: purchaseOrder.fee_override,
      },
      onSubmit: (v) => axios.put(`/api/purchase-orders/${id}`, v),
    }).then(({ data }) => {
      onUpdated(_.pick(data, ['fee', 'fee_override', 'total']));
    });
  };

  const onEditDiscount = () => {
    prompt<{
      discount: number;
      discount_gl_account: Account | null;
      fee_include_discount: boolean;
    }>({
      title: 'Edit Discount',
      fields: [
        FieldFactory.curr('discount').withSize('medium'),
        FieldFactory.belongsTo('discount_gl_account', 'accounts'),
        FieldFactory.boolean('fee_include_discount', 'Include discount in fee calculation'),
      ],
      initialValues: _.pick(purchaseOrder, [
        'discount',
        'fee_include_discount',
        'discount_gl_account',
      ]),
      onSubmit: (v) => axios.put(`/api/purchase-orders/${id}`, v),
    }).then(({ data }) => {
      onUpdated(_.pick(data, ['discount', 'fee_include_discount', 'discount_gl_account', 'total']));
    });
  };

  return (
    <TotalTable>
      <tbody>
        <tr>
          <TotalLabel>Subtotal</TotalLabel>
          <TotalValue>{curr(cachedSubtotal)}</TotalValue>
        </tr>
        <tr>
          <TotalLabel>Shipping</TotalLabel>
          <TotalValue>
            <TextButton
              onClick={onEdit('shipping')}
              disabled={!hasPermission('write:purchase_orders')}
            >
              {curr(shipping || 0)}
            </TextButton>
          </TotalValue>
        </tr>
        <tr>
          <TotalLabel>Fees</TotalLabel>
          <TotalValue>
            <TextButton onClick={onEditFee} disabled={!hasPermission('write:purchase_orders')}>
              {curr(cachedFee)}
            </TextButton>
          </TotalValue>
        </tr>
        <tr>
          <TotalLabel>Tax</TotalLabel>
          <TotalValue>
            <TextButton onClick={onEdit('tax')} disabled={!hasPermission('write:purchase_orders')}>
              {curr(tax || 0)}
            </TextButton>
          </TotalValue>
        </tr>
        <tr>
          <TotalLabel>Discount</TotalLabel>
          <TotalValue>
            <TextButton onClick={onEditDiscount} disabled={!hasPermission('write:purchase_orders')}>
              {curr(discount)}
            </TextButton>
          </TotalValue>
        </tr>
        <tr>
          <TotalLabel>Total</TotalLabel>
          <TotalValue>{curr(cachedTotal)}</TotalValue>
        </tr>
      </tbody>
    </TotalTable>
  );
}
