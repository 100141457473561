import { ChangeEvent } from 'react';
import { Grid, Button, Typography, Box, FormControlLabel, Checkbox } from '@mui/material';
import { Field, getValueFromEvent, DrawerFormSection, NonReduxField, FieldFactory } from '@/admin';
import { CustomsDeclaration, CustomsItem } from '@/responses';

export const DEFAULT_CUSTOMS: CustomsDeclaration = {
  incoterm: 'DDP',
  items: [{}],
};

const ITEMS: Field[] = [
  FieldFactory.text('description').withPlaceholder('e.g. T-shirt'),
  FieldFactory.number('quantity'),
  FieldFactory.number('net_weight').with({
    label: 'Weight',
    postfix: 'lbs',
  }),
  FieldFactory.curr('value_amount').withLabel('Value'),
];

export default function CustomsDeclarationBuilder({
  customs,
  onChange,
}: {
  customs: CustomsDeclaration | null;
  onChange: (v: CustomsDeclaration | null) => void;
}) {
  const show = customs != null;

  const handleItemChange =
    (index: number, name: keyof CustomsItem) => (e: ChangeEvent<HTMLInputElement>) => {
      if (!customs) {
        return;
      }
      const eventValue = getValueFromEvent(e);
      onChange({
        ...customs,
        items: (customs.items || []).map((i, idx) => {
          if (idx === index) {
            return {
              ...i,
              [name]: eventValue,
            };
          }
          return i;
        }),
      });
    };

  const addItem = () => {
    if (!customs) {
      return;
    }
    onChange({
      ...customs,
      items: [...customs.items, {}],
    });
  };

  const removeItem = () => {
    if (!customs) {
      return;
    }
    onChange({
      ...customs,
      items: customs.items.filter((item, i) => i !== customs.items.length - 1),
    });
  };

  return (
    <Box mb={2}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={show}
            onChange={(e) => onChange(e.target.checked ? DEFAULT_CUSTOMS : null)}
          />
        }
        label="Create Customs Declaration"
      />

      {show && customs && (
        <DrawerFormSection title="Customs">
          <NonReduxField
            value={customs.incoterm}
            onChange={(e) =>
              onChange({
                ...customs,
                incoterm: e.target.value,
              })
            }
            field={FieldFactory.select('incoterm', {
              DDP: 'Delivery Duty Paid (DDP)',
              DDU: 'Delivery Duty Unpaid (DDU)',
            })}
          />

          {customs.items.map((item, idx) => (
            <div key={idx}>
              <Typography
                color="textSecondary"
                variant="overline"
                component="div"
                sx={{
                  mb: -1.5,
                  mt: 1,
                }}
              >
                Item #{idx + 1}
              </Typography>
              <Grid container spacing={1}>
                {ITEMS.map((field) => (
                  <Grid item xs={6} key={field.name}>
                    <NonReduxField
                      field={field}
                      value={item[field.name as keyof CustomsItem]}
                      onChange={handleItemChange(idx, field.name as keyof CustomsItem)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
          <Box display="flex">
            {customs.items.length > 1 && (
              <Button size="small" onClick={removeItem}>
                Remove
              </Button>
            )}
            <Button size="small" onClick={addItem} sx={{ ml: 'auto' }}>
              Add
            </Button>
          </Box>
        </DrawerFormSection>
      )}
    </Box>
  );
}
