import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { WrappedFieldInputProps } from 'redux-form';
import { ArrayBullets } from '@/admin';
import SkuLabel from '@/components/Products/SkuLabel';
import { ProductVariant } from '@/models';
import InventoryText from './InventoryText';
import ProductFinderDialog from './ProductFinderDialog';

export default function VariantField({ input }: { input: WrappedFieldInputProps }) {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const variant = input.value;

  const search = () => setIsAdvanced(true);

  const onProduct = (product: ProductVariant) => {
    setIsAdvanced(false);
    input.onChange(product);
  };

  return (
    <div>
      {variant ? (
        <SkuLabel variant={variant}>
          <Typography variant="body2" color="textSecondary">
            <ArrayBullets
              elements={[variant.product.style_number, variant.product.color_code, variant.size]}
            />
          </Typography>
          <InventoryText variant={input.value} />
        </SkuLabel>
      ) : (
        <Typography color="textSecondary">No SKU Selected</Typography>
      )}

      <Button size="small" onClick={search} style={{ marginTop: 8 }}>
        {variant ? 'Change' : 'Choose'} SKU
      </Button>

      <ProductFinderDialog
        open={isAdvanced}
        mode="variant"
        onSuccess={onProduct}
        onCancel={() => setIsAdvanced(false)}
      />
    </div>
  );
}
