import { useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { GenericField } from '../../components/Form/ReduxField';
import useOnChangeEffect from '../../hooks/useOnChangeEffect';
import { FieldProps } from '../../types';
import Field from '../Field';

function UseDefault({ input, meta, field }: FieldProps<UseDefaultField>) {
  const [useDefault, setUseDefault] = useState(!input.value);

  const handleUseDefaultChange = (bool: boolean) => {
    setUseDefault(bool);
    if (bool) {
      input.onChange(null);
    } else if (!input.value && field.defaultValue) {
      input.onChange(field.defaultValue);
    }
  };

  useOnChangeEffect(() => {
    setUseDefault(!input.value);
  }, [input.value]);

  return (
    <div>
      {!useDefault && <GenericField input={input} meta={meta} field={field.subField} />}

      <FormControlLabel
        label={
          <Typography variant={field.size === 'small' ? 'body2' : 'body1'}>
            {field.useDefaultLabel}
          </Typography>
        }
        control={
          <Checkbox
            size={field.size}
            checked={useDefault}
            onChange={(e) => handleUseDefaultChange(e.target.checked)}
          />
        }
      />
    </div>
  );
}

export default class UseDefaultField extends Field {
  subField: Field;
  useDefaultLabel = 'Use Default';
  defaultValue?: any;

  constructor(name: string, subField: Field) {
    super(name);
    this.subField = subField;
  }

  renderEditComponent(props: WrappedFieldProps) {
    return <UseDefault {...props} field={this} />;
  }

  renderCell(value: any, row: Record<string, any>) {
    return this.subField.renderCell(value, row);
  }
}
