import { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useDialogs } from '@/admin';
import { mentionHighlighter } from '@/utils/notes';
import UserAvatar from './UserAvatar';

export default function NoteRenderer({ note }: { note: string }) {
  const { alert } = useDialogs();

  const onUser = useCallback(
    (identifier: string) => {
      const [type, id] = identifier.split(':');
      if (type === 'user') {
        axios.get(`/api/users/${id}`).then(({ data }) => {
          alert(
            'View User',
            <Box display="flex" alignItems="center">
              <UserAvatar user={data} sx={{ width: 88, height: 88, marginRight: 3 }} />
              <Box flexGrow="1">
                <Typography variant="h6">{data.name}</Typography>
                {data.title && <Typography variant="subtitle1">{data.title}</Typography>}
                <Typography variant="body2" color="primary">
                  <a href={`mailto:${data.email}`}>{data.email}</a>
                </Typography>
              </Box>
            </Box>,
          );
        });
      }
    },
    [alert],
  );

  const remarkPlugins = [remarkGfm];
  const rehypePlugins = [mentionHighlighter(onUser)];

  // Add two spaces before every single line break
  const markdown = note ? note.replaceAll(/([^\n])\n([^\n])/g, '$1  \n$2') : '';

  return (
    <Markdown
      remarkPlugins={remarkPlugins}
      rehypePlugins={rehypePlugins}
      components={{
        a: (props) => <a {...props} target="_blank" rel="noreferrer" />,
      }}
    >
      {markdown}
    </Markdown>
  );
}
