import { useState, useMemo, useEffect, useRef } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Autocomplete, TextField, Grid, Typography } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import _ from 'lodash';

import AutocompletePrediction = google.maps.places.AutocompletePrediction;

export default function GoogleAutocomplete({
  value,
  setValue,
}: {
  value: AutocompletePrediction | null;
  setValue: (v: AutocompletePrediction | null) => void;
}) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<AutocompletePrediction[]>([]);
  const autocompleteService = useRef<google.maps.places.AutocompleteService>(
    new google.maps.places.AutocompleteService(),
  );

  const fetch = useMemo(
    () =>
      _.throttle(
        (
          request: google.maps.places.AutocompletionRequest,
          callback: (results: AutocompletePrediction[] | null) => void,
        ) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        },
        200,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
      },
      (results: AutocompletePrediction[] | null) => {
        if (active && results) {
          let newOptions: AutocompletePrediction[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete<AutocompletePrediction>
      id="google-map-autocomplete"
      getOptionLabel={(option) => option.description}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search for Address" variant="outlined" fullWidth />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        if (!matches) {
          return null;
        }
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
