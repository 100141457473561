import { useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import useGetResource from '../hooks/useGetResource';
import DateRangeField from '../models/Fields/DateRangeField';
import RelationField from '../models/Fields/RelationField';
import Resource from '../models/Resource';
import { DataTableState } from '../types';
import wrap from '../utils/wrap';

const toDateString = (v: any) => moment(v).toISOString();
const isEmpty = (v: any) => v == null || v === '';

export default function useGetParamsForIndexRequest() {
  const getResource = useGetResource();

  return useCallback(
    (resource: Resource, state: Partial<DataTableState>, query: string) => {
      const fromState = _.pick(state, ['count', 'page', 'sort']);

      const filters = _.chain(state.filters)
        .mapValues((value, fieldName) => {
          const field = resource
            .getFilterableFields()
            .find((f) => f.getNameForFilterForm() === fieldName);
          if (!field) {
            return value;
          }

          if (field instanceof RelationField) {
            const pk = getResource(field.resource).primaryKey;
            return wrap(value)
              .map((v) => v[pk])
              .join(',');
          }

          if (field instanceof DateRangeField) {
            if (Array.isArray(value)) {
              return _.take(value, 2)
                .filter((v) => !!v)
                .map(toDateString)
                .join(',');
            }
          }

          if (Array.isArray(value)) {
            return value.join(',');
          }
          return value;
        })
        .mapKeys((value, fieldName) => {
          const field = resource
            .getFilterableFields()
            .find((f) => f.getNameForFilterForm() === fieldName);

          return field?.getFilterKey() || fieldName;
        })
        .value();

      const aggregations = _.chain(state.aggregations)
        .mapKeys((v, k) => `with_${k}`)
        .mapValues((a) => a.join(','))
        .value();

      return _.omitBy(
        {
          ...fromState,
          ...filters,
          ...aggregations,
          ...resource.getQueryParamsForIndex(),
          query,
        },
        isEmpty,
      );
    },
    [getResource],
  );
}
