import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import AuthLayout from '@/pages/auth/AuthLayout';

export default function ForgotPassword() {
  const forgotRequest = useMutation<
    { status: string },
    AxiosError<{ errors: Record<string, string[]>; message: string }>,
    { email: string }
  >((v) => axios.post(`/api/forgot-password`, v).then(({ data }) => data));

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    forgotRequest.mutate({
      email: data.get('email') as string,
    });
  };

  return (
    <AuthLayout title="Forgot Password">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {forgotRequest.data && <Alert severity="success">{forgotRequest.data.status}</Alert>}
        {forgotRequest.error && (
          <Alert severity="error" sx={{ my: 1 }}>
            {forgotRequest.error.response?.data.message || 'An unknown error occurred'}
          </Alert>
        )}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />

        <LoadingButton
          loading={forgotRequest.isLoading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Send Password Reset Link
        </LoadingButton>
      </Box>
    </AuthLayout>
  );
}
