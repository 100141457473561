import { Grid, Typography, Box, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { makeResourceQueryKey, Paper, useRecord } from '@/admin';
import { Order, OrderItemToPurchase } from '@/models';
import IssuesCard from '../Issues/IssuesCard';
import NoteManagerCard from '../Notes/NoteManagerCard';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import PurchasingForOrder from './PurchasingForOrder';

export default function PurchasingScreen() {
  const order = useRecord<Order>();

  const { data: items, isLoading } = useQuery(
    makeResourceQueryKey('ordersToPurchase', order.id, 'itemsToPurchase'),
    () =>
      axios
        .get<{ data: OrderItemToPurchase[] }>(`/api/orders/${order.id}/items-to-purchase`)
        .then(({ data }) => data.data),
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={9}>
        {items && <PurchasingForOrder orderId={order.id} items={items} />}
        {isLoading && (
          <Paper>
            <Box display="flex" mb={3}>
              <Box flexGrow={1} mr={2}>
                <Typography variant="h5">
                  <Skeleton variant="text" />
                </Typography>
              </Box>
              <Box display="flex">
                <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
            </Box>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} lg={3}>
        <OrderSummaryCard order={order} withAddresses />

        <IssuesCard resource="orders" resourceId={order.id} />

        <NoteManagerCard resource="orders" resourceId={order.id} size="small" />
      </Grid>
    </Grid>
  );
}
