import { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import ItemDesignTable from '@/components/Production/ItemDesignTable';
import { OrderDesign, OrderDesignItemPivot, OrderItem } from '@/models';
import useOrderableApiUrl from '@/utils/useOrderableApiUrl';

export default function ImprintsForm({
  orderDesign,
  onSave,
  submitting,
}: {
  orderDesign: OrderDesign;
  onSave?: (items: OrderItem[]) => void;
  submitting: boolean;
}) {
  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState<OrderItem[]>([]);
  const baseUrl = useOrderableApiUrl('items');

  useEffect(() => {
    setLoading(true);
    setItems([]);
    axios
      .get<{ data: OrderItem[] }>(baseUrl)
      .then(({ data }) => {
        setItems(data.data.filter((i) => i.can_apply_designs));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [baseUrl]);

  const setLocationsForItem = (itemIndex: number, locationIds: OrderItem['order_design_ids']) => {
    setItems((prev) =>
      prev.map((item, idx) => {
        if (idx === itemIndex) {
          return { ...item, order_design_ids: locationIds };
        }
        return item;
      }),
    );
  };

  const toggleLocationSelection = (itemIndex: number) => {
    const item = items[itemIndex];
    if (item.order_design_ids[orderDesign.id]) {
      setLocationsForItem(itemIndex, _.omit(item.order_design_ids, orderDesign.id));
    } else {
      setLocationsForItem(itemIndex, {
        ...item.order_design_ids,
        [orderDesign.id]: { qty: null },
      });
    }
  };

  const onSelectAll = () => {
    setItems((prev) =>
      prev.map((i) => ({
        ...i,
        order_design_ids: { ...i.order_design_ids, [orderDesign.id]: { qty: null } },
      })),
    );
  };

  const onDeselectAll = () => {
    setItems((prev) =>
      prev.map((i) => ({
        ...i,
        order_design_ids: _.omit(i.order_design_ids, orderDesign.id),
      })),
    );
  };

  const onOrderDesignChange = (item: OrderItem, payload: OrderDesignItemPivot) => {
    setItems((prev) =>
      prev.map((i) => {
        if (i.id === item.id) {
          return {
            ...i,
            order_design_ids: {
              ...i.order_design_ids,
              [orderDesign.id]: {
                ...i.order_design_ids[orderDesign.id],
                ...payload,
              },
            },
          };
        }
        return i;
      }),
    );
  };

  const onQtyChange = (item: OrderItem, qty: number | null) => onOrderDesignChange(item, { qty });

  return (
    <div>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Each item selected will get <i>{orderDesign.design.name}</i> imprinted on{' '}
        <i>{orderDesign.design.location}</i>.
      </Typography>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <ItemDesignTable
          items={items}
          orderDesign={orderDesign}
          onToggle={onSave && toggleLocationSelection}
          onSelectAll={onSave && onSelectAll}
          onDeselectAll={onSave && onDeselectAll}
          onQtyChange={onSave && onQtyChange}
        />
      )}

      {onSave && (
        <LoadingButton
          type="button"
          variant="contained"
          onClick={() => onSave(items)}
          loading={submitting}
          sx={{ mt: 3 }}
        >
          Save
        </LoadingButton>
      )}
    </div>
  );
}
