import { useState } from 'react';
import { Grid, Button } from '@mui/material';
import axios from 'axios';
import Joi from 'joi';
import _ from 'lodash';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FieldFactory, Paper, ReduxField, validateUsingRules } from '@/admin';
import PricingResults from '@/components/PricingEngine/PricingResults';
import { ArtCostItem, DecorationType, SpecialDesignType } from '@/models';

interface FormShape {
  items: {
    name: string;
    qty: number;
    cost: number;
  }[];
  designs: {
    decoration_type: DecorationType;
    complexity: number;
    special_design_type: SpecialDesignType | null;
  }[];
  art_costs: {
    item: ArtCostItem;
    qty: number;
  }[];
}

function PricingEngine({ handleSubmit, dirty, submitting }: InjectedFormProps<FormShape>) {
  const [items, setItems] = useState<FormShape['items']>();
  const [results, setResults] = useState<{ unit_cost: number }>();

  const onSubmit = (values: FormShape) => {
    setItems(values.items);
    axios
      .post<{ unit_cost: number }>('/api/estimate', {
        qty: _.sumBy(values.items, (i) => Number(i.qty)),
        decoration: _.map(values.designs, (d) => ({
          decoration_type_id: d.decoration_type.id,
          complexity: d.complexity,
          special_design_type_id: d.special_design_type?.id,
        })),
        art_costs: values.art_costs.map((v) => ({
          art_cost_item_id: v.item.id,
          qty: v.qty,
        })),
      })
      .then(({ data }) => {
        setResults(data);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item lg={8} md={12}>
          <Paper title="Blank Product">
            <ReduxField
              field={FieldFactory.list('items', [
                FieldFactory.text('name')
                  .withLabel('Garment Name')
                  .withPlaceholder('e.g. White Locker Tees')
                  .withColumnSpan(6),
                FieldFactory.number('qty').withColumnSpan(2),
                FieldFactory.curr('cost').withColumnSpan(3),
              ])}
            />
          </Paper>
          <Paper title="Decoration">
            <ReduxField
              field={FieldFactory.list('designs', [
                FieldFactory.belongsTo('decoration_type', 'decorationTypes'),
                FieldFactory.number('complexity').withLabel('Complexity (Colors/Stitches)'),
                FieldFactory.belongsTo('special_design_type', 'specialDesignTypes').withColumnSpan(
                  3,
                ),
              ])}
            />
          </Paper>
          <Paper title="Art Costs">
            <ReduxField
              field={FieldFactory.list('art_costs', [
                FieldFactory.belongsTo('item', 'artCostItems').withColumnSpan(6),
                FieldFactory.number('qty').withLabel('Quantity').withColumnSpan(5),
              ])}
            />
          </Paper>
          {dirty && (
            <Button type="submit" variant="contained" disabled={submitting} sx={{ mt: 3 }}>
              Calculate
            </Button>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          {results && items && (
            <PricingResults items={items} decorationUnitCost={results.unit_cost} />
          )}
        </Grid>
      </Grid>
    </form>
  );
}

export default reduxForm<FormShape>({
  form: 'pricingEngine',
  initialValues: {
    items: [{ name: '', qty: 12, cost: 5 }],
    designs: [],
    art_costs: [],
  },
  validate: (v) =>
    validateUsingRules(
      v,
      Joi.object({
        items: Joi.array().items(
          Joi.object({
            name: Joi.string().required(),
            qty: Joi.number().integer().required(),
            cost: Joi.number().required(),
          }),
        ),
        designs: Joi.array().items(
          Joi.object({
            decoration_type: Joi.object().required(),
            complexity: Joi.number().allow(null),
            special_design_type: Joi.any(),
          }),
        ),
        art_costs: Joi.array().items(
          Joi.object({
            item: Joi.object().required(),
            qty: Joi.number().required(),
          }),
        ),
      }),
    ),
  destroyOnUnmount: false,
})(PricingEngine);
