import { FieldFactory, Resource } from '@/admin';
import PackoutPage from '@/components/Kitting/PackoutPage';
import { PACKOUT_STATUS_COLOR } from '@/constants';
import { Packout } from '@/models';

const getTitle = (v: Packout) => `${v.order.increment_id} Packout`;

export default function orderPackouts() {
  return new Resource<Packout>('Order Packouts')
    .setReadOnly(true)
    .getTitleUsing(getTitle)
    .getLabelUsing(getTitle)
    .withDefaultFilters({
      bucket: 'open',
    })
    .withFilters([
      FieldFactory.radio('bucket', {
        open: 'Open',
        all: 'All',
      }).asQuickFilter(),
    ])
    .withColumns([
      FieldFactory.belongsTo('order', 'orders').filterable().sortable('order.increment_id'),
      FieldFactory.belongsTo('order.customer', 'customers')
        .withLabel('Customer')
        .filterable('filter[order.customer_id]'),
      FieldFactory.status('status', PACKOUT_STATUS_COLOR).filterable('status'),
      FieldFactory.number('kits_count').withLabel('# Kits').sortable(),
      FieldFactory.number('assigned_items_count').withLabel('# Items').sortable(),
      FieldFactory.date('scheduled_at').withLabel('Scheduled').sortable(),
      FieldFactory.timestamp('completed_at').sortable(),
    ])
    .editUsing(PackoutPage);
}
