import { ReactNode } from 'react';
import _ from 'lodash';
import BulletSeparatedList from './BulletSeparatedList';

export default function ArrayBullets({
  elements,
}: {
  elements: ReactNode[] | Record<string, ReactNode>;
}) {
  if (Array.isArray(elements)) {
    return (
      <BulletSeparatedList>
        {_.filter(elements).map((s, idx) => (
          <li key={idx}>{s}</li>
        ))}
      </BulletSeparatedList>
    );
  }

  return (
    <BulletSeparatedList>
      {_.map(_.pickBy(elements), (value, key) => (
        <li key={key} title={_.startCase(key)}>
          {value}
        </li>
      ))}
    </BulletSeparatedList>
  );
}
