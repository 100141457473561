import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { Field } from 'redux-form';
import { Can, FieldFactory, ReduxField, useToast } from '@/admin';
import ProductField from '@/components/Products/ProductField';
import { useConfig } from '@/contexts/AppConfigContext';
import { isScreenPrint } from '@/helpers';
import { DecorationType } from '@/models';
import AttachmentsCard from '../Attachments/AttachmentsCard';
import DesignColors from './DesignColors';

export default function DesignSection({
  decorationType,
  id,
}: {
  id?: number;
  decorationType?: DecorationType;
}) {
  const decorationUnit = decorationType?.unit;
  const decorationTypeName = decorationType?.name;
  const hasDrops = decorationType?.has_drops;
  const { decorationLocations } = useConfig();
  const toast = useToast();
  const copyDstFile = useMutation(() =>
    axios.post(`/api/designs/${id}/copy-dst-file`).then(() => {
      toast('If there was a DST file, it has been pushed to embroidery server.');
    }),
  );

  return (
    <Grid container spacing={4}>
      <Grid item md={5}>
        <Box mb={3}>
          <ReduxField field={FieldFactory.image('image').withoutLabel()} />
        </Box>

        <Box mb={1}>
          <ReduxField field={FieldFactory.text('name')} />
        </Box>

        <Box mb={1}>
          <ReduxField field={FieldFactory.belongsTo('customer', 'customers')} />
        </Box>
        <Box mb={1}>
          <ReduxField field={FieldFactory.belongsTo('decoration_type', 'decorationTypes')} />
        </Box>

        <Box mb={1}>
          <ReduxField
            field={FieldFactory.autocomplete(
              'location',
              (decorationLocations[decorationTypeName || ''] || []).map((l) => l.name),
            )}
          />
        </Box>

        {id && (
          <>
            <AttachmentsCard
              resourceId={id}
              resource="designs"
              group="files"
              title="Production Files"
              titleTypographyProps={{ variant: 'h6' }}
              action={
                <Can permission="write:designs">
                  <LoadingButton
                    onClick={() => copyDstFile.mutate()}
                    loading={copyDstFile.isLoading}
                    size="small"
                  >
                    Push DST File
                  </LoadingButton>
                </Can>
              }
              elevation={0}
            />
            <Can permission="write:designs">
              <AttachmentsCard
                resourceId={id}
                resource="designs"
                group="internal"
                title="Internal Art Files"
                titleTypographyProps={{ variant: 'h6' }}
                elevation={0}
              />
            </Can>
          </>
        )}
      </Grid>
      <Grid item md={7}>
        <Typography variant="h6" gutterBottom>
          Details
        </Typography>

        <Box mb={1}>
          <ReduxField field={FieldFactory.textarea('description').with({ rows: 4 })} />
        </Box>

        <Box mb={1} display="flex">
          <ReduxField
            field={FieldFactory.number('complexity').withLabel(
              decorationUnit ? `# of ${_.startCase(decorationUnit)}` : 'Complexity',
            )}
          />
          <div style={{ width: 16 }} />
          <ReduxField field={FieldFactory.text('design_code')} />
        </Box>
        <Box mb={1} display="flex">
          <ReduxField field={FieldFactory.text('width')} />
          <div style={{ width: 16 }} />
          <ReduxField field={FieldFactory.text('height')} />
        </Box>
        <Box mb={1}>
          <ReduxField
            field={FieldFactory.text('product_colors').withHelp(
              'What garment color(s) should this design be applied to?',
            )}
          />
        </Box>
        <Box mb={1}>
          <ReduxField field={FieldFactory.belongsTo('special_design_type', 'specialDesignTypes')} />
        </Box>
        {hasDrops && (
          <Box mb={1}>
            <ReduxField
              field={new ProductField('product')
                .withLabel('Name/Number Product')
                .withHelp('The product to order from Stahls (or other)')}
            />
          </Box>
        )}

        <Typography variant="h6" gutterBottom>
          Colors
        </Typography>
        {isScreenPrint(decorationUnit) && (
          <Box mt={1}>
            <ReduxField field={FieldFactory.boolean('has_underbase', 'Has Underbase')} />
          </Box>
        )}

        <Field
          name="colors"
          component={DesignColors}
          decorationUnit={decorationUnit}
          decorationTypeName={decorationTypeName?.toLowerCase()}
          freeSolo
        />
      </Grid>
    </Grid>
  );
}
