import { ReactNode } from 'react';
import { Box, FormLabel } from '@mui/material';
import _ from 'lodash';
import { WrappedFieldProps } from 'redux-form';
import StatusChip from '../../components/StatusChip';
import SelectField from '../../models/Fields/SelectField';
import Field from '../Field';

function Status({ status, field }: { status: string; field: StatusField }) {
  return (
    <div>
      {field.label && <FormLabel>{field.label}</FormLabel>}
      <Box mt={field.label ? 1 : 0}>
        <StatusChip
          status={status}
          colors={field.colors}
          labels={field.labels}
          startCase={field.startCase}
        />
      </Box>
    </div>
  );
}

export default class StatusField extends Field {
  colors?: Record<string, string> = {};
  labels?: Record<string, string> = {};
  startCase = true;

  constructor(name: string, colors?: Record<string, string>, labels?: Record<string, string>) {
    super(name);
    this.colors = colors;
    this.labels = labels;
  }

  getFilterFieldForType(): Field {
    let options: Record<string, string> = this.labels || {};
    if (Object.keys(options).length === 0 && this.colors) {
      options = _.mapValues(this.colors, (v, k) => _.startCase(k));
    }
    return new SelectField(this.name, options).with({ includeBlank: true });
  }

  renderCell(value: any, row?: Record<string, any>): ReactNode {
    return (
      <StatusChip
        status={value}
        colors={this.colors}
        labels={this.labels}
        startCase={this.startCase}
      />
    );
  }

  renderEditComponent(props: WrappedFieldProps) {
    return <Status status={props.input.value} field={this} />;
  }
}
