import { useMemo } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { Table, TableHead, TableRow, TableCell, TableContainer, TableFooter } from '@mui/material';
import _ from 'lodash';
import { curr } from '@/admin';
import OrderItemGroup from '@/components/Orders/Items/OrderItemGroup';
import OrderItemRow, { BaseOrderLineProps } from '@/components/Orders/Items/OrderItemRow';
import { OrderItem, OrderTotals } from '@/models';

export default function OrderItemsTable(
  props: {
    items: OrderItem[];
    setItemsAndPersist: (i: OrderItem[]) => void;
    totals: OrderTotals;
  } & BaseOrderLineProps,
) {
  const { isGrouped, totals, items, setItemsAndPersist, ...other } = props;

  const groups: Record<string, OrderItem[]> = useMemo(() => {
    if (!isGrouped) {
      return {};
    }

    return _.groupBy(items, (i) =>
      [i.description, i.number, i.color, i.is_custom, i.vendor?.id, i.variant?.product.id].join(
        '|',
      ),
    );
  }, [isGrouped, items]);

  const onSortEndNotGrouped = ({ over, active }: DragEndEvent) => {
    if (!over) {
      return;
    }
    const oldIndex = items.findIndex((i) => i.id === active.id);
    const newIndex = items.findIndex((i) => i.id === over.id);
    setItemsAndPersist(arrayMove(items, oldIndex, newIndex));
  };

  const onSortEndGrouped = ({ over, active }: DragEndEvent) => {
    if (!over) {
      return;
    }
    const groupKeys = Object.keys(groups);
    const oldIndex = groupKeys.findIndex((g) => g === active.id);
    const newIndex = groupKeys.findIndex((g) => g === over.id);
    const newKeys = arrayMove(groupKeys, oldIndex, newIndex);
    setItemsAndPersist(newKeys.flatMap((key) => groups[key]));
  };

  const onSortEndForSpecificGroup = (groupKey: string, { over, active }: DragEndEvent) => {
    if (!over) {
      return;
    }
    const group = groups[groupKey];
    const oldIndex = group.findIndex((i) => i.id === active.id);
    const newIndex = group.findIndex((i) => i.id === over.id);
    const newGroup = arrayMove(group, oldIndex, newIndex);

    setItemsAndPersist(
      Object.keys(groups).flatMap((key) => {
        if (key === groupKey) {
          return newGroup;
        }
        return groups[key];
      }),
    );
  };

  return (
    <TableContainer style={{ minHeight: 400 }}>
      <Table sx={{ mb: 3 }}>
        <TableHead>
          <TableRow style={{ whiteSpace: 'nowrap' }}>
            <TableCell sx={{ pr: 0 }} />
            <TableCell>Item</TableCell>
            <TableCell>Vendor</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Unit Price</TableCell>
            <TableCell title="Discount">Disc.</TableCell>
            <TableCell>Line Price</TableCell>
            <TableCell>Unit Cost</TableCell>
            <TableCell>PO Ship To</TableCell>
            <TableCell>Settings</TableCell>
            <TableCell colSpan={3} />
          </TableRow>
        </TableHead>

        {isGrouped ? (
          <DndContext onDragEnd={onSortEndGrouped}>
            <SortableContext items={Object.keys(groups)}>
              {Object.entries(groups).map(([groupKey, groupedItems]) => (
                <OrderItemGroup
                  key={groupKey}
                  groupKey={groupKey}
                  items={groupedItems}
                  isGrouped
                  onSortEnd={onSortEndForSpecificGroup}
                  {...other}
                />
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          <DndContext onDragEnd={onSortEndNotGrouped}>
            <SortableContext items={items.map((i) => i.id)}>
              {items.map((item) => (
                <OrderItemRow key={item.id} item={item} isGrouped={false} {...other} />
              ))}
            </SortableContext>
          </DndContext>
        )}

        <TableFooter>
          <TableRow>
            <TableCell colSpan={4}>Totals</TableCell>
            <TableCell>{totals.items_sum}</TableCell>
            <TableCell colSpan={2} />
            <TableCell>{curr(totals.subtotal)}</TableCell>
            <TableCell colSpan={5} />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
