import { CloudDownload, PictureAsPdf } from '@mui/icons-material';
import { Card, CardHeader, CardMedia } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { Action, AnchorAction, SubMenu } from '@/admin';
import { Proof } from '@/models';
import { maybeAddQueryParams } from '@/utils/query';

const getExtension = (url: string, fallback = '') => url.match(/\.([a-z]{2,5})$/i)?.[1] || fallback;

const getProofUrl = (proof: Proof, type: 'image' | 'pdf' = 'image', dl = false): string => {
  const url = proof[type];
  const params: Record<string, string> = {};

  if (!url) {
    return '';
  }

  if (proof.file_name) {
    const ext = getExtension(url, type === 'image' ? 'png' : 'pdf');
    params.name = `${proof.file_name.replace(/\.([a-z]{2,5})$/i, '')}.${ext}`;
  }

  if (dl) {
    params.dl = '1';
  }

  const queryParams = new URLSearchParams(params);
  return `${url}?${queryParams.toString()}`;
};

export default function ProofCard({ proof, actions = [] }: { proof: Proof; actions?: Action[] }) {
  return (
    <Card>
      <CardHeader
        action={
          <SubMenu
            items={
              _.filter([
                proof.pdf &&
                  new AnchorAction('View PDF', getProofUrl(proof, 'pdf', false)).withIcon(
                    PictureAsPdf,
                  ),
                proof.pdf &&
                  new AnchorAction('Download PDF', getProofUrl(proof, 'pdf', true)).withIcon(
                    CloudDownload,
                  ),
                ...actions,
              ]) as Action[]
            }
          />
        }
        title={proof.file_name}
        titleTypographyProps={{ variant: 'subtitle2' }}
        subheader={moment(proof.created_at).format('lll')}
        subheaderTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
      />
      <CardMedia
        image={maybeAddQueryParams(proof.image, { w: 800 })}
        title={proof.file_name}
        style={{ width: '100%', aspectRatio: '8.5 / 11' }}
        component="a"
        href={getProofUrl(proof)}
        target="_blank"
      />
    </Card>
  );
}
