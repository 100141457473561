import { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { FieldFactory, FieldProps, GenericField, useHasPermission } from '@/admin';
import { COMMISSION_HIT_OPTIONS } from '@/constants';

export default function CommissionHit({ input, meta, field }: FieldProps) {
  const hasPermission = useHasPermission();
  const [hit, setHit] = useState(!!input.value);

  useEffect(() => {
    if (hit && !input.value) {
      input.onChange(1);
    } else if (!hit) {
      input.onChange(0);
    }
  }, [hit]);

  if (!hasPermission('write:order_commissions')) {
    return null;
  }

  return (
    <div>
      <FormControlLabel
        control={<Checkbox checked={hit} onChange={(e) => setHit(e.target.checked)} />}
        label="Deduct payment from commissions"
      />

      {hit && (
        <GenericField
          input={input}
          meta={meta}
          field={FieldFactory.select(field.name, COMMISSION_HIT_OPTIONS)}
        />
      )}
    </div>
  );
}
