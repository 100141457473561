import { useLocation, useSearchParams } from 'react-router-dom';

export default function useQueryStringState(
  key: string,
  defaultValue?: string,
): [string | undefined, (s: string | undefined) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const value = searchParams.get(key) || defaultValue;

  const setValue = (nextValue: string | undefined) => {
    const newObject = {
      ...Object.fromEntries(searchParams),
    };
    if (nextValue == null) {
      delete newObject[key];
    } else {
      newObject[key] = nextValue;
    }
    setSearchParams(newObject, { state });
  };

  return [value, setValue];
}
