import { ReactNode } from 'react';
import { Inventory } from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { ArrayBullets, curr } from '@/admin';
import OrderLabel from '@/components/Orders/OrderLabel';
import SkuLabel from '@/components/Products/SkuLabel';
import DescriptionCell from '@/components/Purchasing/DescriptionCell';
import { PurchaseOrderItem } from '@/models';

export default function PurchaseOrderItemsTable({
  items,
  isGrouped = false,
  renderActions,
}: {
  items: PurchaseOrderItem[];
  isGrouped?: boolean;
  renderActions?: (i: PurchaseOrderItem) => ReactNode;
}) {
  const renderItems = () => {
    const grouped = isGrouped
      ? _.chain(items)
          .groupBy((i) => [i.number, i.color, i.description].join('|'))
          .mapValues((gItems) => [
            {
              ...gItems[0],
              summaryItem: true,
              qty_received: _.sumBy(gItems, 'qty_received'),
              qty: _.sumBy(gItems, 'qty'),
            },
            ...gItems,
          ])
          .values()
          .flatten()
          .value()
      : items;

    return (
      <TableBody>
        {grouped.map((i) => {
          if (i.summaryItem) {
            return (
              <TableRow key={`summary.${i.id}`}>
                <TableCell />
                <DescriptionCell title={i.description}>{i.description}</DescriptionCell>
                <TableCell colSpan={2} />
                <TableCell variant="footer">
                  {i.qty} (Rec: {i.qty_received})
                </TableCell>
                <TableCell colSpan={renderActions ? 3 : 2} />
              </TableRow>
            );
          }
          return (
            <TableRow key={i.id}>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                <OrderLabel orderId={i.order_id}>{i.order_increment_id}</OrderLabel>
                {i.is_inventory && (
                  <Tooltip title="This item is going into inventory">
                    <Inventory
                      color="primary"
                      fontSize="small"
                      sx={{ verticalAlign: 'bottom', ml: 1 }}
                    />
                  </Tooltip>
                )}
              </TableCell>
              {isGrouped ? (
                <TableCell />
              ) : (
                <DescriptionCell title={i.description}>{i.description}</DescriptionCell>
              )}
              <TableCell>{i.size}</TableCell>
              <DescriptionCell title={[i.number, i.color, i.size].join(' - ')}>
                {i.variant ? (
                  <SkuLabel variant={i.variant} withoutDescription>
                    <Typography variant="body2" color="textSecondary">
                      <ArrayBullets elements={[i.number, i.color, i.size]} />
                    </Typography>
                  </SkuLabel>
                ) : (
                  <ArrayBullets elements={[i.number, i.color, i.size]} />
                )}
              </DescriptionCell>
              <TableCell>
                <span>{i.qty}</span>
                {i.qty_received ? (
                  <Typography component="span" variant="caption" color="textSecondary">
                    {' '}
                    (Rec: {i.qty_received})
                  </Typography>
                ) : null}
              </TableCell>
              <TableCell>{curr(i.cost, { maxDecimals: 4 })}</TableCell>
              <TableCell>{curr(i.cost * i.qty, { maxDecimals: 4 })}</TableCell>
              {renderActions ? renderActions(i) : null}
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Order</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Unit Cost</TableCell>
            <TableCell>Total</TableCell>
            {renderActions && <TableCell />}
          </TableRow>
        </TableHead>
        {renderItems()}
        <TableFooter>
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell>
              {_.sumBy(items, 'qty')} (Rec: {_.sumBy(items, 'qty_received')})
            </TableCell>
            <TableCell colSpan={2} />
            {renderActions && <TableCell />}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
