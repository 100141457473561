import { WrappedFieldArrayProps } from 'redux-form';
import TableElement from '../../components/Form/TableElement';
import Field from '../Field';

export default class TableField extends Field {
  children: Field[];
  addNewValue = {};
  addNewLabel = 'Add New';
  isArray = true;

  constructor(name: string, children: Field[] = []) {
    super(name);
    this.children = children;
  }

  renderArrayComponent(props: WrappedFieldArrayProps) {
    return <TableElement {...props} field={this} />;
  }
}
