import { ChangeEvent, useEffect } from 'react';
import { Table, TableCell, TableHead, TableRow, Button, Box } from '@mui/material';
import axios from 'axios';
import Joi from 'joi';
import _ from 'lodash';
import { reduxForm, FieldArray, InjectedFormProps, FormSubmitHandler } from 'redux-form';
import {
  ReduxField,
  ClosableDrawer,
  validateUsingRules,
  DrawerFormSection,
  useSpecificFormValues,
  FieldFactory,
} from '@/admin';
import { OrderItem, ProductWithVariants } from '@/models';
import useOrderableApiUrl from '@/utils/useOrderableApiUrl';
import BehaviorSection from './Create/BehaviorSection';
import ItemsSection from './Create/ItemsSection';
import ProductSection from './Create/ProductSection';
import VendorSection from './Create/VendorSection';

const updateAmounts = ({
  items,
  useDatabaseCost,
  change,
}: {
  items: Partial<OrderItem>[];
  useDatabaseCost?: boolean;
  change: (f: string, v: any) => void;
}) => {
  if (!useDatabaseCost) {
    return;
  }
  (items || []).forEach((item, index) => {
    if (_.get(item, 'variant.id')) {
      change(`items[${index}].cost`, item.variant?.cost);
    }
  });
};

const updateCosts = _.debounce(updateAmounts, 800);

type FormShape = Partial<OrderItem> & {
  product?: ProductWithVariants;
  items: Partial<OrderItem>[];
};

interface OrderItemWizardProps {
  open: boolean;
  onClose: () => void;
  defaults: Partial<FormShape>;
  onSuccess: (items: OrderItem[]) => void;
}

function OrderItemWizardDrawer({
  form,
  initialize,
  handleSubmit,
  submitting,
  dirty,
  destroy,
  change,
  open,
  onClose,
  defaults,
  onSuccess,
}: InjectedFormProps<FormShape, OrderItemWizardProps> & OrderItemWizardProps) {
  const baseUrl = useOrderableApiUrl('items');
  const [product, items, isCustom = false, useDatabaseCost] = useSpecificFormValues(
    form,
    (o: FormShape) => [o.product, o.items, o.is_custom, o.use_database_cost],
  );
  useEffect(() => {
    if (open) {
      initialize(defaults);
    } else {
      destroy();
    }
  }, [open]);

  // Handle Product Change
  useEffect(() => {
    if (dirty && product) {
      change('is_shippable', product.default_is_shippable);
      change('can_drop_ship', product.default_can_drop_ship);
      change('can_apply_designs', product.default_can_apply_designs);
      change('is_purchasable', product.default_is_purchasable);
      change('show_on_invoice', product.default_show_on_invoice);
      change('vendor', product.vendor);
      change('classification', product.classification);
      change(
        'items',
        product.variants.map((v) => ({
          variant: v,
          variant_id: v.id,
          price: v.price,
          cost: v.cost,
          qty: 0,
        })),
      );
    }
  }, [product]);

  // Handle Database Cost Change
  useEffect(() => {
    updateCosts({
      items,
      useDatabaseCost,
      change,
    });
  }, [useDatabaseCost, items]);

  const onSubmit: FormSubmitHandler<FormShape, OrderItemWizardProps> = (values) => {
    const payload = values.items
      .filter((i) => Number(i.qty) !== 0)
      .map((item) => ({
        ..._.omit(values, ['items']),
        ...item,
      }));

    return axios.post(baseUrl, { items: payload, is_custom: values.is_custom }).then(({ data }) => {
      onSuccess(data);
      onClose();
    });
  };

  const handleCustomChange = (e: ChangeEvent<HTMLInputElement>) => {
    const booleanValue = e.currentTarget.value === 'custom';
    change('is_custom', booleanValue);
    if (booleanValue) {
      change('variant', null);
    }
  };

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Add Product Wizard" width={600}>
      <ProductSection handleCustomChange={handleCustomChange} isCustom={isCustom} />

      <VendorSection hideCost isCustom={isCustom} />

      <DrawerFormSection title="Quantities and Price">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Size</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <FieldArray
            name="items"
            component={ItemsSection}
            useDatabaseCost={useDatabaseCost}
            isCustom={isCustom}
          />
        </Table>

        {!isCustom && (
          <Box mt={2}>
            <ReduxField field={FieldFactory.boolean('use_database_cost', 'Use Database Cost')} />
          </Box>
        )}

        <ReduxField field={FieldFactory.percent('discount')} />
      </DrawerFormSection>

      <BehaviorSection />

      <Button
        type="button"
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={submitting}
        sx={{ mt: 3 }}
      >
        Submit
      </Button>
    </ClosableDrawer>
  );
}

const validate = (values: FormShape) => {
  let rules: Joi.ObjectSchema;

  if (!values.is_custom) {
    rules = Joi.object({
      description: Joi.string().required(),
      product: Joi.any().required(),
      items: Joi.array(),
    });
  } else {
    rules = Joi.object({
      description: Joi.string().required(),
      number: Joi.string().required(),
      color: Joi.any(),
      vendor: values.is_purchasable
        ? Joi.any().required().messages({
            'any.required': `A vendor is required when the item is purchasable`,
          })
        : Joi.any(),
      items: Joi.array().items(
        Joi.object({
          qty: Joi.number().integer(),
          size: Joi.string().required(),
        }).unknown(),
      ),
    });
  }
  const errors = validateUsingRules(values, rules.unknown());

  if (_.sumBy(values.items, 'qty') === 0) {
    _.set(errors, 'items[0].qty', 'At least 1 required');
  }

  return errors;
};

export default reduxForm<FormShape, OrderItemWizardProps>({
  form: 'addItemWizardForm',
  validate,
})(OrderItemWizardDrawer);
