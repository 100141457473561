import { ReactNode } from 'react';
import { CardHeader, CardContent, Card, TypographyVariant } from '@mui/material';

export default function Paper({
  children,
  title,
  titleVariant = 'h5',
  action,
}: {
  children: ReactNode;
  title?: string;
  titleVariant?: TypographyVariant;
  action?: ReactNode;
}) {
  return (
    <Card sx={{ mb: 2 }}>
      {title && (
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: titleVariant }}
          action={action}
        />
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
}
