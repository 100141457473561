import { Box, CardContent, CardHeader, Typography } from '@mui/material';
import { MappedRow, StatusChip, TextLink, useHasPermission } from '@/admin';
import PurchaseOrderItemsTable from '@/components/Purchasing/PurchaseOrderItemsTable';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import { PURCHASE_ORDER_STATUS_COLORS } from '@/constants';
import { PurchaseOrderWithItems } from '@/models';

export default function PurchaseOrderCard({
  po,
  otherRows,
}: {
  po: PurchaseOrderWithItems;
  otherRows: MappedRow[];
}) {
  const hasPermission = useHasPermission();
  return (
    <CardWithGutter>
      <CardContent sx={{ pb: 0, pt: 1 }}>
        <Typography variant="overline" color="primary">
          Purchase Order
        </Typography>
      </CardContent>

      <CardHeader
        sx={{ pt: 0 }}
        title={
          <TextLink
            to={`/purchase-orders/${po.id}`}
            disabled={!hasPermission('read:purchase_orders')}
            otherRows={otherRows}
          >
            {po.label}
          </TextLink>
        }
        subheader={<Box mt={1}>Shipping To: {po.address?.name}</Box>}
        action={<StatusChip status={po.status} colors={PURCHASE_ORDER_STATUS_COLORS} />}
      />
      <PurchaseOrderItemsTable items={po.items} />
    </CardWithGutter>
  );
}
