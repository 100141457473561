import Joi from 'joi';
import { FieldFactory, GroupLayout, Resource } from '@/admin';
import { Address } from '@/models';
import { AppConfig } from '@/responses';

export const ADDRESS_VALIDATION = Joi.object({
  name: Joi.string().max(35).required(),
  company: Joi.string().allow(null, '').max(35),
  address_1: Joi.string().max(35).required(),
  address_2: Joi.string().allow(null, '').max(35),
  city: Joi.string().max(30).required(),
  zip: Joi.string().max(10).required(),
}).unknown();

export default function addresses(appConfig: AppConfig) {
  return new Resource<Address>('Addresses')
    .withDefaultSort('-id')
    .withValidation(ADDRESS_VALIDATION)
    .withFieldsAndColumns([
      FieldFactory.select(
        'method',
        appConfig.shippingMethods.map((m) => m.name),
      )
        .withLabel('Shipping Method')
        .withColumnSpan(12),
      FieldFactory.text('name').withColumnSpan(12),
      FieldFactory.text('company').withColumnSpan(12),
      FieldFactory.text('address_1').withColumnSpan(12),
      FieldFactory.text('address_2').withColumnSpan(12),
      FieldFactory.select('country', appConfig.countries).withColumnSpan(12),
      FieldFactory.text('city').withColumnSpan(5),
      FieldFactory.select('state', []).withColumnSpan(4),
      FieldFactory.text('zip').withColumnSpan(3),
      FieldFactory.text('phone').withColumnSpan(12),
      new GroupLayout('settings', [
        FieldFactory.boolean('is_residential', 'Residential Address'),
        FieldFactory.boolean('is_billing', 'Billing Address'),
        FieldFactory.boolean('is_taxable_address', 'Primary Shipping Address'),
      ])
        .withoutLabel()
        .withColumnSpan(12),
    ]);
}
