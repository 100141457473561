import { ReactNode } from 'react';
import { Box, FormHelperText, InputLabel, styled, Theme } from '@mui/material';
import NotchedOutline from '@mui/material/OutlinedInput/NotchedOutline';
import { WrappedFieldMetaProps } from 'redux-form';
import Field from '../../models/Field';

function getColor(theme: Theme, props: { error?: boolean; disabled?: boolean }) {
  if (props.error) {
    return theme.palette.error.main;
  }
  if (props.disabled) {
    return theme.palette.action.disabled;
  }
  return 'rgba(0,0,0,0.23)';
}

type Display = 'block' | 'inline-block' | 'flex' | 'inline-flex';

const StyledFormControl = styled('div', {
  shouldForwardProp: (prop) => !['error', 'disabled', 'display'].includes(prop as string),
})<{ display?: Display; error: boolean; disabled: boolean }>(({ theme, ...props }) => ({
  position: 'relative',
  display: props.display,
  borderRadius: theme.shape.borderRadius,
  '& fieldset': {
    borderColor: getColor(theme, props),
  },
  '& label': {
    color: props.error ? theme.palette.error.main : theme.palette.text.secondary,
  },
}));

export default function StaticFormControl({
  field,
  meta,
  children,
  display,
}: {
  field: Field;
  meta: WrappedFieldMetaProps;
  children: ReactNode;
  display?: Display;
}) {
  return (
    <Box mb={1}>
      <StyledFormControl
        error={!!meta.error}
        disabled={Boolean(field.readOnly || field.disabled)}
        display={display || (field.fullWidth ? 'block' : 'inline-block')}
      >
        {field.label && (
          <InputLabel shrink variant="outlined">
            {field.label}
          </InputLabel>
        )}
        <NotchedOutline label={field.label} notched />
        {children}
      </StyledFormControl>
      {field.getHelperText(meta) && (
        <FormHelperText
          error={!!meta.error}
          sx={{
            ml: 1.5,
            mt: 0.5,
          }}
        >
          {field.getHelperText(meta)}
        </FormHelperText>
      )}
    </Box>
  );
}
