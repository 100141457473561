import { Fragment, useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { ArtTrack } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import arrayMove from 'array-move';
import axios from 'axios';
import _ from 'lodash';
import {
  FieldFactory,
  FieldProps,
  GenericField,
  GroupLayout,
  Paper,
  useDialogs,
  useFormValues,
  useSpecificFormValues,
} from '@/admin';
import DesignLayoutCard from '@/components/Designs/DesignLayoutCard';
import InkChangeField from '@/components/Designs/InkChangeField';
import OrderDesignListItem from '@/components/Designs/OrderDesignListItem';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';
import { randomElement, requiredFields } from '@/helpers';
import {
  DecorationType,
  Design,
  DesignLayout,
  DesignLayoutWithProofs,
  InkChange,
  Order,
  OrderDesign,
} from '@/models';
import useMutateQueryData from '@/utils/useMutateQueryData';
import useOrderableApiUrl from '@/utils/useOrderableApiUrl';
import ImprintsDrawer from './ImprintsDrawer';
import RequestedDesignDrawer from './RequestedDesignDrawer';
import RosterDrawer from './RosterDrawer';

function ExistingLayoutField({ input, meta, field }: FieldProps) {
  const allCustomers = useSpecificFormValues(
    meta.form,
    (v: { all_customers?: boolean }) => v.all_customers,
  );
  const customerId = useFormValues((o: Order) => o.customer_id);
  const requestParams: Record<string, string> = {};

  if (!allCustomers) {
    requestParams.customer_id = String(customerId);
  }

  const newField = FieldFactory.belongsTo(field.name, 'designLayouts').withRequestParams(
    requestParams,
  );

  return (
    <Box width="500px" maxWidth="100%">
      <GenericField input={input} field={newField} meta={meta} />
    </Box>
  );
}

function ExistingDesignField({ input, field, meta }: FieldProps) {
  const [allCustomers, includeIncomplete] = useSpecificFormValues(
    meta.form,
    (v: { all_customers?: boolean; include_incomplete?: boolean }) => [
      v.all_customers,
      v.include_incomplete,
    ],
  );

  const customerId = useFormValues((o: Order) => o.customer_id);

  const requestParams: Record<string, string> = {};

  if (!allCustomers) {
    requestParams.customer_id = String(customerId);
  }
  if (!includeIncomplete) {
    requestParams.complete = '1';
  }

  const newField = FieldFactory.belongsTo(field.name, 'designs').withRequestParams(requestParams);
  return (
    <Box width="500px" maxWidth="100%">
      <GenericField input={input} field={newField} meta={meta} />
    </Box>
  );
}

export default function OrderArt() {
  const [orderId, isBlank, isWebstore, invoicedAt, releasedAt, cancelledAt] = useFormValues(
    (o: Order) => [
      o.id,
      o.order_type.is_blank,
      o.order_type.is_webstore,
      o.invoiced_at,
      o.released_at,
      o.cancelled_at,
    ],
  );

  const baseUrl = useOrderableApiUrl('design-layouts');
  const [editing, setEditing] = useState<OrderDesign>();
  const [editingImprints, setEditingImprints] = useState<OrderDesign>();
  const [layoutForRoster, setLayoutForRoster] = useState<DesignLayoutWithProofs>();
  const { confirm, prompt } = useDialogs();

  const setDesignLayouts = useMutateQueryData<DesignLayoutWithProofs[]>([baseUrl]);
  const {
    data: designLayouts,
    isLoading,
    refetch,
  } = useQuery([baseUrl], () =>
    axios.get<{ data: DesignLayoutWithProofs[] }>(baseUrl).then(({ data }) => data.data),
  );

  const canEdit = (layout?: DesignLayout): boolean => {
    if (invoicedAt || releasedAt || cancelledAt) {
      return false;
    }
    if (layout && layout.subcontract_po_id) {
      return false;
    }
    return true;
  };

  const onLayoutUpdated = (layout: DesignLayout) => {
    setDesignLayouts((prev) =>
      prev.map((d) => {
        if (d.id === layout.id) {
          return { ...d, ...layout };
        }
        return d;
      }),
    );
  };

  const onLayoutAdded = (layout: DesignLayoutWithProofs) =>
    setDesignLayouts((prev) => [...prev, layout]);

  const onRoster = (layout: DesignLayoutWithProofs) => {
    setLayoutForRoster(layout);
  };

  const createNew = () => {
    prompt({
      title: 'Create New Design Layout',
      fields: [
        FieldFactory.text('name')
          .withSize('medium')
          .withHelp(
            randomElement([
              'e.g. 2021 Basketball Shooting Shirts',
              'e.g. 2020 Holiday Shirts',
              'e.g. Reversible Practice Jerseys',
              'e.g. Turkey Trot Shirts',
            ]),
          ),
      ],
      onSubmit: (v) => axios.post(`${baseUrl}`, v),
    }).then(({ data }) => {
      onLayoutAdded(data);
    });
  };

  const addExisting = () => {
    prompt<{ design_layout?: DesignLayout; all_customers?: boolean }>({
      title: 'Start From Existing Layout',
      fields: [
        FieldFactory.custom('design_layout', ExistingLayoutField),
        FieldFactory.boolean('all_customers', 'Include designs from other customers'),
      ],
      initialValues: {
        all_customers: false,
      },
      validation: requiredFields(['design_layout']),
      onSubmit: (v) =>
        axios.post(baseUrl, {
          order_id: orderId,
          design_layout_id: v.design_layout?.id,
        }),
    }).then(({ data }) => {
      onLayoutAdded(data);
    });
  };

  const onDesignRemove = (layout: DesignLayout, orderDesign: OrderDesign) => {
    confirm(
      'Remove Design From Layout',
      'Are you sure you want to remove this design from this layout?',
      'error',
    ).then(() => {
      axios.delete(`/api/order-designs/${orderDesign.id}`).then(() => {
        setDesignLayouts((prev) =>
          prev.map((d) => {
            if (d.id === layout.id) {
              return {
                ...d,
                order_designs: d.order_designs.filter((od) => od.id !== orderDesign.id),
              };
            }
            return d;
          }),
        );
      });
    });
  };

  const addNewToLayout = (layout: DesignLayout) => {
    prompt<{ name: string; decoration_type: DecorationType }>({
      title: `Request New Design For ${layout.increment_id}`,
      fields: [
        FieldFactory.text('name').withSize('medium'),
        FieldFactory.belongsTo('decoration_type', 'decorationTypes'),
      ],
      validation: requiredFields(['name', 'decoration_type']),
      onSubmit: (v) =>
        axios.post('/api/order-designs', {
          name: v.name,
          decoration_type_id: v.decoration_type.id,
          design_layout_id: layout.id,
        }),
    }).then(({ data }) => {
      setEditing(data as OrderDesign);
      refetch();
    });
  };

  const addExistingToLayout = (layout: DesignLayout) => {
    prompt<{
      design: Design;
      ink_changes: InkChange[];
      all_customers?: boolean;
      include_incomplete?: boolean;
    }>({
      title: `Add Existing Design To ${layout.increment_id}`,
      fields: [
        FieldFactory.custom('design', ExistingDesignField),
        new GroupLayout('', [
          FieldFactory.boolean('all_customers', 'Include designs from other customers'),
          FieldFactory.boolean('include_incomplete', 'Include incomplete designs'),
        ]),
        FieldFactory.custom('ink_changes', InkChangeField),
      ],
      initialValues: {
        all_customers: false,
        include_incomplete: false,
        ink_changes: [],
      },
      validation: requiredFields(['design']),
      onSubmit: (v) =>
        axios.post('/api/order-designs', {
          design_id: v.design.id,
          design_layout_id: layout.id,
          ink_changes: v.ink_changes,
        }),
    }).then(() => {
      refetch();
    });
  };

  const onEditOrderDesign = (orderDesign: OrderDesign) => {
    prompt({
      title: 'Edit Linked Design',
      fields: [
        FieldFactory.belongsTo('design', 'designs'),
        FieldFactory.custom('ink_changes', InkChangeField),
      ],
      initialValues: _.pick(orderDesign, ['design', 'ink_changes']),
      validation: requiredFields(['design']),
      onSubmit: (v) => axios.put(`/api/order-designs/${orderDesign.id}`, v),
    }).then(() => {
      refetch();
    });
  };

  const onDelete = (layout: DesignLayout) => {
    confirm(
      'Delete Design Layout',
      'Are you sure you want to delete from the order?',
      'error',
    ).then(() => {
      axios.delete(`/api/design-layouts/${layout.id}`);
      setDesignLayouts((prev) => prev.filter((p) => p.id !== layout.id));
    });
  };

  const actions = (
    <Box py={1}>
      <Button variant="contained" onClick={addExisting} sx={{ mr: 1 }}>
        Start From Existing
      </Button>
      <Button onClick={createNew}>Create New Design Layout</Button>
    </Box>
  );

  const createSortHandler =
    (l: DesignLayout) =>
    ({ active, over }: DragEndEvent) => {
      setDesignLayouts((prev) =>
        prev.map((layout) => {
          if (layout.id === l.id && over) {
            const oldIndex = layout.order_designs.findIndex((od) => od.id === active.id);
            const newIndex = layout.order_designs.findIndex((od) => od.id === over.id);
            const newOrderDesigns = arrayMove(layout.order_designs, oldIndex, newIndex);
            axios.put(`/api/design-layouts/${layout.id}/order-designs`, {
              ids: newOrderDesigns.map((od) => od.id),
            });
            return { ...layout, order_designs: newOrderDesigns };
          }
          return layout;
        }),
      );
    };

  if (!designLayouts) {
    return <CircularProgress />;
  }

  if (isBlank) {
    return (
      <Paper>
        <Typography>This order type does not allow for decoration.</Typography>
      </Paper>
    );
  }

  return (
    <div>
      {designLayouts.length === 0 ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box textAlign="center" py={2} maxWidth="sm">
            <ArtTrack sx={{ color: 'grey.400', fontSize: 100 }} />
            <Typography variant="h6">Get Started with Art</Typography>
            <Typography color="textSecondary" sx={{ mb: 3 }}>
              Create layouts for your order consisting of new or existing designs. Each layout will
              be proofed separately.
            </Typography>
            {canEdit() && actions}
          </Box>
        </Box>
      ) : (
        <div>
          {canEdit() && actions}

          <Grid container spacing={2}>
            {designLayouts.map((layout) => (
              <Fragment key={layout.id}>
                <Grid item xs={12} lg={4}>
                  <DesignLayoutCard
                    layout={layout}
                    orderId={orderId}
                    isWebstore={isWebstore}
                    invoicedAt={invoicedAt}
                    onDelete={onDelete}
                    onRoster={onRoster}
                    canEdit={canEdit}
                    onLayoutUpdated={onLayoutUpdated}
                  />
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Card>
                    <CardHeader
                      title={`Designs for ${layout.increment_id}`}
                      action={
                        canEdit(layout) && (
                          <>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => addExistingToLayout(layout)}
                              sx={{ mr: 1 }}
                            >
                              Add Existing
                            </Button>
                            <Button size="small" onClick={() => addNewToLayout(layout)}>
                              Request New
                            </Button>
                          </>
                        )
                      }
                    />
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <DndContext onDragEnd={createSortHandler(layout)}>
                            <SortableContext items={layout.order_designs.map((od) => od.id)}>
                              {layout.order_designs.map((orderDesign) => (
                                <OrderDesignListItem
                                  key={orderDesign.id}
                                  orderDesign={orderDesign}
                                  sortable={layout.order_designs.length > 1}
                                  onEdit={
                                    canEdit(layout)
                                      ? () => onEditOrderDesign(orderDesign)
                                      : undefined
                                  }
                                  onEditDesign={
                                    canEdit(layout) ? () => setEditing(orderDesign) : undefined
                                  }
                                  onRemove={
                                    canEdit(layout)
                                      ? () => onDesignRemove(layout, orderDesign)
                                      : undefined
                                  }
                                  onEditImprints={() => setEditingImprints(orderDesign)}
                                />
                              ))}
                            </SortableContext>
                          </DndContext>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </div>
      )}

      <ImprintsDrawer
        open={!!editingImprints}
        onClose={() => setEditingImprints(undefined)}
        orderDesign={editingImprints}
        onSave={canEdit() ? refetch : undefined}
      />

      <RequestedDesignDrawer
        open={!!editing}
        onClose={() => setEditing(undefined)}
        orderDesignId={editing?.id}
        design={editing?.design}
        onSave={() => {
          setEditing(undefined);
          refetch();
        }}
      />

      <RosterDrawer
        layout={layoutForRoster}
        onClose={() => setLayoutForRoster(undefined)}
        onSaved={() => {
          setLayoutForRoster(undefined);
          refetch();
        }}
      />

      <LoadingBackdrop loading={isLoading} />
    </div>
  );
}
