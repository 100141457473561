import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Button,
  CardActions,
} from '@mui/material';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { change, FieldArrayFieldsProps, FieldArrayMetaProps } from 'redux-form';
import { useSpecificFormValues } from '../../hooks/formValues';
import { useRequiresPermissionAndRole } from '../../hooks/permissions';
import Field from '../../models/Field';
import TableField from '../../models/Fields/TableField';
import renderCell from '../../utils/renderCell';
import ClosableDrawer from '../ClosableDrawer';
import TableElementForm from './TableElementForm';

export function ReduxTableCell({
  form,
  name,
  field,
}: {
  form: string;
  name: string;
  field: Field;
}) {
  const value = useSpecificFormValues(form, (r) => _.get(r, name));
  return <TableCell>{renderCell(field, value)}</TableCell>;
}

export default function TableElement({
  field,
  fields,
  meta,
}: {
  field: TableField;
  fields: FieldArrayFieldsProps<any>;
  meta: FieldArrayMetaProps;
}) {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState<{ index: number; mode: 'create' | 'edit' } | undefined>();
  const requiresPermissionAndRole = useRequiresPermissionAndRole();
  const children = field.children.filter(requiresPermissionAndRole);
  const fieldName = `${fields.name}[${editing?.index || 0}]`;

  const onNew = () => {
    const newIndex = fields.length;
    setEditing({
      index: newIndex,
      mode: 'create',
    });
  };

  const onSubmit = (values: Record<string, any>) => {
    dispatch(change(meta.form, fieldName, values));
    setEditing(undefined);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {children.map((child) => (
              <TableCell key={child.name}>{child.label}</TableCell>
            ))}
            {!field.readOnly && <TableCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((f, index) => (
            <TableRow key={f}>
              {children.map((child) => (
                <ReduxTableCell
                  key={child.name}
                  form={meta.form}
                  field={child}
                  name={`${f}.${child.name}`}
                />
              ))}

              {!field.readOnly && (
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setEditing({
                        index,
                        mode: 'edit',
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small" onClick={() => fields.remove(index)}>
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}

          {fields.length === 0 && (
            <TableRow>
              <TableCell colSpan={children.length + 1}>
                <Typography variant="body2" color="textSecondary">
                  No rows found
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {!field.readOnly && (
        <CardActions>
          <Button color="secondary" onClick={onNew}>
            {field.addNewLabel}
          </Button>
        </CardActions>
      )}

      <ClosableDrawer
        open={Boolean(editing)}
        onClose={() => setEditing(undefined)}
        title={editing?.mode === 'create' ? 'Create' : 'Edit'}
      >
        {editing && (
          <TableElementForm
            key={fieldName}
            field={field}
            onSubmit={onSubmit}
            initialValues={editing.mode === 'edit' ? fields.get(editing.index) : {}}
          />
        )}
      </ClosableDrawer>
    </>
  );
}
