import { Box, LinearProgress, List, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FieldProps, StaticFormControl, useFormValues } from '@/admin';
import AddressListItem from '@/components/Addresses/AddressListItem';
import { Address, Order } from '@/models';

export default function BillingAddressChooser({ input, field, meta }: FieldProps) {
  const customerId = useFormValues((o: Order) => o.customer_id);

  const { data: addresses = [], isLoading } = useQuery(
    ['customerBillingAddresses', customerId],
    () =>
      axios
        .get<{ data: Address[] }>(`/api/customers/${customerId}/addresses?filter[is_billing]=1`)
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 5,
    },
  );

  return (
    <StaticFormControl field={field} meta={meta}>
      {isLoading ? (
        <LinearProgress />
      ) : addresses.length > 0 ? (
        <List>
          {addresses.map((a) => (
            <AddressListItem
              key={a.id}
              address={a}
              onToggle={() => input.onChange(a)}
              isSelected={a.id === input.value?.id}
            />
          ))}
        </List>
      ) : (
        <Box px={2} pb={2}>
          <Typography variant="body2" color="textSecondary">
            There are no billing addresses for this customer.
          </Typography>
        </Box>
      )}
    </StaticFormControl>
  );
}
