import { useEffect } from 'react';
import { Grid, CardHeader, Card, styled, LinearProgress } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import {
  FieldFactory,
  makeResourceQueryKey,
  StatusChip,
  useDialogs,
  useFormValues,
  useQueryStringState,
  useUpdateRecord,
} from '@/admin';
import OrderDesignCard from '@/components/Designs/OrderDesignCard';
import OrderSummaryField from '@/components/Orders/OrderSummaryField';
import OrderScraps from '@/components/Production/OrderScraps';
import { DEPARTMENT_STATUS_COLORS } from '@/constants';
import { Order, OrderItem, OrderScrap, ProductionEvent } from '@/models';
import NoteManagerCard from '../Notes/NoteManagerCard';
import NoteViewer from '../Notes/NoteViewer';
import ItemDesignTable from './ItemDesignTable';
import ProductionEventList from './ProductionEventList';

const StagedButton = styled('button')({
  background: 'none',
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
  '& > div': {
    cursor: 'pointer',
  },
});

const DEFAULT_EVENTS: ProductionEvent[] = [];

interface OrderForProduction extends Order {
  items: OrderItem[];
  scraps: OrderScrap[];
}

export default function ProductionOrder() {
  const [items, orderId, stagedStatus, scraps] = useFormValues((o: OrderForProduction) => [
    o.items,
    o.id,
    o.staged_status,
    o.scraps,
  ]);

  const eventsQueryKey = makeResourceQueryKey('ordersToProduce', orderId, 'productionEvents');
  const onUpdateRecord = useUpdateRecord();
  const queryClient = useQueryClient();
  const { prompt, confirm } = useDialogs();

  const {
    data: events = DEFAULT_EVENTS,
    isLoading,
    refetch,
  } = useQuery(eventsQueryKey, () =>
    axios
      .get<{ production_events: ProductionEvent[] }>(
        `/api/orders/${orderId}?with=production_events`,
      )
      .then(({ data }) => data.production_events),
  );

  const [stringEventId, setEventId] = useQueryStringState('event_id', String(events[0]?.id));
  const eventId = Number(stringEventId);
  const event = events.find((e) => e.id === eventId);

  const orderDesign = event ? event.order_design : null;

  useEffect(() => {
    const channel = window.Echo.private('production');

    channel.listen('ProductionEventStatusChanged', (e: { event: Partial<ProductionEvent> }) => {
      queryClient.setQueryData<ProductionEvent[]>(eventsQueryKey, (prev) => {
        if (!prev || prev.length === 0) {
          return prev;
        }
        return prev.map((ev) => {
          if (ev.id === e.event.id) {
            return { ...ev, ...e.event };
          }
          return ev;
        });
      });
    });

    return () => {
      channel.stopListening('ProductionEventStatusChanged');
      window.Echo.leave('production');
    };
  }, [queryClient]);

  const onEventSelect = (ev: ProductionEvent) => {
    setEventId(String(ev.id));
  };

  const onEventAction = (ev: ProductionEvent, action: string) => {
    axios.post(`/api/production-events/${ev.id}/action/${action}`).then(() => {
      refetch();
    });
  };

  const onUpdateQty = (ev: ProductionEvent) => {
    prompt({
      title: 'Update Quantity',
      fields: [FieldFactory.number('quantity').withSize('medium')],
      initialValues: _.pick(ev, 'quantity'),
      onSubmit: (v) => axios.put(`/api/production-events/${ev.id}`, v),
    }).then(() => {
      refetch();
    });
  };

  const onEventDeleted = (ev: ProductionEvent) => {
    confirm('Delete Event', 'Are you sure you want to delete this production event?', 'error').then(
      () => {
        axios.delete(`/api/production-events/${ev.id}`).then(() => {
          refetch();
        });
      },
    );
  };

  const onEventDuplicated = (ev: ProductionEvent) => {
    confirm('Duplicate Event', 'Are you sure you want to duplicate this production event?').then(
      () => {
        axios.post(`/api/production-events/${ev.id}/duplicate`).then(() => {
          refetch();
        });
      },
    );
  };

  const onToggledStaged = () => {
    prompt({
      title: 'Update Staging Status',
      fields: [
        FieldFactory.radio('staged_status', {
          no: 'No',
          partial: 'Started',
          yes: 'Yes',
        }).withoutLabel(),
      ],
      initialValues: { staged_status: stagedStatus },
      onSubmit: (v) => axios.post(`/api/orders/${orderId}/stage`, v),
    }).then(({ data }) => {
      onUpdateRecord(data);
    });
  };

  const stagedActions = (
    <>
      <span>Staged: </span>
      <StagedButton type="button" onClick={onToggledStaged}>
        <StatusChip status={stagedStatus} colors={DEPARTMENT_STATUS_COLORS} />
      </StagedButton>
    </>
  );

  return (
    <div>
      <NoteViewer resource="orders" resourceId={orderId} tag="production" />

      <Grid container spacing={3}>
        <Grid item md={9} xs={12}>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <ProductionEventList
              orderId={orderId}
              events={events}
              selected={event}
              onSelect={onEventSelect}
              onPerformAction={onEventAction}
              onDuplicate={onEventDuplicated}
              onDelete={onEventDeleted}
              onUpdateQty={onUpdateQty}
            />
          )}
        </Grid>
        <Grid item md={3} xs={12}>
          <OrderSummaryField />
        </Grid>

        {orderDesign && (
          <>
            <Grid item lg={5} xs={12}>
              <OrderDesignCard orderDesign={orderDesign} sx={{ mb: 2 }} />

              <NoteManagerCard
                resource="designs"
                resourceId={orderDesign.design.id}
                title="Design Notes"
              />
            </Grid>
            <Grid item lg={7} xs={12}>
              <Card>
                <CardHeader title="Items" action={stagedActions} />
                <ItemDesignTable items={items} orderDesign={orderDesign} />
              </Card>
              <OrderScraps scraps={scraps} />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
