import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Alert,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { startCase, sumBy } from 'lodash';
import moment from 'moment';
import {
  ClosableDrawer,
  curr,
  FieldFactory,
  TextButton,
  useDialogs,
  useHasPermission,
} from '@/admin';
import { InventoryCostLayer } from '@/models';

export default function CostLayerDrawer({
  open,
  onClose,
  variantId,
}: {
  open: boolean;
  onClose: () => void;
  variantId?: number;
}) {
  const { prompt } = useDialogs();
  const hasPermission = useHasPermission();

  const {
    data: layers,
    isLoading,
    refetch,
  } = useQuery(
    ['inventoryCostLayers', variantId],
    () =>
      axios
        .get<{ data: InventoryCostLayer[] }>(`/api/variants/${variantId}/cost-layers`)
        .then(({ data }) => data.data),
    {
      enabled: Boolean(variantId && open),
    },
  );

  const onRevalue = (layer: InventoryCostLayer) => {
    prompt({
      title: 'Revalue Cost Layer',
      fields: [FieldFactory.curr('cost').withSize('medium')],
      initialValues: {
        cost: layer.cost,
      },
      onSubmit: (v) => axios.put(`/api/variants/${variantId}/cost-layers/${layer.id}`, v),
    }).then(() => {
      refetch();
    });
  };

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Cost Layers for SKU">
      {isLoading && <CircularProgress />}

      {layers?.length === 0 && (
        <Alert severity="info">There are no active cost layers for this SKU.</Alert>
      )}

      {layers && layers.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {layers.map((l) => (
              <TableRow key={l.id}>
                <TableCell>{moment(l.created_at).format('l')}</TableCell>
                <TableCell>
                  {startCase(l.source_type)} #{l.source_id}
                </TableCell>
                <TableCell>{l.qty_remaining}</TableCell>
                <TableCell>
                  <TextButton
                    onClick={() => onRevalue(l)}
                    disabled={!hasPermission('write:inventory_adjustments')}
                  >
                    {curr(l.cost)}
                  </TextButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell>{sumBy(layers, 'qty_remaining')}</TableCell>
              <TableCell />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </ClosableDrawer>
  );
}
