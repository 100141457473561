import { CircularProgress, Grid, Button, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { plural } from 'pluralize';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import {
  DateField,
  Field,
  FieldFactory,
  Paper,
  ReduxField,
  RelationField,
  ResetButton,
  SaveButton,
  SelectField,
  useTitle,
} from '@/admin';

const getResourceFromModel = (model: string | undefined | null) => {
  if (!model) {
    return null;
  }
  const last = _.last(model.split('\\')) as string;
  return _.camelCase(plural(last));
};

interface ConfigItem {
  key: string;
  value: any;
  label: string;
  group: string | null;
  model?: string | null;
  type: 'select' | 'text' | 'belongsTo' | 'number' | 'date';
  options?: Record<string, string>;
}

const getFieldTypeFromConfig = (config: ConfigItem): Field => {
  switch (config.type) {
    case 'belongsTo':
      return new RelationField(config.key, getResourceFromModel(config.model) || 'users');
    case 'select':
      return new SelectField(config.key, config.options || {});
    case 'date':
      return new DateField(config.key);
    case 'number':
      return FieldFactory.number(config.key);
    case 'text':
    default:
      return FieldFactory.text(config.key);
  }
};

const getFieldFromConfig = (config: ConfigItem): Field => {
  const field = getFieldTypeFromConfig(config);
  if (config.label) {
    field.label = config.label;
  }
  return field;
};

const toForm = (data: ConfigItem[]) => _.chain(data).keyBy('key').mapValues('value').value();

function Config({ initialize, handleSubmit }: InjectedFormProps) {
  useTitle('Global Configuration', undefined, <SaveButton form="ConfigForm" />);

  const { data: fields } = useQuery(
    ['globalConfig'],
    () => axios.get<{ data: ConfigItem[] }>('/api/config').then(({ data }) => data.data),
    {
      onSuccess: (data) => {
        initialize(toForm(data));
      },
    },
  );

  if (!fields) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: 100 }}>
      {_.map(_.groupBy(fields, 'group'), (groupedFields, group) => (
        <Paper title={group} key={group}>
          <Grid container spacing={3}>
            {groupedFields.map((f) => (
              <Grid item lg={4} key={f.key}>
                <ReduxField field={getFieldFromConfig(f)} />
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}
      <Box display="flex" justifyContent="flex-end">
        <div>
          <ResetButton form="ConfigForm" />
          <Button type="submit" variant="contained" sx={{ ml: 2 }}>
            Save
          </Button>
        </div>
      </Box>
    </form>
  );
}

const onSubmit: FormSubmitHandler<Record<string, any>, InjectedFormProps> = (
  values,
  dispatch,
  props,
) => {
  axios.post('/api/config', values).then(({ data }) => {
    props.initialize(toForm(data.data));
  });
};

export default reduxForm({
  form: 'ConfigForm',
  onSubmit,
})(Config);
