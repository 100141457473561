import { Button, CircularProgress, Alert } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import 'codemirror/mode/sql/sql';
import _ from 'lodash';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { change } from 'redux-form';
import { FieldProps, useSpecificFormValues } from '@/admin';
import { Report } from '@/models';
import { ReportResultsRow } from '@/responses';
import ReportTable from './Types/ReportTable';

export default function ReportQueryBuilder({ input, meta }: FieldProps) {
  const className = useSpecificFormValues(meta.form, (r: Report) => r.class);

  const previewRequest = useMutation<ReportResultsRow[], AxiosError<{ error?: string }>>(
    () =>
      axios
        .post<{ data: ReportResultsRow[] }>('/api/reports/preview', { query: input.value })
        .then(({ data }) => data.data),
    {
      onSuccess: (rows) => {
        const firstRow = rows[0];
        if (firstRow) {
          meta.dispatch(change(meta.form, '_columns', _.keys(firstRow)));
        }
      },
    },
  );

  if (className) {
    return <div>This query is controlled by a class, and therefore cannot be edited.</div>;
  }

  return (
    <div>
      <div style={{ height: 600 }}>
        <CodeMirror
          value={input.value}
          options={{
            mode: 'sql',
            theme: 'material',
            lineNumbers: true,
          }}
          onBeforeChange={(editor, data, value) => {
            input.onChange(value);
          }}
        />
      </div>
      <Button variant="outlined" sx={{ my: 2 }} onClick={() => previewRequest.mutate()}>
        Preview
      </Button>

      {previewRequest.isLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {previewRequest.isError ? (
            <Alert severity="error">
              {previewRequest.error.response?.data?.error || 'An unknown error occurred'}
            </Alert>
          ) : null}
          {previewRequest.data && <ReportTable results={previewRequest.data} />}
        </div>
      )}
    </div>
  );
}
