import { ReactNode, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { common } from '@mui/material/colors';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Color from 'color';

export const primaryColor = '#045AFF';
export const secondaryColor = '#045AFF';
export const drawerColor = '#0A1428';

export const lighterPrimaryColor = Color(primaryColor).lighten(0.2).rgb().string();
export const darkerPrimaryColor = Color(primaryColor).darken(0.2).rgb().string();

export const lighterSecondaryColor = Color(secondaryColor).lighten(0.2).rgb().string();
export const darkerSecondaryColor = Color(secondaryColor).darken(0.2).rgb().string();

// A theme with custom primary and secondary color.
export default function MuiProvider({ children }: { children: ReactNode }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () =>
      createTheme({
        drawerWidth: 225,
        drawerColor,
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            light: lighterPrimaryColor,
            main: primaryColor,
            dark: darkerPrimaryColor,
            contrastText: common.white,
          },
          secondary: {
            light: lighterSecondaryColor,
            main: secondaryColor,
            dark: darkerSecondaryColor,
            contrastText: common.white,
          },
          background: {
            default: prefersDarkMode ? '#121212' : '#f9f9f9',
          },
        },
      }),
    [prefersDarkMode],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
