import { ChangeHistory } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { TextLink, useHasPermission } from '@/admin';
import { OrderDesign } from '@/models';

export default function OrderDesignLabel({ orderDesign }: { orderDesign: OrderDesign }) {
  const { design, ink_changes: inkChanges } = orderDesign;
  const hasPermission = useHasPermission();

  const getTooltip = () => (
    <div>
      <u>Ink Changes</u>
      {inkChanges.map((change) => (
        <div key={change.id}>
          <span>{design.colors?.find((c) => c.id === change.design_color_id)?.color}</span>
          <span> &rarr; </span>
          <span>{change.color}</span>
        </div>
      ))}
    </div>
  );

  return (
    <Typography variant="body2">
      <TextLink disabled={!hasPermission('write:designs')} to={`/designs/${design.id}`}>
        {design.increment_id}
      </TextLink>
      {inkChanges && inkChanges.length > 0 && (
        <Tooltip title={getTooltip()}>
          <ChangeHistory style={{ verticalAlign: 'middle', marginLeft: 4 }} />
        </Tooltip>
      )}
    </Typography>
  );
}
