import { Button } from '@mui/material';
import { reduxForm, InjectedFormProps } from 'redux-form';
import TableField from '../../models/Fields/TableField';
import StackLayout from '../../models/Layouts/StackLayout';
import stopProp from '../../utils/stopProp';
import EditFormFields from '../EditFormFields';

type FormShape = Record<string, any>;

interface TableElementDrawerProps {
  field: TableField;
  onSubmit: (values: FormShape) => void;
}

function TableElementForm({
  field,
  onSubmit,
  handleSubmit,
  submitting,
}: InjectedFormProps<FormShape, TableElementDrawerProps> & TableElementDrawerProps) {
  return (
    <form onSubmit={stopProp(handleSubmit)(onSubmit)}>
      <EditFormFields fields={field.children} defaultLayout={StackLayout} />

      <Button disabled={submitting} type="submit" variant="contained" sx={{ mt: 3 }}>
        Submit
      </Button>
    </form>
  );
}

export default reduxForm<FormShape, TableElementDrawerProps>({
  form: 'TableElementForm',
})(TableElementForm);
