import _ from 'lodash';

function getAllValues(object: Record<string, any>, path: string) {
  if (!path) {
    return object;
  }
  const indexOfWildcard = path.indexOf('*');
  if (indexOfWildcard === -1) {
    return [_.get(object, path)];
  }

  const beforeWildcard = path.slice(0, indexOfWildcard - 1); // kits
  const afterWildcard = path.slice(indexOfWildcard + 2);
  return _.flatten(
    _.get(object, beforeWildcard, []).map((kit: Record<string, any>) =>
      getAllValues(kit, afterWildcard),
    ),
  );
}

export default function searchCollection<T extends Record<string, any>>(
  collection: T[],
  searchTerm: string | null | undefined,
  props: (keyof T | string)[],
): T[] {
  if (!searchTerm) {
    return collection;
  }

  return collection.filter((object) => {
    const values = props
      ? _.flatten(props.map((path) => getAllValues(object, path as string)))
      : Object.values(object);
    const longString = values.join(' ').toLowerCase();
    return longString.includes(searchTerm.toLowerCase());
  });
}
