import { useState, useMemo, useEffect, useRef, ReactNode } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import searchCollection from '@/utils/searchCollection';
import DebouncedTextField from './DebouncedTextField';

export default function GroupedPaginatedTable<T extends Record<string, any>>({
  items,
  renderRow,
  header,
  footer,
  searchable,
  initialRowsPerPage = 25,
  groupBy,
  renderGroupHeader,
}: {
  items: T[];
  renderRow: (r: T, index: number, groupLength: number) => ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  searchable?: string[];
  initialRowsPerPage?: number;
  groupBy: (item: T, index: number) => string;
  renderGroupHeader: (items: T[]) => ReactNode;
}) {
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const prevLength = useRef<number>();

  const groups = useMemo(() => {
    const filteredItems = searchable ? searchCollection(items, query, searchable) : items;
    return _.groupBy(filteredItems, groupBy);
  }, [items, query, searchable, groupBy]) as Record<string, T[]>;

  const groupKeys = Object.keys(groups);
  const numGroups = groupKeys.length;
  const groupKeysOnPage = groupKeys.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  // Go to last page when item added
  useEffect(() => {
    if (prevLength.current && numGroups > prevLength.current) {
      setPage(Math.ceil(numGroups / rowsPerPage) - 1);
    }
    prevLength.current = numGroups;
  }, [numGroups]);

  return (
    <>
      {items.length > rowsPerPage && searchable && (
        <Box px={2}>
          <DebouncedTextField
            initialValue={query}
            onChange={(value: string) => {
              setQuery(value);
              setPage(0);
            }}
            size="small"
            type="search"
            label="Search"
            fullWidth
            style={{ maxWidth: 300 }}
          />
        </Box>
      )}

      <TableContainer>
        <Table>
          {header && <TableHead>{header}</TableHead>}

          {groupKeysOnPage.map((groupKey) => {
            const group = groups[groupKey];
            return (
              <TableBody key={groupKey}>
                {group.length > 1 && renderGroupHeader && renderGroupHeader(group)}
                {group.map((item, index) =>
                  renderRow(item, page * rowsPerPage + index, group.length),
                )}
              </TableBody>
            );
          })}

          {numGroups === 0 && (
            <TableBody>
              <TableRow>
                {/* @ts-ignore */}
                <TableCell colSpan="100%">
                  <Typography color="textSecondary" variant="body2">
                    No results found
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {footer && <TableBody>{footer}</TableBody>}
          {numGroups > rowsPerPage && (
            <TablePagination
              count={numGroups}
              page={page}
              onPageChange={(e, p) => setPage(p)}
              onRowsPerPageChange={(e) => {
                setPage(0);
                setRowsPerPage(Number(e.target.value));
              }}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={_.uniq([initialRowsPerPage, 25, 50, 100])}
            />
          )}
        </Table>
      </TableContainer>
    </>
  );
}
