import { useEffect, useState } from 'react';
import { Button, Grid, Tabs, Tab, Stack } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { ClosableDrawer, FieldFactory, ReduxField } from '@/admin';
import ReportEditor from '@/components/Reports/ReportEditor';
import { REPORT_TYPES } from '@/constants';
import { Report } from '@/models';

interface EditReportDrawerProps {
  dashboardId?: number;
  report?: Report;
  open: boolean;
  onClose: () => void;
  onSuccess: (r: Report) => void;
}

function EditReportDrawer({
  dashboardId,
  report,
  open,
  onClose,
  onSuccess,
  initialize,
  submitting,
  handleSubmit,
  form,
}: InjectedFormProps<Report, EditReportDrawerProps> & EditReportDrawerProps) {
  const [tab, setTab] = useState('setup');

  useEffect(() => {
    if (report) {
      initialize(report);
    }
  }, [open]);

  const onSubmit = (values: Partial<Report>) => {
    const url = dashboardId
      ? `/api/dashboards/${dashboardId}/reports/${values.id}`
      : `/api/reports/${values.id}`;

    return axios.put(url, _.omit(values, 'order')).then(({ data }) => {
      onSuccess(data);
    });
  };

  return (
    <ClosableDrawer
      closeOnClickOutside={false}
      open={open}
      onClose={onClose}
      title="Edit Report"
      width={800}
    >
      <Tabs value={tab} onChange={(e, t) => setTab(t)} sx={{ mt: -2, mb: 2 }}>
        <Tab value="setup" label="Setup" />
        <Tab value="access" label="Access" />
      </Tabs>

      <form onSubmit={handleSubmit(onSubmit)}>
        {tab === 'setup' && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReduxField field={FieldFactory.text('name')} />
            </Grid>
            {dashboardId && (
              <Grid item xs={12}>
                <ReduxField field={FieldFactory.text('title').withLabel('Title for Dashboard')} />
              </Grid>
            )}
            <Grid item xs={6}>
              <ReduxField field={FieldFactory.select('type', REPORT_TYPES)} />
            </Grid>
            <Grid item xs={6}>
              {dashboardId && (
                <ReduxField field={FieldFactory.number('pivot.columns').withLabel('Columns')} />
              )}
            </Grid>
            <Grid item xs={12}>
              <ReduxField field={FieldFactory.textarea('description')} />
            </Grid>
            <Grid item xs={12}>
              <ReduxField field={FieldFactory.text('group')} />
            </Grid>
            <Grid item xs={12}>
              <ReduxField field={FieldFactory.text('summary')} />
            </Grid>
            <Grid item xs={12}>
              <ReportEditor form={form} />
            </Grid>
          </Grid>
        )}

        {tab === 'access' && (
          <Stack spacing={2}>
            <ReduxField field={FieldFactory.hasMany('roles', 'roles')} />
            <ReduxField
              field={FieldFactory.boolean('is_global', 'Give all users access to this report')}
            />
          </Stack>
        )}

        <Button type="submit" variant="contained" disabled={submitting} sx={{ mt: 3 }}>
          Submit
        </Button>
      </form>
    </ClosableDrawer>
  );
}

export default reduxForm<Report, EditReportDrawerProps>({
  form: 'EditReportForm',
})(EditReportDrawer);
