import { ReactElement } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { FieldArrayProps } from '../../types';
import Field from '../Field';

export default class CustomArrayField extends Field {
  component: (props: FieldArrayProps) => ReactElement | null;

  constructor(name: string, component: (props: FieldArrayProps) => ReactElement | null) {
    super(name);
    this.component = component;
    this.isArray = true;
  }

  renderArrayComponent(props: WrappedFieldArrayProps): ReactElement | null {
    const Component = this.component;
    return <Component {...props} field={this} />;
  }
}
