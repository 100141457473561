import { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { reduxForm, InjectedFormProps, FormSubmitHandler } from 'redux-form';
import Field from '../../models/Field';
import StackLayout from '../../models/Layouts/StackLayout';
import stopProp from '../../utils/stopProp';
import ClosableDrawer from '../ClosableDrawer';
import EditFormFields from '../EditFormFields';

type FilterForm = Record<string, any>;

interface FilterDrawerProps {
  filterableFields: Field[];
  onSuccess: (v: FilterForm) => void;
  open: boolean;
  onClose: () => void;
}

function FilterDrawer({
  filterableFields,
  onSuccess,
  open,
  onClose,
  initialValues,
  handleSubmit,
  initialize,
}: InjectedFormProps<FilterForm, FilterDrawerProps> & FilterDrawerProps) {
  useEffect(() => {
    if (open) {
      initialize(initialValues);
    }
  }, [open]);

  const onSubmit: FormSubmitHandler<FilterForm, FilterDrawerProps> = (values) => {
    onSuccess(values);
    onClose();
  };

  return (
    <ClosableDrawer title="Filters" open={open} onClose={onClose}>
      <form onSubmit={stopProp(handleSubmit)(onSubmit)}>
        <EditFormFields
          fields={filterableFields.map((f) => f.getFilterField())}
          defaultLayout={StackLayout}
        />

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button type="button" onClick={() => initialize({})} sx={{ mr: 2 }}>
            Reset
          </Button>

          <Button type="submit" variant="contained">
            Apply Filters
          </Button>
        </Box>
      </form>
    </ClosableDrawer>
  );
}

export default reduxForm<FilterForm, FilterDrawerProps>({
  form: 'FilterForm',
})(FilterDrawer);
