import { Edit } from '@mui/icons-material';
import { Box, CardHeader, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { Can, useDialogs, useFormValues, useGetResource, useOnReloadRecord } from '@/admin';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import { InventoryLocation } from '@/models';
import InventoryLevels from './InventoryLevels';

export default function InventoryLocationPage() {
  const location = useFormValues((l: InventoryLocation) => l);
  const getResource = useGetResource();

  const { prompt } = useDialogs();
  const onReload = useOnReloadRecord();

  const onEdit = () => {
    prompt({
      title: 'Edit Location',
      fields: getResource('inventoryLocations').fields,
      initialValues: _.omit(location, 'id'),
      onSubmit: (v) => axios.put(`/api/inventory-locations/${location.id}`, v),
    }).then(() => {
      onReload();
    });
  };

  const action = (
    <Can permission="write:inventory_locations">
      <Tooltip title="Edit Location Details">
        <IconButton onClick={onEdit} size="large">
          <Edit />
        </IconButton>
      </Tooltip>
    </Can>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        <CardWithGutter>
          <CardHeader title={location.path} action={action} />
          <Box px={2} pb={2} mt={-1}>
            {location.location_group && (
              <Typography gutterBottom>Group: {location.location_group.name}</Typography>
            )}
            {location.description && (
              <Typography gutterBottom variant="body2">
                {location.description}
              </Typography>
            )}
          </Box>
        </CardWithGutter>
      </Grid>
      <Grid item xs={12} lg={8}>
        <CardWithGutter>
          <CardHeader title="SKUs in Location" />
          <InventoryLevels
            filterKey="location_id"
            filterValue={location.id}
            label={location.path}
          />
        </CardWithGutter>
      </Grid>
    </Grid>
  );
}
