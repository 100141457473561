import { Backdrop, CircularProgress } from '@mui/material';
import { useAppContext } from '../contexts/AppContext';

export default function LoadingBackdrop() {
  const { isLoading } = useAppContext();

  return (
    <Backdrop
      sx={{
        color: 'background.paper',
        zIndex: 'drawer',
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
