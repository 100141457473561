import { Block, CheckCircle, Help, LocalShipping } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { InventoryCheckRes, VendorInventorySku } from '@/responses';

const getIconForInventoryRow = (inv: VendorInventorySku) => {
  if (inv.status === 'instock') {
    return <CheckCircle style={{ color: green[500] }} />;
  }
  if (inv.status === 'backordered') {
    return <LocalShipping style={{ color: orange[400] }} />;
  }
  if (inv.status === 'unknown') {
    return <Help style={{ color: grey[600] }} />;
  }
  return <Block style={{ color: red[500] }} />;
};

export default function InventoryCheckIcon({
  inventory,
  sku,
}: {
  inventory?: InventoryCheckRes;
  sku?: string;
}) {
  if (inventory && sku && inventory[sku]) {
    const copyToClipboard = () => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(inventory[sku].message);
      }
    };

    return (
      <Tooltip title={inventory[sku].message}>
        <IconButton size="small" onClick={copyToClipboard}>
          {getIconForInventoryRow(inventory[sku])}
        </IconButton>
      </Tooltip>
    );
  }

  return null;
}
