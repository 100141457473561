import { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  TableSortLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Hidden,
  Chip,
  Box,
  Skeleton,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { FieldFactory, NonReduxField, useQueryStringState, useTitle } from '@/admin';
import CustomerReceivableRow from '@/components/Receivables/CustomerReceivableRow';
import ReceivablesSummary from '@/components/Receivables/ReceivablesSummary';
import { RECEIVABLE_BREAKDOWN_LABELS } from '@/constants';
import { ReceivablesCustomer, ReceivablesSummary as SummaryModel, User } from '@/models';
import useMutateQueryData from '@/utils/useMutateQueryData';

const COLUMNS = {
  name: 'Customer',
  'sales_rep.name': 'Sales Rep',
  'breakdown.current.balance': RECEIVABLE_BREAKDOWN_LABELS.current,
  'breakdown.1_30.balance': RECEIVABLE_BREAKDOWN_LABELS['1_30'],
  'breakdown.31_60.balance': RECEIVABLE_BREAKDOWN_LABELS['31_60'],
  'breakdown.61_90.balance': RECEIVABLE_BREAKDOWN_LABELS['61_90'],
  'breakdown.91_.balance': RECEIVABLE_BREAKDOWN_LABELS['91_'],
  'breakdown.total.balance': RECEIVABLE_BREAKDOWN_LABELS['total'],
};

type ReceivableMode = 'all' | 'past_due' | 'current';

interface ReceivableRes {
  data: ReceivablesCustomer[];
  summary: SummaryModel;
  reps: User[];
}

export default function Receivables() {
  useTitle('Receivables');

  const [sort, setSort] = useState<[string, 'asc' | 'desc']>(['breakdown.total.balance', 'desc']);
  const [stringMode, setMode] = useQueryStringState('mode', 'past_due');
  const [rep, setRep] = useState<User>();
  const repId = rep?.id;
  const mode = stringMode as ReceivableMode;
  const queryKey = ['receivables', mode, repId];
  const setData = useMutateQueryData<ReceivableRes>(queryKey);
  const { data } = useQuery(queryKey, () =>
    axios
      .get<ReceivableRes>('/api/receivables', { params: { mode, rep_id: repId } })
      .then(({ data }) => data),
  );

  const customers = data?.data;
  const summary = data?.summary;
  const reps = data?.reps;

  const handleSort = (prop: string) => () => {
    setSort((prev) => {
      if (prev[0] == prop && prev[1] === 'asc') {
        return [prop, 'desc'];
      }
      return [prop, 'asc'];
    });
  };

  const handleMode = (prop: ReceivableMode) => () => {
    setMode(prop);
  };

  const onUpdated = (customerId: number) => (data: Partial<ReceivablesCustomer>) => {
    setData((prev) => ({
      ...prev,
      data: prev.data.map((cust) => {
        if (cust.id === customerId) {
          return { ...cust, ...data };
        }
        return cust;
      }),
    }));
  };

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: 8, alignItems: 'center' }}>
        <RadioGroup row>
          <FormControlLabel
            control={<Radio checked={mode === 'past_due'} onClick={handleMode('past_due')} />}
            label="Past Due"
          />
          <FormControlLabel
            control={<Radio checked={mode === 'current'} onClick={handleMode('current')} />}
            label="Current"
          />
          <FormControlLabel
            control={<Radio checked={mode === 'all'} onClick={handleMode('all')} />}
            label="All Receivables"
          />
        </RadioGroup>

        {reps && reps.length > 1 && (
          <div style={{ marginLeft: 'auto', width: 300 }}>
            <Hidden lgDown>
              <NonReduxField
                onChange={(value) => setRep(reps.find((r) => r.id === (value as number)))}
                value={repId}
                field={FieldFactory.select(
                  'rep',
                  reps.map((r) => ({
                    label: r.name,
                    value: r.id,
                  })),
                )}
              />
            </Hidden>
          </div>
        )}

        {rep && (
          <Box sx={{ ml: 'auto' }}>
            <Chip onClick={() => setRep(undefined)} label={<div>{rep.name} &times;</div>} />
          </Box>
        )}
      </div>

      <Box mb={2}>
        <ReceivablesSummary summary={summary} mode={mode} />
      </Box>

      {customers ? (
        <Card>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {_.map(COLUMNS, (label, key) => (
                    <TableCell
                      key={key}
                      align={key.includes('balance') || key.includes('count') ? 'right' : 'left'}
                    >
                      <TableSortLabel
                        active={sort[0] === key}
                        direction={sort[1]}
                        onClick={handleSort(key)}
                      >
                        {label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {_.orderBy(customers, [sort[0]], [sort[1]]).map((customer) => (
                  <CustomerReceivableRow
                    key={`${customer.id}.${customer.sales_rep?.id}.${customer.business.id}`}
                    customer={customer}
                    onUpdated={onUpdated(customer.id)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      ) : (
        <Card>
          <CardContent>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </CardContent>
        </Card>
      )}
    </div>
  );
}
