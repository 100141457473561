import { FieldFactory, Resource } from '@/admin';
import { TRANSACTION_TYPES } from '@/constants';
import { BankingRule } from '@/models';

export default function bankingRules() {
  return new Resource<BankingRule>('Rules')
    .withApiEndpoint('/api/banking-rules')
    .setUseDrawer()
    .withDefaultSort('-id')
    .withColumns([FieldFactory.text('id').withLabel('#').sortable()])
    .withDefaultValues({
      transaction_type: 'expense',
    })
    .addFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.text('match_description')
        .withLabel('Descriptor Matches')
        .withHelp('Will match if the statement descriptor contains this text'),
      FieldFactory.text('match_amount')
        .withLabel('Amount Matches')
        .withHelp('e.g. >10, =20, <=20, etc.'),
      FieldFactory.text('match_account_owner')
        .withLabel('Account Owner Matches')
        .withHelp('e.g. David, Brad, Brian, etc.'),
      FieldFactory.select('transaction_type', TRANSACTION_TYPES).sortable().filterable(),
      FieldFactory.belongsTo('account', 'accounts').filterable('filter[account_id]'),
      FieldFactory.belongsTo('vendor', 'vendors').filterable('filter[vendor_id]'),
      FieldFactory.boolean('auto_create', 'Auto Create'),
    ]);
}
