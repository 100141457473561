import { styled } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const Label = styled('div', {
  shouldForwardProp: (prop) => !String(prop).startsWith('gutter'),
})<{ gutterBottom?: boolean; gutterLeft?: boolean; gutterTop?: boolean; gutterRight?: boolean }>(
  ({ theme, gutterBottom, gutterLeft, gutterTop, gutterRight }) => ({
    padding: '1px 8px',
    background: alpha(theme.palette.secondary.main, 0.2),
    color: theme.palette.primary.main,
    fontWeight: 500,
    display: 'inline-block',
    borderRadius: 4,
    fontSize: '.85rem',
    marginRight: gutterRight ? 8 : 4,
    marginLeft: gutterLeft ? 8 : 0,
    marginBottom: gutterBottom ? 8 : 0,
    marginTop: gutterTop ? 8 : 0,
  }),
);

export const TotalTable = styled('table')(({ theme }) => ({
  width: '100%',
  fontSize: 15,
  background: theme.palette.action.hover,
  padding: theme.spacing(2),
}));

export const TotalLabel = styled('th')({
  textAlign: 'right',
  padding: '4px 12px 4px 0',
  fontWeight: 500,
});

export const TotalValue = styled('td')({
  textAlign: 'right',
  padding: '4px 12px 4px 0',
});

export default Label;
