import { ArrowForward } from '@mui/icons-material';
import {
  CardContent,
  List,
  ListItemButton,
  ListItemText,
  Card,
  CardHeader,
  ListItemSecondaryAction,
} from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { StatusChip, useFormValues, curr } from '@/admin';
import { TotalLabel, TotalValue } from '@/components/Shared/Label';
import { TRANSACTION_PAID_COLORS, TRANSACTION_PAID_LABELS } from '@/constants';
import { Bill } from '@/models';

export default function BillPaymentsCard() {
  const [balance, total, paid, status, payments] = useFormValues((b: Bill) => [
    b.balance,
    b.total,
    b.paid,
    b.paid_status,
    b.payments,
  ]);

  return (
    <Card>
      <CardHeader title="Bill Payments" />
      <CardContent sx={{ py: 0 }}>
        <StatusChip
          sx={{ width: '100%' }}
          status={status}
          colors={TRANSACTION_PAID_COLORS}
          labels={TRANSACTION_PAID_LABELS}
        />
      </CardContent>

      {payments && (
        <List>
          {payments.map((p) => (
            <ListItemButton key={p.id} component={Link} to={`/transactions/${p.transaction_id}`}>
              <ListItemText
                primary={`Bill Payment #${p.transaction_id}`}
                secondary={moment(p.created_at).format('llll')}
              />
              <ListItemSecondaryAction>
                <ArrowForward sx={{ color: 'text.secondary' }} />
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      )}

      <CardContent>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <TotalLabel>Bill Total</TotalLabel>
              <TotalValue>{curr(total)}</TotalValue>
            </tr>
            <tr>
              <TotalLabel>Paid</TotalLabel>
              <TotalValue>{curr(paid)}</TotalValue>
            </tr>
            <tr>
              <TotalLabel>Balance</TotalLabel>
              <TotalValue>{curr(balance)}</TotalValue>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}
