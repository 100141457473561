import { Autocomplete as BaseAutocomplete, TextField } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { FieldProps } from '../../types';
import Field from '../Field';

export function AutocompleteComponent({ input, field, meta }: FieldProps<AutocompleteField>) {
  return (
    <BaseAutocomplete
      freeSolo
      options={field.options}
      inputValue={input.value}
      onInputChange={(e, value) => input.onChange(value)}
      disabled={field.disabled}
      size={field.size}
      renderInput={(params) => <TextField {...field.getTextFieldProps(meta)} {...params} />}
    />
  );
}

export default class AutocompleteField extends Field {
  options: string[];

  constructor(name: string, options: string[]) {
    super(name);
    this.options = options;
  }

  renderEditComponent(props: WrappedFieldProps) {
    return <AutocompleteComponent {...props} field={this} />;
  }
}
