import { useCallback } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { usePlaidLink } from 'react-plaid-link';
import { useOnReloadRecord, useShowLoading } from '@/admin';

export default function PlaidLink({
  id,
  token,
  isUpdate,
}: {
  id: number;
  token: string;
  isUpdate: boolean;
}) {
  const onReload = useOnReloadRecord();
  const showLoading = useShowLoading();

  const onSuccess = useCallback(
    (publicToken: string, metadata: { accounts: { id: string }[] }) => {
      showLoading(
        axios.put(`/api/accounts/${id}/plaid`, {
          public_token: publicToken,
          account_id: _.map(metadata.accounts, 'id'),
        }),
      ).then(() => {
        onReload();
      });
    },
    [id, onReload, showLoading],
  );

  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
  });

  return (
    <Button onClick={() => open()} disabled={!ready}>
      {isUpdate ? 'Update Credentials via Plaid' : 'Connect via Plaid'}
    </Button>
  );
}
