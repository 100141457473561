import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Close, DragHandle, Edit } from '@mui/icons-material';
import { TableRow, TableCell, Tooltip, IconButton, styled, TableBody } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { useDialogs, TextButton, Field, numString, FieldFactory } from '@/admin';
import OrderItemRow, { BaseOrderLineProps } from '@/components/Orders/Items/OrderItemRow';
import ProductField from '@/components/Products/ProductField';
import { requiredFields } from '@/helpers';
import { OrderItem, Product } from '@/models';
import useOrderableApiUrl from '@/utils/useOrderableApiUrl';
import OrderItemProduct from './OrderItemProduct';

const HeaderTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
}));

export default function OrderItemGroup(
  props: {
    items: OrderItem[];
    groupKey: string;
    onSortEnd: (groupKey: string, e: DragEndEvent) => void;
  } & BaseOrderLineProps,
) {
  const { isOrderLocked, items, onReload, groupKey, onSortEnd } = props;
  const { confirm, prompt } = useDialogs();
  const baseUrl = useOrderableApiUrl('items');

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: groupKey,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onRemove = () => {
    const hasDesigns = items.some((i) => Object.keys(i.order_design_ids).length > 0);
    let message = 'Are you sure you want to delete all items of this product?';
    if (hasDesigns) {
      message += ' At least one of these items has design imprints associated with it.';
    }
    confirm('Remove Product', message, hasDesigns ? 'warning' : undefined).then(() => {
      Promise.all(items.map((item) => axios.delete(`${baseUrl}/${item.id}`))).then(() => {
        onReload();
      });
    });
  };

  const onEditField = (field: Field) => {
    const fields = [
      field.with({
        size: 'medium',
      }),
    ];
    if (field.name === 'cost') {
      fields.push(FieldFactory.boolean('use_database_cost'));
    }
    prompt({
      title: `Edit ${_.startCase(field.name)}`,
      fields,
      initialValues: _.pick(items[0], [field.name, 'use_database_cost']),
      onSubmit: (v) => Promise.all(items.map((item) => axios.put(`${baseUrl}/${item.id}`, v))),
    }).then(() => {
      onReload();
    });
  };

  const onChangeProduct = () => {
    prompt({
      title: 'Change Product',
      description: `This will change the style/color of these ${numString(
        items.length,
        'items',
      )} to the same size of the product you specify below.`,
      fields: [new ProductField('product')],
      validation: requiredFields(['product']),
      onSubmit: (v: { product: Product }) =>
        axios.post(`${baseUrl}/change-product`, {
          order_item_ids: _.map(items, 'id'),
          new_product_id: v.product.id,
        }),
    }).then(() => {
      onReload();
    });
  };

  const getEditButtonForField = (fieldName: keyof OrderItem, isPercent?: boolean) => {
    if (isOrderLocked) {
      return null;
    }

    const field = isPercent
      ? FieldFactory.percent(fieldName)
      : FieldFactory.curr(fieldName).with({ maxDecimals: 4 });

    const values = items.map((i) => i[fieldName]);
    const defaultValue = values.every((v) => v === values[0]) ? values[0] : null;
    const onClick = () => onEditField(field);

    if (defaultValue != null) {
      return <TextButton onClick={onClick}>{field.renderCell(defaultValue)}</TextButton>;
    }

    return (
      <IconButton onClick={onClick} size="small">
        <Edit fontSize="small" />
      </IconButton>
    );
  };

  return (
    <TableBody style={style} ref={setNodeRef}>
      <HeaderTableRow>
        <TableCell sx={{ pr: 0 }}>
          <DragHandle {...attributes} {...listeners} />
        </TableCell>
        <TableCell style={{ minWidth: 400 }}>
          <OrderItemProduct
            item={items[0]}
            actions={
              !isOrderLocked &&
              items.some((i) => i.qty_sourced === 0) && (
                <div>
                  <IconButton onClick={onChangeProduct}>
                    <Edit fontSize="small" />
                  </IconButton>
                </div>
              )
            }
          />
        </TableCell>
        <TableCell>{_.truncate(_.get(items[0], 'vendor.name'), { length: 25 })}</TableCell>
        <TableCell colSpan={2} />
        <TableCell>{getEditButtonForField('price')}</TableCell>
        <TableCell>{getEditButtonForField('discount', true)}</TableCell>
        <TableCell />
        <TableCell>{getEditButtonForField('cost')}</TableCell>
        <TableCell colSpan={3} />
        <TableCell>
          {!isOrderLocked && items.every((i) => i.qty_sourced === 0) && (
            <Tooltip title="Remove Product">
              <IconButton size="small" onClick={onRemove}>
                <Close fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </HeaderTableRow>

      <DndContext onDragEnd={(e) => onSortEnd(groupKey, e)}>
        <SortableContext items={items.map((i) => i.id)}>
          {items.map((item) => (
            <OrderItemRow key={item.id} item={item} isGrouped {...props} />
          ))}
        </SortableContext>
      </DndContext>
    </TableBody>
  );
}
