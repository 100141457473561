import { ChangeEvent } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import {
  DrawerFormSection,
  FieldFactory,
  ReduxField,
  useFormActions,
  useOnChangeEffect,
  useSpecificFormValues,
} from '@/admin';
import VariantField from '@/components/Products/VariantField';
import { Product } from '@/models';

export default function VariantSection({
  isCustom,
  handleCustomChange,
  form,
}: {
  isCustom: boolean;
  handleCustomChange: (e: ChangeEvent<HTMLInputElement>) => void;
  form: string;
}) {
  const { change } = useFormActions(form);
  const variant = useSpecificFormValues(
    form,
    (o: { variant: { product: Product } | null }) => o.variant,
  );

  useOnChangeEffect(() => {
    if (variant) {
      change('description', variant.product.name);
    }
  }, [variant]);

  return (
    <DrawerFormSection title="Product">
      <ReduxField field={FieldFactory.text('description').setRequired().withSize('medium')} />

      <RadioGroup
        row
        value={isCustom ? 'custom' : 'database'}
        onChange={handleCustomChange}
        sx={{ mb: 1 }}
      >
        <FormControlLabel control={<Radio />} value="database" label="Database" />
        <FormControlLabel control={<Radio />} value="custom" label="Custom" />
      </RadioGroup>
      <Grid container spacing={2}>
        {isCustom ? (
          <>
            <Grid item xs={12}>
              <ReduxField field={FieldFactory.text('number').setRequired()} />
            </Grid>
            <Grid item xs={12}>
              <ReduxField field={FieldFactory.text('color')} />
            </Grid>
            <Grid item xs={12}>
              <ReduxField field={FieldFactory.text('size')} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <ReduxField field={FieldFactory.custom('variant', VariantField).withLabel('SKU')} />
            </Grid>
          </>
        )}
      </Grid>
    </DrawerFormSection>
  );
}
