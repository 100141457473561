import { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import _ from 'lodash';
import { WrappedFieldProps } from 'redux-form';
import { Field, GenericField, curr, FieldProps, FieldFactory } from '@/admin';

function AmountToApply({ input, meta, field }: FieldProps<AmountToApplyField>) {
  const [option, setOption] = useState(0);
  const { orderBalance, amountToApply } = field;

  useEffect(() => {
    if (option === 0) {
      input.onChange(amountToApply);
    } else if (option === 1) {
      input.onChange(orderBalance);
    }
  }, [option, orderBalance, amountToApply]);

  return (
    <div>
      <RadioGroup
        aria-label="amount"
        name="amount"
        value={option}
        onChange={(e) => setOption(_.parseInt(e.target.value))}
      >
        <FormControlLabel
          value={0}
          control={<Radio />}
          label={`Payment Amount - ${curr(amountToApply)}`}
        />
        {_.isNumber(orderBalance) && (
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={`Order Balance - ${curr(orderBalance)}`}
          />
        )}
        <FormControlLabel value={2} control={<Radio />} label="Other Amount" />
      </RadioGroup>

      {option === 2 && (
        <GenericField
          input={input}
          field={FieldFactory.curr('amount').withLabel('Other Amount')}
          meta={meta}
        />
      )}
    </div>
  );
}

export default class AmountToApplyField extends Field {
  amountToApply: number;
  orderBalance?: number;

  constructor(name: string, amountToApply: number, orderBalance?: number) {
    super(name);
    this.amountToApply = amountToApply;
    this.orderBalance = orderBalance;
  }

  renderEditComponent(props: WrappedFieldProps): React.ReactElement | null {
    return <AmountToApply {...props} field={this} />;
  }
}
