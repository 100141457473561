import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { Can, FieldFactory, useDialogs } from '@/admin';
import { Dashboard } from '@/models';

export default function DashboardAdminActions({ dashboard }: { dashboard: Dashboard }) {
  const { prompt, confirm } = useDialogs();

  const onEdit = () => {
    prompt({
      title: 'Edit Dashboard',
      fields: [FieldFactory.text('name'), FieldFactory.hasMany('roles', 'roles')],
      onSubmit: (v) => axios.put(`/api/dashboards/${dashboard.id}`, v),
      initialValues: _.pick(dashboard, ['name', 'roles']),
    }).then(() => {
      window.location.reload();
    });
  };

  const onDelete = () => {
    confirm('Delete Dashboard', 'Are you sure? This action is not reversible.', 'error').then(
      () => {
        axios.delete(`/api/dashboards/${dashboard.id}`).then(() => {
          window.location.reload();
        });
      },
    );
  };

  return (
    <Can permission="write:reports">
      <Tooltip title="Edit Dashboard">
        <IconButton onClick={onEdit}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Dashboard">
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
    </Can>
  );
}
