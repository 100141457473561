import {
  Box,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FieldFactory, useDialogs } from '@/admin';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import { CommissionModel, Role, User } from '@/models';
import useMutateQueryData from '@/utils/useMutateQueryData';

const relations = 'roles,commission_model,artist,account_managers';

interface UserWithDetails extends User {
  roles: Role[];
  commission_model: CommissionModel | null;
  artist: User | null;
  account_managers: User[];
  commission_due: number;
}

export default function BusinessUsers() {
  const { drawerPrompt } = useDialogs();

  const setUsers = useMutateQueryData<UserWithDetails[]>(['myBusinessUsers']);
  const { data: users = [] } = useQuery(['myBusinessUsers'], () =>
    axios
      .get<{ data: UserWithDetails[] }>(
        `/api/users?with=${relations}&with_commission_due=1&sort=name&is_disabled=0`,
      )
      .then(({ data }) => data.data),
  );

  const isSales = (u: UserWithDetails) => u.roles.some((role) => role.name === 'Sales');
  const sales = users.filter(isSales);
  const staff = users.filter((u) => !isSales(u));

  const onEditUser = (user: UserWithDetails) => {
    drawerPrompt({
      title: 'Edit User',
      initialValues: user,
      fields: [
        FieldFactory.text('name'),
        FieldFactory.email('email'),
        FieldFactory.belongsTo('artist', 'users').withLabel('Assigned Artist'),
        FieldFactory.hasMany('account_managers', 'users'),
        FieldFactory.belongsTo('commission_model', 'commissionModels').withLabel(
          'Default Commission Model',
        ),
        FieldFactory.percent('commission_percent').withLabel('Default Commission %'),
      ],
      onSubmit: (v) =>
        axios.put(`/api/users/${user.id}?with=roles,commission_model,artist,account_managers`, v),
    }).then(({ data }) => {
      setUsers((prev) =>
        prev.map((p) => {
          if (p.id === data.id) {
            return { ...p, ...data };
          }
          return p;
        }),
      );
    });
  };

  return (
    <Box mb={2}>
      <Card>
        <CardHeader title="Users" />

        {sales.length > 0 && (
          <List
            dense
            subheader={
              <Box px={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Sales Reps
                </Typography>
              </Box>
            }
          >
            {sales.map((u) => (
              <ListItem key={u.id} button onClick={() => onEditUser(u)}>
                <ListItemText secondary={u.email}>
                  <Typography>{u.name}</Typography>
                </ListItemText>
                <ListItemSecondaryAction style={{ textAlign: 'right' }}>
                  <Typography color="textSecondary" variant="caption">
                    Commission Due
                  </Typography>
                  <Typography>
                    <ColoredCurrency amount={u.commission_due} />
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
        {staff.length > 0 && (
          <List
            dense
            subheader={
              <Box px={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Internal Staff
                </Typography>
              </Box>
            }
          >
            {staff.map((u) => (
              <ListItemButton key={u.id} onClick={() => onEditUser(u)}>
                <ListItemText secondary={u.email}>
                  <Typography>{u.name}</Typography>
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        )}
      </Card>
    </Box>
  );
}
