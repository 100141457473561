import { Resource, CardLayout, FieldFactory } from '@/admin';
import InventoryReturnPage from '@/components/Inventory/InventoryReturnPage';
import { RETURN_STATUS_COLORS } from '@/constants';
import { InventoryReturn } from '@/models';

export default function inventoryReturns() {
  return new Resource<InventoryReturn>('Inventory Returns')
    .getLabelUsing((v) => v.number)
    .getTitleUsing((v) => v.number)
    .setCreatable(false)
    .setDeletable(false)
    .withDefaultSort('-id')
    .withDefaultFilters({
      status: 'awaiting',
    })
    .withFields([
      new CardLayout('details', [FieldFactory.text('number')]),
      new CardLayout('items')
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.list('items', [
            FieldFactory.belongsTo('variant', 'inventoryVariants'),
            FieldFactory.number('qty'),
          ]),
        ]),
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.text('number').sortable(),
      FieldFactory.status('status', RETURN_STATUS_COLORS).quickFilterable(),
      FieldFactory.belongsTo('fulfillment_order', 'fulfillmentOrders').sortable(
        'fulfillment_order_id',
      ),
      FieldFactory.timestamp('created_at'),
    ])
    .editUsing(InventoryReturnPage);
}
