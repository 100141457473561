import { CancelOutlined, Undo } from '@mui/icons-material';
import SubmitIcon from '@mui/icons-material/AssignmentTurnedIn';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios';
import _ from 'lodash';
import { Resource, OnClickProps, FieldFactory, ButtonAction } from '@/admin';
import BackorderItemsField from '@/components/FulfillmentOrders/BackorderItemsField';
import FulfillmentOrderPage from '@/components/FulfillmentOrders/FulfillmentOrderPage';
import { WEBSTORE_ORDER_STATUS_COLORS } from '@/constants';
import { FulfillmentOrder } from '@/models';

export default function fulfillmentOrders() {
  return new Resource<FulfillmentOrder>('Fulfillment Orders')
    .getTitleUsing((v) => v.number)
    .getLabelUsing((v) => v.number)
    .withDefaultSort('-date')
    .withExportable({ format: 'xlsx' })
    .withFilters([FieldFactory.belongsTo('invoice', 'orders')])
    .setReadOnly()
    .getSingleActionsUsing((values) => {
      const onIssue = ({ dialogs, onReloadRecord, setIsLoading }: OnClickProps) => {
        dialogs
          .confirm(
            'Issue Order',
            'This will attempt to push the order to fulfillment if items are available.',
          )
          .then(() => {
            setIsLoading(true);
            axios
              .post(`/api/fulfillment-orders/${values.id}/issue`)
              .then(() => {
                onReloadRecord();
              })
              .finally(() => {
                setIsLoading(false);
              });
          });
      };

      const onVoid = ({ dialogs, onReloadRecord, setIsLoading }: OnClickProps) => {
        dialogs
          .confirm(
            'Void Order',
            'This will cancel the order and delete any associated picks. Are you sure you want to proceed?',
            'warning',
          )
          .then(() => {
            setIsLoading(true);
            axios
              .post(`/api/fulfillment-orders/${values.id}/void`)
              .then(() => {
                onReloadRecord();
              })
              .finally(() => {
                setIsLoading(false);
              });
          });
      };

      const onBackorder = ({ dialogs, onReloadRecord }: OnClickProps) => {
        dialogs
          .drawerPrompt({
            title: 'Create Backorder',
            description: 'Select which items you would like to move to a backorder',
            fields: [FieldFactory.custom('items', BackorderItemsField)],
            initialValues: {
              items: values.items.map((i) => ({
                ...i,
                qty: 0,
                maxQty: i.qty,
              })),
            },
            onSubmit: (v) =>
              axios.post(`/api/fulfillment-orders/${values.id}/backorder`, {
                items: v.items.filter((i) => i.qty > 0),
              }),
          })
          .then(() => {
            onReloadRecord();
          });
      };

      const onReturn = ({ dialogs, navigate }: OnClickProps) => {
        dialogs
          .drawerPrompt({
            title: 'Create Return',
            description: 'Select which items you would like to return to inventory',
            fields: [FieldFactory.custom('items', BackorderItemsField)],
            initialValues: {
              items: values.items.map((i) => ({
                ...i,
                qty: 0,
                maxQty: i.qty,
              })),
            },
            onSubmit: (v) => axios.post(`/api/fulfillment-orders/${values.id}/return`, v),
          })
          .then(({ data }) => {
            navigate(`/inventory-returns/${data.id}`);
          });
      };

      const onEdit = ({ dialogs, updateRecord }: OnClickProps) => {
        dialogs
          .drawerPrompt({
            title: 'Edit Fulfillment Order',
            initialValues: _.pick(values, [
              'date',
              'name',
              'email',
              'phone',
              'customer_po',
              'earliest_ship_date',
            ]),
            fields: [
              FieldFactory.text('name'),
              FieldFactory.email('email'),
              FieldFactory.phone('phone'),
              FieldFactory.text('customer_po'),
              FieldFactory.date('earliest_ship_date'),
            ],
            onSubmit: (v) => axios.put(`/api/fulfillment-orders/${values.id}`, v),
          })
          .then(({ data }) => {
            updateRecord(data);
          });
      };

      if (['pending', 'waiting', 'issued', 'backordered'].includes(values.status)) {
        return [
          new ButtonAction('Issue', onIssue, SubmitIcon),
          new ButtonAction('Create Backorder', onBackorder, WarningIcon),
          new ButtonAction('Edit', onEdit, EditIcon),
          new ButtonAction('Void', onVoid, CancelOutlined),
        ];
      }
      return [new ButtonAction('Create Return', onReturn, Undo)];
    })
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.text('number').sortable(),
      FieldFactory.status('status', WEBSTORE_ORDER_STATUS_COLORS).filterable().sortable(),
      FieldFactory.timestamp('date').sortable(),
      FieldFactory.text('name').sortable(),
      FieldFactory.belongsTo('customer', 'customers').filterable(),
      FieldFactory.text('webstore_name').sortable(),
    ])
    .editUsing(FulfillmentOrderPage);
}
