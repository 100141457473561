import {
  Box,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import { curr, getApiUrl } from '@/admin';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import { BusinessPayout, PendingPayout } from '@/models';

export default function BusinessPayouts() {
  const { data: payouts = [] } = useQuery(['myBusinessPayouts'], () =>
    axios
      .get<{ data: BusinessPayout[] }>('/api/business-payouts?sort=-created_at&count=7')
      .then(({ data }) => data.data),
  );

  const { data: pending } = useQuery(['myPendingPayouts'], () =>
    axios
      .get<{ business: PendingPayout | null }>('/api/pending-payouts/mine')
      .then(({ data }) => data.business),
  );

  return (
    <Box mb={2}>
      <Card>
        <CardHeader title="Latest Payouts" />
        <List>
          {pending && (
            <ListItem>
              <ListItemText>
                <Typography color="textSecondary">Next Payout</Typography>
              </ListItemText>
              <ListItemSecondaryAction style={{ textAlign: 'right' }}>
                <Typography variant="body2">
                  <Typography component="span" variant="caption" color="textSecondary">
                    Payout:{' '}
                  </Typography>
                  <ColoredCurrency amount={pending.balance} />
                </Typography>
                <Typography variant="body2">
                  <Typography component="span" variant="caption" color="textSecondary">
                    Commis:{' '}
                  </Typography>
                  {curr(pending.commissions_sum)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          )}
          {payouts.map((p) => (
            <ListItemButton
              key={p.id}
              component="a"
              target="_blank"
              href={getApiUrl(`/api/business-payouts/${p.id}/download`)}
            >
              <ListItemText>{moment(p.created_at).format('LL')}</ListItemText>
              <ListItemSecondaryAction style={{ textAlign: 'right' }}>
                <Typography variant="body2">
                  <Typography component="span" variant="caption" color="textSecondary">
                    Payout:{' '}
                  </Typography>
                  <ColoredCurrency amount={p.amount} />
                </Typography>
                <Typography variant="body2">
                  <Typography component="span" variant="caption" color="textSecondary">
                    Commis:{' '}
                  </Typography>
                  {curr(p.commissions)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </List>
      </Card>
    </Box>
  );
}
