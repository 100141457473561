import { InputAdornment, TextField as MaterialTextField } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import TextInputField from '../../models/Fields/TextInputField';
import { FieldProps } from '../../types';

export function TextArea({ input, field, meta }: FieldProps<TextAreaField>) {
  const InputProps = field.InputProps || {};
  if (field.prefix) {
    InputProps.startAdornment = <InputAdornment position="start">{field.prefix}</InputAdornment>;
  }
  if (field.postfix) {
    InputProps.endAdornment = <InputAdornment position="end">{field.postfix}</InputAdornment>;
  }

  return (
    <MaterialTextField
      {...input}
      {...field.getTextFieldProps(meta)}
      type={field.type}
      InputProps={InputProps}
      multiline
      rows={field.rows}
    />
  );
}

export default class TextAreaField extends TextInputField {
  rows = 5;

  renderEditComponent(props: WrappedFieldProps) {
    return <TextArea {...props} field={this} />;
  }
}
