import { useCallback, useMemo } from 'react';
import { QueryKey, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { initialize } from 'redux-form';
import { useAppContext } from '../contexts/AppContext';
import useFormActions from '../hooks/useFormActions';
import useGetResource from '../hooks/useGetResource';
import Resource from '../models/Resource';

type Mode = 'index' | 'create' | 'edit';

export function useMode(): [Mode, string | undefined] {
  const id = useLocation().pathname.split('/')[2];
  // const {id} = useParams<{ id?: string }>();

  if (!id) {
    return ['index', undefined];
  }
  if (id == 'create') {
    return ['create', undefined];
  }
  return ['edit', id];
}

export function makeResourceQueryKey(
  resourceKey: string,
  pk: string | number,
  add?: string,
): QueryKey {
  const base = ['genericRecord', resourceKey, String(pk)];
  if (add) {
    base.push(add);
  }
  return base;
}

export function useResourceQueryKey(): QueryKey | null {
  const [mode, paramId] = useMode();
  const resourceKey = useMaybeCurrentResource()?.key;

  return useMemo(() => {
    if (mode === 'edit' && paramId && resourceKey) {
      return makeResourceQueryKey(resourceKey, paramId);
    }
    return null;
  }, [mode, paramId, resourceKey]);
}

export function useResourceQuery(refetchOnMount = true): UseQueryResult {
  const resource = useCurrentResource();
  const queryKey = useResourceQueryKey();
  const dispatch = useDispatch();
  const pk = queryKey && queryKey[2] ? (queryKey[2] as string) : '';

  return useQuery(queryKey || [], () => resource.getShowRequest(pk).then(({ data }) => data), {
    enabled: Boolean(queryKey),
    refetchOnMount,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (refetchOnMount) {
        dispatch(initialize(resource.getFormName(), data));
      }
    },
  });
}

export function useUpdateRecord() {
  const queryClient = useQueryClient();
  const queryKey = useResourceQueryKey();
  const { silentChange } = useFormActions();

  return useCallback(
    <T>(updated: Partial<T>) => {
      if (queryKey) {
        silentChange(updated);
        const oldRes = queryClient.getQueryData(queryKey) as T | undefined;
        if (oldRes) {
          queryClient.setQueryData(queryKey, { ...oldRes, ...updated });
        }
      }
    },
    [queryClient, queryKey],
  );
}

export function useRecord<T extends Record<string, any>>(): T {
  const { data } = useResourceQuery(false);
  if (!data) {
    throw new Error('There is no record loaded');
  }
  return data as T;
}

export function useMaybeRecord<T extends Record<string, any>>(): T | undefined {
  const { data } = useResourceQuery(false);
  return data as T | undefined;
}

export function useRecordId(): string | number {
  const resource = useCurrentResource();
  return useRecord()[resource.primaryKey] as string | number;
}

export function useOnReloadRecord() {
  const queryClient = useQueryClient();
  const queryKey = useResourceQueryKey();

  return useCallback(() => {
    if (queryKey) {
      queryClient.invalidateQueries(queryKey);
    }
  }, [queryClient, queryKey]);
}

export function useMaybeCurrentResource(): Resource | undefined {
  const { routes } = useAppContext();
  const { pathname } = useLocation();
  const getResource = useGetResource();

  return useMemo(() => {
    const page = routes.find((r) => _.trimStart(r.href, '/') === pathname.split('/')[1]);
    if (!page || !page.resource) {
      return undefined;
    }
    return getResource(page.resource, false);
  }, [routes, pathname, getResource]);
}

export function useCurrentResource(): Resource {
  const resource = useMaybeCurrentResource();
  if (!resource) {
    throw new Error('No current resource');
  }
  return resource;
}

export function useMaybeCurrentForm(): string | undefined {
  return useMaybeCurrentResource()?.getFormName();
}

export function useCurrentForm() {
  return useCurrentResource().getFormName();
}
