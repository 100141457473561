import { CardHeader, List } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { makeResourceQueryKey } from '@/admin';
import PaymentListItem from '@/components/Payments/PaymentListItem';
import { Payment } from '@/models';

export default function SuggestedPayments({
  orderId,
  onApplyPayment,
}: {
  orderId: number;
  onApplyPayment: (p: Payment) => void;
}) {
  const { data: suggested } = useQuery(
    makeResourceQueryKey('orders', orderId, 'suggestedPayments'),
    () =>
      axios
        .get<{ data: Payment[] }>(`/api/order-payments/suggested?order_id=${orderId}`)
        .then(({ data }) => data.data),
  );

  if (!suggested || suggested.length === 0) {
    return null;
  }

  return (
    <>
      <CardHeader
        title="Suggested Payments to Apply"
        titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
      />
      <List>
        {suggested.map((s) => (
          <PaymentListItem key={s.id} payment={s} onClick={() => onApplyPayment(s)} />
        ))}
      </List>
    </>
  );
}
