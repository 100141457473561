import { Close } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { ClosableDrawer, curr, FieldFactory, TextButton, useDialogs } from '@/admin';
import AddressBlock from '@/components/Addresses/AddressBlock';
import TableCardContainer from '@/components/Shared/TableCardContainer';
import { Kit, Shipment, ShipmentItem } from '@/models';

const fields = {
  id: 'ID',
  carrier_name: 'Carrier',
  service_level: 'Service Level',
  tracking_number: 'Tracking #',
  cost: 'Cost',
  address: 'Address',
  'shipped_by.name': 'Shipped By',
};

interface ShipmentWithDetails extends Shipment {
  items: ShipmentItem[];
  kits: Kit[];
}

export default function ShipmentDetailsDrawer({
  onClose,
  shipment,
}: {
  onClose: () => void;
  shipment?: Shipment;
}) {
  const open = !!shipment;
  const { confirm, prompt } = useDialogs();
  const queryClient = useQueryClient();

  const { data: details, refetch } = useQuery(
    ['shipment', shipment?.id],
    () =>
      axios
        .get<ShipmentWithDetails>(`/api/shipments/${shipment?.id}?with=items,kits`)
        .then(({ data }) => data),
    {
      enabled: open,
    },
  );

  const removeFromShipment = (item: ShipmentItem | Kit, resource = 'items') => {
    confirm(
      `Remove ${resource === 'items' ? 'Item' : 'Kit'} Shipment`,
      'Are you sure you want to remove this from the shipment?',
    ).then(() => {
      axios.delete(`/api/shipments/${shipment?.id}/${resource}/${item.id}`).then(() => {
        refetch();
        queryClient.invalidateQueries(['shipments']);
      });
    });
  };

  const updateShipmentCost = () => {
    prompt({
      title: 'Update Shipment Cost',
      initialValues: _.pick(shipment, 'cost'),
      fields: [FieldFactory.curr('cost').withSize('medium')],
      onSubmit: (v) => axios.put(`/api/shipments/${shipment?.id}`, v),
    }).then(() => {
      refetch();
      queryClient.invalidateQueries(['shipments']);
    });
  };

  const updateShipmentItem = (item: ShipmentItem) => {
    prompt({
      title: 'Update Qty Shipped',
      initialValues: _.pick(item, 'qty_shipped'),
      fields: [FieldFactory.number('qty_shipped').withSize('medium')],
      onSubmit: (v) => axios.put(`/api/shipments/${shipment?.id}/items/${item.id}`, v),
    }).then(() => {
      refetch();
      queryClient.invalidateQueries(['shipments']);
    });
  };

  const renderValueForKey = (shipment: Shipment, key: string) => {
    if (key === 'address') {
      return shipment.address && <AddressBlock address={shipment.address} />;
    }
    if (key === 'cost') {
      return (
        <TextButton disabled={!!shipment.external_id} onClick={updateShipmentCost}>
          {curr(shipment.cost)}
        </TextButton>
      );
    }
    return _.get(shipment, key);
  };

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Shipment Details">
      {details && (
        <>
          <TableCardContainer>
            <Table size="small">
              <TableBody>
                {Object.entries(fields).map(([key, label]) => (
                  <TableRow key={key}>
                    <TableCell variant="head">{label}</TableCell>
                    <TableCell>{renderValueForKey(details, key)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCardContainer>
          {details.items.length > 0 ? (
            <Box mt={2}>
              <Typography variant="subtitle2" gutterBottom>
                Items in Shipment
              </Typography>
              <TableCardContainer>
                <Table size="small">
                  <TableBody>
                    {details.items.map((item) => (
                      <TableRow key={item.id}>
                        {item.shippable_item_type === 'order_item' ? (
                          <>
                            <TableCell>{item.shippable_item.description}</TableCell>
                            <TableCell>{item.shippable_item.size}</TableCell>
                          </>
                        ) : (
                          <TableCell colSpan={2}>{item.shippable_item.description}</TableCell>
                        )}
                        <TableCell>
                          <TextButton onClick={() => updateShipmentItem(item)}>
                            {item.qty_shipped}
                          </TextButton>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Remove From Shipment">
                            <IconButton
                              size="small"
                              onClick={() => removeFromShipment(item, 'items')}
                            >
                              <Close fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCardContainer>
            </Box>
          ) : null}
          {details.kits.length > 0 ? (
            <Box mt={2}>
              <Typography variant="subtitle2" gutterBottom>
                Kits in Shipment
              </Typography>
              <TableCardContainer>
                <Table size="small">
                  <TableBody>
                    {details.kits.map((kit) => (
                      <TableRow key={kit.id}>
                        <TableCell>{kit.number || kit.name}</TableCell>
                        <TableCell>
                          <Tooltip title="Remove From Shipment">
                            <IconButton
                              size="small"
                              onClick={() => removeFromShipment(kit, 'kits')}
                            >
                              <Close fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableCardContainer>
            </Box>
          ) : null}
        </>
      )}
    </ClosableDrawer>
  );
}
