import { useState } from 'react';
import { Tabs, Tab, Badge } from '@mui/material';
import AttachmentsCard from '../Attachments/AttachmentsCard';
import ActivityCard from './ActivityCard';
import NoteManagerCard from './NoteManagerCard';

export default function EventableCard({
  resource,
  resourceId,
  size,
  elevation,
}: {
  resource: string;
  resourceId: string | number;
  size?: 'small' | 'medium';
  elevation?: number;
}) {
  const [attachmentsCount, setAttachmentsCount] = useState<number>();
  const [tab, setTab] = useState('activity');

  const attachmentsLabel = attachmentsCount ? (
    <Badge badgeContent={attachmentsCount} color="primary" sx={{ pr: 1.5 }}>
      Attachments
    </Badge>
  ) : (
    'Attachments'
  );

  return (
    <>
      <Tabs value={tab} onChange={(e, t) => setTab(t)}>
        <Tab value="activity" label="Activity" />
        <Tab value="notes" label="Notes" />
        <Tab value="attachments" label={attachmentsLabel} />
      </Tabs>
      <div>
        {tab === 'activity' && (
          <ActivityCard
            resource={resource}
            resourceId={resourceId}
            size={size}
            title={null}
            elevation={elevation}
            setAttachmentsCount={setAttachmentsCount}
          />
        )}

        {tab === 'notes' && (
          <NoteManagerCard
            resource={resource}
            resourceId={resourceId}
            size={size}
            title={null}
            elevation={elevation}
          />
        )}

        {tab === 'attachments' && (
          <AttachmentsCard
            resource={resource}
            resourceId={resourceId}
            title={null}
            elevation={elevation}
            setAttachmentsCount={setAttachmentsCount}
          />
        )}
      </div>
    </>
  );
}
