import { useMemo, useState } from 'react';
import { AccountBalance, AddCircle, Close, CreditCard, Search } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {
  Can,
  ClosableDrawer,
  FieldFactory,
  makeResourceQueryKey,
  numString,
  StatCard,
  StatusChip,
  Text,
  TextButton,
  TextLink,
  useDialogs,
  useFormValues,
  useHasPermission,
  useOnReloadRecord,
  useSetIsLoading,
} from '@/admin';
import AmountToApplyField from '@/components/Payments/AmountToApplyField';
import ChargeCustomerForm from '@/components/Payments/ChargeCustomerForm';
import CommissionHit from '@/components/Payments/CommissionHit';
import CommissionHitIcon from '@/components/Payments/CommissionHitIcon';
import StripeProvider from '@/components/Payments/StripeProvider';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import ConditionallyRenderField from '@/components/Shared/ConditionallyRenderField';
import PaginatedTable from '@/components/Shared/PaginatedTable';
import { APPLIED_STATUS_COLORS, APPLIED_STATUS_LABELS } from '@/constants';
import { requiredFields } from '@/helpers';
import { Order, OrderPayment, Payment } from '@/models';
import getBusinessName from '@/utils/getBusinessName';
import SuggestedPayments from '../Payments/SuggestedPayments';

export default function OrderPayments() {
  const [orderId, business, total, paid, balance, dueDate, daysPastDue, cancelledAt] =
    useFormValues((o: Order) => [
      o.id,
      o.business,
      o.total,
      o.paid,
      o.balance,
      o.due_date,
      o.days_past_due,
      o.cancelled_at,
    ]);
  const [charging, setCharging] = useState(false);
  const { confirm, prompt } = useDialogs();
  const hasPermission = useHasPermission();
  const onReload = useOnReloadRecord();
  const setIsLoading = useSetIsLoading();
  const dialogs = useDialogs();

  const { data, isLoading } = useQuery(
    makeResourceQueryKey('orders', orderId, 'orderPayments'),
    () =>
      axios
        .get<{ data: OrderPayment[]; meta: { total: number } }>(
          `/api/order-payments?order_id=${orderId}&count=5000`,
        )
        .then(({ data }) => data),
  );

  const orderPayments = data?.data || [];
  const totalCount = data?.meta.total || 0;

  const applyPayment = (payment: Payment, values: Partial<OrderPayment>) =>
    axios
      .post('/api/order-payments', {
        ...values,
        order_id: orderId,
        payment_id: payment.id,
      })
      .then(({ data }) => data);

  const onApplyPayment = (payment: Payment) => {
    const link = (
      <TextButton
        onClick={() => window.open(`/payments/${payment.id}`, '_blank')}
        disabled={!hasPermission('write:payments')}
      >
        {payment.label}
      </TextButton>
    );
    prompt({
      title: `Apply ${payment.label}`,
      description: <div>How much of {link} should be applied?</div>,
      fields: _.filter([
        new AmountToApplyField('amount', payment.amount_to_apply, balance),
        payment.payment_type === 'deduction' &&
          FieldFactory.custom('commission_hit', CommissionHit).withPermission(
            'write:order_commissions',
          ),
      ]),
      initialValues: {
        amount: payment.amount_to_apply,
        commission_hit: payment.commission_hit,
      },
      onSubmit: (v) => applyPayment(payment, v),
    }).then(() => {
      onReload();
    });
  };

  const onFindPayment = () => {
    prompt({
      title: 'Find Payment To Apply',
      fields: [
        FieldFactory.belongsTo('payment', 'payments').withRequestParams({
          has_balance: 1,
        }),
        FieldFactory.curr('amount'),
        new ConditionallyRenderField(
          'commission_hit',
          FieldFactory.custom('commission_hit', CommissionHit),
          (v) => _.get(v, 'payment.payment_type') === 'deduction',
        ).with({
          requiresPermission: 'write:order_commissions',
        }),
      ],
      validation: requiredFields(['payment', 'amount']),
      initialValues: {
        amount: balance,
      },
      onSubmit: (v: { payment: Payment; amount: number; commission_hit: number }) =>
        applyPayment(v.payment, v),
    }).then(() => {
      onReload();
    });
  };

  const createDeduction = () => {
    prompt({
      title: 'Create Payout Deduction',
      description: (
        <div>
          <span>This amount will be deducted from</span>
          <b> {`${getBusinessName(business)}'s`} </b>
          <span>next payout, and will be applied to this order.</span>
        </div>
      ),
      fields: [
        FieldFactory.curr('amount').withSize('medium'),
        FieldFactory.custom('commission_hit', CommissionHit).withPermission(
          'write:order_commissions',
        ),
      ],
      initialValues: {
        amount: balance,
      },
      onSubmit: (v) => axios.post('/api/order-payments/deduction', { ...v, order_id: orderId }),
    }).then(() => {
      onReload();
    });
  };

  const onUnapply = (op: OrderPayment) => {
    confirm(
      'Unapply Payment',
      'Are you sure you want to unapply this payment from the order?',
      'error',
    ).then(() => {
      axios.delete(`/api/order-payments/${op.id}`).then(() => {
        onReload();
      });
    });
  };

  const onAchInstructions = async () => {
    setIsLoading(true);
    const { data: instructions } = await axios
      .get<Record<string, string>>(`/api/orders/${orderId}/ach-instructions`)
      .finally(() => {
        setIsLoading(false);
      });

    dialogs.alert(
      'ACH Instructions',
      <div>
        <Table size="small" sx={{ my: 2 }}>
          {Object.entries(instructions).map(([key, value]) => (
            <TableRow>
              <TableCell variant="head">{key}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </Table>
      </div>,
    );
  };

  const hasCardholder = orderPayments && orderPayments.some(({ payment }) => payment.card_name);

  const columns = useMemo(
    () =>
      [
        {
          id: 'payment_date',
          header: 'Date',
          accessorKey: 'payment.payment_date',
          cell: ({ getValue }) => moment(getValue<string>()).format('L'),
        },
        {
          id: 'payment_number',
          header: 'Payment',
          accessorKey: 'payment.payment_number',
          cell: ({
            row: {
              original: { payment },
            },
          }) => (
            <>
              <TextLink disabled={!hasPermission('write:payments')} to={`/payments/${payment.id}`}>
                {payment.label}
              </TextLink>
              {hasCardholder && (
                <Typography variant="body2" color="textSecondary" style={{ marginTop: 2 }}>
                  {payment.customer?.name}
                </Typography>
              )}
            </>
          ),
        },
        hasCardholder
          ? {
              id: 'card_name',
              header: 'Cardholder',
              accessorKey: 'payment.card_name',
              cell: ({
                row: {
                  original: { payment },
                },
              }) =>
                payment.card_name && (
                  <Text
                    primary={payment.card_name}
                    secondary={
                      payment.card_last_four &&
                      `${_.startCase(payment.card_brand || '')} ****${payment.card_last_four}`
                    }
                  />
                ),
            }
          : {
              id: 'customer',
              header: 'Customer',
              accessorKey: 'payment.customer.name',
            },
        {
          id: 'amount_applied',
          header: 'Amount Applied',
          accessorKey: 'amount_applied',
          cell: ({ getValue }) => <ColoredCurrency amount={getValue<number>()} />,
        },
        {
          id: 'applied_status',
          header: 'Status',
          accessorKey: 'payment.applied_status',
          cell: ({ row, getValue }) => (
            <>
              <CommissionHitIcon hit={row.original.commission_hit} />
              <StatusChip
                size="small"
                status={getValue<string>()}
                colors={APPLIED_STATUS_COLORS}
                labels={APPLIED_STATUS_LABELS}
              />
            </>
          ),
        },
        {
          id: 'actions',
          header: '',
          cell: ({ row }) => (
            <IconButton onClick={() => onUnapply(row.original)} size="small">
              <Close fontSize="small" />
            </IconButton>
          ),
        },
      ] as ColumnDef<OrderPayment>[],
    [hasCardholder],
  );

  return (
    <Grid container spacing={3}>
      <Grid item lg={9} xs={12}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item lg={3} sm={6} xs={12}>
            <StatCard number={total} title="Order Total" format="curr" />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <StatCard number={paid} title="Paid" format="curr" />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <StatCard number={balance} title="Balance" format="curr" />
          </Grid>
          {dueDate && (
            <Grid item lg={3} sm={6} xs={12}>
              <Card>
                <CardHeader
                  title="Due Date"
                  titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
                />
                <CardContent style={{ paddingTop: 0 }}>
                  <Box fontSize="2rem" fontWeight={500}>
                    {moment(dueDate).format('l')}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
          {daysPastDue > 0 && balance > 0 && (
            <Grid item xs={12}>
              <Alert severity="error">The balance on this order is past due.</Alert>
            </Grid>
          )}
        </Grid>

        <Card>
          <CardHeader
            title="Applied Payments"
            subheader={orderPayments && `${numString(totalCount, 'Payments')} Applied`}
          />
          {isLoading ? (
            <CardContent>
              <CircularProgress />
            </CardContent>
          ) : (
            <PaginatedTable
              rows={orderPayments}
              columns={columns}
              searchable={['payment.label', 'payment.card_name']}
              initialSortBy={{
                id: 'payment_number',
                desc: false,
              }}
            />
          )}
        </Card>
      </Grid>

      {!cancelledAt && (
        <Can permission="write:order_payments">
          <Grid item lg={3} xs={12}>
            <Card sx={{ mb: 2 }}>
              <CardHeader
                title="Actions"
                titleTypographyProps={{ variant: 'subtitle1', color: 'textSecondary' }}
              />
              <List>
                <ListItemButton onClick={() => setCharging(true)}>
                  <ListItemIcon>
                    <CreditCard />
                  </ListItemIcon>
                  <ListItemText primary="Charge Customer" />
                </ListItemButton>
                <ListItemButton onClick={onFindPayment}>
                  <ListItemIcon>
                    <Search />
                  </ListItemIcon>
                  <ListItemText primary="Find Existing Payment to Apply" />
                </ListItemButton>
                <ListItemButton onClick={createDeduction}>
                  <ListItemIcon>
                    <AddCircle />
                  </ListItemIcon>
                  <ListItemText primary="Create Payout Deduction" />
                </ListItemButton>
                {balance > 0 && (
                  <ListItemButton onClick={onAchInstructions}>
                    <ListItemIcon>
                      <AccountBalance />
                    </ListItemIcon>
                    <ListItemText primary="Get ACH Instructions" />
                  </ListItemButton>
                )}
              </List>
            </Card>
            <Card>
              <SuggestedPayments orderId={orderId} onApplyPayment={onApplyPayment} />
            </Card>
          </Grid>
        </Can>
      )}

      <StripeProvider>
        <ClosableDrawer title="Charge Customer" open={charging} onClose={() => setCharging(false)}>
          {charging && (
            <ChargeCustomerForm
              onSuccess={() => {
                onReload();
                setCharging(false);
              }}
            />
          )}
        </ClosableDrawer>
      </StripeProvider>
    </Grid>
  );
}
