import { useEffect } from 'react';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import axios from 'axios';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import { Can, ClosableDrawer, useDialogs, useSpecificFormValues } from '@/admin';
import { Design } from '@/models';
import AttachmentsCard from '../Attachments/AttachmentsCard';
import NoteRenderer from '../Notes/NoteRenderer';
import DesignSection from './DesignSection';

const StickyHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  top: theme.spacing(-1),
  zIndex: 10,
  backgroundColor: theme.palette.background.paper,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderColor: theme.palette.action.selected,
  borderStyle: 'solid',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(-3),
  marginRight: theme.spacing(-3),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

type FormShape = Partial<Design>;

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  design: Partial<Design>;
  onSave: (d: Design) => void;
  artRequestId: number;
}

function ArtRequestDesignDrawer({
  form,
  open,
  onClose,
  design,
  onSave,
  dirty,
  initialize,
  artRequestId,
  handleSubmit,
  submitting,
}: InjectedFormProps<FormShape, DrawerProps> & DrawerProps) {
  const { confirm } = useDialogs();

  useEffect(() => {
    if (open) {
      initialize(design);
    }
  }, [open]);

  const decorationType = useSpecificFormValues(form, (d: FormShape) => d.decoration_type);

  const handleClose = (force = false) => {
    if (!dirty || force) {
      onClose();
      return;
    }

    confirm(
      'Unsaved Changes',
      'There are unsaved changes to this design. Are you sure you want to close this drawer?',
      'error',
    ).then(onClose);
  };

  const onSubmit = ({ id, ...values }: FormShape) =>
    axios.put<Design>(`/api/designs/${id}`, values).then(({ data }) => {
      onSave(data);
      onClose();
    });

  const handleComplete: FormSubmitHandler<FormShape, DrawerProps> = (values) =>
    onSubmit({
      ...values,
      completed_via: artRequestId,
    });

  const handleSave: FormSubmitHandler<FormShape, DrawerProps> = (values) =>
    onSubmit({
      ...values,
      completed_via: null,
    });

  return (
    <ClosableDrawer title="Requested Design" open={open} onClose={handleClose} width={1100}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography variant="subtitle1" style={{ fontWeight: 500 }} gutterBottom>
            Notes
          </Typography>
          {design.request_notes && (
            <Typography variant="body2">
              <NoteRenderer note={design.request_notes} />
            </Typography>
          )}
        </Grid>
        <Grid item md={6}>
          <Typography variant="subtitle1" style={{ fontWeight: 500 }} gutterBottom>
            Attachments
          </Typography>
          {design.id && (
            <AttachmentsCard
              resource="designs"
              resourceId={design.id}
              title={null}
              elevation={0}
              group="request"
            />
          )}
        </Grid>
      </Grid>

      <StickyHeader>
        <Typography variant="h5">{design.increment_id}</Typography>

        <Box ml="auto">
          <Can permission="art_requests:release">
            <Button
              type="button"
              variant="contained"
              onClick={handleSubmit(handleComplete)}
              disabled={submitting}
              sx={{ mr: 1 }}
            >
              Save & Complete
            </Button>
          </Can>
          <Button type="button" onClick={handleSubmit(handleSave)} disabled={submitting}>
            Save
          </Button>
        </Box>
      </StickyHeader>
      <DesignSection id={design.id} decorationType={decorationType} />
    </ClosableDrawer>
  );
}

export default reduxForm<FormShape, DrawerProps>({
  form: 'ArtRequestDesignForm',
})(ArtRequestDesignDrawer);
