import { useState } from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  Grid,
  Button,
  ListItemIcon,
  Checkbox,
  Card,
  CardHeader,
  Box,
} from '@mui/material';
import _ from 'lodash';
import { Moment } from 'moment';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import { Paper, ReduxField, useTitle, getApiUrl, FieldFactory } from '@/admin';

interface AccountingReport {
  name: string;
  key: string;
  group?: boolean;
}

const REPORTS: AccountingReport[] = [
  {
    name: 'Income Statement',
    key: 'income-statement',
    group: true,
  },
  {
    name: 'Balance Sheet',
    key: 'balance-sheet',
  },
  {
    name: 'Cash Flow',
    key: 'cash-flow',
    group: true,
  },
  {
    name: 'Avail Fee Analysis',
    key: 'avail-fee-analysis',
  },
  {
    name: 'Scrap Analysis',
    key: 'scrap-analysis',
  },
];

interface FormShape {
  date_range: [Moment, Moment];
  group: string;
  include_account_numbers: boolean;
  format?: string;
}

function AccountingReports({ handleSubmit }: InjectedFormProps<FormShape>) {
  const [selected, setSelected] = useState<AccountingReport>();

  useTitle('Accounting Reports');

  const runReport: FormSubmitHandler<FormShape> = ({
    date_range: dates,
    group,
    include_account_numbers,
    format,
  }) => {
    if (!selected) {
      return;
    }
    const searchParams = new URLSearchParams(
      _.pickBy({
        start_date: dates[0].format('YYYY-MM-DD'),
        end_date: dates[1].format('YYYY-MM-DD'),
        group,
        include_account_numbers: include_account_numbers ? '1' : '0',
        format: format || 'pdf',
      }),
    );
    window.open(getApiUrl(`/api/accounting-reports/${selected.key}?${searchParams.toString()}`));
  };
  return (
    <Grid container spacing={3}>
      <Grid item md={8}>
        <Card>
          <CardHeader title="Available Reports to Run" titleTypographyProps={{ variant: 'h6' }} />
          <List>
            {REPORTS.map((r) => (
              <ListItemButton onClick={() => setSelected(r)} key={r.key}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={r.key === selected?.key}
                    onChange={(e) => setSelected(e.target.checked ? r : undefined)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={r.name} />
              </ListItemButton>
            ))}
          </List>
        </Card>
      </Grid>

      <Grid item md={4}>
        {selected && (
          <Paper title={selected.name}>
            <form onSubmit={handleSubmit(runReport)}>
              <Box mb={2}>
                <ReduxField field={FieldFactory.daterange('date_range')} />
              </Box>

              {selected.group && (
                <Box mb={2}>
                  <ReduxField
                    field={FieldFactory.radio('group', {
                      '': 'None',
                      month: 'Month',
                      quarter: 'Quarter',
                    }).withLabel('Columns')}
                  />
                </Box>
              )}

              <Box mb={2}>
                <ReduxField
                  field={FieldFactory.boolean('include_account_numbers', 'Include Account Numbers')}
                />
              </Box>

              <Button
                type="button"
                variant="contained"
                onClick={handleSubmit((v, ...args) => runReport({ format: 'pdf', ...v }, ...args))}
              >
                Download PDF
              </Button>
              <Button
                type="button"
                onClick={handleSubmit((v, ...args) => runReport({ format: 'xlsx', ...v }, ...args))}
                sx={{ ml: 2 }}
              >
                Download XLSX
              </Button>
            </form>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}

export default reduxForm<FormShape>({
  form: 'AccountingReportForm',
})(AccountingReports);
