import { useMemo } from 'react';
import { Box, Paper } from '@mui/material';
import { DataTable, useGetResource, useRecordId } from '@/admin';
import { Order } from '@/models';
import CustomerNumbers from './CustomerNumbers';

export default function CustomerOrders() {
  const customerId = useRecordId();
  const getResource = useGetResource();

  const resource = useMemo(
    () =>
      getResource('orders').cloneWith({
        queryParams: {
          index: {
            'filter[customer_id]': String(customerId),
          },
          single: {},
        },
      }),
    [customerId, getResource],
  );

  return (
    <div>
      <Box mb={2}>
        <CustomerNumbers customerId={customerId} />
      </Box>

      <Paper>
        <DataTable<Order>
          key="customerOrders"
          resource={resource}
          getHref={(o) => `/orders/${o.id}`}
        />
      </Paper>
    </div>
  );
}
