import { ClosableDrawer } from '@/admin';
import RequestedDesignForm from '@/components/Orders/Art/RequestedDesignForm';
import { Design } from '@/models';

export default function RequestedDesignDrawer({
  open,
  onClose,
  design,
  orderDesignId,
  onSave,
}: {
  open: boolean;
  onClose: () => void;
  design?: Design;
  orderDesignId?: number;
  onSave: () => void;
}) {
  return (
    <ClosableDrawer title={design?.increment_id || 'Edit Design'} open={open} onClose={onClose}>
      {design && orderDesignId ? (
        <RequestedDesignForm
          design={design}
          orderDesignId={orderDesignId}
          onSave={onSave}
          initialValues={design}
        />
      ) : null}
    </ClosableDrawer>
  );
}
