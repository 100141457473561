import { FieldFactory, FieldProps, GenericField, useFormValues } from '@/admin';
import { Customer } from '@/models';

export default function SalesRepField({ input, meta, field }: FieldProps) {
  const businessId = useFormValues((c: Customer) => c.business_id);
  return (
    <GenericField
      input={input}
      meta={meta}
      field={FieldFactory.belongsTo(field.name, 'users').withRequestParams({
        business_id: businessId,
        is_disabled: 0,
      })}
    />
  );
}
