import { CircularProgress, Table, TableCell, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { curr, FieldFactory, ReduxField } from '@/admin';
import { VENDOR_PAYMENT_METHODS } from '@/constants';
import { Vendor } from '@/models';

interface QuickPayResult {
  vendor: Vendor;
  payment_method: string;
  count: number;
  total: number;
}

export default function QuickPay({ ids }: { ids: number[] }) {
  const dispatch = useDispatch();
  const changeField = (f: string, v: any) => dispatch(change('PromptDialog', f, v));

  const { data: preview } = useQuery(
    ['quickPay', ids.join('|')],
    () =>
      axios
        .post<QuickPayResult>('/api/transactions/quick-pay', { ids, preview: true })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        changeField('vendor', data.vendor);
      },
    },
  );

  return (
    <div style={{ width: 400 }}>
      {!preview ? (
        <CircularProgress />
      ) : (
        <div>
          <ReduxField field={FieldFactory.belongsTo('vendor', 'vendors')} />

          <Table size="small" sx={{ my: 2 }}>
            <TableRow>
              <TableCell variant="head">Payment Method</TableCell>
              <TableCell>
                {_.get(VENDOR_PAYMENT_METHODS, preview.payment_method, preview.payment_method)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head"># Bills</TableCell>
              <TableCell>{preview.count}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Total</TableCell>
              <TableCell>{curr(preview.total)}</TableCell>
            </TableRow>
          </Table>
        </div>
      )}
    </div>
  );
}
