import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Close, DragHandle } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { v4 as uuid } from 'uuid';
import { FieldFactory, numString, ReduxField, wrap } from '@/admin';
import ColorChooserField from '@/components/Designs/ColorChooserField';
import StockColorIcon from '@/components/Designs/StockColorIcon';
import WarningIcon from '@/components/Shared/WarningIcon';
import { isScreenPrint } from '@/helpers';
import { ComplexityUnit, Customer, DesignColor } from '@/models';

const ALPHA = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
const SQUEEGEE = ['Red', 'Green', 'Yellow', 'Blue', 'Orange'];

function getColorId(color: DesignColor): string {
  return String(color.id || color.uuid);
}

function ColorRow({
  field,
  onRemove,
  color,
  decorationUnit,
  customer,
  freeSolo,
}: {
  field: string;
  onRemove: () => void;
  color: DesignColor;
  decorationUnit: ComplexityUnit | null;
  customer?: Customer;
  freeSolo?: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: getColorId(color),
  });

  const theme = useTheme();

  return (
    <TableRow
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <TableCell padding="checkbox">
        <DragHandle {...attributes} {...listeners} />
      </TableCell>
      <TableCell style={{ width: 100 }}>
        <ReduxField field={FieldFactory.select('code', ALPHA).withoutLabel()} parentName={field} />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <ReduxField
              field={new ColorChooserField('color').withoutLabel().with({
                freeSolo,
                customer,
                decorationUnit,
              })}
              parentName={field}
            />
          </Box>
          {color.ink_changes_count ? (
            <Box ml={1} pt={1}>
              <WarningIcon
                message={`This color is being changed in ${numString(
                  color.ink_changes_count,
                  'designs',
                )}`}
              />
            </Box>
          ) : null}
          {color.stock_color && <StockColorIcon stockColor={color.stock_color} />}
        </Box>
      </TableCell>
      {isScreenPrint(decorationUnit) && (
        <>
          <TableCell>
            <ReduxField field={FieldFactory.number('mesh').withoutLabel()} parentName={field} />
          </TableCell>
          <TableCell padding="none">
            <ReduxField
              field={FieldFactory.select('squeegee', SQUEEGEE).withoutLabel()}
              parentName={field}
            />
          </TableCell>
          <TableCell>
            <ReduxField field={FieldFactory.boolean('flash').withoutLabel()} parentName={field} />
          </TableCell>
        </>
      )}

      <TableCell
        style={{
          position: 'sticky',
          right: '0',
          width: 'auto',
          backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
          zIndex: '1',
        }}
      >
        <IconButton onClick={onRemove} disabled={Boolean(color.ink_changes_count)} size="large">
          <Close fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default function DesignColors({
  input,
  decorationUnit,
  customer,
  freeSolo = true,
}: WrappedFieldProps & {
  decorationUnit: ComplexityUnit | null;
  customer?: Customer;
  freeSolo?: boolean;
}) {
  const colors = wrap(input.value) as DesignColor[];

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    if (!over) {
      return;
    }
    const oldIndex = colors.findIndex((c) => getColorId(c) === active.id);
    const newIndex = colors.findIndex((c) => getColorId(c) === over.id);
    input.onChange(arrayMove(colors, oldIndex, newIndex));
  };

  return (
    <div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Code</TableCell>
              <TableCell style={{ minWidth: 300 }}>Color</TableCell>
              {isScreenPrint(decorationUnit) && (
                <>
                  <TableCell style={{ minWidth: 100 }}>Mesh</TableCell>
                  <TableCell>Squeegee</TableCell>
                  <TableCell>Flash</TableCell>
                </>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <DndContext onDragEnd={onSortEnd}>
              <SortableContext items={colors.map(getColorId)}>
                {colors.map((color, idx) => (
                  <ColorRow
                    key={getColorId(color)}
                    color={color}
                    field={`${input.name}[${idx}]`}
                    decorationUnit={decorationUnit}
                    customer={customer}
                    freeSolo={freeSolo}
                    onRemove={() => input.onChange(colors.filter((v) => v.id !== color.id))}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        onClick={() => input.onChange([...colors, { uuid: uuid(), code: ALPHA[colors.length] }])}
        style={{ marginTop: 4 }}
        size="small"
        color="secondary"
      >
        Add New
      </Button>
    </div>
  );
}
