import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/OutlinedInput';
import { red } from '@mui/material/colors';
import MaskedInput from 'react-text-mask';
import { WrappedFieldProps } from 'redux-form';
import { createNumberMask } from 'text-mask-addons';
import InputWrapper from '../../components/Form/InputWrapper';
import { FieldProps } from '../../types';
import Field from '../Field';

function Percent({ input, meta, field }: FieldProps<PercentField>) {
  return (
    <InputWrapper meta={meta} field={field}>
      <MaskedInput
        onChange={input.onChange}
        value={input.value}
        mask={createNumberMask({
          allowDecimal: true,
          prefix: '',
          allowNegative: false,
        })}
        placeholder={field.placeholder}
        render={(ref, props) => (
          <Input
            {...props}
            inputRef={ref}
            label={field.label}
            size={field.size}
            margin={field.margin}
            disabled={field.disabled}
            required={field.required}
            fullWidth={field.fullWidth}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        )}
      />
    </InputWrapper>
  );
}

export default class PercentField extends Field {
  renderEditComponent(props: WrappedFieldProps) {
    return <Percent {...props} field={this} />;
  }

  renderCell(value: any) {
    return (
      <span style={{ color: value < 0 ? red[500] : 'inherit' }}>{Number(value).toFixed(2)}%</span>
    );
  }
}
