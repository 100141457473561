import { useLayoutEffect, useRef } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { AxiosError } from 'axios';
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import { PromptOptions } from '../../contexts/AppContext';
import StackLayout from '../../models/Layouts/StackLayout';
import { NullableFieldable } from '../../types';
import stopProp from '../../utils/stopProp';
import truthy from '../../utils/truthy';
import { transformLaravelErrors, validateUsingRules } from '../../utils/validator';
import EditFormFields from '../EditFormFields';

type PromptFormShape = Record<string, any>;
type PromptDialogProps = {
  mode: 'drawer' | 'dialog';
  onCancel: () => void;
  onSuccess: (data: any) => void;
  promptFields: NullableFieldable[];
} & Omit<PromptOptions, 'fields'>;

function PromptDialog<T = PromptFormShape>({
  onCancel,
  onSuccess,
  handleSubmit,
  submitting,
  title,
  mode,
  description,
  submitText = 'Submit',
  cancelText = 'Cancel',
  promptFields,
  ...props
}: InjectedFormProps<T, PromptDialogProps> & PromptDialogProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const autoFocused = useRef(false);
  const fields = truthy(promptFields);

  const onSubmit = (values: T) => {
    if (props.onSubmit) {
      return props
        .onSubmit(values)
        .then((data: any) => onSuccess(data))
        .catch((e: AxiosError<any>) => {
          if (e.response?.status === 422) {
            throw new SubmissionError(transformLaravelErrors(e.response?.data.errors));
          }
          throw e;
        });
    }
    return onSuccess(values);
  };

  useLayoutEffect(() => {
    const input = contentRef.current?.querySelector('input');
    if (input) {
      autoFocused.current = true;
      input.focus();
      input.select();
    }
  }, []);

  const content = (
    <Box>
      {description && <Box mb={2}>{description}</Box>}

      <Box ref={contentRef} py={1}>
        <EditFormFields fields={fields} defaultLayout={StackLayout} />
      </Box>
    </Box>
  );

  if (mode === 'drawer') {
    return (
      <form onSubmit={stopProp(handleSubmit)(onSubmit)}>
        {content}

        <Box mt={3}>
          <Button type="submit" variant="contained" disabled={submitting} sx={{ mr: 1 }}>
            {submitText}
          </Button>
          <Button type="button" onClick={onCancel} disabled={submitting}>
            {cancelText}
          </Button>
        </Box>
      </form>
    );
  }

  return (
    <form onSubmit={stopProp(handleSubmit)(onSubmit)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button type="button" onClick={onCancel} disabled={submitting}>
          {cancelText}
        </Button>
        <Button type="submit" variant="contained" disabled={submitting}>
          {submitText}
        </Button>
      </DialogActions>
    </form>
  );
}

const validate = (values: object, props: PromptDialogProps) => {
  if (props.validation) {
    return validateUsingRules(values, props.validation);
  }
  return {};
};

export default reduxForm<PromptFormShape, PromptDialogProps>({
  form: 'PromptDialog',
  validate,
})(PromptDialog);
