import { Resource, FieldFactory } from '@/admin';
import InventoryLocationLabel from '@/components/Inventory/InventoryLocationLabel';
import UserLabel from '@/components/Users/UserLabel';
import { InventoryAdjustment } from '@/models';

export default function inventoryAdjustments() {
  return new Resource<InventoryAdjustment>('Inventory Adjustments')
    .withDefaultSort('-id')
    .setUseDrawer()
    .withInitialColumns([
      'id',
      'type',
      'created_at',
      'start_location',
      'end_location',
      'variant',
      'qty',
    ])
    .withFilters([
      FieldFactory.belongsTo('customer', 'customers').withFilterKey('customer_id'),
      FieldFactory.belongsTo('vendor', 'vendors').withFilterKey('vendor_id'),
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.status(
        'type',
        {
          add: 'green',
          move: 'amber',
          subtract: 'red',
          scrap: 'purple',
        },
        {
          add: 'Addition',
          subtract: 'Subtraction',
          move: 'Move',
          scrap: 'Scrap',
        },
      ),
      FieldFactory.timestamp('created_at').withLabel('Date').sortable().filterable(),
      FieldFactory.belongsTo('created_by_user', 'users')
        .withLabel('User')
        .renderCellUsing((u) => <UserLabel user={u} />),
    ])
    .addFieldsAndColumns([
      FieldFactory.belongsTo('start_location', 'inventoryLocations')
        .withHelp('Leave this blank if adding to inventory')
        .sortable('start_location.path')
        .renderCellUsing((l) => <InventoryLocationLabel location={l} />),
      FieldFactory.belongsTo('end_location', 'inventoryLocations')
        .withHelp('Leave this blank if removing from inventory')
        .sortable('end_location.path')
        .renderCellUsing((l) => <InventoryLocationLabel location={l} />),
      FieldFactory.belongsTo('variant', 'inventoryVariants')
        .withLabel('SKU')
        .sortable('variant.sku')
        .filterable(
          FieldFactory.hasMany('variants', 'inventoryVariants').withFilterKey(
            'filter[variant_id][in]',
          ),
        ),
      FieldFactory.number('qty').sortable(),
      FieldFactory.belongsTo('expense_account', 'accounts')
        .filterable()
        .withHelp('This will override the default "Inventory Defects" account')
        .withPermission('read:accounts'),
      FieldFactory.textarea('note').withHelp('Why is this adjustment being made?'),
    ]);
}
