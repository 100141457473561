import { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { GenericField } from '../../components/Form/ReduxField';
import { FieldProps } from '../../types';
import Field from '../Field';

function OptIn({ input, meta, field }: FieldProps<OptInField>) {
  const [optIn, setOptIn] = useState(!!input.value);

  useEffect(() => {
    if (!optIn) {
      input.onChange(null);
    } else if (!input.value && field.initialValue) {
      input.onChange(field.initialValue);
    }
  }, [optIn]);

  useEffect(() => {
    setOptIn(!!input.value);
  }, [input.value]);

  return (
    <div>
      {optIn && <GenericField input={input} meta={meta} field={field.subField} />}
      <FormControlLabel
        label={
          <Typography variant={field.size === 'small' ? 'body2' : 'body1'}>
            {field.optInLabel}
          </Typography>
        }
        control={
          <Checkbox
            size={field.size}
            checked={optIn}
            onChange={(e) => setOptIn(e.target.checked)}
          />
        }
      />
    </div>
  );
}

export default class OptInField extends Field {
  subField: Field;
  optInLabel = 'Enable';
  initialValue?: any;

  constructor(name: string, subField: Field) {
    super(name);
    this.subField = subField;
  }

  renderEditComponent(props: WrappedFieldProps) {
    return <OptIn {...props} field={this} />;
  }

  renderCell(value: any, row: Record<string, any>) {
    return this.subField.renderCell(value, row);
  }
}
