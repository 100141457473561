import { useMemo, useRef, useState } from 'react';
import { CardHeader, Button } from '@mui/material';
import axios from 'axios';
import {
  DataTable,
  DataTableHandle,
  FieldFactory,
  Resource,
  useDialogs,
  useRecordId,
} from '@/admin';
import FormDetailsDrawer from '@/components/Shared/FormDetailsDrawer';
import { ProductionEventPrice } from '@/models';

export default function ProductionEventPrices() {
  const id = useRecordId();
  const [editing, setEditing] = useState<Partial<ProductionEventPrice>>();
  const dataTableRef = useRef<DataTableHandle | null>(null);
  const { prompt } = useDialogs();

  const apiEndpoint = `/api/production-event-types/${id}/prices`;

  const onSave = (values: Partial<ProductionEventPrice>) => {
    const promise = values.id
      ? axios.put(`${apiEndpoint}/${values.id}`, values)
      : axios.post(apiEndpoint, values);

    return promise.then(() => {
      dataTableRef.current?.onReload();
    });
  };

  const onImport = () => {
    prompt({
      title: 'Import Production Event Prices',
      fields: [
        FieldFactory.file('file').with({
          accept:
            '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        }),
        FieldFactory.boolean('delete_existing', 'Replace Existing Price Breaks'),
      ],
      onSubmit: (v) => axios.post(`${apiEndpoint}/import`, v),
    }).then(() => {
      dataTableRef.current?.onReload();
    });
  };

  const resource = useMemo(
    () =>
      new Resource('Production Event Prices')
        .withApiEndpoint(apiEndpoint)
        .withExportable({ format: 'xlsx' })
        .withFieldsAndColumns([
          FieldFactory.number('qty').setRequired().withColumnSpan(12),
          FieldFactory.number('complexity')
            .withHelp('Either the stitch count or number of colors (can be blank)')
            .withColumnSpan(12),
          FieldFactory.curr('unit_price').setRequired().withColumnSpan(12),
        ]),
    [apiEndpoint],
  );

  const action = (
    <>
      <Button onClick={onImport} sx={{ mr: 1 }}>
        Import
      </Button>
      <Button variant="contained" onClick={() => setEditing({})}>
        Create
      </Button>
    </>
  );

  return (
    <>
      <CardHeader title="Variable Pricing" action={action} />

      <DataTable
        ref={dataTableRef}
        onEdit={(v) => setEditing(v as ProductionEventPrice)}
        resource={resource}
      />

      <FormDetailsDrawer<ProductionEventPrice>
        form="ProductionEventPriceForm"
        fields={resource.fields}
        initialValues={editing}
        title="Production Price Break"
        open={!!editing}
        onClose={() => setEditing(undefined)}
        onSave={onSave}
      />
    </>
  );
}
