import { FieldFactory, Resource } from '@/admin';
import UserLabel from '@/components/Users/UserLabel';
import { Shipment } from '@/models';
import { AppConfig } from '@/responses';

export default function shipments(appConfig: AppConfig) {
  return new Resource<Shipment>('Shipments')
    .withExportable({ format: 'xlsx' })
    .setReadOnly()
    .withDefaultSort('-id')
    .getTitleUsing((d) => `Shipment #${d.id}`)
    .withQueryParams({
      index: {
        with: 'shippable',
      },
    })
    .withFilters([
      FieldFactory.select(
        'shipping_method',
        appConfig.shippingMethods.map((m) => m.name),
      ).withFilterKey('shipping_method'),
      FieldFactory.text('address_zip').withFilterKey('address_zip'),
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.text('shippable')
        .withLabel('Order')
        .renderCellUsing((v) => v.increment_id || v.number),
      FieldFactory.text('tracking_number'),
      FieldFactory.text('carrier'),
      FieldFactory.belongsTo('shipped_by', 'users')
        .withLabel('Shipped By')
        .renderCellUsing((u) => <UserLabel user={u} />),
      FieldFactory.timestamp('created_at').withLabel('Shipped At').filterable(),
    ]);
}
