import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Note } from '@/models';
import NoteAlert from './NoteAlert';

export default function NoteViewer({
  resource,
  resourceId,
  tag,
}: {
  resource: string;
  resourceId: string | number;
  tag: string;
}) {
  const { data: notes = [] } = useQuery(['notesWithTag', resource, resourceId, tag], () =>
    axios
      .get<{ data: Note[] }>(`/api/${resource}/${resourceId}/notes`, { params: { tag } })
      .then(({ data }) => data.data),
  );

  return (
    <>
      {notes.map((n) => (
        <NoteAlert key={n.id} note={n} />
      ))}
    </>
  );
}
