import { useMemo, useRef, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Fab, Paper } from '@mui/material';
import axios from 'axios';
import { DataTable, DataTableHandle, useGetResource, useRecordId } from '@/admin';
import { Address } from '@/models';
import AddressDrawer from './AddressDrawer';

const ADDRESS_COLUMNS = [
  'name',
  'company',
  'address_1',
  'address_2',
  'city',
  'state',
  'zip',
  'country',
  'phone',
  'is_billing',
];

export default function GenericAddresses({ path }: { path: string }) {
  const dataTableRef = useRef<DataTableHandle | null>(null);
  const [editing, setEditing] = useState<Partial<Address> | null>(null);
  const modelId = useRecordId();
  const getResource = useGetResource();

  const onSubmit = (values: Partial<Address>) => {
    const promise = values.id
      ? axios.put(`/api/${path}/${modelId}/addresses/${values.id}`, values)
      : axios.post(`/api/${path}/${modelId}/addresses`, values);

    return promise.then(() => {
      dataTableRef.current?.onReload();
      setEditing(null);
    });
  };

  const resource = useMemo(() => {
    const resource = getResource('addresses').cloneWith({
      apiEndpoint: `/api/${path}/${modelId}/addresses`,
    });
    resource.columns = resource.columns.filter((c) => ADDRESS_COLUMNS.includes(c.name));
    return resource;
  }, [modelId, getResource]);

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: 72,
      }}
    >
      <Fab
        color="secondary"
        aria-label="add"
        onClick={() => setEditing({})}
        style={{
          position: 'absolute',
          bottom: 8,
          right: 8,
        }}
      >
        <Add />
      </Fab>

      <Paper>
        <DataTable<Address>
          ref={dataTableRef}
          key="modelAddresses"
          onEdit={setEditing}
          resource={resource}
        />
      </Paper>

      <AddressDrawer
        open={!!editing}
        onClose={() => setEditing(null)}
        initialValues={editing || {}}
        onSubmit={onSubmit}
        excludeFields={['method']}
      />
    </div>
  );
}
