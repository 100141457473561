import { useEffect } from 'react';
import { CardContent, CardHeader, Grid } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { FieldArray } from 'redux-form';
import { FieldFactory, Paper, ReduxField, useFormActions, useFormValues } from '@/admin';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import { TRANSACTION_TYPES, VENDOR_PAYMENT_METHODS } from '@/constants';
import { AccountTransactionItem, Bill, PaymentTransactionItem, Transaction } from '@/models';
import AccountItems from './AccountItems';
import PaymentItems from './PaymentItems';

const FIELDS = _.keyBy(
  [
    FieldFactory.belongsTo('bank_account', 'bankAccounts'),
    FieldFactory.text('po_number').withLabel('PO #'),
    FieldFactory.text('ref_number').withLabel('Ref #'),
    FieldFactory.belongsTo('terms', 'terms'),
    FieldFactory.date('due_date'),
    FieldFactory.date('transaction_date'),
    FieldFactory.text('check_number').withHelp('Leave blank to print later'),
    FieldFactory.text('check_payee').withHelp('Uses vendor name by default'),
    FieldFactory.select('payment_method', VENDOR_PAYMENT_METHODS),
    FieldFactory.belongsTo('vendor', 'vendors').withRequestParams({
      with: 'default_accounts',
    }),
  ],
  'name',
);

export default function TransactionFields() {
  const [id, type, vendor, items = [], method] = useFormValues((t: Transaction) => [
    t.id,
    t.type,
    'vendor' in t ? t.vendor : null,
    t.items,
    t.payment_method,
  ]);
  const { silentChange: change } = useFormActions();

  useEffect(() => {
    if (vendor && type !== 'bill_payment') {
      _.get(vendor, 'default_accounts', []).forEach((account, index) => {
        const key = `[${index}].transactable`;
        if (!_.get(items, key)) {
          change(`items${key}`, account);
        }
      });
    }
    if (vendor) {
      change('payment_method', type === 'bill_payment' ? vendor.default_payment_method : null);
    }
  }, [type, vendor?.id]);

  useEffect(() => {
    if (type === 'check') {
      change('payment_method', 'check');
    }
  }, [type]);

  useEffect(() => {
    setTimeout(() => {
      const searchParams = new URLSearchParams(window.location.search);
      if (!type && searchParams.has('type')) {
        change('type', searchParams.get('type'));
      }
      if (searchParams.has('bill_id')) {
        axios
          .get<{ data: Bill[] }>(`/api/transactions`, {
            params: {
              'filter[id][in]': searchParams.get('bill_id'),
              'filter[type]': 'bill',
              count: 22,
            },
          })
          .then(({ data }) => {
            if (data.data.length > 0) {
              change('vendor', data.data[0].vendor);
              data.data.forEach((trans, index) => {
                change(`items[${index}].transactable`, trans);
                change(`items[${index}].amount`, trans.balance);
              });
            }
          });
      }
    }, 10);
  }, [id]);

  const mainFields = [FIELDS.vendor];
  const secondaryFields = [];

  if (type === 'check') {
    mainFields.push(FIELDS.bank_account);
  } else if (type === 'expense') {
    mainFields.push(FIELDS.bank_account);
    secondaryFields.push(FIELDS.ref_number, FIELDS.po_number);
  } else if (type === 'bill') {
    secondaryFields.push(FIELDS.terms, FIELDS.due_date, FIELDS.ref_number, FIELDS.po_number);
  } else if (type === 'bill_payment') {
    mainFields.push(FIELDS.bank_account);
  } else if (type === 'transfer') {
    mainFields.shift();
    mainFields.push(FIELDS.bank_account);
  }

  if (['check', 'bill_payment'].includes(type)) {
    secondaryFields.push(FIELDS.payment_method);
    if (method === 'check') {
      secondaryFields.push(FIELDS.check_number, FIELDS.check_payee);
    }
  }

  mainFields.push(
    FIELDS.transaction_date.withLabel(type === 'bill' ? 'Bill Date' : 'Payment Date'),
  );

  return (
    <div>
      {!id && (
        <div style={{ maxWidth: 300, marginBottom: 16 }}>
          <ReduxField field={FieldFactory.select('type', TRANSACTION_TYPES)} />
        </div>
      )}

      {type && (
        <Paper>
          <Grid container spacing={3}>
            {mainFields.map((f) => (
              <Grid item xs={12} lg={3} md={6} key={f.name}>
                <ReduxField field={f} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} sx={{ mt: -1 }}>
            {secondaryFields.map((f) => (
              <Grid item xs={12} lg={3} md={6} key={f.name}>
                <ReduxField field={f} />
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {type && (
        <CardWithGutter>
          <CardHeader title={type === 'bill_payment' ? 'Bills to Pay' : 'Item Details'} />

          {type === 'bill_payment' ? (
            <FieldArray
              name="items"
              component={PaymentItems}
              items={items as PaymentTransactionItem[]}
              vendor={vendor}
            />
          ) : (
            <FieldArray
              name="items"
              component={AccountItems}
              sum={_.sumBy(items as AccountTransactionItem[], (i) => Number(i.amount || 0))}
            />
          )}
          <CardContent>
            <ReduxField field={FieldFactory.textarea('memo')} />
          </CardContent>
        </CardWithGutter>
      )}
    </div>
  );
}
