import { WrappedFieldProps } from 'redux-form';
import { Field, FieldProps } from '@/admin';
import ColorChooser from '@/components/Designs/ColorChooser';
import { ComplexityUnit, Customer } from '@/models';

function ColorChooserFieldComponent({ input, field }: FieldProps<ColorChooserField>) {
  return (
    <ColorChooser
      decorationUnit={field.decorationUnit}
      customer={field.customer}
      freeSolo={field.freeSolo}
      onChange={input.onChange}
      value={input.value}
      label={field.label}
      size={field.size}
    />
  );
}

export default class ColorChooserField extends Field {
  decorationUnit: ComplexityUnit | null = null;
  customer?: Customer;
  freeSolo = false;

  renderEditComponent(props: WrappedFieldProps) {
    return <ColorChooserFieldComponent {...props} field={this} />;
  }
}
