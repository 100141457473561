import { ChangeEvent, ReactNode } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio as RadioButton,
  RadioGroup,
  SelectChangeEvent,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MaterialSelect from '@mui/material/Select';
import { WrappedFieldProps } from 'redux-form';
import InputWrapper from '../../components/Form/InputWrapper';
import { useRequiresPermissionAndRole } from '../../hooks/permissions';
import { FieldProps, OptionsProp, SelectOption } from '../../types';
import wrap from '../../utils/wrap';
import Field from '../Field';

function getValueAttr(value: string | number | boolean | null): string | number {
  return typeof value === 'boolean' || value === null ? String(value) : value;
}

function maybeStringToBoolean(e: SelectChangeEvent<unknown> | ChangeEvent<HTMLInputElement>) {
  let value = e.target.value as string | number | boolean | null | undefined;
  if (value === 'true') {
    value = true;
  } else if (value === 'false') {
    value = false;
  } else if (value === 'null') {
    value = null;
  }
  return value;
}

function Radio({ input, field, meta }: FieldProps<SelectField>) {
  const error = !meta.valid && meta.touched && meta.error;
  const helperText = field.getHelperText(meta);
  const checkPermission = useRequiresPermissionAndRole();

  return (
    <FormControl error={!!error} component="fieldset" variant="standard">
      {field.label && <FormLabel component="legend">{field.label}</FormLabel>}
      <RadioGroup
        row={field.row}
        aria-label={field.label}
        name={input.name}
        value={input.value}
        onChange={(e) => {
          input.onChange(maybeStringToBoolean(e));
        }}
      >
        {field
          .getOptionCollection()
          .filter(checkPermission)
          .map(({ label, value }) => (
            <FormControlLabel
              key={getValueAttr(value)}
              value={getValueAttr(value)}
              control={<RadioButton size="small" color={field.color || 'primary'} />}
              label={label}
              labelPlacement="end"
            />
          ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

function Select({ input, field, meta }: FieldProps<SelectField>) {
  const { multiple, native, includeBlank } = field;
  const OptionComponent = native ? 'option' : MenuItem;
  const inputValue = multiple ? wrap(input.value).map(getValueAttr) : getValueAttr(input.value);
  const checkPermission = useRequiresPermissionAndRole();

  const options = field
    .getOptionCollection()
    .filter(checkPermission)
    .map(({ label, value }) => (
      <OptionComponent key={getValueAttr(value)} value={getValueAttr(value)}>
        {label}
      </OptionComponent>
    ));

  if (includeBlank) {
    options.unshift(
      <OptionComponent value={getValueAttr(null)} key={0}>
        {includeBlank === true ? ' -- ' : includeBlank}
      </OptionComponent>,
    );
  }

  return (
    <InputWrapper field={field} meta={meta}>
      <MaterialSelect
        native={native}
        multiple={multiple}
        value={inputValue}
        onChange={(e) => {
          input.onChange(maybeStringToBoolean(e));
        }}
        label={field.label}
        margin={field.margin}
        size={field.size}
        required={field.required}
        disabled={field.disabled}
      >
        {options}
      </MaterialSelect>
    </InputWrapper>
  );
}

export default class SelectField extends Field {
  options: OptionsProp;
  isRadio = false;
  row = false;
  multiple = false;
  native = false;
  includeBlank?: string | boolean = false;

  constructor(name: string, options: OptionsProp) {
    super(name);
    this.options = options;
  }

  renderEditComponent(props: WrappedFieldProps) {
    if (this.isRadio) {
      return <Radio {...props} field={this} />;
    }
    return <Select {...props} field={this} />;
  }

  asQuickFilter() {
    if (this.isRadio) {
      this.row = true;
      this.label = undefined;
    }
    return super.asQuickFilter();
  }

  renderCell(value: any) {
    return this.getOptionCollection().find((v) => v.value === value)?.label || value;
  }

  getOptionCollection(): SelectOption[] {
    const options = typeof this.options === 'function' ? this.options() : this.options;

    if (Array.isArray(options)) {
      return options.map((o) => {
        if (typeof o === 'string') {
          return {
            label: o,
            value: o,
          };
        }
        return o;
      });
    }
    if (typeof options === 'object') {
      return Object.keys(options).map((k) => ({
        label: options[k],
        value: k,
      }));
    }
    return [];
  }

  getOptionsObject() {
    const options: Record<string | number, ReactNode> = {};
    this.getOptionCollection().forEach(({ value, label }) => {
      options[getValueAttr(value)] = label;
    });
    return options;
  }
}
