import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FormSubmitHandler, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import {
  FieldFactory,
  numString,
  Paper,
  ReduxField,
  useDialogs,
  useSpecificFormValues,
  useTitle,
  useToast,
} from '@/admin';
import CustomerCommissions from '@/components/Customers/CustomerCommissions';
import { CustomerCommission, User } from '@/models';

interface FormShape {
  from_rep?: User;
  to_rep?: User;
  commissions: CustomerCommission[];
}

function CustomerUpdate({ handleSubmit, submitting, reset, form }: InjectedFormProps<FormShape>) {
  useTitle('Bulk Customer Update');
  const toast = useToast();
  const { confirm } = useDialogs();

  const fromRep = useSpecificFormValues(form, (f: FormShape) => f.from_rep);

  const { data: preview, remove } = useQuery(
    ['bulkCustomerUpdatePreview', fromRep?.id],
    () =>
      axios
        .put<{
          customers: string[];
          count: number;
        }>('/api/customers', { from_rep: fromRep, preview: true })
        .then(({ data }) => data),
    {
      enabled: Boolean(fromRep),
    },
  );

  const onSubmit: FormSubmitHandler<FormShape> = (values) =>
    confirm(
      'Bulk Update Customers',
      'This action is not reversible. Are you sure you want to proceed?',
    ).then(() =>
      axios
        .put('/api/customers', { ...values, preview: false })
        .then(({ data }) => {
          toast(`${numString(data.count, 'customers')} updated`);
          reset();
          remove();
        })
        .catch((e) => {
          throw new SubmissionError(e.response.data.errors);
        }),
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper title="Sales Rep">
        <div style={{ maxWidth: 500 }}>
          <ReduxField field={FieldFactory.belongsTo('from_rep', 'users')} />
          <ReduxField field={FieldFactory.belongsTo('to_rep', 'users')} />
        </div>
      </Paper>

      <Paper title="Commissions">
        <ReduxField
          field={FieldFactory.custom('commissions', CustomerCommissions).withColumnSpan(12)}
        />
      </Paper>

      {preview && (
        <Paper title="Preview">
          <div>Count: {preview.count}</div>
          <ul>
            {preview.customers.slice(0, 5).map((c) => (
              <li key={c}>{c}</li>
            ))}
            {preview.customers.length > 5 && (
              <li>and {numString(preview.customers.length - 5, 'more customers')}</li>
            )}
          </ul>

          {preview.count > 0 && (
            <Button type="submit" variant="contained" disabled={submitting} sx={{ mt: 3 }}>
              Update {numString(preview.count, 'Customers')}
            </Button>
          )}
        </Paper>
      )}
    </form>
  );
}

export default reduxForm<FormShape>({
  form: 'customerUpdate',
  initialValues: {
    commissions: [],
  },
})(CustomerUpdate);
