import { ChangeEvent, ReactNode, useState } from 'react';
import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  CardContent,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CardHeader,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { curr, FieldFactory, useDialogs, useOnChangeEffect, useTitle, useToast } from '@/admin';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import { Reconciliation } from '@/models';
import useMutateQueryData from '@/utils/useMutateQueryData';
import ReconciliationTable from './ReconciliationTable';

function Number({
  title,
  curr: amount,
  colored,
  loading = false,
  action,
  children,
}: {
  title: string;
  curr: number;
  colored?: boolean;
  loading?: boolean;
  action?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
        title={title}
        action={action}
      />
      {loading ? (
        <CardContent>
          <CircularProgress />
        </CardContent>
      ) : (
        <CardContent>
          <Typography variant="h3" color="primary">
            {colored ? <ColoredCurrency amount={amount} /> : curr(amount)}
          </Typography>
        </CardContent>
      )}
      {children}
    </Card>
  );
}

export default function ReconciliationShow({ id }: { id: number }) {
  useTitle('Reconciliation', '/reconciliation');

  const { confirm, prompt } = useDialogs();
  const navigate = useNavigate();
  const toast = useToast();
  const [entries, setEntries] = useState<number[]>([]);

  const setData = useMutateQueryData<Reconciliation>(['reconciliation', id]);

  const { mutate: save } = useMutation(
    ({ entries }: { entries: number[] }) =>
      axios.put<Reconciliation>(`/api/reconciliations/${id}`, { entries }).then(({ data }) => data),
    {
      onSuccess: (rec) => {
        setData(rec);
      },
    },
  );
  const { data, isLoading } = useQuery(
    ['reconciliation', id],
    () => axios.get<Reconciliation>(`/api/reconciliations/${id}`).then(({ data }) => data),
    {
      onSuccess: ({ entries }) => {
        setEntries(entries);
      },
      onError: () => {
        navigate('/reconciliation');
      },
    },
  );

  useOnChangeEffect(() => {
    save({ entries });
  }, [entries?.length]);

  const handleCheck = (e: ChangeEvent<HTMLInputElement>, entryId: number) => {
    if (e.target.checked) {
      setEntries((prev) => _.uniq([...prev, entryId]));
    } else {
      setEntries((prev) => prev.filter((p) => p !== entryId));
    }
  };

  const editEndBalance = () => {
    prompt({
      title: 'Update Ending Balance',
      fields: [FieldFactory.curr('end_balance')],
      initialValues: _.pick(data, 'end_balance'),
      onSubmit: (v) => axios.put(`/api/reconciliations/${id}`, v),
    }).then(({ data: res }) => {
      setData(res);
    });
  };

  const { mutate: complete, isLoading: isCompleting } = useMutation(
    () => axios.post(`/api/reconciliations/${id}/complete`),
    {
      onSuccess: () => {
        toast('Reconciliation Complete!');
        navigate('/reconciliation');
      },
    },
  );

  const { mutate: discard, isLoading: isDiscarding } = useMutation(
    () => axios.post(`/api/reconciliations/${id}/discard`),
    {
      onSuccess: () => {
        toast('Reconciliation Discarded');
        navigate('/reconciliation');
      },
    },
  );

  const onComplete = () => {
    confirm(
      'Complete Reconciliation',
      'Are you sure? This will mark all selected transactions as reconciled',
      'success',
    ).then(() => {
      complete();
    });
  };

  const onDiscard = () => {
    let message = 'Are you sure you want to discard this reconciliation?';
    if (data!.completed_at) {
      message +=
        ' This reconciliation has already been completed, so discarding it will un-reconcile all GL entries.';
    }
    message += ' This action cannot be undone.';
    confirm('Discard Reconciliation', message, 'error').then(() => {
      discard();
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <Box textAlign="right" mb={2}>
        <LoadingButton onClick={onDiscard} loading={isDiscarding}>
          Discard
        </LoadingButton>
        {data.difference === 0 && (
          <LoadingButton
            variant="contained"
            onClick={onComplete}
            loading={isCompleting}
            sx={{ ml: 2 }}
          >
            Complete
          </LoadingButton>
        )}
      </Box>

      <Grid container spacing={3}>
        <Grid item md={3} xs={6}>
          <Number title="Beginning Balance" curr={data.begin_balance} />
        </Grid>
        <Grid item md={3} xs={6}>
          <Number
            title="Ending Balance"
            curr={data.end_balance}
            action={
              <IconButton size="small" onClick={editEndBalance}>
                <Edit fontSize="small" />
              </IconButton>
            }
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <Number title="Cleared Balance" curr={data.cleared_balance}>
            <List dense>
              <ListItem>
                <ListItemText secondary="Payments" />
                <ListItemSecondaryAction>{curr(data.payments_sum)}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText secondary="Deposits" />
                <ListItemSecondaryAction>{curr(data.deposits_sum)}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText secondary="Total" />
                <ListItemSecondaryAction>{curr(data.transactions_sum)}</ListItemSecondaryAction>
              </ListItem>
            </List>
          </Number>
        </Grid>
        <Grid item md={3} xs={6}>
          <Number title="Difference" curr={data.difference} colored />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <b>{data.unreconciled_entries.length}</b> unreconcilated ledger entries found for this
            account on or before {moment(data.end_date).format('LL')}.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <ReconciliationTable
              entries={data.unreconciled_entries}
              selected={entries}
              onSelect={handleCheck}
              setSelected={setEntries}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
