import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Select, Typography, Alert, MenuItem, IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { useTitle, useSessionState, Can, FieldFactory, useDialogs } from '@/admin';
import Dashboard from '@/components/Dashboard/Dashboard';
import { useConfig } from '@/contexts/AppConfigContext';

export default function Home() {
  const { dashboards = [], siteMessage } = useConfig();
  const [dashboardId, setDashboardId] = useSessionState('dashboardId', dashboards[0]?.id);
  const dashboard = dashboards.find((d) => d.id === Number(dashboardId));
  const { prompt } = useDialogs();

  useEffect(() => {
    if (!dashboard && dashboards[0]) {
      setDashboardId(dashboards[0].id);
    }
  }, [setDashboardId]);

  const onCreate = () => {
    prompt({
      title: 'Create Dashboard',
      fields: [FieldFactory.text('name'), FieldFactory.hasMany('roles', 'roles')],
      onSubmit: (v) => axios.post(`/api/dashboards`, v),
    }).then(({ data }) => {
      setDashboardId(data.id);
      window.location.reload();
    });
  };

  useTitle(
    'Dashboards',
    undefined,
    <Can permission="write:reports">
      <Tooltip title="Create Dashboard">
        <IconButton sx={{ ml: 2 }} onClick={onCreate}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Can>,
  );

  return (
    <div>
      {siteMessage && (
        <Box mb={1}>
          <Alert severity="info">{siteMessage}</Alert>
        </Box>
      )}

      {dashboard ? (
        <Box pb={2} position="relative">
          <Dashboard dashboard={dashboard}>
            <Select
              value={dashboardId}
              onChange={(e) => setDashboardId(Number(e.target.value))}
              size="small"
              sx={{ width: 300 }}
            >
              {dashboards.map((d) => (
                <MenuItem key={d.id} value={d.id}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </Dashboard>
        </Box>
      ) : (
        <Typography color="textSecondary">
          There are no dashboards set up for your account.
        </Typography>
      )}
    </div>
  );
}
