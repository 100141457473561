import { Settings } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Checkbox,
  ListItemSecondaryAction,
  Typography,
  Paper,
  Button,
  IconButton,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import { Account } from '@/models';

const BankCard = styled('div')({
  display: 'inline-block',
  width: 400,
  maxWidth: '100%',
  marginRight: 24,
  whiteSpace: 'initial',
  verticalAlign: 'top',
});

const Container = styled('div')({
  padding: '4px 0',
  marginBottom: 16,
  overflowX: 'auto',
  overflowY: 'visible',
  whiteSpace: 'nowrap',
});

export default function BankList({
  onSelect,
  accounts,
  selected,
}: {
  onSelect: (a: Account) => void;
  accounts: Account[];
  selected: Account | undefined | null;
}) {
  return (
    <Container>
      {accounts.map((account) => (
        <BankCard key={account.id}>
          <Paper>
            <List dense>
              <ListItemButton onClick={() => onSelect(account)}>
                <ListItemAvatar>
                  <Avatar
                    src={`data:image/png;base64, ${account.plaid_logo}`}
                    sx={{ bgcolor: 'primary.main' }}
                  >
                    {(account.plaid_name || account.name)[0]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="h6" gutterBottom style={{ lineHeight: 1.4 }}>
                    {account.name}
                  </Typography>
                  <Typography variant="subtitle1" style={{ lineHeight: 1.4 }}>
                    {account.plaid_name}
                  </Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={selected?.id === account.id}
                    onChange={() => onSelect(account)}
                  />
                </ListItemSecondaryAction>
              </ListItemButton>
              <ListItem>
                <ListItemText secondary={`Avail Balance (#${account.number})`} />
                <ListItemSecondaryAction>
                  <ColoredCurrency amount={account.cached_balance} onlyNegative />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText secondary="Bank Balance" />
                <ListItemSecondaryAction>
                  {account.plaid_balance != null ? (
                    <ColoredCurrency amount={account.plaid_balance} onlyNegative />
                  ) : (
                    '?'
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText style={{ margin: 0 }}>
                  <Button
                    component={Link}
                    to={`/reconciliation?account_id=${account.id}`}
                    size="small"
                  >
                    Reconcile
                  </Button>
                </ListItemText>
                <ListItemSecondaryAction>
                  <IconButton component={Link} to={`/accounts/${account.id}`} size="small">
                    <Settings fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </BankCard>
      ))}
    </Container>
  );
}
