import { ElementType, ReactNode } from 'react';
import axios, { AxiosResponse } from 'axios';
import Joi from 'joi';
import _ from 'lodash';
import pluralize from 'pluralize';
import { Actions, Fieldable, NullableFieldable } from '../types';
import { generateFormName } from '../utils/form';
import truthy from '../utils/truthy';
import Field from './Field';
import CustomField from './Fields/CustomField';
import ListField from './Fields/ListField';
import RelationTableField from './Fields/RelationTableField';
import TableField from './Fields/TableField';
import Layout from './Layout';
import SidebarLayout from './Layouts/SidebarLayout';
import TabLayout from './Layouts/TabLayout';

const canBeColumn = (f: Field) => {
  if (f.customRenderCellFunc) {
    return true;
  }
  return !(
    f instanceof TableField ||
    f instanceof ListField ||
    f instanceof RelationTableField ||
    f instanceof CustomField
  );
};

function setDefaultsOnFields(fields: Fieldable[], defaults: FieldDefaults): Fieldable[] {
  fields.forEach((field) => {
    Object.entries(defaults).forEach(([k, v]) => {
      if (typeof field[k as keyof FieldDefaults] !== 'boolean') {
        // The defaults on fieldable is either 1 or 0
        field[k as keyof FieldDefaults] = v;
      }
    });

    if (field instanceof Layout && Array.isArray(field.fields)) {
      setDefaultsOnFields(field.fields, defaults);
    }
    if (field instanceof TabLayout && Array.isArray(field.tabs)) {
      setDefaultsOnFields(field.tabs, defaults);
    }
    if (field instanceof SidebarLayout && Array.isArray(field.sidebarFields)) {
      setDefaultsOnFields(field.sidebarFields, defaults);
    }
  });
  return fields;
}

function recursiveFields(fields: Fieldable[]): Field[] {
  return fields.flatMap((fieldable) => {
    if (fieldable instanceof Field) {
      return [fieldable];
    }
    if (fieldable instanceof TabLayout) {
      return recursiveFields(fieldable.tabs);
    }
    return recursiveFields(fieldable.fields);
  });
}

type FieldDefaults = Partial<{
  creatable: Field['creatable'];
  editable: Field['editable'];
  readOnly: Field['readOnly'];
}>;

interface ResourceQueryParams {
  index: Record<string, string>;
  single: Record<string, string>;
}

type Pk = string | number;

export default class Resource<T extends object = Record<string, any>> {
  name: string;
  key: string;
  singularName: string;
  apiEndpoint: string;
  getApiEndpointFunction?: () => string;
  primaryKey: string;
  creatable: boolean;
  editable: boolean;
  deletable: boolean;
  getValue: (o: T) => Pk;
  getLabel: (o: T) => ReactNode;
  getTitle: (o: T) => string;
  getSubtitle?: (o: T) => ReactNode;
  getAvatar?: (o: T) => ReactNode;
  getSecondaryAction?: (o: T) => ReactNode;
  queryParams: ResourceQueryParams;
  getQueryParamsFunction?: () => ResourceQueryParams;
  fields: Fieldable[];
  columns: Field[];
  filters: Field[];
  initialTableColumns: string[] | null;
  useDrawer: boolean;
  defaultSort: string | null;
  defaultValues: Partial<T>;
  defaultFilters: object;
  bulkActions: Actions;
  indexActions: Actions;
  getSingleActionsFunction: (values: T) => Actions;
  fieldDefaults: FieldDefaults;
  editComponent?: ElementType;
  createComponent?: ElementType;
  exportable?: Record<string, string>;
  staticOptions?: T[];
  validation?: Joi.ObjectSchema;

  constructor(name: string) {
    this.name = name;
    this.key = _.camelCase(name);
    this.singularName = pluralize.singular(name);
    this.apiEndpoint = `/api/${_.kebabCase(this.key)}`;
    this.primaryKey = 'id';
    this.creatable = true;
    this.editable = true;
    this.deletable = true;
    // @ts-ignore
    this.getValue = (r: T) => r.id;
    // @ts-ignore
    this.getLabel = (r: T) => r.name;
    // @ts-ignore
    this.getTitle = (r: T) => r.name;
    this.queryParams = {
      index: {},
      single: {},
    };
    this.fields = [];
    this.columns = [];
    this.filters = [];
    this.initialTableColumns = null;
    this.useDrawer = false;
    this.defaultSort = null;
    this.defaultValues = {};
    this.defaultFilters = {};
    this.bulkActions = [];
    this.indexActions = [];
    this.getSingleActionsFunction = () => [];
    this.fieldDefaults = {
      creatable: true,
      editable: true,
      readOnly: false,
    };
  }

  getFormName(): string {
    return generateFormName(this.key);
  }

  withKey(key: string) {
    this.key = key;
    return this;
  }

  withApiEndpoint(path: string) {
    this.apiEndpoint = path;
    return this;
  }

  withPrimaryKey(name: string) {
    this.primaryKey = name;
    return this;
  }

  withFieldDefaults(defaults: Partial<FieldDefaults>) {
    this.fieldDefaults = { ...this.fieldDefaults, ...defaults };
    return this;
  }

  setCreatable(bool = true) {
    this.creatable = bool;
    return this;
  }

  setEditable(bool = true) {
    this.editable = bool;
    return this;
  }

  setDeletable(bool = true) {
    this.deletable = bool;
    return this;
  }

  setUseDrawer(bool = true) {
    this.useDrawer = bool;
    return this;
  }

  editUsing(component: ElementType) {
    this.editComponent = component;
    return this;
  }

  createUsing(component: ElementType) {
    this.createComponent = component;
    return this;
  }

  getValueUsing(func: Resource<T>['getValue']) {
    this.getValue = func;
    return this;
  }

  getLabelUsing(func: Resource<T>['getLabel']) {
    this.getLabel = func;
    return this;
  }

  getTitleUsing(func: Resource<T>['getTitle']) {
    this.getTitle = func;
    return this;
  }

  getAvatarUsing(func: Resource<T>['getAvatar']) {
    this.getAvatar = func;
    return this;
  }

  getSubtitleUsing(func: Resource<T>['getSubtitle']) {
    this.getSubtitle = func;
    return this;
  }

  getSecondaryActionUsing(func: Resource<T>['getSecondaryAction']) {
    this.getSecondaryAction = func;
    return this;
  }

  getQueryParamsUsing(func: Resource['getQueryParamsFunction']) {
    this.getQueryParamsFunction = func;
    return this;
  }

  getQueryParams(): ResourceQueryParams {
    if (this.getQueryParamsFunction) {
      return this.getQueryParamsFunction();
    }
    return this.queryParams;
  }

  getQueryParamsForIndex() {
    return this.getQueryParams().index || {};
  }

  getQueryParamsForSingle() {
    return this.getQueryParams().single || {};
  }

  withInitialColumns(columns: string[]) {
    this.initialTableColumns = columns;
    return this;
  }

  withColumns(columns: Field[]) {
    this.columns = columns;
    return this;
  }

  withFields(fields: NullableFieldable[]) {
    this.fields = setDefaultsOnFields(truthy(fields), this.fieldDefaults);
    return this;
  }

  addColumns(columns: Field[]) {
    this.columns = [...this.columns, ...columns];
    return this;
  }

  addFields(fields: NullableFieldable[]) {
    this.fields = [...this.fields, ...setDefaultsOnFields(truthy(fields), this.fieldDefaults)];
    return this;
  }

  withFieldsAndColumns(fields: NullableFieldable[]) {
    return this.withFields(fields).withColumns(recursiveFields(truthy(fields)).filter(canBeColumn));
  }

  addFieldsAndColumns(fields: NullableFieldable[]) {
    return this.addFields(fields).addColumns(recursiveFields(truthy(fields)).filter(canBeColumn));
  }

  withFilters(fields: Field[]) {
    this.filters = fields;
    return this;
  }

  withoutPrimaryKey(o: object) {
    return _.omit(o, this.primaryKey);
  }

  getIndexRequest(
    params = {},
    options = {},
  ): Promise<AxiosResponse<{ data: Record<string, any>[]; meta: object }>> {
    return axios.get(this.getApiEndpoint(), {
      ...options,
      params: { ...this.getQueryParamsForIndex(), ...params },
    });
  }

  getShowRequest(id: Pk, params = {}): Promise<AxiosResponse> {
    return axios.get(`${this.getApiEndpoint()}/${id}`, {
      params: { ...this.getQueryParamsForSingle(), ...params },
    });
  }

  getDeleteRequest(id: Pk): Promise<AxiosResponse<never>> {
    return axios.delete(`${this.getApiEndpoint()}/${id}`);
  }

  getAttachRequest(id: Pk): Promise<AxiosResponse<never>> {
    return axios.post(`${this.getApiEndpoint()}/${id}`);
  }

  getStoreRequest(body: object): Promise<AxiosResponse> {
    return axios.post(this.getApiEndpoint(), body, {
      params: this.getQueryParamsForSingle(),
    });
  }

  getUpdateRequest(body: object): Promise<AxiosResponse> {
    // @ts-ignore
    const { [this.primaryKey]: pk, ...rest } = body;
    return axios.put(`${this.getApiEndpoint()}/${pk}`, rest, {
      params: this.getQueryParamsForSingle(),
    });
  }

  withDefaultSort(sort: string) {
    this.defaultSort = sort;
    return this;
  }

  withDefaultValues(values: object) {
    this.defaultValues = values;
    return this;
  }

  withDefaultFilters(filters: object) {
    this.defaultFilters = filters;
    return this;
  }

  getColumnNames() {
    return this.columns.map((c) => c.name);
  }

  getInitialTableColumnNames() {
    return this.initialTableColumns || this.getColumnNames().slice(0, 7);
  }

  getAllFields() {
    return recursiveFields(this.fields);
  }

  findField(name: string) {
    return this.getAllFields().find((f) => f.name === name);
  }

  getFilterableFields() {
    return [
      ...this.columns.filter((f) => f.isFilterable).map((f) => f.getFilterField()),
      ...this.filters,
    ];
  }

  getApiEndpointUsing(func: Resource['getApiEndpointFunction']) {
    this.getApiEndpointFunction = func;
    return this;
  }

  /**
   * @returns {string}
   */
  getApiEndpoint() {
    if (this.getApiEndpointFunction) {
      return this.getApiEndpointFunction();
    }
    return this.apiEndpoint;
  }

  withValidation(object: Joi.ObjectSchema) {
    if (!object.validate) {
      throw new Error('Validation must be a Joi schema object');
    }
    this.validation = object;
    return this;
  }

  withIndexActions(actions: Actions) {
    this.indexActions = actions;
    return this;
  }

  withBulkActions(actions: Actions) {
    this.bulkActions = actions;
    return this;
  }

  getSingleActionsUsing(func: Resource<T>['getSingleActionsFunction']) {
    this.getSingleActionsFunction = func;
    return this;
  }

  withQueryParams(params: Partial<ResourceQueryParams>) {
    this.queryParams = { ...this.queryParams, ...params };
    return this;
  }

  setReadOnly(bool = true) {
    this.getAllFields().forEach((field) => {
      Object.assign(field, { readOnly: bool });
    });
    if (bool) {
      return this.setDeletable(false).setCreatable(false);
    }
    return this;
  }

  withExportable(params: Record<string, string> = { format: 'xlsx' }) {
    this.exportable = params;
    return this;
  }

  cloneWith(overrides: Partial<Resource<T>> = {}) {
    const newResource = new Resource(this.name);
    Object.assign(newResource, this, overrides);
    return newResource;
  }

  setCreatableFields(fieldNames: string[]) {
    this.fields.forEach((f) => {
      if (fieldNames.includes(f.name)) {
        Object.assign(f, { creatable: false });
      }
    });
    return this;
  }

  setEditableFields(fieldNames: string[]) {
    this.fields.forEach((f) => {
      if (fieldNames.includes(f.name)) {
        Object.assign(f, { editable: false });
      }
    });
    return this;
  }

  withStaticOptions(options: T[]) {
    this.staticOptions = options;
    return this;
  }
}
