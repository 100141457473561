import EditFormFields, { EditFormFieldsProps } from '../../components/EditFormFields';
import DrawerFormSection from '../../components/Form/DrawerFormSection';
import { LayoutProps } from '../../types';
import Layout from '../Layout';

function Component({ model, forwardProps = {} }: LayoutProps<FieldsetLayout>) {
  return (
    <DrawerFormSection title={model.label}>
      <EditFormFields fields={model.fields} defaultLayout={model.defaultLayout} {...forwardProps} />
    </DrawerFormSection>
  );
}

export default class FieldsetLayout extends Layout {
  renderLayout(props: Partial<EditFormFieldsProps>): React.ReactElement | null {
    return <Component model={this} forwardProps={props} />;
  }
}
