import { ReactNode } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { StatusChip } from '@/admin';
import AddressBlock from '@/components/Addresses/AddressBlock';
import { PURCHASE_ORDER_STATUS_COLORS } from '@/constants';
import { PurchaseOrder } from '@/models';

export default function PurchaseOrderSummaryCard({
  purchaseOrder,
  children,
  noGutters,
}: {
  purchaseOrder: PurchaseOrder;
  children?: ReactNode;
  noGutters?: boolean;
}) {
  const data = _.pickBy({
    Vendor: purchaseOrder.vendor.name,
    Nickname: purchaseOrder.description,
    Status: <StatusChip status={purchaseOrder.status} colors={PURCHASE_ORDER_STATUS_COLORS} />,
    Address: purchaseOrder.address && <AddressBlock address={purchaseOrder.address} />,
    'Issued Date': purchaseOrder.issued_date
      ? moment(purchaseOrder.issued_date).format('ll')
      : null,
  });

  return (
    <Card sx={{ mb: noGutters ? 0 : 2 }}>
      <CardHeader title={purchaseOrder.increment_id} />

      <TableContainer>
        <Table>
          <TableBody>
            {_.map(data, (value, label) => (
              <TableRow key={label}>
                <TableCell component="th">
                  <Typography color="textSecondary" variant="body2">
                    {label}
                  </Typography>
                </TableCell>
                <TableCell>
                  {_.isString(value) ? <Typography variant="body2">{value}</Typography> : value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {children && <CardContent>{children}</CardContent>}
    </Card>
  );
}
