import { Button } from '@mui/material';
import useFormActions from '../../hooks/useFormActions';
import useFormMeta from '../../hooks/useFormMeta';

export default function ResetButton({ form }: { form: string | undefined }) {
  const { dirty, submitting } = useFormMeta(form);
  const { reset } = useFormActions(form);
  if (dirty) {
    return (
      <Button disabled={submitting} type="button" onClick={reset}>
        Reset
      </Button>
    );
  }

  return null;
}
