import { ChangeEvent, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, IconButton, Box, Alert } from '@mui/material';
import axios from 'axios';
import Joi from 'joi';
import { FormSubmitHandler, InjectedFormProps, reduxForm } from 'redux-form';
import {
  ClosableDrawer,
  getDirtyProperties,
  ResetButton,
  stopProp,
  useRecordId,
  useSpecificFormValues,
  validateUsingRules,
} from '@/admin';
import { OrderItem } from '@/models';
import useApiSegment from '@/utils/useApiSegment';
import BehaviorSection from './Create/BehaviorSection';
import PriceSection from './Create/PriceSection';
import VariantSection from './Create/VariantSection';
import VendorSection from './Create/VendorSection';

type FormShape = Partial<OrderItem>;

interface OrderItemEditProps {
  form: string;
  item?: Partial<OrderItem>;
  onNext?: () => void;
  onPrev?: () => void;
  onSuccess: (i: OrderItem) => void;
  open: boolean;
  onClose: () => void;
  title: string;
}

export const getQtyRuleForItem = (item: FormShape) => {
  const qtyRule = Joi.number().integer().required().not(0);

  if (!item.qty_sourced) {
    return qtyRule;
  }

  if (item.qty_sourced < 0) {
    qtyRule.max(item.qty_sourced);
  } else if (item.qty_sourced > 0) {
    qtyRule.min(item.qty_sourced);
  }

  return qtyRule;
};

function OrderItemEditDrawer({
  form,
  handleSubmit,
  destroy,
  submitting,
  change,
  initialize,
  dirty,
  open,
  onClose,
  onSuccess,
  title,
  onNext,
  onPrev,
  item,
}: InjectedFormProps<FormShape, OrderItemEditProps> & OrderItemEditProps) {
  const orderId = useRecordId();
  const segment = useApiSegment(1);
  const [variant, qty, isCustom = false, useDatabaseCost = false] = useSpecificFormValues(
    form,
    (i: FormShape) => [i.variant, i.qty, i.is_custom, i.use_database_cost],
  );

  useEffect(() => {
    if (open && item) {
      initialize(item);
    } else {
      destroy();
    }
  }, [open, item?.id]);

  // Handle Variant Change
  useEffect(() => {
    if (dirty && variant) {
      change('is_shippable', variant.product.default_is_shippable);
      change('can_drop_ship', variant.product.default_can_drop_ship);
      change('can_apply_designs', variant.product.default_can_apply_designs);
      change('is_purchasable', variant.product.default_is_purchasable);
      change('show_on_invoice', variant.product.default_show_on_invoice);
      change('vendor', variant.product.vendor);
      change('classification', variant.product.classification);
    }
  }, [variant?.id]);

  // Handle Cost Change
  useEffect(() => {
    if (useDatabaseCost && !isCustom && variant && qty) {
      change('cost', variant.cost);
    }
  }, [useDatabaseCost, isCustom, variant, qty]);

  const onSubmit: FormSubmitHandler<FormShape, OrderItemEditProps> = (values) => {
    const promise = item?.id
      ? axios.put(`/api/${segment}/${orderId}/items/${item.id}`, getDirtyProperties(values, item))
      : axios.post(`/api/${segment}/${orderId}/items`, values);

    return promise.then(({ data }) => {
      onSuccess(data);
      onClose();
    });
  };

  const handleCustomChange = (e: ChangeEvent<HTMLInputElement>) => {
    change('is_custom', e.currentTarget.value === 'custom');
  };

  const actions = [];
  if (onPrev) {
    actions.push(
      <IconButton onClick={onPrev} size="large">
        <ChevronLeft />
      </IconButton>,
    );
  }
  if (onNext) {
    actions.push(
      <IconButton onClick={onNext} size="large">
        <ChevronRight />
      </IconButton>,
    );
  }

  let min: number | undefined = undefined;
  let max: number | undefined = undefined;
  if (item?.qty_sourced) {
    if (item.qty_sourced > 0) {
      min = item.qty_sourced;
    } else {
      max = item.qty_sourced;
    }
  }

  return (
    <ClosableDrawer open={open} onClose={onClose} title={title} icons={actions}>
      <form onSubmit={stopProp(handleSubmit)(onSubmit)}>
        {item?.qty_sourced ? (
          <Alert severity="warning" sx={{ mt: -1, mb: 1 }}>
            This item has been purchased. Therefore, only some fields are editable.
          </Alert>
        ) : null}
        <VariantSection handleCustomChange={handleCustomChange} isCustom={isCustom} form={form} />

        <PriceSection minQty={min} maxQty={max} />

        <VendorSection isCustom={isCustom} useDatabaseCost={useDatabaseCost} />

        <BehaviorSection />

        <Box mt={3}>
          <Button type="submit" variant="contained" disabled={submitting} sx={{ mr: 1 }}>
            Save
          </Button>
          <ResetButton form={form} />
        </Box>
      </form>
    </ClosableDrawer>
  );
}

const validate = (values: FormShape) => {
  if (!values.is_custom) {
    return validateUsingRules(
      values,
      Joi.object({
        variant: Joi.any().required(),
        qty: getQtyRuleForItem(values),
      }).unknown(),
    );
  }

  return validateUsingRules(
    values,
    Joi.object({
      number: Joi.any().required(),
      color: Joi.any(),
      size: Joi.any(),
      vendor: values.is_purchasable
        ? Joi.any().required().messages({
            'any.required': `A vendor is required when the item is purchasable`,
          })
        : Joi.any(),
      description: Joi.string().required(),
      qty: getQtyRuleForItem(values),
    }).unknown(),
  );
};

export default reduxForm<FormShape, OrderItemEditProps>({
  validate,
})(OrderItemEditDrawer);
