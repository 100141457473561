import { CardLayout, FieldFactory, Resource } from '@/admin';
import { DepartmentSet } from '@/models';

export default function departmentSets() {
  return new Resource<DepartmentSet>('Department Sets')
    .withQueryParams({
      index: {},
      single: {
        with: 'departments',
      },
    })
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      new CardLayout('Department Options')
        .withoutPadding()
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.table('departments', [FieldFactory.text('name')]).withColumnSpan(12),
        ]),
    ]);
}
