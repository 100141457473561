import {
  CircularProgress,
  ImageList,
  ImageListItem,
  Typography,
  ImageListItemBar,
  Box,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';

export default function OrderDesignTiles({ orderId }: { orderId: number }) {
  const { data: designs = [], isLoading } = useQuery(
    ['order', orderId, 'designs'],
    () => axios.get(`/api/designs?order_id=${orderId}`).then(({ data }) => data.data),
    {
      staleTime: 30 * 1000, // 30 secs
    },
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (designs.length === 0) {
    return (
      <Box textAlign="center" py={4}>
        <Typography color="textSecondary">There are no designs</Typography>
      </Box>
    );
  }

  return (
    <div>
      <ImageList rowHeight={205} cols={2}>
        {_.chain(designs)
          .orderBy(['image', 'desc'])
          .take(6)
          .map((d) => (
            <ImageListItem key={d.id} cols={1}>
              <img
                src={d.image || 'https://via.placeholder.com/300.png?text=No+Design+Thumbnail'}
                alt={d.name}
              />
              <ImageListItemBar
                title={d.name}
                subtitle={
                  <span>
                    #{d.increment_id} &bull; {_.get(d, 'decoration_type.name')}
                  </span>
                }
              />
            </ImageListItem>
          ))
          .value()}
      </ImageList>
    </div>
  );
}
