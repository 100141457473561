import { styled } from '@mui/material';
import { getApiUrl } from '@/admin';
import { useConfig } from '@/contexts/AppConfigContext';

const Banner = styled('div')(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main,
  textAlign: 'center',
  padding: '8px 12px',
  position: 'fixed',
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: 10,
}));

export default function ImpersonatingBanner() {
  const { impersonating, user } = useConfig();

  if (impersonating) {
    return (
      <Banner>
        You are logged in as {user.name}.
        <a href={getApiUrl('/impersonate/exit')} style={{ color: 'white' }}>
          Exit &times;
        </a>
      </Banner>
    );
  }

  return null;
}
