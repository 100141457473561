import { DisabledByDefault, Undo } from '@mui/icons-material';
import axios from 'axios';
import { ButtonAction, numString } from '@/admin';
import { Customer } from '@/models';

export function getSingleDisableAction(
  objectName: string,
  apiPath: string,
  values: { id: number; is_disabled: boolean },
): ButtonAction {
  if (values.is_disabled) {
    return new ButtonAction(
      'Enable',
      ({ dialogs, toast, onReloadRecord }) => {
        dialogs
          .confirm(`Enable ${objectName}`, `Are you sure you want to enable this ${objectName}?`)
          .then(() => {
            axios.post(`/api/${apiPath}/${values.id}/disable`).then(() => {
              onReloadRecord();
              toast(`${objectName} successfully enabled`);
            });
          });
      },
      Undo,
    );
  }
  return new ButtonAction(
    'Disable',
    ({ dialogs, toast, onReloadRecord }) => {
      dialogs
        .confirm(`Disable ${objectName}`, `Are you sure you want to disable this ${objectName}?`)
        .then(() => {
          axios.post(`/api/${apiPath}/${values.id}/disable`).then(() => {
            onReloadRecord();
            toast(`${objectName} successfully disabled`);
          });
        });
    },
    DisabledByDefault,
  );
}

export function getSingleHeldAction(
  objectName: string,
  apiPath: string,
  values: { id: number; is_disabled: boolean },
  onUpdated?: (c: { is_disabled: boolean }) => void,
): ButtonAction {
  if (!values.is_disabled) {
    return new ButtonAction(
      'Put on Hold',
      ({ dialogs, toast, onReloadRecord }) => {
        dialogs
          .confirm(
            `Put ${objectName} on Hold`,
            `Are you sure you want to put this ${objectName} on hold?`,
          )
          .then(() => {
            axios.post<Customer>(`/api/${apiPath}/${values.id}/disable`).then(({ data }) => {
              onReloadRecord();
              toast(`${objectName} successfully put on hold`);
              if (onUpdated) {
                onUpdated(data);
              }
            });
          });
      },
      DisabledByDefault,
    );
  }
  return new ButtonAction(
    'Remove Hold',
    ({ dialogs, toast, onReloadRecord }) => {
      dialogs
        .confirm(
          `Remove Hold on ${objectName}`,
          `Are you sure you want to remove the hold on this ${objectName}?`,
        )
        .then(() => {
          axios.post<Customer>(`/api/${apiPath}/${values.id}/disable`).then(({ data }) => {
            onReloadRecord();
            toast(`Hold successfully removed from ${objectName}`);
            if (onUpdated) {
              onUpdated(data);
            }
          });
        });
    },
    Undo,
  );
}

export function getBulkDisableAction(objectName: string, apiPath: string): ButtonAction {
  return new ButtonAction(
    'Disable',
    ({ selected, showLoading, onReloadTable, dialogs }) => {
      dialogs
        .confirm(
          `Toggle Disabled Status`,
          `Are you sure you want to toggle the disabled status of ${numString(
            selected.length,
            objectName,
          )}?`,
        )
        .then(() => {
          showLoading(
            Promise.all(selected.map((id) => axios.post(`/api/${apiPath}/${id}/disable`))).then(
              onReloadTable,
            ),
          );
        });
    },
    DisabledByDefault,
  );
}
