import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { FormControlLabel, Checkbox, Typography, Box, Button, IconButton } from '@mui/material';
import { Can, FieldFactory, FieldProps, getValueFromEvent, ReduxField, wrap } from '@/admin';
import { CustomerCommission } from '@/models';

interface CommissionRowProps {
  field: string;
  row: CustomerCommission;
  change: (f: string, v: any) => void;
  onRemove: () => void;
}

function CustomerCommissionRow({ field, row, change, onRemove }: CommissionRowProps) {
  const [isDefault, setIsDefault] = useState(row.percent === null && !row.commission_model);
  const [isFixed, setIsFixed] = useState(row.percent != null && !row.commission_model);

  useEffect(() => {
    if (isDefault) {
      change('percent', null);
      change('commission_model', null);
    }
  }, [isDefault]);

  useEffect(() => {
    if (isFixed) {
      change('commission_model', null);
    }
  }, [isFixed]);

  return (
    <Box mb={1} display="flex">
      <Box mr={3} minWidth={300}>
        <ReduxField field={FieldFactory.belongsTo('user', 'users')} parentName={field} />
      </Box>

      <Box mr={3}>
        <Can permission="write:order_commissions">
          <FormControlLabel
            label={<Typography variant="body2">Use User&apos;s Default Commission</Typography>}
            control={
              <Checkbox
                size="small"
                onChange={(e) => setIsDefault(e.target.checked)}
                checked={isDefault}
              />
            }
          />
        </Can>
      </Box>
      <Can permission="write:order_commissions">
        {!isDefault && (
          <Box mr={3} minWidth={300}>
            {isFixed ? (
              <ReduxField
                field={FieldFactory.percent('percent').withLabel('Fixed Percent')}
                parentName={field}
              />
            ) : (
              <ReduxField
                field={FieldFactory.belongsTo('commission_model', 'commissionModels')}
                parentName={field}
              />
            )}
            <FormControlLabel
              label={<Typography variant="body2">Use Fixed Percent</Typography>}
              control={
                <Checkbox
                  size="small"
                  onChange={(e) => setIsFixed(e.target.checked)}
                  checked={isFixed}
                />
              }
            />
          </Box>
        )}
      </Can>
      <Box pt={1.5}>
        <IconButton size="small" onClick={onRemove}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}

function OrderCommissionRow({ field, row, change, onRemove }: CommissionRowProps) {
  const [isFixed, setIsFixed] = useState(row.percent != null && !row.commission_model);

  useEffect(() => {
    if (isFixed) {
      change('commission_model', null);
    }
  }, [isFixed]);

  return (
    <Box mb={1} display="flex">
      <Box mr={3} style={{ minWidth: 300 }} flexGrow={1}>
        <ReduxField field={FieldFactory.belongsTo('user', 'users')} parentName={field} />
      </Box>

      <Can permission="write:order_commissions">
        <Box flexGrow={1} mr={3}>
          {isFixed ? (
            <ReduxField
              field={FieldFactory.percent('percent').withLabel('Fixed Percent')}
              parentName={field}
            />
          ) : (
            <ReduxField
              field={FieldFactory.belongsTo('commission_model', 'commissionModels')}
              parentName={field}
            />
          )}
          <FormControlLabel
            label={<Typography variant="body2">Use Fixed Percent</Typography>}
            control={
              <Checkbox
                size="small"
                onChange={(e) => setIsFixed(e.target.checked)}
                checked={isFixed}
              />
            }
          />
        </Box>
      </Can>
      <Box pt={1.5}>
        <IconButton size="small" onClick={onRemove}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default function CustomerCommissions({ input, field }: FieldProps) {
  const rows = wrap(input.value) as CustomerCommission[];

  const onRemove = (index: number) => {
    input.onChange(rows.filter((r, i) => i !== index));
  };

  const onAdd = () => {
    input.onChange([...rows, {}]);
  };

  const createChangeHandler = (index: number) => (f: string, e: any) => {
    const value = getValueFromEvent(e);
    input.onChange(
      rows.map((r, i) => {
        if (i === index) {
          return {
            ...r,
            [f]: value,
          };
        }
        return r;
      }),
    );
  };

  const RowComponent =
    field.name === 'commission_users' ? OrderCommissionRow : CustomerCommissionRow;

  return (
    <div>
      {rows.map((row, index) => (
        <RowComponent
          key={row.id || index}
          field={`${input.name}[${index}]`}
          row={row}
          change={createChangeHandler(index)}
          onRemove={() => onRemove(index)}
        />
      ))}
      {rows.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          No users are set up to receive commission.
        </Typography>
      )}
      <Box mt={2}>
        <Button onClick={onAdd}>Add User</Button>
      </Box>
    </div>
  );
}
