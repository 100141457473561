import { CardLayout, FieldFactory, Resource } from '@/admin';
import { getLedgerEntriesAction } from '@/components/Accounting/ModelLedgerEntries';
import DepositPaymentSelector from '@/components/Deposits/DepositPaymentSelector';
import DepositPayments from '@/components/Deposits/DepositPayments';
import { getEventableConfigForResource } from '@/helpers';
import { Deposit } from '@/models';

const getTitle = (d: Deposit) =>
  `${String(d.deposit_date).substring(0, 10)} - ${d.bank_account.name}`;

export default function deposits() {
  return new Resource<Deposit>('Deposits')
    .getTitleUsing(getTitle)
    .getLabelUsing(getTitle)
    .withDefaultValues({
      deposit_date: new Date(),
    })
    .withDefaultSort('-id')
    .getSingleActionsUsing((values) => [getLedgerEntriesAction('deposits', values.id)])
    .withColumns([FieldFactory.text('id').withLabel('#').sortable()])
    .addFieldsAndColumns([
      FieldFactory.belongsTo('bank_account', 'bankAccounts')
        .filterable()
        .sortable('bank_account.name'),
      FieldFactory.date('deposit_date').filterable().sortable(),
      FieldFactory.textarea('memo'),
    ])
    .addColumns([
      FieldFactory.curr('total').sortable(),
      FieldFactory.number('payments_count').withLabel('# Payments').sortable(),
    ])
    .addFields([
      new CardLayout('payments')
        .withoutPadding()
        .withoutDefaultLayout()
        .withFields([
          FieldFactory.custom('payments', DepositPayments).setCreatable(false),
          FieldFactory.custom('payments', DepositPaymentSelector).setEditable(false),
        ]),
      getEventableConfigForResource('deposits'),
    ]);
}
