import { SyntheticEvent, useState } from 'react';
import { Button, Alert, LinearProgress, Box } from '@mui/material';
import { UseMutationResult, useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import RateList from '@/components/Shipping/RateList';
import { ShippoRate } from '@/models';
import { CreateShipmentPayload } from '@/responses';

const INIT_LIMIT = 5;

export default function ChooseRates({
  requestParams,
  values,
  submitRequest,
  onError,
}: {
  requestParams: Record<string, any>;
  values: CreateShipmentPayload;
  submitRequest: UseMutationResult<unknown, unknown, { rate_id: string; address_id: number }>;
  onError: (e: AxiosError) => void;
}) {
  const [showAll, setShowAll] = useState(false);
  const [selectedRate, setSelectedRate] = useState<string>();

  const payload = {
    ...requestParams,
    address_id: values.address.id,
    from_address_id: values.from_address.id,
    third_party_account_id: values.third_party_account_id,
    require_signature: values.require_signature,
    service_level: values.service_level,
    customs: values.customs,
    parcels: values.parcels,
  };

  const { isLoading, data: rates = [] } = useQuery(
    ['rates', JSON.stringify(payload)],
    () =>
      axios
        .post<{ rates: ShippoRate[] }>('/api/shipments/quote', payload)
        .then(({ data }) => data.rates),
    {
      retry: false,
      onError,
    },
  );

  const ratesMatchingMethod = rates.filter((r) => r.matches_shipping_method);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedRate) {
      submitRequest.mutate({ rate_id: selectedRate, address_id: values.address.id });
    }
  };

  let ratesToShow = rates;

  if (!showAll) {
    ratesToShow = ratesMatchingMethod.length > 0 ? ratesMatchingMethod : _.take(rates, INIT_LIMIT);
  }

  if (isLoading) {
    return <LinearProgress sx={{ mt: 4 }} />;
  }

  return (
    <form onSubmit={onSubmit}>
      {ratesToShow.length === 0 ? (
        <Alert severity="warning">No rates found</Alert>
      ) : (
        <RateList
          rates={ratesToShow}
          selectedRate={selectedRate}
          setSelectedRate={setSelectedRate}
        />
      )}

      {!showAll && rates.length > ratesToShow.length ? (
        <Button size="small" onClick={() => setShowAll(true)}>
          Show All Rates ({rates.length})
        </Button>
      ) : null}

      <Box mt={3}>
        <Button
          type="submit"
          disabled={submitRequest.isLoading || !selectedRate}
          variant="contained"
        >
          Purchase Label
        </Button>
      </Box>
    </form>
  );
}
