import { Dialog } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import ConfirmDialog from './ConfirmDialog';

export default function ConfirmDialogContainer() {
  const { confirm, setConfirmDialog } = useAppContext();

  if (!confirm) {
    return null;
  }

  const onClose = () => {
    setConfirmDialog({ ...confirm, open: false });
  };

  const onSuccess = (v?: any) => {
    confirm.promise.resolve(v);
    onClose();
  };

  const onCancel = () => {
    confirm.promise.reject();
    onClose();
  };

  const { options } = confirm;

  return (
    <Dialog
      open={confirm.open}
      onClose={onCancel}
      fullWidth
      maxWidth={confirm.options.maxWidth || 'xs'}
    >
      <ConfirmDialog {...options} onSuccess={onSuccess} onCancel={onCancel} />
    </Dialog>
  );
}
