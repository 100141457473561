import { FileCopy } from '@mui/icons-material';
import axios from 'axios';
import { ButtonAction, CardLayout, FieldFactory, GroupLayout, Resource } from '@/admin';
import ProductionEventPrices from '@/components/Production/ProductionEventPrices';
import { ProductionEventType } from '@/models';

export default function productionEventTypes() {
  return new Resource<ProductionEventType>('Production Event Types')
    .getSingleActionsUsing((values) => {
      return [
        new ButtonAction('Duplicate', ({ dialogs, navigate }) => {
          dialogs
            .confirm('Duplicate Event Type', 'Are you sure you want to duplicate this event type?')
            .then(() => {
              axios.post(`/api/production-event-types/${values.id}/duplicate`).then(({ data }) => {
                navigate(`/production-event-types/${data.id}`);
              });
            });
        }).withIcon(FileCopy),
      ];
    })
    .withFieldsAndColumns([
      new CardLayout('info', [
        FieldFactory.text('name').withColumnSpan(3).sortable(),
        FieldFactory.belongsTo('default_machine', 'productionMachines').withColumnSpan(3),
        FieldFactory.select('unit', {
          colors: 'Colors',
          stitches: 'Stitches',
        })
          .with({ includeBlank: true })
          .withColumnSpan(3)
          .sortable(),
        FieldFactory.number('sort')
          .withHelp('Higher numbers will show up first')
          .withColumnSpan(3)
          .sortable(),
        new GroupLayout('Settings', [
          FieldFactory.boolean('requires_approval', 'Requires Supervisor Approval to Start')
            .sortable()
            .filterable(),
        ]),
      ]),
      new CardLayout('fixed_pricing', [
        FieldFactory.curr('setup_cost').sortable(),
        FieldFactory.curr('ink_change_cost').sortable(),
      ]),
      new CardLayout('')
        .withoutPadding()
        .withoutDefaultLayout()
        .withFields([FieldFactory.custom('prices', ProductionEventPrices)])
        .setCreatable(false),
    ]);
}
