import { Add } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ButtonAction, FieldFactory, LinkAction, SubMenu, useDialogs } from '@/admin';
import AttachmentsList from '@/components/Attachments/AttachmentsList';
import { requiredFields } from '@/helpers';
import { Attachment, DesignLayout } from '@/models';

export default function PurchaseOrderDesignLayoutsCard({ poId }: { poId: number }) {
  const { prompt, confirm } = useDialogs();
  const {
    data: po,
    isLoading,
    refetch,
  } = useQuery(['poDesignLayouts', poId], () =>
    axios
      .get<{
        design_layouts: DesignLayout[];
        submit_attachments: Attachment[];
      }>(`/api/purchase-orders/${poId}?with=design_layouts,submit_attachments`)
      .then(({ data }) => data),
  );

  const designLayouts = po?.design_layouts || [];
  const submitAttachments = po?.submit_attachments || [];

  const onAdd = () => {
    prompt<{ design_layout: DesignLayout }>({
      title: 'Add Design Layout to PO',
      fields: [FieldFactory.belongsTo('design_layout', 'designLayouts')],
      validation: requiredFields(['design_layout']),
      onSubmit: (v) =>
        axios.post(`/api/purchase-orders/${poId}/design-layouts/${v.design_layout.id}`),
    }).then(() => {
      refetch();
    });
  };

  const onRemove = (layout: DesignLayout) => {
    confirm(`Remove ${layout.increment_id} from PO`, 'Are you sure?').then(() => {
      axios.delete(`/api/purchase-orders/${poId}/design-layouts/${layout.id}`).then(() => {
        refetch();
      });
    });
  };

  const getInner = () => {
    if (isLoading) {
      return (
        <CardContent>
          <CircularProgress />
        </CardContent>
      );
    }

    if (designLayouts.length === 0) {
      return (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There is no art linked to this PO.
          </Typography>
        </CardContent>
      );
    }

    return (
      <List dense>
        {designLayouts.map((layout) => (
          <ListItem key={layout.id}>
            <ListItemText primary={layout.increment_id} secondary={layout.name} />
            <ListItemSecondaryAction>
              <SubMenu
                items={[
                  new LinkAction('View Art', `/orders/${layout.order_id}?tab=art`),
                  new ButtonAction('Remove from PO', () => onRemove(layout)),
                ]}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        title="Subcontracted Art"
        action={
          <IconButton onClick={onAdd}>
            <Add />
          </IconButton>
        }
      />
      {getInner()}

      {submitAttachments.length > 0 && (
        <>
          <CardHeader
            title="Design Files"
            titleTypographyProps={{ variant: 'subtitle1' }}
            sx={{ py: 1 }}
          />
          <AttachmentsList attachments={submitAttachments} />
          <Box px={2} color="text.secondary" py={1} fontSize="small">
            These files will be attached to the PO email.
          </Box>
        </>
      )}
    </Card>
  );
}
