import { ReactNode, SyntheticEvent, useState } from 'react';
import { CircularProgress, Popover } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { TextButton } from '@/admin';
import { Order } from '@/models';
import WarningIcon from '../Shared/WarningIcon';
import OrderSummaryCard from './OrderSummaryCard';

export default function OrderLabel({
  orderId,
  children,
}: {
  orderId: number;
  children?: ReactNode;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const { data: order } = useQuery(
    ['order', orderId],
    () => axios.get<Order>(`/api/orders/${orderId}`).then(({ data }) => data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: Boolean(orderId),
    },
  );

  const onOpenPopover = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: SyntheticEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <span style={{ marginRight: 4 }}>
      {orderId ? (
        <TextButton onClick={onOpenPopover} style={{ textAlign: 'left' }}>
          {children || orderId}
        </TextButton>
      ) : (
        <WarningIcon message="This item isn't linked to an order" />
      )}

      <Popover
        id="order-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {order ? (
          <OrderSummaryCard order={order} noGutters withAddresses />
        ) : (
          <div
            style={{
              width: 300,
              height: 300,
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Popover>
    </span>
  );
}
