import { createContext, ReactNode, useContext, useState } from 'react';

interface SchedulingState {
  eventIds: number[];
  onSuccess?: () => void;
}

const SchedulingContext = createContext<
  SchedulingState & { setScheduling: (s: SchedulingState) => void }
>({
  setScheduling: () => null,
  eventIds: [],
});

export function SchedulingProvider({ children }: { children: ReactNode }) {
  const [state, setScheduling] = useState<SchedulingState>({
    eventIds: [],
  });

  return (
    <SchedulingContext.Provider value={{ ...state, setScheduling }}>
      {children}
    </SchedulingContext.Provider>
  );
}

export function useSchedulingState() {
  return useContext(SchedulingContext);
}
