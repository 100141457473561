import { Box, Chip } from '@mui/material';
import { formatNumber } from '@/admin';
import { StatFormat } from '@/admin/utils/formatNumber';
import { COMPARE_TO_LABELS } from '@/constants';
import { CompareTo } from '@/responses';

export default function RateOfChangeChip({
  number,
  compareTo,
  format,
  compareToType,
}: {
  number: number;
  compareTo: number;
  format: StatFormat;
  compareToType: CompareTo;
}) {
  const change = (number - compareTo) / Math.abs(compareTo);
  const isPositive = change > 0;

  return (
    <>
      <Box display="inline-block" mr={1}>
        <b>{formatNumber(compareTo, format)}</b>
        <span> {COMPARE_TO_LABELS[compareToType]}</span>
      </Box>
      <Chip
        color={isPositive ? 'success' : 'error'}
        label={
          <>
            {isPositive ? <span>&#9650;</span> : <span>&#9660;</span>}
            <span> {Math.abs(change * 100).toFixed(2)}%</span>
          </>
        }
        size="small"
      />
    </>
  );
}
