import { ReactNode } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  CircularProgress,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails as AccordionDetailsRaw,
  Box,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ClosableDrawer, curr } from '@/admin';
import ColoredCurrency from '@/components/Shared/ColoredCurrency';
import {
  Business,
  BusinessCredit,
  BusinessPayout,
  OrderPayment,
  PendingPayout,
  PurchaseOrder,
} from '@/models';
import getBusinessName from '@/utils/getBusinessName';

function AccordionDetails({ children }: { children: ReactNode }) {
  return <AccordionDetailsRaw style={{ display: 'block' }}>{children}</AccordionDetailsRaw>;
}

export interface DetailedPendingPayout {
  business: Business;
  lines: {
    id: number;
    customer_name: string;
    order_id: number;
    order_increment_id: string;
    order_description: string;
    amount: number;
  }[];
  salespeople: { id: number; user_name: string; amount: number }[];
  deductions: OrderPayment[];
  credits: BusinessCredit[];
  purchase_orders: PurchaseOrder[];
  history: BusinessPayout[];
}

export default function BalanceDetailsDrawer({
  open,
  onClose,
  business,
}: {
  open: boolean;
  onClose: () => void;
  business?: PendingPayout;
}) {
  const businessId = business?.id;
  const { data, isLoading } = useQuery(
    ['pendingPayouts', businessId],
    () =>
      axios
        .get<DetailedPendingPayout>(`/api/pending-payouts/${businessId}`)
        .then((res) => res.data),
    {
      enabled: Boolean(open && businessId),
    },
  );

  return (
    <ClosableDrawer open={open} onClose={onClose} title="Payout Details" width={600}>
      {business && (
        <Box mt={-2} mb={3}>
          <Typography variant="subtitle1" gutterBottom color="textSecondary">
            Business: {getBusinessName(business)}
          </Typography>
          <Typography gutterBottom>
            Total: <span style={{ fontWeight: 500 }}>{curr(business.balance)}</span>
          </Typography>
        </Box>
      )}

      {isLoading && <CircularProgress />}
      {data && (
        <div>
          {data.lines.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Orders</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.lines.map((l) => (
                    <ListItemButton key={l.id} component={Link} to={`/orders/${l.order_id}`}>
                      <ListItemText primary={l.order_increment_id} secondary={l.customer_name} />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={l.amount} />
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          {data.credits.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div>
                  <Typography>Business Credits</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Credits for orders that Avail did not complete correctly.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.credits.map((c) => (
                    <ListItem key={c.id}>
                      <ListItemText primary={c.description} />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={c.amount} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          {data.deductions.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Payout Deductions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.deductions.map((op) => (
                    <ListItem key={op.id}>
                      <ListItemText primary={op.payment.label} />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={op.amount_applied} opposite />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          {data.purchase_orders.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div>
                  <Typography>Purchase Orders</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Items we purchased from the LOB.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.purchase_orders.map((po) => (
                    <ListItem key={po.id}>
                      <ListItemText primary={po.label} secondary={po.description} />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={po.total} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}

          {data.salespeople.length > 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div>
                  <Typography>Commissions Earned</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Just for reference. Not included in payout total.
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {data.salespeople.map((s) => (
                    <ListItem key={s.id}>
                      <ListItemText primary={s.user_name} />
                      <ListItemSecondaryAction>
                        <ColoredCurrency amount={s.amount} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      )}
    </ClosableDrawer>
  );
}
