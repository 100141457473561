import { useRef, useState } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import { WrappedFieldProps } from 'redux-form';
import CreateButton from '../../components/Buttons/CreateButton';
import ClosableDrawer from '../../components/ClosableDrawer';
import { default as DataTable, DataTableHandle } from '../../components/DataTable/DataTable';
import EditForm from '../../components/EditForm';
import { useSpecificFormValues } from '../../hooks/formValues';
import { useResource } from '../../hooks/useGetResource';
import { FieldProps } from '../../types';
import Field from '../Field';
import StackLayout from '../Layouts/StackLayout';
import { RelationCell } from './RelationField';

function RelationTable({ field, meta }: FieldProps<RelationTableField>) {
  const record = useSpecificFormValues(meta.form, (t) => t);
  const resource = useResource(field.resource);

  if (field.getApiEndpointFunc) {
    resource.apiEndpoint = field.getApiEndpointFunc(record);
  }
  if (field.getQueryParamsFunc) {
    Object.assign(resource.queryParams.index, field.getQueryParamsFunc(record));
  }

  const [editing, setEditing] = useState<{ mode: 'edit' | 'create'; values: object } | undefined>();
  const dataTableRef = useRef<DataTableHandle | null>(null);

  const onCreate = () =>
    setEditing({
      mode: 'create',
      values: resource.defaultValues,
    });

  const onEdit = (values: object) =>
    resource.editable
      ? setEditing({
          mode: 'edit',
          values,
        })
      : null;

  const onDoneEditing = () => {
    setEditing(undefined);
    dataTableRef.current?.onReload();
  };

  const isCreate = _.get(editing, 'mode') === 'create';

  const drawer = (
    <ClosableDrawer
      open={!!editing}
      onClose={() => setEditing(undefined)}
      title={`${isCreate ? 'Create' : 'Edit'} ${resource.singularName}`}
    >
      {resource && (
        <EditForm
          onSuccess={onDoneEditing}
          initialValues={_.get(editing, 'values', {})}
          isCreate={isCreate}
          resource={resource}
          form={resource.getFormName()}
          defaultLayout={StackLayout}
        />
      )}
    </ClosableDrawer>
  );

  return (
    <Box mt={-1} position="relative" pb={4}>
      {resource.creatable && (
        <CreateButton onClick={onCreate} size="small" position="absolute" margin={1} />
      )}

      <DataTable resource={resource} onEdit={onEdit} ref={dataTableRef} />

      {drawer}
    </Box>
  );
}

export default class RelationTableField extends Field {
  resource: string;
  getApiEndpointFunc?: (record: any) => string;
  getQueryParamsFunc?: (record: any) => Record<string, string>;

  constructor(name: string, resource: string, getApiEndpointFunc?: (record: any) => string) {
    super(name);
    this.resource = resource;
    this.getApiEndpointFunc = getApiEndpointFunc;
  }

  getApiEndpointUsing(func: RelationTableField['getApiEndpointFunc']) {
    this.getApiEndpointFunc = func;
    return this;
  }

  getQueryParamsUsing(func: RelationTableField['getQueryParamsFunc']) {
    this.getQueryParamsFunc = func;
    return this;
  }

  renderEditComponent(props: WrappedFieldProps) {
    return <RelationTable {...props} field={this} />;
  }

  renderCell(value: any) {
    return <RelationCell value={value} field={this} />;
  }
}
