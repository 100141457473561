import { Edit, AddCircle, Delete } from '@mui/icons-material';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CardHeader,
  CardContent,
  Box,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import {
  Can,
  curr,
  FieldFactory,
  StatusChip,
  useDialogs,
  useFormActions,
  useFormValues,
} from '@/admin';
import UserLabel from '@/components/Users/UserLabel';
import { ART_REQUEST_STATUS_COLORS } from '@/constants';
import { requiredFields } from '@/helpers';
import { ArtCost, ArtRequest } from '@/models';
import useMutateQueryData from '@/utils/useMutateQueryData';

export default function ArtRequestFields() {
  const [id, status, assignee, orderId] = useFormValues((ar: ArtRequest) => [
    ar.id,
    ar.status,
    ar.assignee,
    ar.order_id,
  ]);

  const { silentChange } = useFormActions();

  const { confirm, prompt } = useDialogs();

  const setArtCosts = useMutateQueryData<ArtCost[]>(['artCosts', orderId]);
  const { data: artCosts = [] } = useQuery(['artCosts', orderId], () =>
    axios
      .get<{ data: ArtCost[] }>(`/api/orders/${orderId}/art-costs`)
      .then(({ data }) => data.data),
  );

  const onAssign = () => {
    prompt({
      title: 'Assign Art Request',
      initialValues: {
        assignee,
      },
      fields: [
        FieldFactory.belongsTo('assignee', 'users')
          .withRequestParams({
            with: 'business',
            artist: 1,
          })
          .with({
            getSubtitle: (u) => u.business?.name,
          }),
      ],
      onSubmit: (v) =>
        axios.post(`/api/art-requests/${id}/assign`, { assigned_to: v.assignee?.id || null }),
    }).then(({ data }) => {
      silentChange('assignee', data.assignee);
    });
  };

  const onArtCost = (editing?: ArtCost) => {
    prompt<Partial<ArtCost>>({
      title: editing ? 'Edit Art Cost' : 'Add Art Cost',
      initialValues: editing || { qty: 1 },
      fields: [
        !editing &&
          FieldFactory.belongsTo('cost_item', 'artCostItems').with({
            getSubtitle: (i) => curr(i.cost),
          }),
        FieldFactory.number('qty').withSize('medium'),
      ],
      validation: requiredFields(['cost_item', 'qty']),
      onSubmit: (v) => {
        if (v.id) {
          return axios.put(`/api/orders/${orderId}/art-costs/${v.id}`, {
            cost_item_id: v.cost_item?.id,
            qty: v.qty,
          });
        }
        return axios.post(`/api/orders/${orderId}/art-costs`, {
          cost_item_id: v.cost_item?.id,
          qty: v.qty,
          art_request_id: id,
        });
      },
    }).then(({ data }) => {
      if (editing) {
        setArtCosts((prev) =>
          prev.map((cost) => {
            if (cost.id === data.id) {
              return data;
            }
            return cost;
          }),
        );
      } else {
        setArtCosts((prev) => [...prev, data]);
      }
    });
  };

  const onDelete = (cost: ArtCost) => {
    confirm('Confirm Delete', 'Are you sure you want to remove this art cost?').then(() => {
      axios.delete(`/api/orders/${orderId}/art-costs/${cost.id}`);
      setArtCosts((prev) => _.reject(prev, ['id', cost.id]));
    });
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th">
                <Typography color="textSecondary" variant="body2">
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <StatusChip status={status} colors={ART_REQUEST_STATUS_COLORS} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th">
                <Typography color="textSecondary" variant="body2">
                  Assignee
                </Typography>
              </TableCell>
              <TableCell style={{ minWidth: 200 }}>
                <Box display="flex" alignItems="center">
                  <Typography>{assignee ? <UserLabel user={assignee} /> : 'Unassigned'}</Typography>
                  <Can permission="write:art_requests">
                    <IconButton onClick={onAssign} size="small" sx={{ ml: 'auto' }}>
                      <Edit fontSize="small" />
                    </IconButton>
                  </Can>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <CardHeader
        title="Art Costs for Order"
        titleTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
        action={
          <Can permission="write:art_costs">
            <IconButton onClick={() => onArtCost()} size="small">
              <AddCircle />
            </IconButton>
          </Can>
        }
      />

      {artCosts.length > 0 ? (
        <List>
          {artCosts.map((cost) => (
            <ListItem key={cost.id} dense>
              <ListItemText primary={cost.name} secondary={`Qty: ${cost.qty}`} />

              <ListItemSecondaryAction>
                <IconButton onClick={() => onArtCost(cost)} size="large">
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton onClick={() => onDelete(cost)} size="large">
                  <Delete fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <CardContent>
          <Typography variant="body2">There are no art costs associated with this order</Typography>
        </CardContent>
      )}
    </>
  );
}
