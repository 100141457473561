import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { curr } from '@/admin';
import TableCardContainer from '@/components/Shared/TableCardContainer';
import { VENDOR_PAYMENT_METHODS } from '@/constants';
import { Vendor } from '@/models';

interface BatchPayRow {
  vendor: Vendor;
  payment_method: string;
  count: number;
  total: number;
}

export default function BatchPay({ ids }: { ids: number[] }) {
  const { data: preview } = useQuery(['batchPay', ids.join('|')], () =>
    axios
      .post<{ data: BatchPayRow[] }>('/api/transactions/batch-pay', {
        ids,
        preview: true,
      })
      .then(({ data }) => data.data),
  );

  return (
    <div style={{ width: 600, maxWidth: '100%' }}>
      {!preview ? (
        <CircularProgress />
      ) : (
        <div>
          <Typography variant="body2">
            A bill payment will be created for each of the following vendors:
          </Typography>

          <TableCardContainer>
            <Table size="small" style={{ margin: '16px 0' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor</TableCell>
                  <TableCell>Method</TableCell>
                  <TableCell># Bills</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {preview.map((row) => (
                  <TableRow key={row.vendor.id}>
                    <TableCell>{row.vendor.name}</TableCell>
                    <TableCell>
                      {_.get(VENDOR_PAYMENT_METHODS, row.payment_method, row.payment_method)}
                    </TableCell>
                    <TableCell>{row.count}</TableCell>
                    <TableCell>{curr(row.total)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCardContainer>
        </div>
      )}
    </div>
  );
}
