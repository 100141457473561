import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import Resource from '../../models/Resource';

export default function ColumnsDialog({
  open,
  onClose,
  resource,
  toggleColumn,
  columns,
}: {
  open: boolean;
  onClose: () => void;
  resource: Resource;
  toggleColumn: (name: string) => void;
  columns: string[];
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="dialog-title">Visible Columns</DialogTitle>

      <DialogContent
        style={{
          width: 600,
          maxWidth: '100%',
        }}
      >
        {resource.columns.map((f) => (
          <div key={f.name}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={columns.includes(f.name)}
                  onChange={() => toggleColumn(f.name)}
                />
              }
              label={f.label || f.name}
            />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
