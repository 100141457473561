import { ReactElement, ReactNode, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import _ from 'lodash';
import { FormSubmitHandler, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import {
  ClosableDrawer,
  EditFormFields,
  Fieldable,
  getDirtyProperties,
  GridLayout,
  transformLaravelErrors,
} from '@/admin';

interface FormDetailsDrawerProps<FormShape> {
  title: string;
  open: boolean;
  onClose: () => void;
  onSave: (values: FormShape) => Promise<void>;
  fields: Fieldable[];
  width?: number;
  children?: ReactNode;
  form?: string;
  initialValues?: Partial<FormShape>;
}

function FormDetailsDrawer<FormShape extends object = Record<string, any>>({
  initialValues,
  submitting,
  handleSubmit,
  initialize,
  destroy,
  title,
  open,
  onClose,
  onSave,
  fields,
  width,
  children,
}: InjectedFormProps<FormShape, FormDetailsDrawerProps<FormShape>> &
  FormDetailsDrawerProps<FormShape>) {
  useEffect(() => {
    if (open) {
      initialize(initialValues);
    } else {
      destroy();
    }
  }, [open]);

  const onSubmit: FormSubmitHandler<FormShape, FormDetailsDrawerProps<FormShape>> = (values) =>
    onSave(getDirtyProperties(values, initialValues, ['id']) as FormShape)
      .then(onClose)
      .catch((e) => {
        const errors = transformLaravelErrors(_.get(e, 'response.data.errors'));
        throw new SubmissionError(errors);
      });

  return (
    <ClosableDrawer title={title} open={open} onClose={onClose} width={width}>
      {children && (
        <Box mb={3} mt={-1}>
          {children}
        </Box>
      )}
      <EditFormFields fields={fields} defaultLayout={GridLayout} />
      <Button
        type="button"
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        disabled={submitting}
        sx={{ mt: 2 }}
      >
        Submit
      </Button>
    </ClosableDrawer>
  );
}

type FormDetailsDrawerType = <T>(p: FormDetailsDrawerProps<T>) => ReactElement;

export default reduxForm<any, any>({})(FormDetailsDrawer) as unknown as FormDetailsDrawerType;
