import { useRef } from 'react';
import { Typography, CardContent, Box, CircularProgress } from '@mui/material';
import { getColor } from '@/components/Shared/DueDateBar';
import { ReportResultsRow, ReportTypeProps } from '@/responses';

function ReportProgressInstance({ row, count }: { row: ReportResultsRow; count: number }) {
  const boxRef = useRef<HTMLDivElement>();
  const percent = typeof row.percent === 'number' ? row.percent : 0;
  const boxHeight = boxRef.current?.offsetHeight || 250;
  return (
    <Box
      position="relative"
      display="inline-flex"
      key={row.id || row.name}
      p={4}
      width={row.width || (count === 1 ? '500px' : '250px')}
      maxWidth="100%"
      ref={boxRef}
      color={getColor(percent / 100)}
    >
      <CircularProgress variant="determinate" value={percent} size="100%" color="inherit" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        p={7}
        position="absolute"
        display="flex"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        overflow="hidden"
      >
        <div style={{ fontSize: `${boxHeight / 14}px` }}>
          {row.name && (
            <Typography
              variant="subtitle2"
              component="div"
              color="textPrimary"
              gutterBottom
              style={{ fontSize: '100%' }}
            >
              {row.name}
            </Typography>
          )}
          <Typography
            variant="body2"
            component="div"
            color="textSecondary"
            style={{ fontSize: '85%' }}
          >
            {row.description || `${Math.round(percent)}%`}
          </Typography>
          {row.total && (
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              style={{ fontSize: '75%' }}
            >
              {`of ${row.total}`}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  );
}

export default function ReportProgress({ results, summary }: ReportTypeProps) {
  return (
    <CardContent>
      {summary && <Typography gutterBottom>{summary}</Typography>}
      {results.map((row) => (
        <ReportProgressInstance
          key={row.id || JSON.stringify(row)}
          row={row}
          count={results.length}
        />
      ))}
    </CardContent>
  );
}
