import { Alert, Button, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { useDialogs } from '@/admin';
import { ArtRequest, OrderDesign } from '@/models';

export default function OrderDesignPurchasing({
  orderDesign,
  status,
  onReload,
}: {
  orderDesign: OrderDesign;
  status: ArtRequest['status'];
  onReload: () => void;
}) {
  const { confirm } = useDialogs();
  const canEdit = !(status === 'completed' || status === 'rejected');

  const doInHouse = () => {
    confirm(
      'Create In House',
      `Are you sure you want to print/cut ${orderDesign.qty_to_purchase} names/numbers in house?`,
    ).then(() => {
      axios
        .post(`/api/order-designs/${orderDesign.id}/purchase`, { drops_in_house: true })
        .then(() => {
          onReload();
        });
    });
  };

  const purchase = () => {
    const productName = _.get(orderDesign.design, 'product.name', 'items');
    confirm(
      'Create Purchase Order',
      `Are you sure you want to create a PO with ${orderDesign.qty_to_purchase} ${productName}?`,
    ).then(() => {
      axios
        .post(`/api/order-designs/${orderDesign.id}/purchase`, { drops_in_house: false })
        .then(() => {
          onReload();
        });
    });
  };

  const undo = () => {
    confirm(
      'Undo',
      'Are you sure you want to undo the purchasing design for these names/numbers?',
      'error',
    ).then(() => {
      axios.post(`/api/order-designs/${orderDesign.id}/undo`).then(() => {
        onReload();
      });
    });
  };

  if (orderDesign.qty_to_purchase == null) {
    return null;
  }

  if (orderDesign.qty_to_purchase === 0) {
    return (
      <TableRow>
        <TableCell colSpan={6}>
          <Alert
            severity="success"
            action={
              <div>
                {!orderDesign.drops_in_house && (
                  <Button
                    size="small"
                    color="inherit"
                    component="a"
                    target="_blank"
                    href={`/purchase-orders/${orderDesign.purchase_order_items?.[0].purchase_order_id}`}
                  >
                    View PO
                  </Button>
                )}
                {canEdit && (
                  <Button size="small" color="inherit" onClick={undo}>
                    Undo
                  </Button>
                )}
              </div>
            }
          >
            This design
            {orderDesign.drops_in_house ? ' is being done in house' : ' has been purchased'}
          </Alert>
        </TableCell>
      </TableRow>
    );
  }

  if (!canEdit) {
    return null;
  }

  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Alert
          severity="error"
          action={
            <div>
              <Button size="small" variant="outlined" color="inherit" onClick={purchase}>
                Purchase From Vendor
              </Button>
              <Button size="small" sx={{ ml: 1 }} color="inherit" onClick={doInHouse}>
                Do In-House
              </Button>
            </div>
          }
        >
          {orderDesign.qty_to_purchase} items need to be purchased for this design
        </Alert>
      </TableCell>
    </TableRow>
  );
}
