import { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { curr, FieldFactory, ReduxField, useSpecificFormValues } from '@/admin';
import { Order, Payment } from '@/models';
import AmountToApplyField from './AmountToApplyField';
import CommissionHit from './CommissionHit';

export default function PaymentApplicationFields({
  paymentType,
  customerId,
  amountToApply,
  form,
}: {
  paymentType: Payment['payment_type'];
  customerId?: number | null;
  amountToApply: number;
  form: string;
}) {
  const [tab, setTab] = useState<'order' | 'account'>('order');
  const dispatch = useDispatch();
  const changeField = (f: string, v: any) => dispatch(change(form, f, v));
  const orderBalance = useSpecificFormValues(form, (v: { order?: Order }) => v.order?.balance);

  useEffect(() => {
    if (tab === 'account') {
      changeField('order', null);
      changeField('commission_hit', null);
    } else {
      changeField('account', null);
    }
  }, [tab]);

  return (
    <div>
      <Tabs value={tab} onChange={(e, t) => setTab(t)} sx={{ mb: 2 }}>
        <Tab value="order" label="Apply to Order" />
        <Tab value="account" label="Credit GL Account" />
      </Tabs>

      {tab === 'account' ? (
        <ReduxField
          key="account_field"
          field={FieldFactory.belongsTo('account', 'accounts').withLabel('Account to Credit')}
        />
      ) : (
        <ReduxField
          key="order_field"
          field={FieldFactory.belongsTo('order', 'orders')
            .withRequestParams(
              _.pickBy({
                has_balance: 1,
                'filter[customer_id]': customerId,
              }),
            )
            .with({
              getSubtitle: (v) => `Balance: ${curr(v.balance)}`,
            })}
        />
      )}

      <ReduxField field={new AmountToApplyField('amount', amountToApply, orderBalance)} />

      {paymentType === 'deduction' && tab === 'order' && (
        <ReduxField field={FieldFactory.custom('commission_hit', CommissionHit)} />
      )}
    </div>
  );
}
