import { SyntheticEvent, useCallback, useState } from 'react';
import {
  Close,
  CloudUpload,
  Description,
  GridOn,
  Image,
  InsertDriveFile,
  Slideshow,
  Upload,
} from '@mui/icons-material';
import {
  alpha,
  Avatar,
  Box,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { WrappedFieldProps } from 'redux-form';
import StaticFormControl from '../../components/Form/StaticFormControl';
import { useAdminConfig } from '../../contexts/AdminConfigContext';
import { FieldProps } from '../../types';
import Field from '../Field';

export const getIconFromFileName = (file: string) => {
  const ext = _.last(file.split('.'))?.toLowerCase() || '';

  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
    case 'gif':
    case 'tif':
      return Image;

    case 'xlsx':
    case 'xls':
    case 'csv':
      return GridOn;

    case 'doc':
    case 'docx':
    case 'txt':
      return Description;

    case 'ppt':
    case 'pptx':
      return Slideshow;

    default:
      return InsertDriveFile;
  }
};

export function FileComponent({ input, field, meta }: FieldProps<FileField>) {
  const { onUpload } = useAdminConfig();
  const [loading, setLoading] = useState<false | number>(false);
  const theme = useTheme();
  const fileUrl = input.value;
  const isImage = field.image;

  const handleProgress = (progress: number) => {
    setLoading(Math.round(progress * 100));
  };

  const onDrop = useCallback((files: FileList | File[]) => {
    setLoading(0);
    onUpload(files, field.uploadType, handleProgress)
      .then((data) => {
        input.onChange(data[0]?.url);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: isImage ? 'image/*' : field.accept,
  });

  const basename = fileUrl && fileUrl.split('/').pop();
  const Icon = fileUrl && getIconFromFileName(fileUrl);

  return (
    <StaticFormControl field={field} meta={meta}>
      {fileUrl && isImage && (
        <CardMedia component="img" height={field.height || 150} image={fileUrl} alt={basename} />
      )}

      {fileUrl && !isImage && (
        <List>
          <ListItemButton dense component="a" target="_blank" href={fileUrl}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText>{basename}</ListItemText>
          </ListItemButton>
        </List>
      )}

      {loading !== false ? (
        <CardContent>
          <LinearProgress variant="determinate" value={loading} />
        </CardContent>
      ) : (
        !fileUrl && (
          <CardContent
            {...getRootProps()}
            style={{
              textAlign: 'center',
              backgroundColor: isDragActive
                ? alpha(theme.palette.primary.main, 0.08)
                : theme.palette.background.paper,
            }}
          >
            <input {...getInputProps()} />
            <CloudUpload fontSize="large" sx={{ color: 'text.secondary' }} />
            <Box mt={2}>
              <Box component="label" fontWeight={500} style={{ cursor: 'pointer' }}>
                Choose a file
              </Box>
              <span> or drag it here.</span>
            </Box>
          </CardContent>
        )
      )}

      {fileUrl && (
        <CardHeader
          title={isImage ? basename : ''}
          titleTypographyProps={{ variant: 'caption' }}
          action={
            <>
              <IconButton component="label" onClick={(e: SyntheticEvent) => e.stopPropagation()}>
                <Upload />
                <input {...getInputProps()} />
              </IconButton>
              <IconButton onClick={() => input.onChange(null)}>
                <Close />
              </IconButton>
            </>
          }
        />
      )}
    </StaticFormControl>
  );
}

export default class FileField extends Field {
  image = false;
  uploadType = 'file';
  height = 150;
  accept?: string;

  renderEditComponent(props: WrappedFieldProps) {
    return <FileComponent {...props} field={this} />;
  }

  renderCell(value: any) {
    if (this.image) {
      return <Avatar src={value} alt={this.label} variant="square" />;
    }
    return value;
  }
}
