import { Avatar, CardContent, Tooltip, Typography, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ProductWithVariants } from '@/models';

const AvatarButton = styled('a')({
  cursor: 'pointer',
  display: 'inline-block',
  marginRight: 16,
  marginBottom: 16,
});

export default function ProductColors({
  styleNumber,
  onSelect,
}: {
  styleNumber: string;
  onSelect: (p: ProductWithVariants) => void;
}) {
  const { data: colors = [] } = useQuery(['productColors', styleNumber], () =>
    axios
      .get<{ data: ProductWithVariants[] }>(
        `/api/products?filter[style_number]=${styleNumber}&with=vendor,brand,tags,variants,images&count=100`,
      )
      .then(({ data }) => data.data),
  );

  if (colors.length > 0) {
    return (
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Other Colors
        </Typography>
        {colors.map((color) => (
          <Tooltip title={color.name} key={color.id}>
            <AvatarButton role="button" href="#" onClick={() => onSelect(color)}>
              <Avatar
                variant="rounded"
                style={{ height: 60, width: 45 }}
                src={`${color.image}?w=200`}
              />
            </AvatarButton>
          </Tooltip>
        ))}
      </CardContent>
    );
  }

  return null;
}
