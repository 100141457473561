import { Checkroom } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { addSearchParams } from '@/admin';

interface HasImage {
  image?: string | null;
}

export function thumbnail(imageUrl: string | null | undefined): string | undefined {
  if (!imageUrl) {
    return undefined;
  }
  return addSearchParams(imageUrl, {
    w: 100,
  });
}

export default function ProductImage({ product }: { product: HasImage }) {
  return (
    <Avatar src={thumbnail(product.image)} variant="square">
      <Checkroom />
    </Avatar>
  );
}
