import { Alert, Button, CardActions, Table, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { StatusChip, useFormValues, useOnReloadRecord } from '@/admin';
import { ArtRequestActivity } from '@/models';
import AttachmentsCard from '../Attachments/AttachmentsCard';
import PartyMessage from '../Notes/PartyMessage';

export default function ArtActivityCompleteForm() {
  const [activityId, completedBy, completedAt, note, type, sewOutRequested] = useFormValues(
    (a: ArtRequestActivity) => [
      a.id,
      a.completed_user?.name,
      a.completed_at,
      a.note,
      a.type,
      a.sew_out_requested,
    ],
  );
  const onReload = useOnReloadRecord();

  const onSubmit = () => {
    axios.post(`/api/art-request-activities/${activityId}/complete`).then(() => {
      onReload();
    });
  };

  return (
    <>
      <PartyMessage message={note} />

      {type === 'sew_out' && sewOutRequested && (
        <Alert severity="info">
          This sew-out will be shown to the sales rep when they are reviewing the final production
          specs before the order is released.
        </Alert>
      )}

      {completedAt ? (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Status</TableCell>
              <TableCell>
                <StatusChip status="Completed" colors={{ Completed: 'green' }} />{' '}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Date</TableCell>
              <TableCell>{moment(completedAt).format('lll')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">User</TableCell>
              <TableCell>{completedBy}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Files</TableCell>
              <TableCell>See Attachments</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <>
          <AttachmentsCard
            resourceId={activityId}
            resource="art-request-activities"
            elevation={0}
            title={null}
          />

          <CardActions>
            <Button type="button" variant="contained" sx={{ mr: 2 }} onClick={onSubmit}>
              Submit
            </Button>
            <p>This will notify the requester.</p>
          </CardActions>
        </>
      )}
    </>
  );
}
