import { FieldFactory, GroupLayout, Resource } from '@/admin';
import { OrderType } from '@/models';
import { AppConfig } from '@/responses';

export default function orderTypes(appConfig: AppConfig) {
  return new Resource<OrderType>('Order Types')
    .setUseDrawer()
    .withStaticOptions(appConfig.orderTypes)
    .getAvatarUsing((v) => (
      <div
        style={{
          height: 32,
          width: 32,
          backgroundColor: v.color || '#ccc',
        }}
      />
    ))
    .getSubtitleUsing(() => null)
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.text('short_name').withHelp('For use in order title'),
      FieldFactory.color('color'),
      FieldFactory.belongsTo('revenue_account', 'accounts').sortable('revenue_account.number'),
      FieldFactory.belongsTo('cogs_account', 'accounts').sortable('cogs_account.number'),
      FieldFactory.percent('decoration_discount').withHelp('To be used for production sample'),
      FieldFactory.percent('avail_split_max').withHelp(
        'The % of COGS that Avail takes will be no more than this number.',
      ),
      FieldFactory.number('min_access_level').withHelp('0 for all, 1 for managers, 10 for admins'),
      FieldFactory.select('is_public', [
        { value: false, label: 'Internal' },
        { value: true, label: 'Public' },
      ]).withHelp('Determines visibility to customer (portal, receivable emails, etc.)'),
      FieldFactory.number('sort').withHelp('Higher numbers will show up first').sortable(),
      FieldFactory.boolean('is_blank', 'Blanks Only (Art disabled for orders)'),
      FieldFactory.boolean('is_webstore', 'Webstore Order Type'),
      FieldFactory.boolean('has_children', 'Has Children'),
      new GroupLayout('Item Behavior Defaults', [
        FieldFactory.boolean('default_can_apply_designs', 'Can Apply Designs'),
        FieldFactory.boolean('default_can_drop_ship', 'Can Drop Ship'),
        FieldFactory.boolean('default_is_purchasable', 'Purchasable'),
        FieldFactory.boolean('default_show_on_invoice', 'Show On Invoice'),
        FieldFactory.boolean('default_is_shippable', 'Shippable'),
      ]),
    ]);
}
