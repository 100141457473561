import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ThirdPartyShippingAccount } from '@/models';

export default function useThirdPartyAccountsForCustomer(
  customerId: number | undefined | boolean,
): ThirdPartyShippingAccount[] {
  return (
    useQuery(
      ['thirdPartyShippingAccounts', customerId],
      () =>
        axios
          .get<{ data: ThirdPartyShippingAccount[] }>(`/api/third-party-shipping-accounts`, {
            params: { customer_id: customerId, count: 100 },
          })
          .then(({ data }) => data.data),
      { enabled: Boolean(customerId) },
    ).data || []
  );
}
