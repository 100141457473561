import { ChangeEvent } from 'react';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import {
  DrawerFormSection,
  FieldFactory,
  ReduxField,
  useFormActions,
  useOnChangeEffect,
  useSpecificFormValues,
} from '@/admin';
import ProductField from '@/components/Products/ProductField';
import { Product } from '@/models';

export default function ProductSection({
  isCustom,
  handleCustomChange,
}: {
  isCustom: boolean;
  handleCustomChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
  const { change } = useFormActions('addItemWizardForm');
  const product = useSpecificFormValues(
    'addItemWizardForm',
    (o: { product: Product | null }) => o.product,
  );

  useOnChangeEffect(() => {
    if (product) {
      change('description', product.name);
    }
  }, [product]);

  return (
    <DrawerFormSection title="Product">
      <ReduxField field={FieldFactory.text('description').setRequired().withSize('medium')} />

      <RadioGroup
        row
        value={isCustom ? 'custom' : 'database'}
        sx={{ my: 1 }}
        onChange={handleCustomChange}
      >
        <FormControlLabel value="database" control={<Radio />} label="Database" />
        <FormControlLabel value="custom" control={<Radio />} label="Custom" />
      </RadioGroup>

      {isCustom ? (
        <Stack>
          <ReduxField field={FieldFactory.text('number')} />
          <ReduxField field={FieldFactory.text('color')} />
        </Stack>
      ) : (
        <ReduxField
          field={new ProductField('product').with({
            requestParams: { with: 'variants,images' },
          })}
        />
      )}
    </DrawerFormSection>
  );
}
