import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  CardContent,
  Alert,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Joi from 'joi';
import _ from 'lodash';
import { useDialogs, useFormValues, getApiUrl, FieldFactory } from '@/admin';
import BrandGuidelinesCard from '@/components/Customers/BrandGuidelinesCard';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import LoadingBackdrop from '@/components/Shared/LoadingBackdrop';
import {
  ArtRequest,
  Design,
  DesignLayoutWithProofs,
  OrderDesign,
  Proof,
  ProofType,
} from '@/models';
import useMutateQueryData from '@/utils/useMutateQueryData';
import AttachmentsCard from '../Attachments/AttachmentsCard';
import EventableCard from '../Notes/EventableCard';
import NoteAlert from '../Notes/NoteAlert';
import NoteViewer from '../Notes/NoteViewer';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import ArtRequestActivityDrawer from './ArtRequestActivityDrawer';
import ArtRequestDesignDrawer from './ArtRequestDesignDrawer';
import ArtRequestFields from './ArtRequestFields';
import DesignProofs from './DesignProofs';
import InkChangeField from './InkChangeField';
import OrderDesignListItem from './OrderDesignListItem';
import OrderDesignPurchasing from './OrderDesignPurchasing';

export default function ArtRequestEditor() {
  const [note, order, id, type, title, createdBy, createdAt, status] = useFormValues(
    (ar: ArtRequest) => [
      ar.note,
      ar.order,
      ar.id,
      ar.type,
      ar.label,
      ar.created_by_user,
      ar.created_at,
      ar.status,
    ],
  );

  const [editing, setEditing] = useState<Design>();
  const [showArtActivities, setShowArtActivities] = useState<Design>();
  const { prompt } = useDialogs();
  const orderId = order.id;

  const QUERY_KEY = ['artDesignLayouts', orderId];
  const setDesignLayouts = useMutateQueryData<DesignLayoutWithProofs[]>(QUERY_KEY);
  const {
    data: designLayouts = [],
    isFetching,
    refetch,
  } = useQuery(QUERY_KEY, () =>
    axios
      .get<{ data: DesignLayoutWithProofs[] }>(
        `/api/design-layouts?with=order_designs,production_proofs,customer_proofs&order_id=${orderId}&count=100`,
      )
      .then(({ data }) => data.data),
  );

  // Push these changes up, so the appbar actions know
  useEffect(() => {
    if (designLayouts) {
      window.artRequestMeta = {
        _customerProofCount: designLayouts.map((d) => d.customer_proofs.length),
        _productionProofCount: designLayouts.map((d) => d.production_proofs.length),
        _completedAts: _.chain(designLayouts)
          .map('order_designs')
          .flatten()
          .filter()
          .map('design.completed_at')
          .value(),
      };
    }
  }, [designLayouts]);

  const onUpdateProofs = (
    layout: DesignLayoutWithProofs,
    proofType: ProofType,
    func: (prevProofs: Proof[]) => Proof[],
  ) => {
    const proofKey = `${proofType}_proofs` as 'production_proofs' | 'customer_proofs';
    setDesignLayouts((prevLayouts) =>
      prevLayouts.map((pl) => {
        if (pl.id === layout.id) {
          return {
            ...pl,
            [proofKey]: func(pl[proofKey]),
          };
        }
        return pl;
      }),
    );
  };

  const onEditOrderDesign = (orderDesign: OrderDesign) => {
    prompt({
      title: 'Edit Linked Design',
      fields: [
        FieldFactory.belongsTo('design', 'designs'),
        FieldFactory.custom('ink_changes', InkChangeField).withPermission('write:designs'),
      ],
      initialValues: _.pick(orderDesign, ['design', 'ink_changes']),
      validation: Joi.object({ design: Joi.any().required() }).unknown(),
      onSubmit: (v) => axios.put(`/api/order-designs/${orderDesign.id}`, v),
    }).then(() => {
      refetch();
    });
  };

  const onUncomplete = (orderDesign: OrderDesign) => {
    axios
      .post(`/api/designs/${orderDesign.design.id}/uncomplete`, {
        uncompleted_via: id,
      })
      .then(() => refetch());
  };

  const renderNameNumber = (layout: DesignLayoutWithProofs, orderDesign: OrderDesign) => {
    const hasDrops = orderDesign?.design?.decoration_type?.has_drops || false;
    return (
      <>
        <OrderDesignPurchasing orderDesign={orderDesign} status={status} onReload={refetch} />
        {hasDrops && (
          <TableRow>
            <TableCell colSpan={3} sx={{ borderBottom: 0 }}>
              <Typography variant="subtitle2">Name/Number Drop</Typography>
              <Typography variant="body2" gutterBottom>
                If this design requires files specific to the name/number list, please attach here.
              </Typography>
              <a href={getApiUrl(`/api/design-layouts/${orderDesign.design_layout_id}/roster`)}>
                Roster.xlsx
              </a>
            </TableCell>
            <TableCell colSpan={3} sx={{ borderBottom: 0 }}>
              <AttachmentsCard
                resource="order-designs"
                resourceId={orderDesign.id}
                title={null}
                compact
              />
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={9}>
          {note && (
            <NoteAlert
              note={{
                // @ts-ignore
                created_by_user: createdBy || { name: 'From Customer' },
                note,
                created_at: createdAt,
                attachments: [],
                reactions: [],
              }}
            />
          )}
          <NoteViewer resource="orders" resourceId={order.id} tag="art" />

          {designLayouts.map((layout) => (
            <CardWithGutter key={layout.id}>
              <CardHeader
                title={layout.increment_id}
                subheader={
                  <>
                    <span>{layout.name}</span>
                    {layout.subcontractor_id && <span> &#40;Subcontracted&#41;</span>}
                  </>
                }
              />

              {layout.sew_out_requested && (
                <CardContent sx={{ py: 1 }}>
                  <Alert severity="info">
                    The sew-out for designs in this layout will be shown to the sales rep when they
                    are reviewing the final production specs before the order is released.
                  </Alert>
                </CardContent>
              )}

              <TableContainer>
                <Table>
                  {layout.order_designs.map((orderDesign) => (
                    <TableBody key={orderDesign.id} style={{ borderBottom: '1px solid #ccc' }}>
                      <OrderDesignListItem
                        orderDesign={orderDesign}
                        onEdit={() => onEditOrderDesign(orderDesign)}
                        onEditDesign={() => setEditing(orderDesign.design)}
                        onShowArtActivities={() => setShowArtActivities(orderDesign.design)}
                        onUncomplete={_.partial(onUncomplete, orderDesign)}
                      />
                      {type === 'release' && renderNameNumber(layout, orderDesign)}
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Customer Proofs
                </Typography>
                <DesignProofs
                  layout={layout}
                  type="customer"
                  onUpdateProofs={type === 'proof' ? onUpdateProofs : undefined}
                />
              </CardContent>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  Production Proofs
                </Typography>
                <DesignProofs
                  layout={layout}
                  type="production"
                  onUpdateProofs={type === 'release' ? onUpdateProofs : undefined}
                />
              </CardContent>
            </CardWithGutter>
          ))}
        </Grid>
        <Grid item xs={12} lg={3}>
          <CardWithGutter>
            <CardHeader title={title} />
            <ArtRequestFields />
          </CardWithGutter>
          <BrandGuidelinesCard customerId={order.customer.id} />
          <OrderSummaryCard order={order} />
          <EventableCard resource="art-requests" resourceId={id} size="small" />
        </Grid>
      </Grid>

      <ArtRequestDesignDrawer
        open={!!editing}
        onClose={() => setEditing(undefined)}
        design={editing || {}}
        onSave={() => refetch()}
        artRequestId={id}
      />

      <ArtRequestActivityDrawer
        open={!!showArtActivities}
        onClose={() => setShowArtActivities(undefined)}
        design={showArtActivities}
        artRequestId={id}
      />

      <LoadingBackdrop loading={isFetching} />
    </div>
  );
}
