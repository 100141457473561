import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { curr } from '@/admin';
import { InboundShipment } from '@/models';

export default function PurchaseOrderInboundShipmentsCard({ poId }: { poId: number }) {
  const { data: inboundShipments, isLoading } = useQuery(['poInboundShipments', poId], () =>
    axios
      .get<{ inbound_shipments: InboundShipment[] }>(
        `/api/purchase-orders/${poId}?with=inbound_shipments`,
      )
      .then(({ data }) => data.inbound_shipments),
  );

  const getInner = () => {
    if (isLoading || !inboundShipments) {
      return (
        <CardContent>
          <CircularProgress />
        </CardContent>
      );
    }

    if (inboundShipments.length === 0) {
      return (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There are no inbound shipments linked to this PO.
          </Typography>
        </CardContent>
      );
    }

    return (
      <List>
        {inboundShipments.map((s) => (
          <ListItemButton key={s.id} component={Link} to={`/inbound-shipments/${s.id}`}>
            <ListItemText primary={`Inbound Shipment #${s.id}`} secondary={curr(s.cost)} />
          </ListItemButton>
        ))}
      </List>
    );
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader title="Inbound Shipments" />
      {getInner()}
    </Card>
  );
}
