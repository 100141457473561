import { TableRow, TableCell, Typography, TableBody } from '@mui/material';
import Resource from '../../models/Resource';
import { useDataTable } from './DataTableContext';

export default function EmptyState({ resource }: { resource: Resource }) {
  const { visibleColumns, showCheckbox } = useDataTable();
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={visibleColumns.length + Number(showCheckbox)}>
          <Typography variant="body1" color="textSecondary">
            There were no {resource.name.toLowerCase()} found.
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
