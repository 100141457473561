import { Box, Card } from '@mui/material';
import _ from 'lodash';
import { FieldProps, useFormValues, wrap } from '@/admin';
import { Order, PurchaseOrder, PurchaseOrderItem } from '@/models';
import NoteViewer from '../Notes/NoteViewer';
import ReceivePurchaseOrder from './ReceivePurchaseOrder';

export default function ReceivePurchaseOrderField({ input }: FieldProps) {
  const items = wrap(input.value) as PurchaseOrderItem[];

  const [id, customerId] = useFormValues((f: Order | PurchaseOrder) => [
    f.id,
    'customer_id' in f ? f.customer_id : undefined,
  ]);
  const orderIds = _.chain(items).map('order_id').filter().uniq().value();
  const purchaseOrderIds = _.chain(items).map('purchase_order_id').filter().uniq().value();

  const type = customerId ? 'order' : 'purchase_order';

  return (
    <>
      {orderIds.map((orderId) => (
        <NoteViewer key={orderId} tag="receiving" resource="orders" resourceId={orderId} />
      ))}
      {purchaseOrderIds.map((poId) => (
        <NoteViewer key={poId} tag="receiving" resource="purchase-orders" resourceId={poId} />
      ))}

      <Card sx={{ mb: 2 }}>
        <Box mb={2}>
          <ReceivePurchaseOrder
            title="To Receive"
            items={_.reject(items, 'is_received')}
            toReceive
            type={type}
            id={id}
          />
        </Box>
        <ReceivePurchaseOrder title="Received" items={items.filter((i) => i.is_received)} />
      </Card>
    </>
  );
}
