import { SyntheticEvent } from 'react';
import { ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { Order } from '@/models';
import OrderStatus from './OrderStatus';

export default function OrderListItem({
  order,
  push,
}: {
  order: Order;
  push?: (s: string) => void;
}) {
  const props = push
    ? {
        component: 'a',
        href: `/orders/${order.id}`,
        onClick: (e: SyntheticEvent) => {
          e.preventDefault();
          push(`/orders/${order.id}`);
        },
      }
    : {
        component: Link,
        to: `/orders/${order.id}`,
      };
  return (
    <ListItemButton {...props}>
      <ListItemText primary={order.increment_id} secondary={order.description} />
      <ListItemSecondaryAction>
        <OrderStatus status={order.status} size="small" />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
}
