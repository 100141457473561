import { FieldFactory, Resource } from '@/admin';
import { ART_REQUEST_ACTIVITY_TYPES } from '@/constants';
import { requiredFields } from '@/helpers';
import { ArtCostItem } from '@/models';
import { AppConfig } from '@/responses';

export default function artCostItems(appConfig: AppConfig) {
  return new Resource<ArtCostItem>('Art Cost Items')
    .setUseDrawer()
    .withStaticOptions(appConfig.artCostItems)
    .withValidation(requiredFields(['name', 'cost']))
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.curr('cost'),
      FieldFactory.select('add_after_activity', ART_REQUEST_ACTIVITY_TYPES),
      FieldFactory.boolean('rebate_lob', 'Rebate this cost back to LOB'),
    ]);
}
