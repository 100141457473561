import { Avatar, Tooltip, Typography } from '@mui/material';
import { TextLink, useHasPermission } from '@/admin';
import { InventoryLocation } from '@/models';

export default function InventoryLocationLabel({
  location,
}: {
  location: InventoryLocation | null;
}) {
  const hasPermission = useHasPermission();

  if (!location) {
    return null;
  }

  const { location_group: group } = location;
  return (
    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
      {group && (
        <Tooltip title={`Location Group: ${group.name}`}>
          <Avatar sx={{ height: 24, width: 24, mr: 1, fontSize: 16 }}>{group.abbr}</Avatar>
        </Tooltip>
      )}
      <TextLink
        disabled={!hasPermission('write:inventory_locations')}
        to={`/inventory-locations/${location.id}`}
      >
        {location.path}
      </TextLink>
    </Typography>
  );
}
