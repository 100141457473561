import { useState } from 'react';
import { Edit, Settings } from '@mui/icons-material';
import {
  IconButton,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardHeader,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { Can, curr, Field, FieldFactory, Paper } from '@/admin';
import CardWithGutter from '@/components/Shared/CardWithGutter';
import FormDetailsDrawer from '@/components/Shared/FormDetailsDrawer';
import Label from '@/components/Shared/Label';
import { OrderTiming as TimingModel } from '@/models';

function EstimateLabel({ is }: { is?: boolean }) {
  return is ? <Label>Estimate</Label> : null;
}

const formatDate = (d: string | null) => (d ? moment(d).format('l') : null);

export default function OrderTiming({ orderId, disabled }: { orderId: number; disabled: boolean }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isOverriding, setIsOverriding] = useState(false);
  const queryClient = useQueryClient();

  const { data: timing, isLoading } = useQuery(['orders', orderId, 'timing'], () =>
    axios.get<TimingModel>(`/api/orders/${orderId}/timing`).then(({ data }) => data),
  );

  const onUpdate = (values: TimingModel) =>
    axios.put(`/api/orders/${orderId}/timing`, values).then(({ data }) => {
      queryClient.setQueryData<TimingModel>(['orders', orderId, 'timing'], data);
    });

  const actions = disabled ? null : (
    <Can permission="write:orders">
      <IconButton onClick={() => setIsEditing(true)} size="large">
        <Edit />
      </IconButton>
      {timing?.is_estimate === false && (
        <Can permission="production_events:admin">
          <IconButton onClick={() => setIsOverriding(true)} size="large">
            <Settings />
          </IconButton>
        </Can>
      )}
    </Can>
  );

  if (isLoading) {
    return (
      <Paper title="Timing">
        <CircularProgress />
      </Paper>
    );
  }

  if (!timing) return null;

  const fields = _.filter([
    FieldFactory.date('earliest_ship_date')
      .withHelp(
        'Setting this will ensure we do not ship the order before this date. It will also allow us to prioritize other orders ahead of this one.',
      )
      .withColumnSpan(12),

    FieldFactory.date('drop_dead_date')
      .withHelp(
        'Moving this up will prioritize this order.  However, if this date is before our normal turnaround time, you will be charged rush fees.',
      )
      .withColumnSpan(12),

    FieldFactory.date('expected_received_at')
      .withLabel('Expected Received Date')
      .withHelp(
        'Use this date to estimate the committed date and rush fees before all items are received.',
      )
      .setDisabled(!!timing.received_at)
      .withColumnSpan(12),

    timing.has_art &&
      FieldFactory.date('expected_released_at')
        .withLabel('Expected Release Date')
        .withHelp(
          'Use this date to estimate the committed date and rush fees before the art is approved & released.',
        )
        .setDisabled(!!timing.released_at)
        .withColumnSpan(12),
  ]) as Field[];

  return (
    <CardWithGutter>
      <CardHeader title="Timing" action={actions} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Earliest Ship Date</TableCell>
            <TableCell>{formatDate(timing.earliest_ship_date)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Drop Dead Date</TableCell>
            <TableCell>{formatDate(timing.drop_dead_date)}</TableCell>
          </TableRow>

          {timing.received_date && (
            <TableRow>
              <TableCell variant="head">
                Received Date <EstimateLabel is={!timing.received_at} />
              </TableCell>
              <TableCell>{formatDate(timing.received_date)}</TableCell>
            </TableRow>
          )}

          {timing.has_art && timing.released_date && (
            <TableRow>
              <TableCell variant="head">
                Released Date <EstimateLabel is={!timing.released_at} />
              </TableCell>
              <TableCell>{formatDate(timing.released_date)}</TableCell>
            </TableRow>
          )}

          {timing.standard_ship_date && (
            <TableRow>
              <TableCell variant="head">
                Standard Ship Date <EstimateLabel is={timing.is_estimate} />
              </TableCell>
              <TableCell>{formatDate(timing.standard_ship_date)}</TableCell>
            </TableRow>
          )}

          {timing.committed_ship_date && (
            <TableRow>
              <TableCell variant="head">
                Committed Ship Date <EstimateLabel is={timing.is_estimate} />
              </TableCell>
              <TableCell>{formatDate(timing.committed_ship_date)}</TableCell>
            </TableRow>
          )}

          {timing.rush_fees > 0 && (
            <TableRow>
              <TableCell variant="head">{timing.rush_days}-Day Rush Fees</TableCell>
              <TableCell>{curr(timing.rush_fees)}</TableCell>
            </TableRow>
          )}

          {timing.scheduled_at.length > 0 && (
            <Can permission="write:production_events">
              <TableRow>
                <TableCell variant="head">Scheduled For</TableCell>
                <TableCell>{timing.scheduled_at.map((d) => formatDate(d)).join(', ')}</TableCell>
              </TableRow>
            </Can>
          )}
        </TableBody>
      </Table>

      <FormDetailsDrawer<TimingModel>
        fields={fields}
        form="OrderTimingForm"
        initialValues={_.pick(timing, [
          'earliest_ship_date',
          'drop_dead_date',
          'expected_received_at',
          'expected_released_at',
        ])}
        title="Edit Order Dates"
        open={isEditing}
        onClose={() => setIsEditing(false)}
        onSave={onUpdate}
      />

      <FormDetailsDrawer<TimingModel>
        fields={[
          FieldFactory.date('received_at').withLabel('Actual Received Date').withColumnSpan(12),
          FieldFactory.date('released_at').withLabel('Actual Release Date').withColumnSpan(12),
        ]}
        form="OrderDatesForm"
        initialValues={_.pick(timing, ['received_at', 'released_at'])}
        title="Override Order Dates"
        open={isOverriding}
        onClose={() => setIsOverriding(false)}
        onSave={onUpdate}
      >
        Only production admins have access to this. Use these fields to adjust the committed date
        (and therefore rush fees) for orders that have the incorrect received or released dates.
      </FormDetailsDrawer>
    </CardWithGutter>
  );
}
