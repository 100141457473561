import { Resource } from '@/admin';
import { Account } from '@/models';

export default function bankAccounts() {
  return new Resource<Account>('Accounts').withQueryParams({
    index: {
      'filter[type][in]': 'bank,credit_card',
    },
    single: {},
  });
}
