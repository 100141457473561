import { SyntheticEvent, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  colors,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import _ from 'lodash';
import { FieldFactory, StatusChip, useDialogs } from '@/admin';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import PurchaseOrderItemsTable from '@/components/Purchasing/PurchaseOrderItemsTable';
import { ISSUE_STATUS_COLORS } from '@/constants';
import { Issue } from '@/models';
import EventableCard from '../Notes/EventableCard';

const StatusButton = styled('button')({
  borderRadius: 20,
  color: 'white',
  paddingLeft: 16,
  paddingRight: 16,
  whiteSpace: 'nowrap',
  border: 'none',
  paddingTop: 10,
  paddingBottom: 10,
});

export default function IssueDetails({
  issue,
  onUpdate,
  size,
}: {
  issue: Issue;
  onUpdate: (i: Issue) => void;
  size?: 'small' | 'medium';
}) {
  const { prompt } = useDialogs();
  const [anchorEl, setAnchorEl] = useState<Element>();
  const [loading, setLoading] = useState(false);

  const onResolve = () => {
    setAnchorEl(undefined);
    prompt({
      title: 'Resolve Issue',
      description: 'Please describe how the issue was resolved?',
      fields: [FieldFactory.textarea('resolution')],
      onSubmit: (v) => axios.post(`/api/issues/${issue.id}/resolve`, v),
    }).then(({ data }) => {
      onUpdate(data);
    });
  };

  const onChangeStatus = (status: Issue['status']) => {
    setAnchorEl(undefined);
    setLoading(true);
    axios
      .post(`/api/issues/${issue.id}/status`, { status })
      .then(({ data }) => {
        onUpdate(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getInner = () => {
    if (loading) {
      return <CircularProgress />;
    }

    if (issue.type === 'purchasing') {
      if (!issue.resolved_at) {
        return (
          <Button variant="contained" onClick={onResolve}>
            Resolve
          </Button>
        );
      }
      return <StatusChip status={issue.status} colors={ISSUE_STATUS_COLORS} />;
    }
    return (
      <div>
        <StatusButton
          style={{
            // @ts-ignore
            background: colors[ISSUE_STATUS_COLORS[issue.status]][500],
          }}
          onClick={(e: SyntheticEvent) => setAnchorEl(e.currentTarget)}
        >
          {_.startCase(issue.status)}
        </StatusButton>
        <Menu
          id="status-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(undefined)}
        >
          {Object.keys(_.omit(ISSUE_STATUS_COLORS, 'resolved')).map((k) => (
            <MenuItem
              key={k}
              onClick={() => onChangeStatus(k as Issue['status'])}
              selected={k === issue.status}
              disabled={k === issue.status}
            >
              {_.startCase(k)}
            </MenuItem>
          ))}
          <MenuItem
            onClick={onResolve}
            selected={issue.status === 'resolved'}
            disabled={issue.status === 'resolved'}
          >
            Resolved
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <>
      <DialogTitle>
        <Box display="flex">
          <Typography variant="h5">{issue.label}</Typography>

          <Box display="flex" ml="auto" pl={1}>
            {getInner()}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="subtitle2" gutterBottom>
            Description
          </Typography>
          <Typography style={{ whiteSpace: 'pre-wrap' }}>{issue.details}</Typography>
        </Box>

        {issue.order_items && issue.order_items.length > 0 ? (
          <Box mb={3}>
            <Typography variant="subtitle2" gutterBottom>
              Affected Order Items
            </Typography>
            <Table size="small">
              <TableBody>
                {issue.order_items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <OrderItemProduct item={item} showSize />
                    </TableCell>
                    <TableCell>Qty: {item.qty}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        ) : null}

        {issue.purchase_order_items && issue.purchase_order_items.length > 0 ? (
          <Box mb={3}>
            <Typography variant="subtitle2" gutterBottom>
              Affected PO Items
            </Typography>
            <PurchaseOrderItemsTable items={issue.purchase_order_items} />
          </Box>
        ) : null}
      </DialogContent>
      <Box px={1}>
        <EventableCard resource="issues" resourceId={issue.id} size={size} elevation={0} />
      </Box>
    </>
  );
}
