import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { change, destroy, initialize, reset, submit } from 'redux-form';
import { useMaybeCurrentForm } from '../utils/genericResource';

export interface FormActions {
  change: (field: string, value: any) => void;
  silentChange: (field: string | Record<string, any>, value?: any) => void;
  initialize: (data: object) => void;
  reset: () => void;
  submit: () => void;
  destroy: () => void;
}

export const silentChanged = (form: string, field: string | object, value?: any) => ({
  type: 'FORM_SILENT_CHANGE',
  form,
  field,
  value,
});

export default function useFormActions(formName?: string): FormActions {
  const dispatch = useDispatch();
  const currentForm = useMaybeCurrentForm();
  const form = formName || currentForm || '';

  return useMemo(
    () => ({
      change: (field: string, value: any) => dispatch(change(form, field, value)),
      silentChange: (field: string | object, value?: any) =>
        dispatch(silentChanged(form, field, value)),
      initialize: (values: object) => dispatch(initialize(form, values)),
      reset: () => dispatch(reset(form)),
      submit: () => dispatch(submit(form)),
      destroy: () => dispatch(destroy(form)),
    }),
    [dispatch, form],
  );
}
