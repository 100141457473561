import { ReactNode } from 'react';
import { useHasPermission, useHasRole } from '../../hooks/permissions';

type RoleOrPermission =
  | { role: string; permission?: undefined }
  | { role?: undefined; permission: string };

type CanProps = {
  children: ReactNode;
} & RoleOrPermission;

export default function Can({ permission, role, children }: CanProps) {
  const hasPermission = useHasPermission();
  const hasRole = useHasRole();

  if (permission && !hasPermission(permission)) {
    return null;
  }
  if (role && !hasRole(role)) {
    return null;
  }
  return <>{children}</>;
}
