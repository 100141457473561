import { Radio, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { WrappedFieldProps } from 'redux-form';
import { Field, FieldProps } from '@/admin';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import { OrderKitItem } from '@/models';

function ChooseKitItems({ input, field }: FieldProps<ChooseKitItemsField>) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell>Order #</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Alloc</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {field.items.map((i) => (
          <TableRow key={i.id}>
            <TableCell padding="checkbox">
              <Radio checked={input.value === i.id} onChange={() => input.onChange(i.id)} />
            </TableCell>
            <TableCell>{i.order_id}</TableCell>
            <TableCell>
              <OrderItemProduct item={i} showSize />
            </TableCell>
            <TableCell>
              {i.qty_assigned} / {i.qty}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default class ChooseKitItemsField extends Field {
  items: OrderKitItem[];

  constructor(name: string, items: OrderKitItem[]) {
    super(name);
    this.items = items;
  }

  renderEditComponent(props: WrappedFieldProps): React.ReactElement | null {
    return <ChooseKitItems {...props} field={this} />;
  }
}
