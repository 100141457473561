import { useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { ClosableDrawer, useDialogs } from '@/admin';
import ImprintsForm from '@/components/Orders/Art/ImprintsForm';
import { OrderDesign, OrderItem } from '@/models';

export default function ImprintsDrawer({
  orderDesign,
  open,
  onClose,
  onSave: onSaveProp,
}: {
  open: boolean;
  onClose: () => void;
  orderDesign?: OrderDesign;
  onSave?: () => void;
}) {
  const [submitting, setSubmitting] = useState(false);
  const { confirm } = useDialogs();

  const onSave =
    onSaveProp && orderDesign
      ? async (items: OrderItem[]) => {
          const payload = _.chain(items)
            .keyBy('id')
            .pickBy((i) => i.order_design_ids[orderDesign.id])
            .mapValues((i) => i.order_design_ids[orderDesign.id])
            .value();

          setSubmitting(true);

          try {
            if (Object.keys(payload).length > 4) {
              await confirm(
                'Multiple Sizes',
                `
          You are applying this design to more than 4 sizes. 
          Your order might require more than one size of this logo to ensure the image properly fits on the garment.
          `,
                'warning',
                { submitText: 'OK', cancelText: 'Cancel' },
              );
            }
            await axios.post(`/api/order-designs/${orderDesign.id}/items`, { items: payload });

            onSaveProp();
            onClose();
          } finally {
            setSubmitting(false);
          }
        }
      : undefined;

  return (
    <ClosableDrawer
      title={`Choose Items For ${orderDesign?.design.increment_id}`}
      open={open}
      onClose={onClose}
      width={750}
      closeOnClickOutside={false}
    >
      {orderDesign && (
        <ImprintsForm orderDesign={orderDesign} onSave={onSave} submitting={submitting} />
      )}
    </ClosableDrawer>
  );
}
