export const maybeAddQueryParams = (
  value: string | undefined | null,
  params = {},
): string | undefined => {
  if (!value) {
    return undefined;
  }
  if (value.startsWith('data:') || !value.includes('files.availerp.com')) {
    return value;
  }
  try {
    const url = new URL(value);
    if (!url.search) {
      url.search = `?${new URLSearchParams(params).toString()}`;
    }
    return url.toString();
  } catch (e) {
    return value;
  }
};
