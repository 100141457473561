import { shallowEqual, useSelector } from 'react-redux';
import { useCurrentForm } from '../utils/genericResource';

type StateWithForm<T extends Record<string, any>> = {
  form: Record<
    string,
    {
      values: T;
      initial: T;
    }
  >;
};

export function useSpecificFormValues<FormShape extends Record<string, any>, Return>(
  formName: string,
  getter: (f: FormShape) => Return,
): Return {
  return useSelector<StateWithForm<FormShape>, Return>(
    (s) => getter(s.form[formName]?.values || {}),
    shallowEqual,
  );
}

export function useFormValues<FormShape extends Record<string, any>, Return>(
  getter: (f: FormShape) => Return,
): Return {
  return useSpecificFormValues<FormShape, Return>(useCurrentForm(), getter);
}

export function useSpecificFormInitialValues<FormShape extends Record<string, any>, Return>(
  formName: string,
  getter: (f: FormShape) => Return,
): Return {
  return useSelector<StateWithForm<FormShape>, Return>(
    (s) => getter(s.form[formName]?.initial || {}),
    shallowEqual,
  );
}

export function useFormInitialValues<FormShape extends Record<string, any>, Return>(
  getter: (f: FormShape | undefined) => Return,
): Return {
  return useSpecificFormInitialValues<FormShape, Return>(useCurrentForm(), getter);
}

export function useSpecificFormValuesMaybe<FormShape extends Record<string, any>, Return>(
  formName: string,
  getter: (f: FormShape | undefined) => Return,
): Return {
  return useSelector<StateWithForm<FormShape>, Return>(
    (s) => getter(formName in s.form ? s.form[formName].values : undefined),
    shallowEqual,
  );
}

export function useFormValuesMaybe<FormShape extends Record<string, any>, Return>(
  getter: (f: FormShape | undefined) => Return,
): Return {
  return useSpecificFormValuesMaybe<FormShape, Return>(useCurrentForm(), getter);
}

export function useSpecificFormInitialValuesMaybe<FormShape extends Record<string, any>, Return>(
  formName: string,
  getter: (f: FormShape | undefined) => Return,
): Return {
  return useSelector<StateWithForm<FormShape>, Return>(
    (s) => getter(formName in s.form ? s.form[formName].initial : undefined),
    shallowEqual,
  );
}

export function useFormInitialValuesMaybe<FormShape extends Record<string, any>, Return>(
  getter: (f: FormShape | undefined) => Return,
): Return {
  return useSpecificFormInitialValuesMaybe<FormShape, Return>(useCurrentForm(), getter);
}
