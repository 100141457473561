import { useFormValues } from '@/admin';
import OrderSummaryCard from '@/components/Orders/OrderSummaryCard';
import { Order } from '@/models';

export default function OrderSummaryField() {
  const order = useFormValues((o) => {
    if ('order' in o) {
      return o.order as Order;
    }
    return o as Order;
  });
  if (!order) {
    return null;
  }
  return <OrderSummaryCard order={order} />;
}
