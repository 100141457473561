import { SyntheticEvent } from 'react';
import axios from 'axios';
import { useDialogs, TextButton, FieldFactory } from '@/admin';
import { ProductionEvent } from '@/models';

export default function ScreenLocation({
  event,
  onSuccess,
}: {
  event: ProductionEvent;
  onSuccess: () => void;
}) {
  const { confirm, prompt } = useDialogs();

  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    let promise;
    if (!event.screen_location) {
      promise = prompt({
        title: 'Burn Screen',
        description: 'What location was the screen placed in?',
        fields: [FieldFactory.text('screen_location').withSize('medium')],
        onSubmit: (v) => axios.post(`/api/production-events/${event.id}/burn`, v),
      });
    } else {
      promise = confirm(
        'Unburn Screen',
        'Are you sure you want to undo the burning of this screen?',
      ).then(() => axios.post(`/api/production-events/${event.id}/burn`));
    }
    promise.then(onSuccess);
  };

  return <TextButton onClick={onClick}>{event.screen_location || 'Not Burned'}</TextButton>;
}
